import React from 'react'
import BasicTable from 'components/BasicTable'
import Modal from 'components/Modal'
import Button from 'components/form/Button'
import Select from 'components/form/Select'

// dados
import { ofences, infractions, users } from 'data/dashboard'

const atualizar = () => {
    console.log('Atualizou!')
}

export default function Escalation () {
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable
                        title='Escalações Recentes'
                        headers={ infractions.headers }
                        items={ infractions.items }
                        slotActions={ (
                            <>
                                <Select items={ ofences } />
                                <Select items={ users } />
                                <Button text="Atualizar" onClick={ atualizar } color="cyan" />
                            </>
                        ) }
                    />
                </div>
            </div>

            <Modal isOpen={ false } />
        </>
    )
}
