import React from 'react'

const Card = ({ children }) => {

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg bg-white'>
            { children }
        </div>
    )
}

export default Card
