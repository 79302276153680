import Avatars from "Pages/Users/Avatars";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAvatarsFilters } from "store/modules/users/avatars/avatarsSlice";

export function AvatarsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId");
    const avatarId = searchParams.get("avatarId");
    const avatarOnline = searchParams.get("avatarOnline");
    const avatarFirstName = searchParams.get("avatarFirstName");
    const avatarLastName = searchParams.get("avatarLastName");
    const avatarNameInstance  = searchParams.get("avatarNameInstance");
    

    const dispatch = useDispatch()

    if (userId || avatarId || avatarOnline || avatarFirstName || avatarLastName || avatarNameInstance) {
        fetchAvatarsFilters(dispatch, { userId, avatarId, avatarOnline, avatarFirstName, avatarLastName, avatarNameInstance})
        return <Avatars userId={userId ?? null} avatarId={avatarId ?? null} avatarOnline={avatarOnline ?? null} avatarFirstName={avatarFirstName ?? null} avatarLastName={avatarLastName ?? null} avatarNameInstance={avatarNameInstance ?? null}/>;
    }
    else{
        return <Avatars/>;
    }

}