import {
    HomeModernIcon,
    ComputerDesktopIcon,
    CubeIcon,
    IdentificationIcon,
    ScaleIcon,
    RocketLaunchIcon,
    WindowIcon,
    BanknotesIcon
} from '@heroicons/react/24/outline'
import RegisterInfraction from 'Pages/Moderation/Partials/Modals/RegisterInfraction'
import ImportCatalog from 'Pages/Items/Partials/Modals/ImportCatalog'
import ExportCatalog from 'Pages/Items/Partials/Modals/ExportCatalog'
import ConvertXML from 'Pages/Administration/Partials/Modals/ConvertXML'

import mode from 'helpers/mode'
import { logout } from 'services/auth'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

const useMainNavigation = () => {
    const verifyUserSearch = useUserPermission(SMIPermissionsCode.MOD_USER_SEARCH);
    const verifyAvatarSearch = useUserPermission(SMIPermissionsCode.MOD_AVATAR_SEARCH);
    const verifyIpSearch = useUserPermission(SMIPermissionsCode.MOD_USER_IP_VIEW);
    const verifyEmailSearch = useUserPermission(SMIPermissionsCode.MOD_USER_MAIL);
    const verifyAbuseManage = useUserPermission(SMIPermissionsCode.MOD_ABUSE_MANAGE);
    const verifyInfranctions = useUserPermission(SMIPermissionsCode.MOD_VIEW_INFRACTIONS);
    const verifyGloballyInfract = useUserPermission(SMIPermissionsCode.MOD_GLOBALLY_INFRACT_USERS);
    const verifyTransaction = useUserPermission(SMIPermissionsCode.REVENUE_TRANSACTION_LOGS);
    const verifyItems = useUserPermission(SMIPermissionsCode.ITEMS_SEARCH);
    const verifyCatalog = useUserPermission(SMIPermissionsCode.ITEMS_MANAGE_CATALOG);
    const verifySpin = useUserPermission(SMIPermissionsCode.ITEMS_MANAGE_SPINTOWIN);
    const verifySpaceSearch = useUserPermission(SMIPermissionsCode.SPACE_SEARCH);
    const verifySpaceCatalog = useUserPermission(SMIPermissionsCode.MOD_SPACE_CATALOG);
    const verifyMission = useUserPermission(SMIPermissionsCode.MOD_MISSION_MANAGE);
    const verifyShiftLead = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_SHIFTLEAD);
    const verifyEscalation = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_ESCALATION);
    const verifyContent = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_CONTENT);
    const verifyAdmin = useUserPermission(SMIPermissionsCode.MOD_ADMIN);
    const verifyGroup = useUserPermission(SMIPermissionsCode.MOD_GROUP_MANAGE);

    const mainNavigation = [
        { name: 'Dashboard', to: '/dashboard', icon: WindowIcon, current: false, active: verifyShiftLead && verifyEscalation && verifyContent },
        {
            name: 'Usuários', to: '/users', icon: IdentificationIcon, current: false, active: verifyUserSearch,
            children: [
                { name: 'Busca', to: '/users', icon: IdentificationIcon, current: false, active: verifyUserSearch  },
                { name: 'Amizades', to:'/users/friends', icon: IdentificationIcon, current:false, active: true },
                { name: 'Avatars', to: '/users/avatars', icon: IdentificationIcon, current: false, active: verifyAvatarSearch },
                { name: 'Pesquisa de IP', to: '/users/ips', icon: IdentificationIcon, current: false, active: verifyIpSearch },
                { name: 'Log de Atividades', to: '/users/activities', icon: IdentificationIcon, current: false, active: false },
                { name: 'Pontos de Cidadão', to: '/users/points', icon: IdentificationIcon, current: false, active: false },
                { name: 'Email', to: '/users/email', icon: IdentificationIcon, current: false, active: verifyEmailSearch },
                { name: 'Kudos log', to: '/users/kudos-log', icon: IdentificationIcon, current: false, active: false },
                { name: 'Convites', to: '/users/invites', icon: IdentificationIcon, current: false, active: false },
            ]
        },
        {
            name: 'Moderação', to: '/moderation', icon: ScaleIcon, current: false, active: verifyAbuseManage,
            children: [
                { name: 'Denúncias de abuso', to: '/moderation/abuse-reports', icon: IdentificationIcon, current: false, active: verifyAbuseManage },
                { name: 'Bate-papo', to: '/moderation/chat-logs', icon: IdentificationIcon, current: false, active: verifyAbuseManage },
                { name: 'Infrações', to: '/moderation/infractions', icon: IdentificationIcon, current: false, active: verifyInfranctions },
                { name: 'Eventos', to: '/moderation/events', icon: IdentificationIcon, current: false, active: false },
                { name: 'Lista Negra de IPs', to: '/moderation/blacklist', icon: IdentificationIcon, current: false, active: false },
                { name: 'Gerenciar', to: '/moderation/manage', icon: IdentificationIcon, current: false, active: false },
                { name: 'Registrar Infração', component: RegisterInfraction, icon: IdentificationIcon, current: false, active: verifyGloballyInfract }
            ]
        },
        {
            name: 'Receitas', to: '/revenue/transactions', icon: BanknotesIcon, current: false, active: verifyTransaction,
            children: [
                { name: 'Transações', to: '/revenue/transactions', icon: IdentificationIcon, current: verifyTransaction }
            ]
        },
        {
            name: 'Items', to: '/items', icon: CubeIcon, current: false, active: verifyItems,
            children: [
                { name: 'Busca', to: '/items', icon: IdentificationIcon, current: false, active: verifyItems, },
                { name: 'Catálogo', to: '/items/catalog', icon: IdentificationIcon, current: false, active: verifyCatalog },
                { name: 'Importar catálogo', component: ImportCatalog, icon: IdentificationIcon, current: false, active: mode, },
                { name: 'Exportar catálogo', component: ExportCatalog, icon: IdentificationIcon, current: false, active: mode, },
                { name: 'Roleta', to: '/items/spin', icon: IdentificationIcon, current: false, active: verifySpin, },
                { name: 'Recompensas', to: '/items/rewards', icon: IdentificationIcon, current: false, active: mode, }
            ]
        },
        {
            name: 'Espaços', to: '/spaces', icon: HomeModernIcon, current: false, active: verifySpaceSearch,
            children: [
                { name: 'Busca', to: '/spaces', icon: IdentificationIcon, current: verifySpaceSearch },
                { name: 'Catálogo', to: '/spaces/catalog', icon: IdentificationIcon, current: false, active: verifySpaceCatalog }
            ]
        },
        {
            name: 'Missões', to: '/missions', icon: RocketLaunchIcon, current: false, active: verifyMission,
            children: [
                { name: 'Busca', to: '/missions', icon: IdentificationIcon, current: false, active: verifyMission},
                { name: 'Administrar', to: '/missions/director', icon: IdentificationIcon, current: false, active: false },
                { name: 'Tarefas', to: '/missions/tasks', icon: IdentificationIcon, current: false, active: false},
            ]
        },
        {
            name: 'Administração', to: '/administration', icon: ComputerDesktopIcon, current: false, active: verifyAdmin,
            children: [
                { name: 'Grupos de Usuários', to: '/administration/groups', icon: IdentificationIcon, current: false, active: verifyGroup},
                { name: 'Anúncios Interstitials', to: '/administration/interstitials', icon: IdentificationIcon, current: false, active: verifyAdmin},
                { name: 'Logs Mod', to: '/administration/logs', icon: IdentificationIcon, current: false, active: verifyAdmin},
                { name: 'Filtros de Comunicação', to: '/administration/filters', icon: IdentificationIcon, current: false, active: false},
                { name: 'Converter XML', component: ConvertXML, icon: IdentificationIcon, current: false, active: false},
            ]
        },
    ]

    return mainNavigation
}



const userNavigation = [
    { name: 'Seu Perfil', to: '/profile' },
    { name: 'Sair', action: () => logout() },
]
export { useMainNavigation, userNavigation }
