import { getCategoryDetails } from "services/categoryService";

const { createSlice } = require("@reduxjs/toolkit");

const categoryDetailsSlice = createSlice({
    name: 'categoryDetails',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
    },
    reducers: {
        setCategoryDetailsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addCategoryDetailsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
    }
})

export async function fetchCategoryDetails(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const categoryDetails = await getCategoryDetails(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addCategoryDetailsData(categoryDetails))
        }
        else{
            dispatch(setCategoryDetailsData(categoryDetails));
        }
        
        if(categoryDetails.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}
export const { setCategoryDetailsData, addCategoryDetailsData, setCurrentSkip, setHasMore } = categoryDetailsSlice.actions
export default categoryDetailsSlice.reducer