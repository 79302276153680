import Items from "Pages/Items";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchItemsFilters } from "store/modules/items/itemsSlice";

export function ItemsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const idUser = searchParams.get("idUser");
    const idSpace = searchParams.get("idSpace");
    const idItem = searchParams.get("idItem");
    const idModel = searchParams.get("idModel");
    const state = searchParams.get("state");
    const forSale = searchParams.get("forSale");
    let propName = {
        inventory: 'No inventário',
        space: 'No espaço',
    }
    let propSaleName = {
        true: 'Sim',
        false: 'Não',
    }

    const dispatch = useDispatch()

    if (idUser || idSpace || idItem || idModel || state || forSale) {
        fetchItemsFilters(dispatch, {idUser, idSpace, idItem, idModel, state: state ? {name: propName[state], value: state} : '', forSale: forSale ? {name: propSaleName[forSale], value: forSale} : ''})
        return <Items idUser={idUser ?? null} idSpace={idSpace ?? null} idItem={idItem ?? null} idModel={idModel ?? null} state={state ?? null} forSale={forSale ?? null}/>;
    }
    else{
        return <Items/>;
    }

}