class MissionDetail {
    constructor({ missionName, missionId, avatarId, avatarFirstName, avatarLastName, avatarNameInstance, avatarIcon, entryFee, experiencePath, approved, isOfficial, spaceId, spaceName }) {
        this.missionName = missionName
        this.missionId = missionId
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarIcon = avatarIcon
        this.entryFee = entryFee
        this.experiencePath = experiencePath
        this.approved = approved
        this.isOfficial = isOfficial
        this.spaceId = spaceId,
        this.spaceName = spaceName
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'missionId',
                    name: "ID Missão",
                    value: this.missionId,
                },
                {
                    key: 'missionName',
                    name: "Nome da Missão",
                    value: this.missionName,
                },
                {
                    key: 'avatarId',
                    name: "Id do Avatar",
                    value: this.avatarId,
                },
                {
                    key: 'avatarName',
                    name: "Nome do Avatar",
                    value: this.avatarFullName(this.avatarNameInstance, this.avatarFirstName, this.avatarLastName),
                },
                {
                    key: 'entryFee',
                    name: "Custo de Entrada",
                    value: this.entryFee,
                },
                {
                    key: 'experiencePath',
                    name: "Tipo de Missão",
                    value: this.handleExperience(this.experiencePath),
                },
                {
                    key: 'spaceId',
                    name: "ID do Espaço",
                    value: this.spaceId,
                },
                {
                    key: 'spaceName',
                    name: "Nome do Espaço",
                    value: this.spaceName,
                },
                {
                    key: 'approved',
                    name: "Aprovado",
                    value: this.approved,
                },
                {
                    key: 'isOfficial',
                    name: "Oficial",
                    value: this.isOfficial,
                },

            ],
            missionName: this.missionName,
            missionId: this.missionId,
            avatarId: this.avatarId,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarNameInstance: this.avatarNameInstance,
            avatarIcon: this.avatarIcon,
            entryFee: this.entryFee,
            experiencePath: this.experiencePath,
            approved: this.approved,
            isOfficial: this.isOfficial,
            spaceId: this.spaceId,
            spaceName: this.spaceName,

        }

    }

    handleExperience(experiencePath){
        const experiencePathName = {
            1: "Artista",
            2: "Explorador",
            3: "Jogador",
            4: "Social",
            5: "Arena",
            6: "Fazendeiro",
            7: "Artesão",
            8: "Primário"
        }
        return experiencePathName[experiencePath]
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }

}

export default MissionDetail