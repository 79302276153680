const { createSlice, } = require("@reduxjs/toolkit");

const socketSlice = createSlice({
    name: "socket",
    initialState: {
        client: null
    },
    reducers: {
        setSocketClient: (state, action) => {
            return { ...state, client: action.payload }
        },

    }
})

export const { setSocketClient } = socketSlice.actions
export default socketSlice.reducer