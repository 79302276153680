class Avatar {
    constructor({ avatarId, avatarFirstName, avatarLastName, avatarNameInstance, avatarIcon, userId, userFirstName, userLastName, headPostfix }) {
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarIcon = avatarIcon
        this.userId = userId
        this.userFirstName = userFirstName
        this.userLastName = userLastName
        this.headPostfix = headPostfix
    }

    get() {
        return {
            avatarId: this.avatarId,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarNameInstance: this.avatarNameInstance,
            avatarIcon: this.avatarIcon,
            avatarName: this.avatarFullName(this.avatarNameInstance, this.avatarFirstName, this.avatarLastName),
            userId: this.userId,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userName: this.userFirstName + " " + this.userLastName,
            headPostfix: this.headPostfix
        }
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if(avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }
}

export default Avatar