import { createSlice } from '@reduxjs/toolkit'
import { getInterstitialGroups } from 'services/interstitialService'

const interstitialGroupsSlice = createSlice({
    name: 'interstitialGroups',
    initialState: [],
    reducers: {
        setInterstitialGroups: (state, action) => {
            return action.payload
        },
    },
})

export const fetchInterstitialGroups = (params) => async (dispatch) => {
    try {
        const groups = await getInterstitialGroups(params)
        dispatch(setInterstitialGroups(groups))
    } catch (error) {
        console.error('Erro ao carregar grupos:', error)
    }
}

export const { setInterstitialGroups } = interstitialGroupsSlice.actions
export default interstitialGroupsSlice.reducer