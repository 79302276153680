import moment from "moment"

class Interstitial {
    constructor({ interstitialId, name, active, groupId, imgUrl, redirectUrl, newWindow, startDate, endDate, token, tokenAge }) {
        this.interstitialId = interstitialId
        this.name = name
        this.active = active
        this.groupId = groupId
        this.imgUrl = imgUrl
        this.redirectUrl = redirectUrl
        this.newWindow = newWindow
        this.startDate = startDate
        this.endDate = endDate
        this.token = token
        this.tokenAge = tokenAge
    }

    get() {
        return {
            interstitialId: this.interstitialId,
            name: this.name,
            active: this.active,
            groupId: this.groupId,
            imgUrl: this.imgUrl,
            redirectUrl: this.redirectUrl,
            newWindow: this.newWindow,
            startDate: this.startDate,
            endDate: this.endDate,
            token: this.token,
            tokenAge: this.tokenAge,
            cooldown: this.formatDuration,
        }
    }

    get formatDuration(){
        const duration = moment.duration(this.tokenAge, 'seconds');
    
        if (duration.asWeeks() >= 1) {
            return `${Math.floor(duration.asWeeks())} semana(s)`;
        } else if (duration.asDays() >= 1) {
            return `${Math.floor(duration.asDays())} dia(s)`;
        } else if (duration.asHours() >= 1) {
            return `${Math.floor(duration.asHours())} hora(s)`;
        } else if (duration.asMinutes() >= 1) {
            return `${Math.floor(duration.asMinutes())} minuto(s)`;
        } else {
            return `${this.tokenAge} segundo(s)`;
        }
    };
}

export default Interstitial