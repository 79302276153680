import { permissionCategoriesMapping, userGroupDetailsMapping, userGroupMapping } from 'domains/Administration/UserGroups/mapping';
import api, { apiNormalize } from './api'
import UserGroup from '../domains/Administration/UserGroups/userGroup'
import handleRequestError from 'helpers/apiErrorHandler';
import UserGroupDetails from 'domains/Administration/UserGroups/UserGroupDetails';
import PermissionCategory from 'domains/Administration/UserGroups/PermissionCategory';

export async function getUserGroups() {
    const response = await api.get("/userGroups");
    const groups = apiNormalize.responseMapping(response.data, userGroupMapping)

    groups.sort(function (a, b) {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        return 0
    })

    const normalizedGroups = groups.map(group => new UserGroup(group).get());
    return normalizedGroups
}

export async function deleteUserGroup(id) {
    try {
        const response = await api.post(`/userGroups/${id}/deleteUserGroup`)
        return(response.data)
    }
    catch (error) {
        handleRequestError(error)
    }
}

export const createUserGroup = async (params) => {
    const response = await api.post('/userGroups/createUserGroup', { params });
    return response.data;
};

export const getSelectedtUserGroup = async (params) => {
    try {
        const response = await api.get('/userGroups/getSelectedtUserGroup', { params })
        const userGroup = apiNormalize.responseMapping(response.data, userGroupDetailsMapping)
        const normalizedUserGroup = userGroup.map(item => new UserGroupDetails(item).get())
        return normalizedUserGroup
    } catch (error) {
        handleRequestError(error)
    }
}

export const getPermissionCategories = async (tab) => {
    const response = await api.get("/userGroups/getPermissionCategories", { params: { tab }});
    const categories = apiNormalize.responseMapping(response.data, permissionCategoriesMapping)

    const normalizedPermissionCategories = categories.map(category => new PermissionCategory(category).get());
    return normalizedPermissionCategories
}

export const savePermissions = async (newPermissions, userGroupId) => {
    try {
        const response = await api.post('/userGroups/savePermissions', { params: { newPermissions, userGroupId }})
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}