import { getCategories } from 'services/categoryService'
import { setCategories } from './categorySlice'

export const fetchCategories = (params) => async (dispatch) => {
    try {
        const categories = await getCategories(params)
        dispatch(setCategories(categories))
    } catch (error) {
        console.error('Erro ao carregar categorias:', error)
    }
}
