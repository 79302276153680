import { InfinitySpin } from 'react-loader-spinner'
import TableRow from './row'
import EmptyState from 'components/EmptyState'
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

export default function BasicTableDnD({
    headers,
    items,
    selectItems,
    title = null,
    slotHeader = null,
    slotActions = null,
    slotFooter = null,
    isLoading = false,
    height = '40vh',
    width = '40vw',
    round = true,
    onSelectRow,
    onDetailRow,
    onSelectItem,
    onClickBtnItem,
    selectKey,
    children = null,
    isSelectable = false,
    hasButton = false,
    onClickBtnItemHeader = null,
    onClickBtnItemInfo = null,
    onDragEnd,
    onDragEndChild
}) {
    const tbodyStyle = {
        height: height,
        width: width
    }
    /*
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(items.map(() => {
            return false
        }));
    }, [items])
    */


    const handleOnDragEnd = (result) => {
        console.log(result)
        onDragEnd(result)
    }

    const handleOnDragEndChild = (result, item) => {
        onDragEndChild(result, item)
    }

    const handleSelectRow = (item, cell) => {
        onSelectRow(item, cell);
    }

    const handleDetailRow = (item) => {
        onDetailRow(item)
    }

    const handleSelectItem = (item) => {
        /*
        const index = items.indexOf(item);
        const selectedCopy = [...selected];
        selectedCopy.splice(index, 1,  !selected[index]);
        setSelected(selectedCopy);

        */
        onSelectItem(item)
    }

    const handleBtnItem = (item) => {
        onClickBtnItem(item)
    }

    function calculateWidthClass(headerCount) {
        const availableWidthClasses = [
            "w-1/2",
            "w-1/3",
            "w-1/4",
            "w-1/5",
            "w-1/6",
            "w-2/6",
            "w-3/6",
            "w-4/6",
            "w-5/6",
            "w-1/12",
            "w-2/12",
            "w-3/12",
            "w-4/12",
            "w-5/12",
            "w-6/12",
        ]

        if (headerCount <= availableWidthClasses.length) {
            return availableWidthClasses[headerCount - 2]
        } else {
            return "w-1"
        }
    }

    return (
        <div className={(round) ? "overflow-hidden shadow-lg ring-2 ring-black ring-opacity-10 sm:rounded-lg" : "overflow-hidden shadow-lg ring-2 ring-black ring-opacity-10"}>
            {slotHeader && (
                <div>
                    {slotHeader}
                </div>
            )}

            {title && slotActions && (
                <div className='flex justify-between px-2 py-2 border-b font-medium bg-gradient-to-b from-gray-300'>
                    {title && (
                        <div className=''>
                            {title}
                        </div>
                    )}

                    {slotActions && (
                        <div className='flex justify-between space-x-2 items-center'>
                            {slotActions}
                        </div>
                    )}
                </div>
            )}

            {isLoading && <div style={tbodyStyle} className='flex items-center justify-center'>
                <InfinitySpin
                    width='200'
                    color="#155f75"
                />
            </div>}

            {!isLoading && !hasButton && !isSelectable &&
                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-200 flex w-full pr-2.5">
                        <tr className='flex w-full'>
                            {headers.map((item, i) => (
                                <th key={i} scope="col" className={`px-2 py-2 text-left text-sm font-semibold text-gray-900 ${calculateWidthClass(headers.length)}`} >
                                    {/*
                                        {headers.map((headersItem, i) => (
                                            
                                            let maior = items[0][headersItem.title].length

                                            items.map((itemsItem, i) => (
                                                if(itemsItem[headersItem.title].length > maior) maior = itemsItem[headersItem.title].length
                                            ))

                                            <th key={ i } scope="col" className={ `px-2 py-2 text-left text-sm font-semibold text-gray-900 ${ calculateWidthClass(maior) }` } >
                                                    { headersItem.title }
                                            </th>
                                        ))}

                                        item.title (esse item é do header)
                                        itemsItem[headersItem.title].length (esse item é do items que vem)
                                    */}
                                    {item.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='parentItems' type='list' direction='vertical'>
                            {(provided) => (
                                <tbody
                                    className="divide-y divide-gray-200 bg-white flex flex-col justify-start basic-table"
                                    style={tbodyStyle}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {items.length > 0 ? items.map((item, i) => (
                                        <TableRow
                                            key={i}
                                            index={items.indexOf(item)}
                                            headers={headers}
                                            item={item}
                                            cellWidth={calculateWidthClass(headers.length)}
                                            onSelectRow={handleSelectRow}
                                            onDetailRow={handleDetailRow}
                                            children={children} 
                                            onDragEndChild={handleOnDragEndChild}
                                            />
                                    )) : (
                                        <tr className='flex w-full'>
                                            <td className="py-1 text-sm text-gray-900 px-2 w-full text-center justify-center h-64 flex flex-col items-center" colSpan={4}>
                                                <EmptyState />
                                            </td>
                                        </tr>
                                    )}

                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
            }



            {slotFooter && (
                <div className='flex justify-between space-x-2 items-center p-2 bg-gradient-to-t from-gray-300 border-t border-gray-300 border-opacity-60'>
                    {slotFooter}
                </div>
            )}
        </div>
    )
}
