import { FolderOpenIcon } from '@heroicons/react/24/outline'

export default function EmptyState ({ title, description }) {
    return (
        <div className="text-center">
            <FolderOpenIcon className="h-12 w-12 text-gray-400 mx-auto" />

            <h3 className="mt-2 text-sm font-semibold text-gray-900">{ title ?? 'Nenhum registro encontrado' }</h3>
            <p className="mt-1 text-sm text-gray-500">{ description ?? 'Atualize a tabela ou tente novamente mais tarde' }</p>
        </div>
    )
}
