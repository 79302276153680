export class Paths {
    static libraryPath = "https://cdn.minimania.app/content/content-v102/assets/";
    static avatarImagesPath = "https://cdn.minimania.app/avatars/";
    static spaceImagesPath = "https://cdn.minimania.app/media/images/space";
    static gatewayPath = "https://minimania-web-gateway.azurewebsites.net/";
    static contentPath = "https://cdn.minimania.app/content/content-v102/";
    static widgetImagesPath = "https://cdn.minimania.app/widgets/items/bitmaps/"
    static restPath = "https://minimania-web-rest.azurewebsites.net/";
    static configPath = "https://cdn.minimania.app/content/config/";
    static mediaPath = "https://cdn.minimania.app/media";
 
    static getAvatarHeadURL(param1, param2) {
        if (Boolean(param1) && param1.charAt(0) == "_") {
            return Paths.avatarImagesPath + "guest.png";
        }
        if (Boolean(param1) && Boolean(param2)) {
            return Paths.avatarImagesPath + param1 + param2 + ".png";
        }
        return null;
    }
 
    static getAvatarSnapshotURL(param1, param2) {
        if (Boolean(param1) && param1.charAt(0) == "_") {
            return Paths.avatarImagesPath + "guest.png";
        }
        if (Boolean(param1) && Boolean(param2)) {
            return Paths.avatarImagesPath + param1 + param2 + "_snap.png";
        }
        return null;
    }
 
    static getAvatarThumbURL(param1, param2) {
        if (Boolean(param1) && param1.charAt(0) == "_") {
            return Paths.avatarImagesPath + "guest.png";
        }
        if (Boolean(param1) && Boolean(param2)) {
            return Paths.avatarImagesPath + param1 + param2 + "_thumb.png";
        }
        return null;
    }
 
    static getSpaceIconURL(param1, param2, param3) {
        if (param1) {
            if (param1.indexOf("https://") > -1) {
                return param1;
            }
            return Paths.mediaPath + param1;
        }
        return Paths.getSpaceThumbnailURL(param2, param3);
    }
 
    static getSpaceThumbnailURL(param1, param2) {
        if (param1) {
            if (param1.indexOf("https://") > -1) {
                return param1;
            }
            return Paths.mediaPath + param1;
        }
        if (param2) {
            return Paths.spaceImagesPath + "/" + param2 + "_default_thumb.jpg";
        }
        return null;
    }
}