import Modal from 'components/Modal'
import React from 'react'
import styles from '../../../../styles/EmailDetails.module.css'

export default function EmailDetailsModal({ isOpen, onClose, item, isLoading }) {

    function fullName(type, userFirstName, userLastName, avatarFirstName, avatarLastName, avatarInstance) {
        if (type === 'S') return 'MiniMania'
        else if (type === 'U') {
            if (userFirstName === null || userLastName === null) return "Usuário Apagado"
            else return `${userFirstName} ${userLastName}`
        }
        else if (type === 'A') {
            function ordinal(nameInstance) {
                const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
                if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                    return nameInstance + "th";
                else
                    return nameInstance + "" + ends[nameInstance % 10];
            }
            if (avatarInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarInstance);
            else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
            else return avatarFirstName + " " + avatarLastName;
        }
    }



    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
        >
            <div className={styles.corpo}>
                <span className={styles.header}>{item.emailSubject}</span>
                <span className={styles.from}>From: {fullName(item.senderType, item.userSenderFirstName, item.userSenderLastName, item.avatarSenderFirstName, item.avatarSenderLastName, item.avatarInstance)}</span>
                <span className={styles.from}>To: {fullName(item.receiverType, item.userReceiverFirstName, item.userReceiverLastName, item.avatarReceiverFirstName, item.avatarReceiverLastName, item.avatarInstance)}</span>
                <div className={styles.separator}></div>
                <span className={styles.message}>{item.emailText}</span>
            </div>
        </Modal>
    )
}
