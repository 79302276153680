import { createSlice } from '@reduxjs/toolkit'
import { getUserLogins } from 'services/userService';

const userLoginsSlice = createSlice({
    name: 'userLogins',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false
    },
    reducers: {
        setUserLoginsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addUserLoginsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
    },
})

export async function fetchUserLogins(dispatch, params, skip){
    try{
        const LIMIT = 200;
        const userLogins = await getUserLogins(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addUserLoginsData(userLogins))
        }
        else {
            dispatch(setUserLoginsData(userLogins));
        }

        if (userLogins.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Logins', error)
    }
}



export const { setUserLoginsData, addUserLoginsData, setCurrentSkip, setHasMore  } = userLoginsSlice.actions
export default userLoginsSlice.reducer