import { toast } from 'react-toastify';
import { logout } from 'services/auth'

const handleRequestError = (error) => {
    if (error.response?.status === 403) {
        console.log('403: Erro de autenticação!');
        alert('403: Erro de autenticação');
        return logout()
    }
    if(error.response?.status === 401) {{
        return toast.error("Operação não autorizada");
    }}

    console.error('Erro na requisição:', error)
    throw error
}

export default handleRequestError
