import BasicTable from "components/BasicTable"
import Modal from "components/Modal"
import Tabs from "components/Tabs"
import { useInterstitialItem } from "./interstitialSearch";
import { fetchSelectedInterstitialItemDetails } from "store/modules/administration/interstitials/selectedInterstitialItemDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react'
import style from '../../../../styles/UserTabs.module.css'
import styles from '../../../../styles/Search.module.css'
import Button from 'components/form/Button';
import { toast } from 'react-toastify';
import { Paths } from 'paths/Paths';
import moment from "moment";
import { saveInterstitialImg, saveInterstitialItem } from "services/interstitialService";
import { fetchInterstitials } from "store/modules/administration/interstitials/interstitialsSlice";


export default function ItemDetails({ item }) {

    const getInterstitials = () => {
        if (Object.keys(selectedInterstitialGroup).length != 0) {
            dispatch(fetchInterstitials(selectedInterstitialGroup.groupId))
        }
    }

    const selectedInterstitialGroup = useSelector((state) => {
        return state.selectedInterstitialGroup
    })

    const interstitialGroups = useSelector((state) => {
        return Array.isArray(state.interstitialGroups) ? state.interstitialGroups : []
    })

    const interstitialItemHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstInterstitialItemData = useInterstitialItem(item);
    const [interstitialItemData, setInterstitialItemData] = useState(firstInterstitialItemData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [weeksInput, setWeeksInput] = useState(0);
    const [daysInput, setDaysInput] = useState(0);
    const [hoursInput, setHoursInput] = useState(0);

    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstInterstitialItemDataCopy = { ...firstInterstitialItemData }
        setInterstitialItemData(firstInterstitialItemDataCopy)

        setOriginalValues({
            interstitialId: firstInterstitialItemData.interstitialId,
            name: firstInterstitialItemData.name,
            active: firstInterstitialItemData.active,
            groupId: firstInterstitialItemData.groupId,
            groupName: firstInterstitialItemData.groupName,
            redirectUrl: firstInterstitialItemData.redirectUrl,
            newWindow: firstInterstitialItemData.newWindow,
            startDate: firstInterstitialItemData.startDate,
            endDate: firstInterstitialItemData.endDate,
            token: firstInterstitialItemData.token,
            cooldown: firstInterstitialItemData.cooldown,
            tokenAge: firstInterstitialItemData.tokenAge


        })

        setChangedValues({
            interstitialId: firstInterstitialItemData.interstitialId,
            name: firstInterstitialItemData.name,
            active: firstInterstitialItemData.active,
            groupId: firstInterstitialItemData.groupId,
            groupName: firstInterstitialItemData.groupName,
            redirectUrl: firstInterstitialItemData.redirectUrl,
            newWindow: firstInterstitialItemData.newWindow,
            startDate: firstInterstitialItemData.startDate,
            endDate: firstInterstitialItemData.endDate,
            token: firstInterstitialItemData.token,
            cooldown: firstInterstitialItemData.cooldown,
            tokenAge: firstInterstitialItemData.tokenAge

        })

    }, [firstInterstitialItemData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
        if(modalTitle == 'Grupo'){
            setInputValue(changedValues.groupId)
        }
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");

        setWeeksInput(0);
        setDaysInput(0);
        setHoursInput(0);

        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name == "Duração do Token") {
            setCurrentItem(item)
            setModalTitle(item.name)

            const duration = moment.duration(changedValues.tokenAge, 'seconds');

            const weeksValue = Math.floor(duration.asWeeks());
            const daysValue = Math.floor(duration.subtract(weeksValue, 'weeks').asDays());
            const hoursValue = Math.floor(duration.subtract(daysValue, 'days').asHours());

            setWeeksInput(weeksValue);
            setDaysInput(daysValue);
            setHoursInput(hoursValue);


            //setInputValue(firstInterstitialItemData.tokenAge)
            //transformar esses segundos
            handleOpenModal()
        }
        if (item.name == 'Data de Início' || item.name == 'Data de Término') {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(moment(item.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'))
            handleOpenModal()
        }
        if (item.name == 'Grupo') {
            setCurrentItem(item)
            setModalTitle(item.name)
            handleOpenModal()
        }
        if (item.name != "ID Interstitial" && item.name != "Duração do Token" && item.name != 'Data de Início' && item.name != 'Data de Término') {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleOnChangeWeeksInput = (event) => {
        setWeeksInput(event.target.value)
    }

    const handleOnChangeDaysInput = (event) => {
        setDaysInput(event.target.value)
    }

    const handleOnChangeHoursInput = (event) => {
        setHoursInput(event.target.value)
    }

    const handleFileSubmit = async (e) => {
        try {
            const file = e.target.files[0];
            if (modalTitle == "Imagem") {
                await saveInterstitialImg(item.interstitialId, file);
            }
            await fetchSelectedInterstitialItemDetails(dispatch, { id: item.interstitialId });
            toast.success('Imagem Salva com Sucesso!');
        }
        catch {
            toast.error('Erro ao Salvar Imagem!');
        }
        handleCloseModal()
    }

    const handleSubmit = () => {
        let interstitialItemDataCopy = { ...interstitialItemData, tableProperties: [...interstitialItemData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (modalTitle == 'Duração do Token') {
            if (!(weeksInput == 0 && daysInput == 0 && hoursInput == 0)) {
                //valor em segundos
                const totalSeconds = (weeksInput * 7 * 24 * 60 * 60) + (daysInput * 24 * 60 * 60) + (hoursInput * 60 * 60);
                const duration = moment.duration(totalSeconds, 'seconds');
                let temp

                if (duration.asWeeks() >= 1) {
                    temp = `${Math.floor(duration.asWeeks())} semana(s)`;
                } else if (duration.asDays() >= 1) {
                    temp = `${Math.floor(duration.asDays())} dia(s)`;
                } else if (duration.asHours() >= 1) {
                    temp = `${Math.floor(duration.asHours())} hora(s)`;
                } else if (duration.asMinutes() >= 1) {
                    temp = `${Math.floor(duration.asMinutes())} minuto(s)`;
                } else {
                    temp = `${totalSeconds} segundo(s)`;
                }

                //o que vai mudar no tableProperties é cooldown
                for (let i = 0; i < interstitialItemData.tableProperties.length; i++) {
                    if (interstitialItemData.tableProperties[i].key == 'cooldown') {
                        interstitialItemDataCopy.tableProperties[i] = { name: 'Duração do Token', value: temp, key: 'cooldown' };
                        interstitialItemDataCopy['cooldown'] = temp;
                    }
                }

                //o que vai mudar em changedValue é cooldown e tokenAge (em segundos)
                changedValuesCopy['tokenAge'] = totalSeconds;
                changedValuesCopy['cooldown'] = temp;


                setChangedValues(changedValuesCopy)
                setInterstitialItemData(interstitialItemDataCopy);
            }
        }
        else {
            if (inputValue != '' && (modalTitle == 'Data de Início' || modalTitle == 'Data de Término')) {
                for (let i = 0; i < interstitialItemData.tableProperties.length; i++) {
                    if (interstitialItemData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != moment(inputValue, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm')) {
                        interstitialItemDataCopy.tableProperties[i] = { name: currentItem.name, value: moment(inputValue, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm'), key: currentItem.key };
                        interstitialItemDataCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
                        changedValuesCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
                    }
                }
                setChangedValues(changedValuesCopy)
                setInterstitialItemData(interstitialItemDataCopy);
            }
            if (modalTitle == 'Grupo' && changedValues.groupName != inputValue) {
                //inputValue é o Id
                //no changed values troca groupName e groupId
                //no tableproperties troca groupName
                let j = 0;
                while (interstitialGroups[j].groupId != inputValue) {
                    j++;
                }
                let inputGroupName = interstitialGroups[j].name

                for (let i = 0; i < interstitialItemData.tableProperties.length; i++) {
                    if (interstitialItemData.tableProperties[i].key == currentItem.key && changedValues.groupId != inputValue) {
                        interstitialItemDataCopy.tableProperties[i] = { name: currentItem.name, value: inputGroupName, key: currentItem.key };
                        interstitialItemDataCopy[currentItem.key] = inputGroupName;
                        interstitialItemDataCopy.groupId = inputValue;
                        changedValuesCopy.groupId = inputValue;
                    }
                }

                setChangedValues(changedValuesCopy)
                setInterstitialItemData(interstitialItemDataCopy);
            }
            if (inputValue != '' && modalTitle != 'Data de Início' && modalTitle != 'Data de Término' && modalTitle != 'Grupo') {
                for (let i = 0; i < interstitialItemData.tableProperties.length; i++) {
                    if (interstitialItemData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        interstitialItemDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        interstitialItemDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setInterstitialItemData(interstitialItemDataCopy);
            }
            if (inputValue == '' && modalTitle == 'URL') {
                for (let i = 0; i < interstitialItemData.tableProperties.length; i++) {
                    if (interstitialItemData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        interstitialItemDataCopy.tableProperties[i] = { name: currentItem.name, value: "-", key: currentItem.key };
                        interstitialItemDataCopy[currentItem.key] = "-";
                        changedValuesCopy[currentItem.key] = "-";
                    }
                }
                setChangedValues(changedValuesCopy)
                setInterstitialItemData(interstitialItemDataCopy);
            }
            setInputValue("");
        }

        setCurrentItem({});
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setInterstitialItemData(firstInterstitialItemData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                name: 'title',
                active: 'active',
                groupId: 'groupId',
                redirectUrl: 'url',
                newWindow: 'newWindow',
                startDate: 'startDate',
                endDate: 'endDate',
                token: 'token',
                tokenAge: 'tokenAge'
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName] && propName != 'groupName' && propName != 'cooldown') {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            if (changes['startDate']) {
                changes['startDate'] = moment(changes['startDate'], 'DD/MM/YYYY HH:mm').utc().format();
            }

            if (changes['endDate']) {
                changes['endDate'] = moment(changes['endDate'], 'DD/MM/YYYY HH:mm').utc().format();
            }

            if (changes['newWindow']) {
                changes['newWindow'] == 'Sim' ? changes['newWindow'] = true : changes['newWindow'] = false
            }

            if (changes['active']) {
                changes['active'] == 'Sim' ? changes['active'] = true : changes['active'] = false
            }

            if (changes['url'] == '-') {
                changes['url'] = null
            }

            await saveInterstitialItem(item.interstitialId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            await fetchSelectedInterstitialItemDetails(dispatch, { id: item.interstitialId });

            getInterstitials()

            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={interstitialItemHeaders}
                        items={interstitialItemData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {(modalTitle != "Imagem") ?
                            <div className={style.modalContent}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {modalTitle != "Grupo" ?
                                    (modalTitle != 'Ativo' && modalTitle != 'Nova Janela') ?
                                        (modalTitle != 'Data de Início' && modalTitle != 'Data de Término') ?
                                            (modalTitle != 'Duração do Token') ?
                                                <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                                :
                                                <>
                                                    <div className="flex gap-2 items-center ">
                                                        <div>
                                                            <input className={style.modalInput} type="number" placeholder='' value={weeksInput} onChange={handleOnChangeWeeksInput} />
                                                        </div>
                                                        <span>Semana(s)</span>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <div>
                                                            <input className={style.modalInput} type="number" placeholder='' value={daysInput} onChange={handleOnChangeDaysInput} />
                                                        </div>
                                                        <span>Dia(s)</span>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <div>
                                                            <input className={style.modalInput} type="number" placeholder='' value={hoursInput} onChange={handleOnChangeHoursInput} />
                                                        </div>
                                                        <span>Hora(s)</span>
                                                    </div>

                                                </>
                                            :
                                            <input value={inputValue} onChange={handleOnChangeInput} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                                        :
                                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                            <option key={'Sim'} value={'Sim'}>Sim</option>
                                            <option key={'Não'} value={'Não'}>Não</option>
                                        </select>
                                    :
                                    <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                        {interstitialGroups != [] && interstitialGroups.map((item, i) => {
                                            console.log(item)
                                            return (
                                                <option key={item.groupId} value={item.groupId}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                }

                                <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                            </div>
                            :
                            <div className={style.modalImgContent}>
                                <img className={style.modalImg} src={`${Paths.mediaPath}${inputValue}`} />
                                <span className={style.modalImgLabel}>{`Nova Imagem para Interstitial:`}</span>
                                <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />
                            </div>

                        }
                    </Modal>
                </>
            )
        },
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm'>
                        <b>{item.name}</b>
                        <p>{item.interstitialId}</p>
                    </div>
                )}
            />
        </div>
    )
}