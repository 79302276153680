import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import SpacesFilter from 'Pages/Spaces/SpacesFilter'
import styles from 'styles/Search.module.css'
import { fetchSpaces } from 'store/modules/spaces/spacesSlice'
import Details from './Partials/Details'
import { selectSpaceForDetails } from 'store/modules/spaces/selectedSpaceSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Spaces({ idSpace, nameSpace, idAvatar, idModel }) {
    const verifySpace = useUserPermission(SMIPermissionsCode.SPACE_SEARCH);
    const verifySpaceDetails = useUserPermission(SMIPermissionsCode.SPACE_DETAILS_VIEW);

    const [spacesHeaders, setSpacesHeaders] = useState([
        { title: 'ID do Espaço', key: 'smallSpaceId', copy: 'id', detailable: verifySpaceDetails, appear: true },
        { title: 'Nome do Espaço', key: 'spaceName', appear: true },
        { title: 'Nome do Avatar', key: 'avatarName', copy: 'avatarId', appear: true },
        { title: 'Nome do Modelo', key: 'modelName', copy: 'modelId', appear: true },
    ]);

    const [spacesMomentHeaders, setSpacesMomentHeaders] = useState([...spacesHeaders]);

    const handleAppearHeader = (item) => {
        setSpacesHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setSpacesMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const spaces = useSelector((state) => {
        return Array.isArray(state.spaces.data) ? state.spaces.data : []
    })

    const selectedSpace = useSelector((state) => {
        return state.selectedSpace
    })

    const skip = useSelector((state) => {
        return (state.spaces.currentSkip) ? state.spaces.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.spaces.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.spaces.filters) ? state.spaces.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [spaces]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (idSpace || nameSpace || idAvatar || idModel) {
            setLoading(true)
            fetchSpaces(dispatch, { idSpace, nameSpace, idAvatar, idModel }, 0);
        }
    }

    const search = () => {
        try {
            setLoading(true)
            const query = new URLSearchParams();
            if (filter.form.idSpace) query.append('idSpace', filter.form.idSpace);
            if (filter.form.nameSpace) query.append('nameSpace', filter.form.nameSpace);
            if (filter.form.idAvatar) query.append('idAvatar', filter.form.idAvatar);
            if (filter.form.idModel) query.append('idModel', filter.form.idModel);
            window.history.pushState({}, '', `/spaces?${query.toString()}`);
            fetchSpaces(dispatch, filter.form, 0);
        } catch (error) {
            console.log(error)
        }
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchSpaces(dispatch, filter.form, skip + 200);
    })

    const handleSpaceDetails = (item) => {
        dispatch(selectSpaceForDetails({ id: item.id }))
    }

    return (
        <>
            {verifySpace && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-7'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={spacesHeaders}
                    headers={spacesMomentHeaders}
                        slotHeader={<SpacesFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Espaços'
                        onDetailRow={handleSpaceDetails}
                        isLoading={isLoading && spaces.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={spaces}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(spaces.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : spaces.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
                <div className='col-span-5'>
                    {selectedSpace.id && <Details item={selectedSpace} />}
                </div>
            </div>}
        </>
    )
}
