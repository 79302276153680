import Card from 'components/Card'
import Button from 'components/form/Button'

import {
    XMarkIcon,
    UserIcon,
    MagnifyingGlassIcon,
    ScaleIcon,
    BellAlertIcon,
    CubeIcon,
    HomeModernIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownOnSquareIcon
} from '@heroicons/react/24/outline'
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { closeUserCard } from 'store/modules'

// const users = [
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw258we9g' },
//     { name: 'Jhon Doe', id: 'rw78rf58g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw258we9g' },
//     { name: 'Jhon Doe', id: 'rw78rf58g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
//     { name: 'Jhon Doe', id: 'rw258we9g' },
//     { name: 'Jhon Doe', id: 'rw78rf58g' },
//     { name: 'Jhon Doe', id: 'rw78rwe9g' },
// ]
export default function SidebarCards () {
    const dispatch = useDispatch()
    // Redux
    const cards = useSelector((state) => {
        return Array.isArray(state.cards) ? state.cards : []
    })

    useEffect(() => {
        console.log(cards);
    }, [cards]);

    const [isDivVisible, setIsDivVisible] = useState(false)

    const toggleDivVisibility = () => {
        setIsDivVisible(!isDivVisible)
    };

    const cardsBar = useRef(null)
    const handleWheel = (e) => {
        if (cardsBar.current) {
            cardsBar.current.scrollLeft += e.deltaY
        }
    }

    const logoutUser = (id) => {
        console.log('Usuario deslogado');
    }

    const removeFromSpaces = (id) => {
        console.log('Remove dos Espaços');
    }

    const removeUserFromBar = (id) => {
        console.log('Remove usuário da barra', id);
        dispatch(closeUserCard(id))
    }

    return (
        <div className={ `bg-gray-500 bg-opacity-30 w-full h-32 fixed z-50 transition-all duration-300 ease-in-out ${ isDivVisible ? '-mt-14' : 'mt-16'}`}>
            <div className='h-full flex flex-row gap-x-2 px-2 py-2 overflow-x-auto sidebar-cards' ref={ cardsBar } onWheel={ handleWheel }>
                <button onClick={ toggleDivVisibility } className='bg-slate-500 absolute -mt-10 rounded-t-md text-gray-50 left-1 px-2 py-1'>
                    { isDivVisible ? 'Minimizar' : 'Barra de Usuários' }
                </button>
                { cards.map((user, i) => (
                    <Card key={ i }>
                        <div className='text-xs px-1 py-1 border-b font-medium bg-gray-300 rounded-t-lg flex'>
                            { user.name }: { user.id }
                            <Button onClick={ () => removeUserFromBar(user.id) } onlyIcon color="cyan" icon={ <XMarkIcon className='h-3 w-3' /> } />
                        </div>
                        <div className='py-1 px-2 grid grid-cols-4 gap-1'>
                            <Button link={ `/users/${ user.id }` } icon={ <MagnifyingGlassIcon className='h-3 w-3' /> } color="cyan" />
                            <Button link={ `/users/avatars/${ user.id }` } icon={ <UserIcon className='h-3 w-3' /> } color="cyan" />
                            <Button link={ `/moderation/infractions/${ user.id }` } icon={ <ScaleIcon className='h-3 w-3' /> } color="cyan" />
                            <Button link={ `/moderation/abuse-reports/${ user.id }` } icon={ <BellAlertIcon className='h-3 w-3' /> } color="cyan" />
                            <Button link={ `/items/${ user.id }` } icon={ <CubeIcon className='h-3 w-3' /> } color="cyan" />
                            <Button link={ `/spaces/${ user.id }` } icon={ <HomeModernIcon className='h-3 w-3' /> } color="cyan" />
                            <Button onClick={ logoutUser } icon={ <ArrowLeftOnRectangleIcon className='h-3 w-3' /> } color="cyan" />
                            <Button title="Remover o usuário de todos os espaços" onClick={ removeFromSpaces } icon={ <ArrowDownOnSquareIcon className='h-3 w-3' /> } color="cyan" />
                        </div>
                    </Card>
                )) }

            </div>
        </div>
    )
}
