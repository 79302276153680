import { getSpaces } from "services/spaceService";

const { createSlice } = require("@reduxjs/toolkit");

const spacesSlice = createSlice({
    name: "spaces",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            idSpace: '',
            nameSpace: '',
            idAvatar: '',
            idModel: ''
        }
    },
    reducers: {
        setSpacesData: (state, action) => {
            return {...state, data: action.payload}
        },
        addSpacesData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setSpacesFilter: (state, action) => {
            return {...state, filters: action.payload}
        },
    }
})

export async function fetchSpaces(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const spaces = await getSpaces(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addSpacesData(spaces))
        }
        else{
            dispatch(setSpacesData(spaces));
        }
        
        if(spaces.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Espaços', error)
        dispatch(setCurrentSkip(0));
        dispatch(setSpacesData([]));
        dispatch(setHasMore(false));

    }
}

export async function fetchSpacesFilters(dispatch, params){
        dispatch(setSpacesFilter(params));
}

export const { setSpacesData, setCurrentSkip, setHasMore, addSpacesData, setSpacesFilter } = spacesSlice.actions
export default spacesSlice.reducer