import moment from "moment"

class InterstitialGroup {
    constructor({ groupId, name, active, token, tokenAge }) {
        this.groupId = groupId
        this.name = name
        this.active = active
        this.token = token
        this.tokenAge = tokenAge
    }

    get() {
        return {
            groupId: this.groupId,
            name: this.name,
            cooldown: this.formatDuration,
            active: this.active,
            token: this.token,
            tokenAge: this.tokenAge

        }
    }

    get formatDuration(){
        const duration = moment.duration(this.tokenAge, 'seconds');
    
        if (duration.asWeeks() >= 1) {
            return `${Math.floor(duration.asWeeks())} semana(s)`;
        } else if (duration.asDays() >= 1) {
            return `${Math.floor(duration.asDays())} dia(s)`;
        } else if (duration.asHours() >= 1) {
            return `${Math.floor(duration.asHours())} hora(s)`;
        } else if (duration.asMinutes() >= 1) {
            return `${Math.floor(duration.asMinutes())} minuto(s)`;
        } else {
            return `${this.tokenAge} segundo(s)`;
        }
    };
}

export default InterstitialGroup