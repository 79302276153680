
class SpinCategory {
    constructor({ id, name, weighting, background, active, count, isDeluxe, totalWeighting }) {
        this.id = id
        this.name = name
        this.weighting = weighting
        this.background = background
        this.active = active
        this.count = count
        this.isDeluxe = isDeluxe
        this.totalWeighting = totalWeighting
    }

    get() {
        return {
            id: this.id,
            name: this.count > 1 ? `${this.name} ${this.count}x` : this.name,
            weighting: `${this.weighting} (${this.calculoPercentage}%)`,
            background: this.background,
            active: this.active,
            count: this.count,
            isDeluxe: this.isDeluxe,
            totalWeighting: this.totalWeighting
        }
    }

    get calculoPercentage() {
        if (((this.weighting / this.totalWeighting) * 100) > 1) {
            return Math.round(((this.weighting / this.totalWeighting) * 100));
        }
        else if (((this.weighting / this.totalWeighting) * 100) > 0 && ((this.weighting / this.totalWeighting) * 100) < 1) {
            let str = ((this.weighting / this.totalWeighting) * 100).toString();
            let firstNonZeroIndex = str.search(/[1-9]/);

            if (firstNonZeroIndex !== -1) {
                let significantPart = str.substring(0, firstNonZeroIndex + 2);
                return parseFloat(significantPart).toFixed(firstNonZeroIndex + 1 - str.indexOf('.')).replace(/\.?0+$/, '');
            }
            else {
                return 0;
            }
        } else {
            return ((this.weighting / this.totalWeighting) * 100);
        }




    }
}

export default SpinCategory