import { createSlice } from '@reduxjs/toolkit'
import { getSelectedInterstitialItemDetails } from 'services/interstitialService';

const selectedInterstitialItemDetailsSlice = createSlice({
    name: 'selectedInterstitialItemDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedInterstitialItemDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedInterstitialItemDetails(dispatch, params){
    try{
        const details = await getSelectedInterstitialItemDetails(params);
        console.log(details)
        dispatch(setSelectedInterstitialItemDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedInterstitialItemDetailsDetails } = selectedInterstitialItemDetailsSlice.actions
export default selectedInterstitialItemDetailsSlice.reducer