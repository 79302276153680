import handleRequestError from "helpers/apiErrorHandler";
import api, { apiNormalize } from "./api";
import { prizesMapping, spinCategoriesMapping } from "domains/Item/Spin/mapping";
import SpinCategory from "domains/Item/Spin/SpinCategory";
import SpinCategoryDetail from "domains/Item/Spin/SpinCategoryDetail";
import SpinPrize from "domains/Item/Spin/SpinPrize";

export async function getSpinCategories(isDeluxe) {
    try {
        const response = await api.get("/spin/getSpinCategories", { params: { isDeluxe }});
        const spinCategories = apiNormalize.responseMapping(response.data, spinCategoriesMapping)
        const normalizedSpinCategories = spinCategories.map(item => new SpinCategory(item).get());
        return normalizedSpinCategories
    }
    catch (error) {
        handleRequestError(error)
    }

}

export async function deleteSpinCategory(id){
    try{
        const response = await api.post(`/spin/${id}/deleteSpinCategory`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
    }
}

export const createSpinCategory = async (params) => {
    try {
        const response = await api.post('/spin/createSpinCategory', { params })
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedSpinCategoryDetails = async (params) => {
    try {
        const response = await api.get('/spin/spinCategoryDetails', { params })
        const detail = spinCategoriesMapping(response.data[0])
        const normalizedDetail = new SpinCategoryDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveSpinCategory = async (id, changes) => {
    try{
        const response = await api.post(`/spin/${id}/saveSpinCategory`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const getSelectedSpinPrizes = async (params) => {
    try {
        const response = await api.get('/spin/getSelectedSpinPrizes', { params })
        const prizes = apiNormalize.responseMapping(response.data, prizesMapping)
        const normalizedSpinPrizes = prizes.map(prize => new SpinPrize(prize).get());
        return normalizedSpinPrizes
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveSpinCategoryPrize = async (apiInfo) => {
    try{
        const response = await api.post(`/spin/saveSpinCategoryPrize`, {apiInfo})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export async function deleteSpinPrize(id){
    try{
        const response = await api.post(`/spin/${id}/deleteSpinPrize`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
    }
}