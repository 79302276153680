import { createSlice } from '@reduxjs/toolkit'

const spaceCategoriesSlice = createSlice({
    name: 'spaceCategories',
    initialState: [],
    reducers: {
        setSpaceCategories: (state, action) => {
            return action.payload
        },
    },
})

export const { setSpaceCategories } = spaceCategoriesSlice.actions
export default spaceCategoriesSlice.reducer
