import { emailsMapping } from 'domains/User/Emails/mapping';
import api, { apiNormalize } from './api'
import Email from '../domains/User/Emails/Email'
import handleRequestError from 'helpers/apiErrorHandler';

export async function getEmails(params, skip){
    const response = await api.get("/users/emails", {params:{...params, skip}});
    const emails = apiNormalize.responseMapping(response.data, emailsMapping)
    const normalizedEmails = emails.map(email => new Email(email).get());
    return normalizedEmails
}

export const getEmailById = async (id) => {
    try {
        const response = await api.get(`/users/email/${id}`)
        return await response.data
    } catch (error) {
        handleRequestError(error)
    }
}