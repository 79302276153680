import { createSlice } from '@reduxjs/toolkit'
import { getSelectedSpinCategoryDetails } from 'services/spinService';

const selectedSpinCategoryDetailsSlice = createSlice({
    name: 'selectedSpinCategoryDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedSpinCategoryDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedSpinCategoryDetails(dispatch, params){
    try{
        const details = await getSelectedSpinCategoryDetails(params);
        dispatch(setSelectedSpinCategoryDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedSpinCategoryDetailsDetails } = selectedSpinCategoryDetailsSlice.actions
export default selectedSpinCategoryDetailsSlice.reducer