import React from 'react'
import { Link } from 'react-router-dom'

export default function Revenue () {
    return (
        <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">Receitas</h3>
            <Link to='/revenue/transactions'>Transações</Link>
        </div>
    )
}
