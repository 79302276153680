import { useEffect, useState } from "react";
import { UseItem } from "./itemSearch";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { returnItemToInventory } from "services/itemService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchSelectedItemDetails } from "store/modules/items/selectedItemDetailSlice";
import Tabs from "components/Tabs";
import useUserPermission from "domains/Permissions/useUserPermission";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";

export default function Details({ item }) {
    const verifySpaceRemove = useUserPermission(SMIPermissionsCode.ITEMS_SPACE_REMOVE);

    const itemsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value' },
    ]

    const firstItemData = UseItem(item);
    const [itemData, setItemData] = useState(firstItemData)

    useEffect(() => {
        let firstItemDataCopy = { ...firstItemData }
        setItemData(firstItemDataCopy)

    }, [firstItemData])

    const dispatch = useDispatch()

    const handleReturnToInventory = async () => {
        try {
            await returnItemToInventory(item.id)

            fetchSelectedItemDetails(dispatch, { id: item.id });
            toast.success('Item Retornado com Sucesso!')
        }
        catch {
            toast.error('Erro ao Retornar Item!')
        }
    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <BasicTable
                    height="100%"
                    headers={itemsHeaders}
                    items={itemData.tableProperties}
                />
            )
        }
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>
                        <b>{itemData.modelName}</b>
                        <p>{item.id}</p>
                        </div>
                        { verifySpaceRemove && <Button text="Devolver Item" color="cyan" onClick={handleReturnToInventory} disabled={!(itemData.spaceId != '-')} />}
                    </div>
                )}
            />
        </div>
    )
}