import moment from "moment"

class InterstitialDetail {
    constructor({ interstitialId, name, active, groupId, imgUrl, redirectUrl, newWindow, startDate, endDate, token, tokenAge, groupName }) {
        this.interstitialId = interstitialId
        this.name = name
        this.active = active
        this.groupId = groupId
        this.imgUrl = imgUrl
        this.redirectUrl = redirectUrl
        this.newWindow = newWindow
        this.startDate = startDate
        this.endDate = endDate
        this.token = token
        this.tokenAge = tokenAge
        this.groupName = groupName
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'active',
                    name: "Ativo",
                    value: this.active,
                },
                {
                    key: 'interstitialId',
                    name: "ID Interstitial",
                    value: this.interstitialId,
                },
                {
                    key: 'name',
                    name: "Título",
                    value: this.name,
                },
                {
                    key: 'groupName',
                    name: "Grupo",
                    value: this.groupName,
                },
                {
                    key: 'imgUrl',
                    name: "Imagem",
                    value: this.imgUrl,
                },
                {
                    key: 'redirectUrl',
                    name: "URL",
                    value: this.redirectUrl,
                },
                {
                    key: 'token',
                    name: "Token",
                    value: this.token,
                },
                {
                    key: 'cooldown',
                    name: "Duração do Token",
                    value: this.formatDuration,
                },
                {
                    key: 'newWindow',
                    name: "Nova Janela",
                    value: this.newWindow,
                },
                {
                    key: 'startDate',
                    name: "Data de Início",
                    value: moment(this.startDate).format('DD/MM/YYYY') + " " + moment(this.startDate).format('HH:mm'),
                },
                {
                    key: 'endDate',
                    name: "Data de Término",
                    value: moment(this.endDate).format('DD/MM/YYYY') + " " + moment(this.endDate).format('HH:mm'),
                },
            ],
            interstitialId: this.interstitialId,
            name: this.name,
            active: this.active,
            groupId: this.groupId,
            groupName: this.groupName,
            imgUrl: this.imgUrl,
            redirectUrl: this.redirectUrl,
            newWindow: this.newWindow,
            startDate: moment(this.startDate).format('DD/MM/YYYY') + " " + moment(this.startDate).format('HH:mm'),
            endDate: moment(this.endDate).format('DD/MM/YYYY') + " " + moment(this.endDate).format('HH:mm'),
            token: this.token,
            tokenAge: this.tokenAge,
            cooldown: this.formatDuration,
        }
    }

    get formatDuration(){
        const duration = moment.duration(this.tokenAge, 'seconds');
    
        if (duration.asWeeks() >= 1) {
            return `${Math.floor(duration.asWeeks())} semana(s)`;
        } else if (duration.asDays() >= 1) {
            return `${Math.floor(duration.asDays())} dia(s)`;
        } else if (duration.asHours() >= 1) {
            return `${Math.floor(duration.asHours())} hora(s)`;
        } else if (duration.asMinutes() >= 1) {
            return `${Math.floor(duration.asMinutes())} minuto(s)`;
        } else {
            return `${this.tokenAge} segundo(s)`;
        }
    };
}

export default InterstitialDetail