
import { getSpaceCategories } from 'services/spaceService'
import { setSpaceCategories } from './categorySlice'

export const fetchSpaceCategories = (params) => async (dispatch) => {
    try {
        const categories = await getSpaceCategories(params)
        dispatch(setSpaceCategories(categories))
    } catch (error) {
        console.error('Erro ao carregar categorias:', error)
    }
}
