import moment from "moment"

class UserDetail {
    constructor({ id, firstName, lastName, email, avatar, authenticator, dateOfBirth, gender, primaryGroupId, secondaryGroups, gold, cp, tokens, modNotes }, isEmail) {
        this.id = id
        this.firstName = firstName
        this.lastName = lastName
        this.dateOfBirth = dateOfBirth
        this.gender = gender
        this.email = email
        this.avatar = avatar
        this.authenticator = authenticator
        this.primaryGroupId = primaryGroupId
        this.secondaryGroups = secondaryGroups
        this.gold = gold
        this.cp = cp
        this.tokens = tokens
        this.modNotes = modNotes
        this.isEmail = isEmail
    }

    get() {
        return {
            tableProperties: this.isEmail ? [
                {
                    key: 'id',
                    name: "ID",
                    value: this.id,
                },
                {
                    key: 'firstName',
                    name: "Primeiro Nome",
                    value: this.firstName
                },
                {
                    key: 'lastName',
                    name: "Último Nome",
                    value: this.lastName,
                },
                {
                    key: 'dateOfBirth',
                    name: "Data de Nascimento",
                    value: moment(this.dateOfBirth).format("DD/MM/YYYY")
                },
                {
                    key: 'gender',
                    name: "Gênero",
                    value: this.gender,
                },
                {
                    key: 'email',
                    name: "Email",
                    value: this.email,
                },
            ] : [
                {
                    key: 'id',
                    name: "ID",
                    value: this.id,
                },
                {
                    key: 'firstName',
                    name: "Primeiro Nome",
                    value: this.firstName
                },
                {
                    key: 'lastName',
                    name: "Último Nome",
                    value: this.lastName,
                },
                {
                    key: 'dateOfBirth',
                    name: "Data de Nascimento",
                    value: moment(this.dateOfBirth).format("DD/MM/YYYY")
                },
                {
                    key: 'gender',
                    name: "Gênero",
                    value: this.gender,
                },
            ]
            ,
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            dateOfBirth: this.dateOfBirth,
            gender: this.gender,
            email: this.email,
            avatar: this.avatar,
            authenticator: this.authenticator,
            dateOfBirth: this.dateOfBirth,
            gender: this.gender,
            primaryGroupId: this.primaryGroupId,
            secondaryGroups: this.secondaryGroups,
            gold: this.gold,
            tokens: this.tokens,
            cp: this.cp,
            modNotes: this.modNotes
        }

    }
}

export default UserDetail
