import { getMissions } from "services/missionsService";

const { createSlice } = require("@reduxjs/toolkit");

const missionsSlice = createSlice({
    name: 'missions',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            missionId: '',
            missionName: '',
            avatarId: '',
            spaceId: ''
        }
    },
    reducers: {
        setMissionsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addMissionsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setMissionsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchMissions(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const missions = await getMissions(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addMissionsData(missions))
        }
        else{
            dispatch(setMissionsData(missions));
        }
        
        if(missions.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Missões', error)
    }
}

export async function fetchMissionsFilters(dispatch, params){
    dispatch(setMissionsFilter(params));
}

export const { setMissionsData, addMissionsData, setCurrentSkip, setHasMore, setMissionsFilter } = missionsSlice.actions
export default missionsSlice.reducer