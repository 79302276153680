const kudosLogs
    = {
    headers: [
        { title: 'Timestamp' },
        { title: 'Task' },
    ],
    items: [
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
        { timestamp: '2023-04-06 18:35:05', task: 'Test task' },
    ]
}

export default kudosLogs

