const modLogsMapping = item => ({
    date: item.date ?? null,
    property: item.property ?? null,
    oldValue: item.oldValue ? (typeof item.oldValue == 'object' ? JSON.stringify(item.oldValue, null, 4) : item.oldValue) : null,
    newValue: item.newValue ? (typeof item.newValue == 'object' ? JSON.stringify(item.newValue, null, 4) : item.newValue) : null,
    modFirstName: item.modFirstName ?? null,
    modLastName: item.modLastName ?? null,
    modId: item.modId ?? null
})

export {modLogsMapping}