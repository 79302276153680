const friendsAvatarMapping = (item) => ({
    senderId: item.senderId ?? null,
    senderFirstName: item.senderFirstName ?? null,
    senderLastName: item.senderLastName ?? null,
    senderNameInstance: item.senderNameInstance ?? null,
    senderIcon: (!item.senderFirstName || !item.senderLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.senderIcon}`,
    receiverId: item.receiverId ?? null,
    receiverFirstName: item.receiverFirstName ?? null,
    receiverLastName: item.receiverLastName ?? null,
    receiverNameInstance: item.receiverNameInstance ?? null,
    receiverIcon: (!item.receiverFirstName || !item.receiverLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" :`https://cdn.minimania.app/avatars/${item.receiverIcon}`,
    isAccepted: item.isAccepted ? "Sim" : "Pendente"
})

export {friendsAvatarMapping}