import React, { useRef } from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { required, minLength, isEmail } from 'helpers/validation'

const FormNewUser = ({ onFormChange }) => {
    const formRef = useRef()

    const handleSubmit = (formData, isValid) => {
        onFormChange(formData, isValid)
        console.log('Dados do formulário:', formData, isValid)
    }

    // TODO: Tipo de transação deve ser dinâmico e buscar as opções a partir da API
    const transactionTypes = [
        { value: '', name: '-- Tipo de transação --' },
        { value: '1', name: 'Transferência' },
        { value: '2', name: 'Cartão de Crédito' },
        { value: '3', name: 'Pix' },
    ]

    const fields = [
        {
            name: 'email',
            label: 'Email',
            value: '',
            placeholder: 'usuario@exemplo.com',
            type: 'email',
            validate: [
                { rule: required },
                { rule: isEmail }
            ],
            gridClass: 'col-span-12'
        },
        {
            name: 'password',
            label: 'Senha',
            value: '',
            placeholder: 'Senha',
            type: 'password',
            validate: [
                { rule: required },
                { rule: minLength, args: [8] }
            ],
            gridClass: 'col-span-12'
        }
    ]

    return (
        <>
            <DefaultForm ref={ formRef } fields={ fields } onChange={ handleSubmit } />
        </>
    )
}

export default FormNewUser
