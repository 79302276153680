const activities = {
    headers: [
        { title: 'Timestamp' },
        { title: 'Avatar' },
        { title: 'Space' },
        { title: 'Duração' },
        { title: 'Campo' },
    ],
    items: [
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
        { timestamp: '2023-04-06 18:35:05', avatar: 'Test Avatar', space: 'Test Space', duration: '00:05:25', field: 'test' },
    ]
}

export default activities
