import { isAuthenticated } from "services/auth";
import {io} from 'socket.io-client'
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSocketClient } from "store/modules/socketSlice";

let storedSocket = null;

export default function useSMISocket() {
    const token = isAuthenticated();
    const [socket, setSocket] = useState(storedSocket);

    useEffect(() => {
        if(!socket) {
            const socket = io("https://smi-listener.azurewebsites.net", {
                auth: {
                    swsid: token
                },
                transports: ["websocket"]
            });
    
            storedSocket = socket;
            setSocket(storedSocket);

            socket.connect();
    
            return () => {
                //socket.disconnect();
                //storedSocket = null
            }
        }
    }, [token])

    return socket;
}