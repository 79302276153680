import { getSpaceMembers } from "services/spaceService";

const { createSlice } = require("@reduxjs/toolkit");

const spaceMembersSlice = createSlice({
    name: "spaceMembers",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
    },
    reducers: {
        setSpaceMembersData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addSpaceMembersData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
    }
})

export async function fetchSpaceMembers(dispatch, params, skip) {
    try {
        const LIMIT = 200;
        const spaceMembers = await getSpaceMembers(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addSpaceMembersData(spaceMembers))
        }
        else {
            dispatch(setSpaceMembersData(spaceMembers));
        }

        if (spaceMembers.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Membros', error)
    }
}

export const { setSpaceMembersData, addSpaceMembersData, setCurrentSkip, setHasMore } = spaceMembersSlice.actions
export default spaceMembersSlice.reducer