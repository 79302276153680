class AvatarOption{
    constructor({options}){
        this.options = options
    }

    get(){
        return{
            options: this.options
        }
    }
}

export default AvatarOption