import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import FormSearch from './Partials/FormSearch'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../src/styles/Search.module.css'
import style from '../../../src/styles/UserTabs.module.css'
import proofStyle from '../../../src/styles/Proof.module.css'
import messageStyle from '../../../src/styles/Message.module.css'
import { fetchAbuseReports } from 'store/modules/moderation/abuseReports/abuseReportSlice'
import { useNavigate } from 'react-router-dom'
import { assignAbuseReports, closeAbuseReports, deleteChatLogProofList, deleteTransactionProofList, reactivateClosedAbuseReports, reassignAbuseReports, saveProofList } from 'services/abuseReportService'
import { toast } from 'react-toastify'
import Modal from 'components/Modal'
import Tabs from 'components/Tabs'
import { getChatLog, getChatLogs } from 'services/chatLogService'
import moment from 'moment'
import { Paths } from 'paths/Paths'
import { ChatMessage } from 'Pages/Spaces/Partials/chatMessage'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { getTransactionById, getTransactions } from 'services/transactionService'
import { getAvatars, getSelectedAvatarDetails } from 'services/avatarsService'
import { InfinitySpin } from 'react-loader-spinner'
import { getInfractionOptions, getUserInfractionPoints, getUserStep, reverseUserInfraction, saveUserInfraction } from 'services/infractionService'
import ButtonCss from 'components/BasicTable/ButtonCss'
import Verified from '../../../src/img/verified.png'
import Cross from '../../../src/img/cross.png'
import Attention from '../../../src/img/attention.png'
import Notification from '../../../src/img/notification.png'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function AbuseReports({ type, reportType, categoryId, reporterId, reportedId, assignedTo, status, dateFrom, dateTo }) {
    const verifyAbuseManage = useUserPermission(SMIPermissionsCode.MOD_ABUSE_MANAGE)
    const verifyAbuseGoTo = useUserPermission(SMIPermissionsCode.MOD_ABUSE_REPORT_GOTO_SPACE)

    const statusIconObject = { key: 'statusIcon' }
    const reporterIconObject = { key: 'reporterIcon' }
    const reportedIconObject = { key: 'reportedIcon' }

    const [abuseReportsHeaders, setAbuseReportsHeaders] = useState([
        { title: 'Data', key: 'date', icon: () => statusIconObject, detailable: verifyAbuseGoTo, appear: true },
        { title: 'Denunciante', key: 'reporterFullName', selectable: true, copy: 'reporterId', icon: () => reporterIconObject, appear: true },
        { title: 'Denunciado', key: 'reportedFullName', selectable: true, copy: 'reportedId', icon: () => reportedIconObject, appear: true },
        { title: 'Categoria', key: 'category', appear: true },
        { title: 'Motivo', key: 'reason', appear: true },
        { title: 'Provas', key: 'isProofList', selectable: true, appear: true },
        { title: 'Responsável', key: 'responsable', selectable: true, copy: 'assignedToId', appear: true },
    ])

    const [abuseReportsMomentHeaders, setAbuseReportsMomentHeaders] = useState([...abuseReportsHeaders]);

    const handleAppearHeader = (item) => {
        setAbuseReportsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setAbuseReportsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [isProofLoading, setProofLoading] = useState(false)
    const [searchedAux, setSearchedAux] = useState(false)
    const [abuseReportIds, setAbuseReportIds] = useState([]);
    const [isProofModalOpen, setIsProofModalOpen] = useState(false);
    const [isProofListModalOpen, setIsProofListModalOpen] = useState(false);
    const [actualProofList, setActualProofList] = useState([]);
    const [actualAbuseReportId, setActualAbuseReportId] = useState([]);
    const navigate = useNavigate();

    const abuseReports = useSelector((state) => {
        return Array.isArray(state.abuseReports.data) ? state.abuseReports.data : []
    })

    const skip = useSelector((state) => {
        return (state.abuseReports.currentSkip) ? state.abuseReports.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.abuseReports.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.abuseReports.filters) ? state.abuseReports.filters : {}
    })

    const mod = useSelector((state) => {
        return Object(state.loggedUser) ? state.loggedUser : {}
    })


    const [actualStatus, setActualStatus] = useState('');

    useEffect(() => {
        setLoading(false);

        if (abuseReports.length > 0) {
            let tempStatus = filters.status.value;
            /*
            for (const item of abuseReports) {
                if (item.status !== tempStatus) {
                    tempStatus = '';
                    break;
                }
            }
            */
            setActualStatus(tempStatus);
        }
        else {
            let tempStatus = '';
            setActualStatus(tempStatus);
        }
    }, [abuseReports]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (type || reportType || categoryId || reporterId || reportedId || assignedTo || status || dateFrom || dateTo) {
            setLoading(true)
            fetchAbuseReports(dispatch, { type, reportType, categoryId, reporterId, reportedId, assignedTo, status, dateFrom, dateTo }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        let tempSearchedAux = !searchedAux
        setSearchedAux(tempSearchedAux);

        const query = new URLSearchParams();
        if (filter.form.type) query.append('type', filter.form.type);
        if (filter.form.reportType) query.append('reportType', filter.form.reportType);
        if (filter.form.categoryId) query.append('categoryId', filter.form.categoryId);
        if (filter.form.reporterId) query.append('reporterId', filter.form.reporterId);
        if (filter.form.reportedId) query.append('reportedId', filter.form.reportedId);
        if (filter.form.assignedTo) query.append('assignedTo', filter.form.assignedTo);
        if (filter.form.status) query.append('status', filter.form.status);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        window.history.pushState({}, '', `/moderation/abuse-reports?${query.toString()}`);

        fetchAbuseReports(dispatch, filter.form, 0);
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchAbuseReports(dispatch, filter.form, skip + 200);
    })

    useEffect(() => {
        let tempArr = [];
        setAbuseReportIds(tempArr);
    }, [searchedAux])

    const handleSelect = (item) => {
        let tempArr = [...abuseReportIds];
        if (!tempArr.includes(item.abuseReportId)) {
            tempArr.push(item.abuseReportId);
        }
        else {
            let index = tempArr.indexOf(item.abuseReportId);
            tempArr.splice(index, 1);

        }
        setAbuseReportIds(tempArr);
    }

    const handleRedirectChatLog = (item) => {
        const formattedDate = moment(item.date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm').replace(':', '%3A')
        navigate(`/moderation/chat-logs?avatarId=${item.reportedId}&dateTo=${formattedDate}`)
    }

    const handleSelectRedirect = (item, cell) => {
        if (cell.key == 'reporterFullName') {
            navigate(`/users/avatars?avatarId=${item.reporterId}`)
        }
        if (cell.key == 'reportedFullName') {
            navigate(`/users/avatars?avatarId=${item.reportedId}`)
        }
        if (cell.key == 'responsable') {
            navigate(`/users?tab=2&userId=${item.assignedToId}`)
        }
        if (cell.key == 'isProofList' && item.isProofList == 'Sim') {
            handleSelect(item);
            setActualAbuseReportId(item.abuseReportId)
            if (!(abuseReportIds.length > 1)) {
                setProofLoading(true)
                setActualProofList(item.proofList);
                handleProofListAvatars(item.proofList)
                handleProofListTransactions(item.proofList)
                handleOpenProofListModal();
            }
            else {
                setAbuseReportIds([])
            }
        }
    }

    const handleAcceptAbuseReport = async () => {

        try {
            await assignAbuseReports(abuseReportIds);

            setLoading(true)
            let tempSearchedAux = !searchedAux
            setSearchedAux(tempSearchedAux);
            await fetchAbuseReports(dispatch, filter.form, 0);

            toast.success('Denúncia(s) Atribuída(s) com Sucesso!')
        }
        catch {
            toast.error('Erro ao Atribuir Denúncia(s)!')
        }
    }

    const handleCloseAbuseReport = async () => {
        try {
            await closeAbuseReports(abuseReportIds);

            setLoading(true)
            let tempSearchedAux = !searchedAux
            setSearchedAux(tempSearchedAux);
            await fetchAbuseReports(dispatch, filter.form, 0);

            toast.success('Denúncia(s) Fechada(s) com Sucesso!')
        }
        catch {
            toast.error('Erro ao Fechar Denúncia(s)!')
        }
    }

    const handleReassignAbuseReport = async () => {
        try {
            await reassignAbuseReports(abuseReportIds);

            setLoading(true)
            let tempSearchedAux = !searchedAux
            setSearchedAux(tempSearchedAux);
            await fetchAbuseReports(dispatch, filter.form, 0);

            toast.success('Denúncia(s) Reatribuída(s) com Sucesso!')
        }
        catch {
            toast.error('Erro ao Reatribuir Denúncia(s)!')
        }
    }

    const handleReactivateAbuseReport = async () => {
        try {
            await reactivateClosedAbuseReports(abuseReportIds);

            setLoading(true)
            let tempSearchedAux = !searchedAux
            setSearchedAux(tempSearchedAux);
            await fetchAbuseReports(dispatch, filter.form, 0);

            toast.success('Denúncia(s) Reativadas(s) com Sucesso!')
        }
        catch {
            toast.error('Erro ao Reativar Denúncia(s)!')
        }
    }

    const [chatLogIdInputValue, setChatLogIdInputValue] = useState('');
    const [transactionIdInputValue, setTransactionIdInputValue] = useState('');
    const [proofList, setProofList] = useState([]);
    const [transactionsProof, setTransactionsProof] = useState([]);

    const handleOnChangeChatLogIdInput = (e) => {
        setChatLogIdInputValue(e.target.value);
    }

    const handleOnChangeTransactionIdInput = (e) => {
        setTransactionIdInputValue(e.target.value);
    }

    const handleOpenProofModal = () => {
        setIsProofModalOpen(true);
        setProofList([]);
    }

    const handleCloseProofModal = () => {
        setIsProofModalOpen(false);
        setTransactionIdInputValue('');
        setChatLogIdInputValue('');
        setProofList([]);
        setActualAbuseReportId('')
        setTransactionsProof([]);
    }

    const handleOpenProofListModal = () => {
        setIsProofListModalOpen(true);
    }

    const handleCloseProofListModal = () => {
        setAbuseReportIds([])
        setActualAbuseReportId('');
        setIsProofListModalOpen(false);
    }

    const handleAddChatLogProof = async () => {
        try {
            const msg = await getChatLog({ id: chatLogIdInputValue });
            setChatLogIdInputValue('');
            if (!msg.hasOwnProperty("messageId")) {
                toast.error('Erro ao Adicionar ChatLog!')
            }
            else {
                let newMsgProof = {
                    abuseReportId: abuseReportIds[0],
                    msgId: msg.messageId,
                    headUrl: Paths.getAvatarHeadURL(msg.avatarId, msg.headPostfix),
                    avatarFullName: msg.avatarName,
                    type: "chatLog",
                    timestamp: new Date(),
                    data: {
                        id: msg.avatarId,
                        message: msg.message,
                        timestamp: moment(msg.date, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'chatLog') {
                        if (item.msgId == newMsgProof.msgId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    tempList.push(newMsgProof);
                }
                else {
                    toast.error('ChatLog já adicionado!')
                }
                setProofList(tempList);
            }
        }
        catch {
            toast.error('Erro ao Adicionar ChatLog!')
        }
    }

    const transactionsHeaders = [
        { title: 'Tipo', key: 'type', copy: 'type' },
        { title: 'Remetente', key: 'sender', copy: 'senderId' },
        { title: 'Destinatário', key: 'receiver', copy: 'receiverId' },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' /> },
        { title: 'Detalhes', key: 'details' },
        { title: 'Data', key: 'timestamp' },
    ]

    const handleAddTransactionProof = async () => {
        try {
            const transaction = await getTransactions({ id: transactionIdInputValue }, 0);
            setTransactionIdInputValue('');
            if (transaction.length == 0) {
                toast.error('Erro ao Adicionar Transação!')
            }
            else {
                let newTransactionProof = {
                    abuseReportId: abuseReportIds[0],
                    transactionId: transaction[0].id,
                    type: "transaction",
                    addedBy: '',
                    timestamp: new Date(),
                    data: {
                        item: transaction[0].item == '-' ? null : transaction[0].item,
                        senderId: transaction[0].senderId,
                        senderName: transaction[0].sender,
                        receiverId: transaction[0].receiverId,
                        receiverName: transaction[0].receiver,
                        type: transaction[0].type,
                        details: transaction[0].details,
                        gold: transaction[0].gold,
                        tokens: transaction[0].tokens,
                        modelId: transaction[0].modelId == '-' ? null : transaction[0].modelId,
                        timestamp: moment(transaction[0].timestamp, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'transaction') {
                        if (item.transactionId == newTransactionProof.transactionId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    let tempArr = [...transactionsProof];
                    tempArr.push(transaction[0]);
                    setTransactionsProof(tempArr)

                    tempList.push(newTransactionProof);
                }
                else {
                    toast.error('Transação já adicionada!')
                }
                setProofList(tempList);
            }
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Adicionar Transação!')
        }
    }

    const handleSaveProofList = async () => {
        try {
            await saveProofList(proofList);
            handleCloseProofModal();

            search()

            toast.success('Prova(s) Anexada(s) com Sucesso!');
        }
        catch {
            toast.error('Erro ao Anexar Prova(s)!');
        }
    }

    const [transactionsProofList, setTransactionsProofList] = useState([]);
    const [avatarInfos, setAvatarInfos] = useState([])

    const handleProofListAvatars = async (list) => {
        let avatarInfos = []
        list.map((item, i) => {
            if (item.type == 'chatLog') {
                avatarInfos.push({ avatarId: item.data.avatarId, message: item.data.message, proofId: item._id, messageDate: item.data.timestamp })
            }
        })

        for (let i = 0; i < avatarInfos.length; i++) {
            let actualAvatar = await getAvatars({ avatarId: avatarInfos[i].avatarId }, 0);
            avatarInfos[i] = {
                avatarId: avatarInfos[i].avatarId,
                avatarName: actualAvatar[0].avatarName,
                headPostfix: actualAvatar[0].headPostfix,
                message: avatarInfos[i].message,
                proofId: avatarInfos[i].proofId,
                messageDate: avatarInfos[i].messageDate
            }
        }
        console.log(avatarInfos)
        setAvatarInfos(avatarInfos)
        setProofLoading(false)
    }

    const handleDeleteChatLogProof = async (proofId) => {
        try {
            await deleteChatLogProofList(actualAbuseReportId, proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)
            handleProofListAvatars(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }
    }

    const handleProofListTransactions = async (list) => {
        let transactionItems = []
        list.map((item, i) => {
            if (item.type == 'transaction') {
                transactionItems.push({
                    type: item.data.type,
                    sender: item.data.senderName,
                    senderId: item.data.senderId,
                    receiverId: item.data.receiverId,
                    receiver: item.data.receiverName,
                    gold: item.data.gold,
                    tokens: item.data.tokens,
                    details: item.data.details,
                    timestamp: moment(item.data.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY HH:mm"),
                    proofId: item._id
                });
            }
        })
        setTransactionsProofList(transactionItems)
    }


    const handleDeleteTransactionProof = async (tableItem) => {
        try {
            await deleteTransactionProofList(actualAbuseReportId, tableItem.proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == tableItem.proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)

            handleProofListTransactions(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }

    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.filterContainer}>
                            <input className={style.modalInput} type="text" placeholder={'ID da Mensagem'} value={chatLogIdInputValue} onChange={handleOnChangeChatLogIdInput} />
                            <Button disabled={chatLogIdInputValue == ''} text="Adicionar" onClick={handleAddChatLogProof} color="cyan" />
                        </div>
                        <div className={proofStyle.infoContainer}>
                            {proofList.map((item, i) => {
                                if (item.type == 'chatLog') {
                                    return (
                                        <ChatMessage key={i} avatarIcon={item.headUrl} avatarFirstName={item.avatarFullName} message={item.data.message} />
                                    )
                                }
                            })}
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.filterContainer}>
                            <input className={style.modalInput} type="text" placeholder={'ID de Transação'} value={transactionIdInputValue} onChange={handleOnChangeTransactionIdInput} />
                            <Button disabled={transactionIdInputValue == ''} text="Adicionar" onClick={handleAddTransactionProof} color="cyan" />
                        </div>
                        <div className={proofStyle.transactionInfoContainer}>
                            <BasicTable
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProof}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        }
    ]

    const proofListTabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.infoContainer}>
                            {
                                avatarInfos.map((item, i) => {
                                    return (
                                        <div className={proofStyle.manageChatLog}>
                                            <Button disabled={false} text="Excluir" onClick={() => handleDeleteChatLogProof(item.proofId)} color="cyan" />
                                            <div className={proofStyle.message}>
                                                <div className={proofStyle.messageContent}>
                                                    <ChatMessage key={i} avatarIcon={Paths.getAvatarHeadURL(item.avatarId, item.headPostfix)} avatarFirstName={item.avatarName} message={item.message} />
                                                </div>
                                                <div className={proofStyle.messageDate}>{moment(item.messageDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY HH:mm")}</div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.transactionInfoContainer}>
                            <BasicTable
                                onClickBtnItemInfo={'Excluir'}
                                hasButton={true}
                                onClickBtnItem={handleDeleteTransactionProof}
                                onClickBtnItemHeader={'Delete'}
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProofList}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        }
    ]

    const [isInfractionModalOpen, setIsInfractionModalOpen] = useState(false);
    const [infractionOptions, setInfractionOptions] = useState([])
    const [inputValue, setInputValue] = useState("")
    const [userPoints, setUserPoints] = useState(0)
    const [newPoints, setNewPoints] = useState(0)
    const [punishment, setPunishment] = useState("")

    const handleOpenInfractionModal = async () => {
        let apiInfractionOptions = await getInfractionOptions()
        setInputValue(JSON.stringify({ typeId: apiInfractionOptions[0].typeId, points: apiInfractionOptions[0].points }))
        let userInfractionPoints = await getUserInfractionPoints(abuseReportIds[0])
        let tempStep = await getUserStep(apiInfractionOptions[0].typeId, abuseReportIds[0])

        setPunishment(tempStep.step.name)
        setUserPoints(userInfractionPoints.currentPoints)
        setNewPoints(userInfractionPoints.currentPoints + apiInfractionOptions[0].points)

        setInfractionOptions(apiInfractionOptions)
        setIsInfractionModalOpen(true);
    }

    const handleCloseInfractionModal = () => {
        setInputValue("");
        setIsInfractionModalOpen(false);
    }

    const handleOnChangeInput = async (event) => {
        const selectedValue = JSON.parse(event.target.value);
        const typeId = selectedValue.typeId;
        const points = selectedValue.points;

        handleSetStep(typeId)

        setInputValue(event.target.value)

        setNewPoints(userPoints + points)
    }

    const handleSetStep = async (typeId) => {
        const tempStep = await getUserStep(typeId, abuseReportIds[0])
        setPunishment(tempStep.step.name)
    }

    const handleSaveUserInfraction = async () => {
        try {
            const selectedValue = JSON.parse(inputValue);
            await saveUserInfraction(selectedValue.typeId, abuseReportIds[0]);
            handleCloseInfractionModal();

            search()

            toast.success('Infração Aplicada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Aplicar Infração!')
        }
    }

    const handleReverseUserInfraction = async () => {
        try {
            await reverseUserInfraction(abuseReportIds);

            search()

            toast.success('Denúncia(s) Reativadas(s) com Sucesso!')
        }
        catch {
            toast.error('Erro ao Reativar Denúncia!')
        }
    }


    const handleCheckAllUnread = () => {
        let tempArr = []
        abuseReports.map((item) => {
            if (item.status == 'U') {
                tempArr.push(item.abuseReportId)
            }
        })
        if (JSON.stringify(tempArr) === JSON.stringify(abuseReportIds)) {
            tempArr = []
        }
        setAbuseReportIds(tempArr)
    }

    const handleCheckAllAssigned = () => {
        let tempArr = []
        abuseReports.map((item) => {
            if (item.status == 'A' && item.assignedToId == mod.id) {
                tempArr.push(item.abuseReportId)
            }
        })
        console.log(tempArr)
        console.log(abuseReportIds)
        if (JSON.stringify(tempArr) === JSON.stringify(abuseReportIds)) {
            tempArr = []
        }
        setAbuseReportIds(tempArr)

    }

    const handleCheckAllResolved = () => {
        let tempArr = []
        abuseReports.map((item) => {
            if (item.status == 'R' && item.assignedToId == mod.id) {
                tempArr.push(item.abuseReportId)
            }
        })
        if (JSON.stringify(tempArr) === JSON.stringify(abuseReportIds)) {
            tempArr = []
        }
        setAbuseReportIds(tempArr)
    }

    const handleCheckAllClosed = () => {
        let tempArr = []
        abuseReports.map((item) => {
            if (item.status == 'B' && item.assignedToId == mod.id) {
                tempArr.push(item.abuseReportId)
            }
        })
        if (JSON.stringify(tempArr) === JSON.stringify(abuseReportIds)) {
            tempArr = []
        }
        setAbuseReportIds(tempArr)
    }

    return (
        <>
            {verifyAbuseManage && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <FormSearch onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
                <div className='col-span-12' style={{ marginBottom: '50px' }}>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={abuseReportsHeaders}
                        headers={abuseReportsMomentHeaders}
                        title='Denúncias de Abuso'
                        selectItems={abuseReports.map((value) => {
                            return {
                                item: value,
                                selected: abuseReportIds.includes(value.abuseReportId)
                            }
                        })}
                        onDetailRow={handleRedirectChatLog}
                        selectKey={"abuseReportId"}
                        isLoading={isLoading && abuseReports.length === 0}
                        slotActions={
                            <div className={styles.slotAbuseReportBtnsFooter}>
                                <ButtonCss onlyIcon={true} icon={Notification} onClick={handleCheckAllUnread} imgHeight='18px' color='#e5e7eb' height='25px' width='25px' />
                                <ButtonCss onlyIcon={true} icon={Attention} onClick={handleCheckAllAssigned} imgHeight='19px' color='#e5e7eb' height='25px' width='25px' />
                                <ButtonCss onlyIcon={true} icon={Verified} onClick={handleCheckAllResolved} imgHeight='15px' color='#e5e7eb' height='25px' width='25px' />
                                <ButtonCss onlyIcon={true} icon={Cross} onClick={handleCheckAllClosed} imgHeight='15px' color='#e5e7eb' height='25px' width='25px' />
                                <Button disabled={(!filter.isValid)} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                            </div>
                        }
                        isSelectable={true}
                        onSelectItem={handleSelect}
                        onSelectRow={handleSelectRedirect}
                        slotFooter={
                            <div className={styles.slotAbuseReportFooter}>
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMore || isLoading || abuseReports.length == 0} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                    {(abuseReports.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : abuseReports.length} resultados</span>}
                                </div>
                                {
                                    (actualStatus == '') ?
                                        ""
                                        :
                                        (actualStatus == 'U') ?
                                            <div className={styles.slotAbuseReportBtnsFooter}>
                                                <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Adicionar Prova" onClick={handleOpenProofModal} color="cyan" />
                                                <Button disabled={(abuseReportIds.length == 0)} text="Aceitar" onClick={handleAcceptAbuseReport} color="cyan" />
                                            </div>
                                            :
                                            (actualStatus == 'A') ?
                                                <div className={styles.slotAbuseReportBtnsFooter}>
                                                    <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Adicionar Prova" onClick={handleOpenProofModal} color="cyan" />
                                                    <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Resolver" onClick={handleOpenInfractionModal} color="cyan" />
                                                    <Button disabled={(abuseReportIds.length == 0)} text="Fechar" onClick={handleCloseAbuseReport} color="cyan" />
                                                    <Button disabled={(abuseReportIds.length == 0)} text="Reatribuir" onClick={handleReassignAbuseReport} color="cyan" />
                                                </div>
                                                :
                                                (actualStatus == 'B') ?
                                                    <div className={styles.slotAbuseReportBtnsFooter}>
                                                        <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Adicionar Prova" onClick={handleOpenProofModal} color="cyan" />
                                                        <Button disabled={(abuseReportIds.length == 0)} text="Reativar" onClick={handleReactivateAbuseReport} color="cyan" />
                                                    </div>
                                                    :
                                                    (actualStatus == 'R') ?
                                                        <div className={styles.slotAbuseReportBtnsFooter}>
                                                            <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Adicionar Prova" onClick={handleOpenProofModal} color="cyan" />
                                                            <Button disabled={(abuseReportIds.length == 0)} text="Reativar" onClick={handleReverseUserInfraction} color="cyan" />
                                                        </div>
                                                        :
                                                        ""
                                }
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isProofModalOpen}
                        onClose={handleCloseProofModal}
                        footer={
                            <div className={proofStyle.proofModalControler}>
                                <Button disabled={!(proofList.length > 0)} text="Descartar" onClick={() => { setProofList([]), setTransactionsProof([]) }} color="cyan" />
                                <Button disabled={!(proofList.length > 0)} text="Salvar" onClick={handleSaveProofList} color="cyan" />
                            </div>
                        }
                    >
                        <div className={proofStyle.proofModalContainer}>
                            <Tabs
                                tabs={tabs}
                            />
                        </div>

                    </Modal>
                    <Modal
                        isOpen={isProofListModalOpen}
                        onClose={handleCloseProofListModal}
                    >
                        <div className={proofStyle.proofModalContainer}>
                            {isProofLoading && <div className={proofStyle.proofModalContainerSpin}>
                                <InfinitySpin
                                    width='200'
                                    color="#155f75"
                                />
                            </div>}
                            {!isProofLoading && <Tabs
                                tabs={proofListTabs}
                            />}
                        </div>

                    </Modal>
                    <Modal
                        isOpen={isInfractionModalOpen}
                        onClose={handleCloseInfractionModal}
                        footer={
                            <div className={proofStyle.proofModalControler}>
                                <Button disabled={false} text="Salvar" onClick={handleSaveUserInfraction} color="cyan" />
                            </div>
                        }
                        header={'Log Infraction'}
                    >
                        <div className={proofStyle.infractionModalContainer}>
                            <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                {infractionOptions && infractionOptions.length > 0 ? (
                                    infractionOptions.map((item, index) => (
                                        <option key={item.typeId} value={JSON.stringify({ typeId: item.typeId, points: item.points })}>
                                            {`${item.desc} (${item.points} pontos)`}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>Sem opções</option>
                                )}
                            </select>
                            <div className={proofStyle.pointsInfo}>
                                <span>Pontos Atuais: {userPoints}</span>
                                <span>Nova Pontuação: {newPoints}</span>
                                <span>Punição: {punishment}</span>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>}
        </>
    )
}
