import { useEffect, useState } from 'react';
import EntryStyle from '../../../styles/EntryContainer.module.css'
import UserTabsStyle from '../../../styles/UserTabs.module.css'
import { getCategories } from 'services/categoryService';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import InputMask from 'react-input-mask'
import moment from 'moment';
import { BiCoin } from 'react-icons/bi';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Tag from '../../../img/tag.png'

export default function EntryContainer({ key, value, onAddCategory, onChangeStartDate, onChangeEndDate, onChangeDiscountValue, onCloseCategory, onCloseModel }) {

    useEffect(() => {
        console.log(value)
    }, [])

    const [editCategoryInput, setEditCategoryInput] = useState('');
    const [categoryEditList, setCategoryEditList] = useState([]);
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const categories = useSelector((state) => {
        return Array.isArray(state.categories) ? state.categories : []
    })

    useEffect(() => {
        getCategories()

        /*
        let tempArr = []
        categories.map((item) => {
            tempArr.push({
                value: item.id,
                label: item.name
            })
        })
        */

        let tempArr = []
        for (let i = 0; i < categories.length; i++) {
            tempArr.push({
                label: categories[i].name,
                value: categories[i].id,
            })

            if (categories[i].children.length > 0) {
                for (let j = 0; j < categories[i].children.length; j++) {
                    tempArr.push({
                        label: `${categories[i].name} > ${categories[i].children[j].name}`,
                        value: categories[i].children[j].id,
                    })
                }
            }
        }

        setCategoryEditList(tempArr);
    }, [])

    const handleEditCategoryInput = (option) => {
        onAddCategory(option, value.modelId)
        setEditCategoryInput('');
    };

    const handleChangeStartDate = (e, category) => {
        onChangeStartDate(e.target.value, category, value.modelId)
    }

    const handleChangeEndDate = (e, category) => {
        onChangeEndDate(e.target.value, category, value.modelId)
    }

    const handleChangeDiscountValue = (e) => {
        onChangeDiscountValue(Math.round(e.target.value * 10) / 10, value.modelId)
    }

    const handleCloseCategory = (category) => {
        onCloseCategory(category, value.modelId)
    }

    const handleCloseModel = () => {
        onCloseModel(value.modelId)
    }

    return (
        <div className={EntryStyle.generalContainer}>

            <div className={EntryStyle.modelInfo}>
                <img src={value.icon} />
                <span>{value.modelName}</span>
            </div>

            <div className={EntryStyle.addCategory}>
                <span>Adicionar:</span>
                <Select
                    classNamePrefix="select"
                    onChange={handleEditCategoryInput}
                    value={editCategoryInput}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="categories"
                    options={categoryEditList}
                    styles={customStyles}
                    placeholder={"Selecione"}
                />
            </div>

            <div className={EntryStyle.categories}>
                {
                    Object.keys(value.categories).map((key, i) => {

                        let startDate = value.categories[key].startDate ? moment(value.categories[key].startDate).format('YYYY-MM-DDTHH:mm') : '';
                        let endDate = value.categories[key].endDate ? moment(value.categories[key].endDate).format('YYYY-MM-DDTHH:mm') : '';

                        return (
                            <div className={EntryStyle.categoryItemContainer}>

                                <div className={EntryStyle.categoryItemName}>
                                    <span>{value.categories[key].categoryComposeName}</span>
                                </div>

                                <div className={EntryStyle.categoryItemDates}>
                                    <div className={EntryStyle.categoryItemStartDate}>
                                        <span>De:</span>
                                        <input value={startDate} onChange={(e) => { handleChangeStartDate(e, key) }} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                                    </div>
                                    <div className={EntryStyle.categoryItemEndDate}>
                                        <span>Até:</span>
                                        <input value={endDate} onChange={(e) => { handleChangeEndDate(e, key) }} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                                    </div>
                                </div>

                                <div className={EntryStyle.categoryItemClose}>
                                    <button
                                        type="button"
                                        className="items-center text-gray-400 hover:text-gray-500"
                                        onClick={() => { handleCloseCategory(key) }}>
                                        <XMarkIcon className='h-4 w-4' />
                                    </button>
                                </div>

                            </div>
                        )
                    })
                }
            </div>

            <div className={EntryStyle.financial}>
                <div className={EntryStyle.financialDiscount}>
                    <img src={Tag} className='w-4 h-4' />
                    <input type='number' className={UserTabsStyle.modalInput} value={value.discount} onChange={handleChangeDiscountValue} min="0" max="100" step="0.1" />
                </div>
                <div className={EntryStyle.financialPrice}>
                    {!value.discounted && <div className={EntryStyle.financialPrice}>
                        <span>{value.goldPrice}</span>
                        <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />
                    </div>}

                    {value.discounted && <div>
                        <div className={EntryStyle.financialPrice}>
                            <span className="opacity-50 line-through">{value.goldPrice}</span>
                            <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />
                        </div>
                        <div className={EntryStyle.financialPrice}>
                            <span>{String(Number(value.goldPrice) - (Number(value.goldPrice) * Number(value.discount) * 0.01)).replace('.', ',')}</span>
                            <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />
                        </div>
                    </div>}


                </div>
            </div>

            <div className={EntryStyle.modalItemClose}>
                <button
                    type="button"
                    className="items-center text-gray-400 hover:text-gray-500"
                    onClick={handleCloseModel}>
                    <XMarkIcon className='h-4 w-4' />
                </button>
            </div>

        </div>
    )
}