import React, { useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import TransactionsForm from './Partials/Transactionsform'
import useTransactions from './hooks/useTransactions'
import Modal from 'components/Modal'
import styles from '../../../styles/Search.module.css'

import { TransactionDetailsModal } from './Partials/TransactionDetailsModal'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Transactions ({sender, receiver, space_id, space_instance_id, item_id, dateFrom, dateTo, transactionType, desc}) {

    const verifyTransaction = useUserPermission(SMIPermissionsCode.REVENUE_TRANSACTION_LOGS);
    const verifyAnalyseTransaction = useUserPermission(SMIPermissionsCode.REVENUE_ANALYSE_TRANSACTIONS);

    const modelIcon = { key: 'modelIcon' }
    
    const [transactionsHeaders, setTransactionsHeaders] = useState([
        { title: 'ID', key: 'small_id', copy: 'id', detailable: verifyAnalyseTransaction, appear: true },
        { title: 'Tipo', key: 'type', copy: 'type', appear: true },
        { title: 'Espaço', key: 'small_space', copy: 'space', appear: true },
        { title: 'Item', key: 'small_item', copy: 'item', appear: true },
        { title: 'ID do Model', key: 'modelId', copy: 'modelId', icon: () => modelIcon, appear: true},
        { title: 'Remetente', key: 'sender', copy: 'senderId', appear: true },
        { title: 'Destinatário', key: 'receiver', copy: 'receiverId', appear: true},
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500'/>, appear: true },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />, appear: true },
        { title: 'Detalhes', key: 'details', appear: true },
        { title: 'Data', key: 'timestamp', appear: true },
    ])

    const [transactionsMomentHeaders, setTransactionsMomentHeaders] = useState([...transactionsHeaders])

    const handleAppearHeader = (item) => {
        setTransactionsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setTransactionsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    console.log(transactionType)

    const [detailsStateModal, setDetailsStateModal] = useState(false)
    const [detailsItem, setDetailsItem] = useState({
        _id: '',
        description: '',
        senterIP: '',
        receiverIP: '',
        tokens: 0,
        gold: 0,
        transactionType: '',
        timestamp: '',
        sender: {
            _id: '',
            firstName: '',
            lastName: '',
            currentAvatar: ''
        },
        receiver: {
            _id: '',
            firstName: '',
            lastName: '',
            currentAvatar: ''
        },
        item: {
            _id: '',
            model_id: '',
            model: {
                model_id: 0,
                model_icon: '',
                model_desc: ''
            }
        },
        spaceInstanceId: 0
    })

    const {
        isLoading,
        isLoadingDetails,
        filter,
        transactions,
        hasMore,
        skip,
        search,
        cancel,
        handleFormChange,
        handleLoadMore,
        searchTransactionById,
        filters
    } = useTransactions(sender, receiver, space_id, space_instance_id, item_id, dateFrom, dateTo, transactionType, desc)

    const FormActions  = () => {
        return (
            <>
                <Button text="Cancelar" onClick={ cancel } color="gray" colorTone={400} />
                <Button disabled={ !filter.isValid } text="Buscar" onClick={ search } color="cyan" isLoading={ isLoading } />
            </>
        )
    }

    async function selectedTransactionDetails(item) {
        setDetailsStateModal(true)

        const transaction = await searchTransactionById(item)
        setDetailsItem(transaction)
    }

    const tableProps = {
        title: 'Buscar Transações',
        headers: transactionsHeaders,
        items: transactions,
        isLoading: (isLoading  && transactions.length===0),
        slotHeader: <TransactionsForm onInputChange={handleFormChange} onFormChange={handleFormChange} defaultValues={filters}/>,
        slotActions: <FormActions />,
        onDetailRow: selectedTransactionDetails
    };

    return (
        <>
            {filter.value}
            { verifyTransaction && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable {...tableProps} 
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={transactionsHeaders}
                        headers={transactionsMomentHeaders}
                        slotFooter={ 
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={ handleLoadMore } color="cyan" /> 
                                {(transactions.length===0)? "" : <span>Exibindo {0}-{(hasMore) ? skip+200 : transactions.length} resultados</span>}
                            </div>
                        }
                    />
                </div>

                <TransactionDetailsModal
                    isOpen={detailsStateModal} 
                    onClose={setDetailsStateModal} 
                    item={detailsItem}
                    isLoading={isLoadingDetails}
                />
            </div>}
        </>
    );
}
