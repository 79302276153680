import { createSlice } from '@reduxjs/toolkit'

const loggedUserSlice = createSlice({
    name: 'loggedUser',
    initialState: {},
    reducers: {
        setLoggedUser: (state, action) => {
            return action.payload
        },
    },
})

export const { setLoggedUser } = loggedUserSlice.actions
export default loggedUserSlice.reducer
