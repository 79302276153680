import ChatLog from 'domains/Moderation/ChatLog/ChatLog';
import api, { apiNormalize } from './api'
import { chatLogsMapping } from 'domains/Moderation/ChatLog/mapping';
import handleRequestError from 'helpers/apiErrorHandler';

export async function getChatLogs(params, skip){
    console.log(params)
    const response = await api.get("/chatLogs", {params:{...params, skip}});
    const chatLogs = apiNormalize.responseMapping(response.data, chatLogsMapping)
    const normalizedIChatLogs = chatLogs.map(chatLog => new ChatLog(chatLog).get());
    return normalizedIChatLogs
}

export const getChatLog = async (params) => {
    try {
        const response = await api.get('/chatLogs/byId', { params })
        const chatLog = apiNormalize.responseMapping(response.data, chatLogsMapping)
        const normalizedChatLog = chatLog.map(chatLog => new ChatLog(chatLog).get());
        return normalizedChatLog[0]
    } catch (error) {
        handleRequestError(error)
    }
}