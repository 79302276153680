import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import FormSearch from './Partials/FormSearch'
import Button from 'components/form/Button'
import styles from '../../../styles/Search.module.css'
import proofStyle from '../../../styles/Proof.module.css'

import { points } from 'data/users'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllInfractions } from 'store/modules/moderation/infractions/allInfractionsSlice'
import { toast } from 'react-toastify'
import { reverseInfraction } from 'services/infractionService'
import { InfinitySpin } from 'react-loader-spinner'
import Tabs from 'components/Tabs'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { getAvatars } from 'services/avatarsService'
import { deleteChatLogProofList, deleteTransactionProofList } from 'services/abuseReportService'
import { ChatMessage } from 'Pages/Spaces/Partials/chatMessage'
import { Paths } from 'paths/Paths'
import Modal from 'components/Modal'
import moment from 'moment'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Infractions({ userId, infractionId, punishmentId, dateFrom, dateTo }) {
    const verifyInfractions = useUserPermission(SMIPermissionsCode.MOD_VIEW_INFRACTIONS);
    const verifyInfractionPunishments = useUserPermission(SMIPermissionsCode.MOD_SEE_INFRACTION_PUNISHMENTS);
    const verifyReverseInfraction = useUserPermission(SMIPermissionsCode.MOD_REVERSE_INFRACTIONS);

    const [infractionsHeaders, setInfractionsHeaders] = useState(verifyInfractionPunishments ? [
        { title: "Data", key: "date", appear: true },
        { title: "Usuário", key: "userName", copy: "userId", appear: true },
        { title: "Infração", key: "offence", appear: true },
        { title: "Punição", key: "punishment", appear: true},
        { title: 'Provas', key: 'isProofList', selectable: true, appear: true },
        { title: "Moderador", key: "modName", copy: "modId", appear: true },
        { title: "Ativo", key: "active", appear: true },
    ] : [
        { title: "Data", key: "date", appear: true },
        { title: "Usuário", key: "userName", copy: "userId", appear: true },
        { title: "Infração", key: "offence", appear: true },
        { title: 'Provas', key: 'isProofList', selectable: true, appear: true },
        { title: "Moderador", key: "modName", copy: "modId", appear: true },
        { title: "Ativo", key: "active", appear: true },
    ])

    const [infractionsMomentHeaders, setInfractionsMomentHeaders] = useState([...infractionsHeaders])

    const handleAppearHeader = (item) => {
        setInfractionsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setInfractionsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const allInfractions = useSelector((state) => {
        return Array.isArray(state.allInfractions.data) ? state.allInfractions.data : []
    })

    const skip = useSelector((state) => {
        return (state.allInfractions.currentSkip) ? state.allInfractions.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.allInfractions.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.allInfractions.filters) ? state.allInfractions.filters : {}
    })

    console.log(filters)

    useEffect(() => {
        setLoading(false)
    }, [allInfractions]);

    const dispatch = useDispatch()


    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (userId || infractionId || punishmentId || dateFrom || dateTo) {
            setLoading(true)
            fetchAllInfractions(dispatch, { userId, infractionId, punishmentId, dateFrom, dateTo }, 0);
        }
    }


    const search = () => {
        setLoading(true)

        const query = new URLSearchParams();
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.infractionId) query.append('infractionId', filter.form.infractionId);
        if (filter.form.punishmentId) query.append('punishmentId', filter.form.punishmentId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        window.history.pushState({}, '', `/moderation/infractions?${query.toString()}`);

        fetchAllInfractions(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchAllInfractions(dispatch, filter.form, skip + 200);
    })

    const handleReverseInfraction = async (item) => {
        console.log(item)
        try {
            await reverseInfraction(item.userInfractionId);


            search()

            toast.success('Infração Desativada com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Desativar Infração!');
        }

    }

    const [isProofListModalOpen, setIsProofListModalOpen] = useState(false);
    const [actualProofList, setActualProofList] = useState([]);
    const [actualAbuseReportId, setActualAbuseReportId] = useState([]);
    const [transactionsProofList, setTransactionsProofList] = useState([]);
    const [avatarInfos, setAvatarInfos] = useState([]);
    const [isProofLoading, setProofLoading] = useState(false);

    const transactionsHeaders = [
        { title: 'Tipo', key: 'type', copy: 'type' },
        { title: 'Remetente', key: 'sender', copy: 'senderId' },
        { title: 'Destinatário', key: 'receiver', copy: 'receiverId' },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' /> },
        { title: 'Detalhes', key: 'details' },
        { title: 'Data', key: 'timestamp' },
    ]

    const handleOpenProofListModal = () => {
        setIsProofListModalOpen(true);
    }

    const handleCloseProofListModal = () => {
        setActualAbuseReportId('');
        setIsProofListModalOpen(false);
    }


    const handleSelect = (item, cell) => {
        if (cell.key == 'isProofList' && item.isProofList == 'Sim') {
            setActualAbuseReportId(item.abuseReportId)
            setProofLoading(true)
            setActualProofList(item.proofList);
            handleProofListAvatars(item.proofList)
            handleProofListTransactions(item.proofList)
            handleOpenProofListModal();
        }
    }

    const handleProofListAvatars = async (list) => {
        let avatarInfos = []
        list.map((item, i) => {
            if (item.type == 'chatLog') {
                avatarInfos.push({ avatarId: item.data.avatarId, message: item.data.message, proofId: item._id, messageDate: item.data.timestamp })
            }
        })

        for (let i = 0; i < avatarInfos.length; i++) {
            let actualAvatar = await getAvatars({ avatarId: avatarInfos[i].avatarId }, 0);
            avatarInfos[i] = {
                avatarId: avatarInfos[i].avatarId,
                avatarName: actualAvatar[0].avatarName,
                headPostfix: actualAvatar[0].headPostfix,
                message: avatarInfos[i].message,
                proofId: avatarInfos[i].proofId,
                messageDate: avatarInfos[i].messageDate
            }
        }
        console.log(avatarInfos)
        setAvatarInfos(avatarInfos)
        setProofLoading(false)
    }

    const handleDeleteChatLogProof = async (proofId) => {
        try {
            await deleteChatLogProofList(actualAbuseReportId, proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)
            handleProofListAvatars(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }
    }

    const handleProofListTransactions = async (list) => {
        let transactionItems = []
        list.map((item, i) => {
            if (item.type == 'transaction') {
                transactionItems.push({
                    type: item.data.type,
                    sender: item.data.senderName,
                    senderId: item.data.senderId,
                    receiverId: item.data.receiverId,
                    receiver: item.data.receiverName,
                    gold: item.data.gold,
                    tokens: item.data.tokens,
                    details: item.data.details,
                    timestamp: moment(item.data.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY HH:mm"),
                    proofId: item._id
                });
            }
        })
        setTransactionsProofList(transactionItems)
    }


    const handleDeleteTransactionProof = async (tableItem) => {
        try {
            await deleteTransactionProofList(actualAbuseReportId, tableItem.proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == tableItem.proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)

            handleProofListTransactions(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }

    }

    const proofListTabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.infoContainer}>
                            {
                                avatarInfos.map((item, i) => {
                                    return (
                                        <div className={proofStyle.manageChatLog}>
                                            <Button disabled={false} text="Excluir" onClick={() => handleDeleteChatLogProof(item.proofId)} color="cyan" />
                                            <div className={proofStyle.message}>
                                                <div className={proofStyle.messageContent}>
                                                    <ChatMessage key={i} avatarIcon={Paths.getAvatarHeadURL(item.avatarId, item.headPostfix)} avatarFirstName={item.avatarName} message={item.message} />
                                                </div>
                                                <div className={proofStyle.messageDate}>{moment(item.messageDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY HH:mm")}</div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.transactionInfoContainer}>
                            <BasicTable
                                onClickBtnItemInfo={'Excluir'}
                                hasButton={true}
                                onClickBtnItem={handleDeleteTransactionProof}
                                onClickBtnItemHeader={'Delete'}
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProofList}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        }
    ]

    return (
        <>
            {verifyInfractions && <div>
                <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <FormSearch onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
            </div>
            <div className='col-span-12'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={infractionsHeaders}
                    headers={infractionsMomentHeaders}
                    onSelectRow={handleSelect}
                    hasButton={verifyReverseInfraction}
                    onClickBtnItem={handleReverseInfraction}
                    onClickBtnItemHeader={'Desativar'}
                    onClickBtnItemInfo={'Desativar'}
                    title='Buscar Infrações'
                    isLoading={isLoading && allInfractions.length === 0}
                    slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                    items={allInfractions}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(allInfractions.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : allInfractions.length} resultados</span>}
                        </div>
                    }
                />
            </div>
            {
                <Modal
                    isOpen={isProofListModalOpen}
                    onClose={handleCloseProofListModal}
                >
                    <div className={proofStyle.proofModalContainer}>
                        {isProofLoading && <div className={proofStyle.proofModalContainerSpin}>
                            <InfinitySpin
                                width='200'
                                color="#155f75"
                            />
                        </div>}
                        {!isProofLoading && <Tabs
                            tabs={proofListTabs}
                        />}
                    </div>

                </Modal>
            }
            </div>}
            
        </>
    )
}
