const itemsSearch = {
    headers: [
        { title: 'Item' },
        { title: 'Valor' },
        { title: 'Propriedade de' },
        { title: 'Local' },
        { title: 'Tipo' },
        { title: 'De' },
        { title: 'horário' },
    ],
    items: [
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
        { item: 'Collant Curto Play', price: '720', owner: 'Test User', location: '', type: 'Test', from: '', time: '' },
    ]
}

export default itemsSearch
