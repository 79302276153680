import React from 'react'
import Tabs from 'components/Tabs'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'

import { users } from 'data/users'


export default function Users () {
    return (
        <>
            <div className='ring-2 ring-black ring-opacity-10 rounded-lg'>
                <BasicTable
                    title='Cadeia de punições'
                    slotActions={ <Button text="Atualizar" color="cyan" /> }
                    headers={ users.headers }
                    items={ users.items }
                />
            </div>
            <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2 mt-4'>
                Detalhes...
            </div>
        </>
    )
}
