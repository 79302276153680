import Modal from "components/Modal"

import { FaArrowAltCircleRight } from 'react-icons/fa'
import { PiCoinDuotone } from "react-icons/pi";
import { MdOutlineGeneratingTokens } from "react-icons/md";

export const TransactionDetailsModal = ({ isOpen, onClose, item, isLoading }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
        >
            <div className="">
                <h1 className='rounded text-xl text-gray-900 py-3 text-center'>Detalhes da transação</h1>
                <div>
                    <div className="grid grid-cols-2 mx-2 gap-2">
                        <div className="border border-gray-300 rounded shadow flex flex-col items-center p-2">
                            <h1 className="text-lg text-gray-600 flex items-center justify-center w-full border-b p-1">ID</h1>
                            <span className='text-gray-400 text-xl p-1'>{item._id}</span>
                        </div>
                        <div className="border border-gray-300 rounded shadow flex flex-col items-center p-2">
                            <h1 className="text-lg text-gray-600 flex items-center justify-center w-full border-b p-1">Tipo</h1>
                            <span className='text-gray-400 text-xl p-1'>{item.transactionType}</span>
                        </div>
                    </div>
                    <div className='bg-white grid grid-cols-2 border border-gray-300 rounded my-2 p-2 mx-2 shadow'>
                        <div className="flex justify-center flex-col items-center border-r border-gray-300">
                            <h1 className="text-lg text-gray-600 flex items-center justify-center gap-1 w-full border-b p-1">
                                <PiCoinDuotone className="text-yellow-500" size={24} />
                                Gold
                            </h1>
                            <span className='text-gray-400 text-2xl p-1'>{item.gold}</span>
                        </div>
                        <div className="flex justify-center flex-col items-center">
                            <h1 className="text-lg text-gray-600 flex items-center justify-center gap-1 w-full border-b p-1">
                                <MdOutlineGeneratingTokens className="text-violet-500" size={24} />
                                Tokens
                            </h1>
                            <span className='text-gray-400 text-2xl p-1'>{item.tokens}</span>
                        </div>
                    </div>
                    <div className='flex gap-4 flex-col lg:flex-row px-2'>
                        <div className='border border-gray-300 bg-white p-2 rounded lg:w-[350px] shadow'>
                            <h1 className='text-lg text-gray-600 flex items-center justify-center gap-1 w-full border-b p-1 mb-2'>Remetente</h1>
                            {item.sender ? (
                                <div className="flex flex-col gap-2">
                                    <div className="flex justify-between">
                                        <p className="text-gray-600">ID</p>
                                        <p className='text-gray-400'>{item.sender._id}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="text-gray-600">Nome</p>
                                        <p className='text-gray-400'>{item.sender.firstName}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="text-gray-600">Sobrenome</p>
                                        <p className='text-gray-400'>{item.sender.lastName}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="text-gray-600">CurrentAvatar</p>
                                        <p className='text-gray-400'>{item.sender.currentAvatar}</p>
                                    </div>
                                </div>
                            ) :
                                <>
                                    <h1 className='text-gray-400 text-center'>Nenhum</h1>
                                </>
                            }

                        </div>
                        <div className='flex justify-center items-center'>
                            <FaArrowAltCircleRight size={42} className='text-gray-500' />
                        </div>
                        <div className='border border-gray-300 bg-white p-2 rounded lg:w-[350px] shadow'>
                            <h1 className='text-lg text-gray-600 flex items-center justify-center gap-1 w-full border-b p-1 mb-2'>Destinatário</h1>
                            <div>
                                {item.receiver ? (
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between">
                                            <p className="text-gray-600">ID</p>
                                            <p className='text-gray-400'>{item.receiver._id}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="text-gray-600">Nome</p>
                                            <p className='text-gray-400'>{item.receiver.firstName}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="text-gray-600">Sobrenome</p>
                                            <p className='text-gray-400'>{item.receiver.lastName}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="text-gray-600">CurrentAvatar</p>
                                            <p className='text-gray-400'>{item.receiver.currentAvatar}</p>
                                        </div>
                                    </div>
                                ) :

                                    <>
                                        <h1 className='text-gray-400 text-center'>Nenhum</h1>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="py-4 rounded-b"></div>
                </div>
            </div>
        </Modal>
    )
}