class CategoryModelEntry {
    constructor({ entryId, modelId, modelName, icon, goldPrice, startDate, endDate, discount, discounted, categoryId, categoryName, parentCategoryName}) {
        this.entryId = entryId
        this.modelName = modelName
        this.icon = icon
        this.goldPrice = goldPrice
        this.startDate = startDate
        this.endDate = endDate
        this.discount = discount
        this.discounted = discounted
        this.modelId = modelId
        this.categoryId = categoryId
        this.categoryName = categoryName
        this.parentCategoryName = parentCategoryName
    }

    get() {
        return ({
            entryId: this.entryId,
            modelName: this.modelName,
            icon: this.icon,
            goldPrice: this.goldPrice,
            startDate: this.startDate ?? '',
            endDate: this.endDate ?? '',
            discount: Number(this.discount),
            discounted: this.discounted,
            modelId: this.modelId,
            categoryId: this.categoryId,
            categoryName: this.categoryName,
            categoryComposeName: this.parentCategoryName ? `${this.parentCategoryName} > ${this.categoryName}` : this.categoryName,
        })
    }
}

export default CategoryModelEntry