const categories = {
    headers: [
        { title: 'Categorias' },
        { title: '' },
    ],
    items: [
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
        { category: 'Test Category', value: '' },
    ]
}

export default categories
