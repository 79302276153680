import { getUsers, getUser, getUsersByIp, getUsersByAvatar } from 'services/userService'
import { setUsersData, setCurrentSkip, setHasMore, addUsersData, setUsersFilters } from './userSlice'
import { setSelectedUser } from './selectedUserSlice'
import { addUserCard, removeUserCard } from './cardSlice'

export const fetchUsers = (params, skip) => async (dispatch) => {
    try {
        const LIMIT = 200;
        const users = await getUsers(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addUsersData(users))
        }
        else {
            dispatch(setUsersData(users));
        }

        if (users.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Usuários', error)
    }
}

export const fetchUsersByAvatar = (params, skip) => async (dispatch) => {
    try {
        const LIMIT = 200;
        const users = await getUsersByAvatar(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addUsersData(users))
        }
        else {
            dispatch(setUsersData(users));
        }

        if (users.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Usuários', error)
    }
}

export async function fetchUsersFilters(dispatch, params){
    dispatch(setUsersFilters(params));
}

export const resetUsers = () => async (dispatch) => {
    try {
        dispatch(setUsersData([]))
        dispatch(setCurrentSkip(0));
        dispatch(setHasMore(true));

    } catch (error) {
        console.error('Erro ao resetar usuários:', error)
    }
}

export const fetchUsersByIp = (params, skip) => async (dispatch) => {
    try {
        const LIMIT = 200;
        const users = await getUsersByIp(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addUsersData(users))
        }
        else {
            dispatch(setUsersData(users));
        }

        if (users.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Usuários', error)
    }
}

export const openUserCard = (user) => async (dispatch) => {
    try {
        dispatch(addUserCard(user))
    } catch (error) {
        console.error('Erro ao selecionar usuário:', error)
    }
}

export const closeUserCard = (userId) => async (dispatch) => {
    try {
        dispatch(removeUserCard(userId))
    } catch (error) {
        console.error('Erro ao fechar usuário:', error)
    }
}

export const selectUserForDetails = (user) => async (dispatch) => {
    try {
        //const selectedUser = await getUser(params)
        dispatch(setSelectedUser(user))
    } catch (error) {
        console.error('Erro ao carregar usuário:', error)
    }
}
