import InterstitialGroup from "domains/Administration/interstitials/InterstitialGroup"
import api, { apiNormalize } from "./api"
import { interstitialDetailMapping, interstitialGroupDetailMapping, interstitialGroupMapping, interstitialMapping } from "domains/Administration/interstitials/mapping"
import handleRequestError from "helpers/apiErrorHandler"
import Interstitial from "domains/Administration/interstitials/Interstitial"
import moment from "moment"
import InterstitialDetail from "domains/Administration/interstitials/InterstitialDetail"
import InterstitialGroupDetail from "domains/Administration/interstitials/InterstitialGroupDetail"

export const getInterstitialGroups = async (params) => {
    try {
        const response = await api.get('/interstitial/getInterstitialGroups', { params })
        const groups = apiNormalize.responseMapping(response.data, interstitialGroupMapping)
        const normalizedGroups = groups.map(item => new InterstitialGroup(item).get())
        return normalizedGroups
    } catch (error) {
        handleRequestError(error)
    }
}

export const getInterstitials = async (id) => {
    try {
        const response = await api.get(`/interstitial/${id}/getInterstitials`)
        const interstitials = apiNormalize.responseMapping(response.data, interstitialMapping)
        const normalizedInterstitials = interstitials.map(item => new Interstitial(item).get())
        return normalizedInterstitials
    } catch (error) {
        handleRequestError(error)
    }
}

export const createInterstitialGroup = async (params) => {
    try {
        const response = await api.post('/interstitial/createInterstitialGroup', { params })
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const deleteGroup = async (groupId) => {
    try {
        const response = await api.post(`/interstitial/${groupId}/deleteGroup`)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const createInterstitial = async (
    createInterstitialNameInput,
    createInterstitialTokenInput,
    createInterstitialActiveInput,
    tokenAge,
    createInterstitialGroupInput,
    createInterstitialUrlInput,
    createInterstitialWindowInput,
    createInterstitialStartInput,
    createInterstitialEndInput,
    createInterstitialFile) => {
    try {
        const formData = new FormData();
        formData.append('img', createInterstitialFile);
        formData.append('token', createInterstitialTokenInput);
        formData.append('tokenAge', tokenAge);
        formData.append('startDate', moment(createInterstitialStartInput).utc().format());
        formData.append('endDate', moment(createInterstitialEndInput).utc().format());
        formData.append('active', createInterstitialActiveInput);
        formData.append('newWindow', createInterstitialWindowInput);
        formData.append('title', createInterstitialNameInput);
        formData.append('url', createInterstitialUrlInput);
        formData.append('groupId', createInterstitialGroupInput);

        const response = await api.post(`/interstitial/createInterstitial`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteInterstitial = async (interstitialId) => {
    try {
        const response = await api.post(`/interstitial/${interstitialId}/deleteInterstitial`)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveInterstitialImg = async (id, img) => {
    try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('img', img);
        const response = await api.post(`/interstitial/saveInterstitialImg`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getSelectedInterstitialItemDetails = async (params) => {
    try {
        const response = await api.get('/interstitial/interstitialItemDetails', { params })
        const detail = interstitialDetailMapping(response.data[0])
        const normalizedDetail = new InterstitialDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedInterstitialGroupDetails = async (params) => {
    try {
        const response = await api.get('/interstitial/interstitialGroupDetails', { params })
        const detail = interstitialGroupDetailMapping(response.data[0])
        const normalizedDetail = new InterstitialGroupDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveInterstitialItem = async (interstitialId, changes) => {
    try{
        const response = await api.post(`/interstitial/${interstitialId}/saveInterstitialItem`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveInterstitialGroup = async (groupId, changes) => {
    try{
        const response = await api.post(`/interstitial/${groupId}/saveInterstitialGroup`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

