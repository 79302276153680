import { createSlice } from '@reduxjs/toolkit'
import { getSelectedUserDetails } from 'services/userService';

const selectedUserDetailsSlice = createSlice({
    name: 'selectedUserDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedUserDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedUserDetails(dispatch, params, isEmail){
    try{
        const details = await getSelectedUserDetails(params, isEmail);
        dispatch(setSelectedUserDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}



export const { setSelectedUserDetailsDetails } = selectedUserDetailsSlice.actions
export default selectedUserDetailsSlice.reducer
