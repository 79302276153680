import { getUserGroups } from "services/userGroupsService";

const { createSlice } = require("@reduxjs/toolkit");

const userGroupsSlice = createSlice({
    name: 'userGroups',
    initialState: {
        s: [],
        b: [],
        f: [],
        c: [],
        groups: [],
    },
    reducers: {
        setS: (state, action) => {
            return { ...state, s: action.payload }
        },
        setB: (state, action) => {
            return { ...state, b: action.payload }
        },
        setF: (state, action) => {
            return { ...state, f: action.payload }
        },
        setC: (state, action) => {
            return { ...state, c: action.payload }
        },
        setGroups: (state, action) => {
            return { ...state, groups: action.payload }
        },
    },
})

export async function fetchUserGroups(dispatch) {
    try {
        const userGroups = await getUserGroups();

        if (userGroups) {
            let sGroup = [];
            let bGroup = [];
            let fGroup = [];
            let cGroup = [];

            userGroups.forEach((group) => {
                if (group.type=="Grupo de Sistema") {
                    sGroup.push(group);
                }
                if (group.type=="Grupo de Ban") {
                    bGroup.push(group);
                }
                if (group.type=="Grupo de Fraude") {
                    fGroup.push(group);
                }
                if (group.type=="Grupo Customizado") {
                    cGroup.push(group);
                }
            });

            dispatch(setS(sGroup));
            dispatch(setB(bGroup));
            dispatch(setF(fGroup));
            dispatch(setC(cGroup));
            dispatch(setGroups(userGroups));
        }
    } catch (error) {
        console.error("Erro ao buscar grupos", error)
    }

}

export const { setS, setB, setC, setF, setGroups } = userGroupsSlice.actions
export default userGroupsSlice.reducer