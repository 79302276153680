import { createSlice } from '@reduxjs/toolkit'

const cardsSlice = createSlice({
    name: 'cards',
    initialState: [],
    reducers: {
        addUserCard: (state, action) => {
            const newCard = action.payload;

            const isCardAlreadyAdded = state.some(card => card.id === newCard.id);

            if (!isCardAlreadyAdded) {
                return [...state, newCard]
            } else {
                return state
            }
        },
        removeUserCard: (state, action) => {
            const idToRemove = action.payload
            return state.filter(card => card.id !== idToRemove)
        },
    },
})

export const { addUserCard, removeUserCard } = cardsSlice.actions
export default cardsSlice.reducer
