class Space {
    constructor({ spaceId, avatarId, modelId, spaceName, avatarFirstName, avatarLastName, modelName, avatarNameInstance }) {
        this.spaceId = spaceId
        this.avatarId = avatarId
        this.modelId = modelId
        this.spaceName = spaceName
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.modelName = modelName
        this.avatarNameInstance = avatarNameInstance
    }

    get() {
        return {
            id: this.spaceId,
            smallSpaceId: this.smallID(this.spaceId),
            spaceName: this.spaceName,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarName: this.avatarFullName,
            modelId: this.modelId,
            avatarId: this.avatarId,
            smallAvatarId: this.smallID(this.avatarId),
            modelName: this.modelName,
            avatarNameInstance: this.avatarNameInstance
        }
    }

    smallID(id) {
        return id?.slice(0, 7)
    }

    get avatarFullName() {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (this.avatarNameInstance > 1) return this.avatarFirstName + " " + this.avatarLastName + " the " + ordinal(this.avatarNameInstance);
        else if(this.avatarFirstName===null || this.avatarLastName === null) return "Avatar Apagado"
        else return this.avatarFirstName + " " + this.avatarLastName;
    }
}

export default Space