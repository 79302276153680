import ChatLogs from "Pages/Moderation/ChatLogs";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchChatLogsFilters } from "store/modules/moderation/chatLogs/chatLogsSlice";

export function ChatLogsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const avatarId = searchParams.get("avatarId");
    const spaceId = searchParams.get("spaceId");
    const instanceId = searchParams.get("instanceId");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    const messageText = searchParams.get("messageText");
    

    const dispatch = useDispatch()

    if (avatarId || spaceId || instanceId || dateFrom || dateTo || messageText) {
        fetchChatLogsFilters(dispatch, { avatarId, spaceId, instanceId, dateFrom, dateTo, messageText })
        return <ChatLogs avatarId={avatarId ?? null} spaceId={spaceId ?? null} instanceId={instanceId ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null} messageText={messageText ?? null}/>;
    }
    else{
        return <ChatLogs/>;
    }

}