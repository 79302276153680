class UserGroup{
    constructor({name, type, id}){
        this.name = name
        this.type = type
        this.id = id
    }

    get(){
        return{
            id: this.id,
            name: `${this.name} (${this.id})`,
            type: this.getType(this.type)
        }
    }

    getType(type){
        const types = {
            S: "Grupo de Sistema",
            B: "Grupo de Ban",
            F: "Grupo de Fraude",
            C: "Grupo Customizado",
        }

        return types[type]
    }
}

export default UserGroup