class AvatarPets{
    constructor({petName, petType, petDesc}){
            this.petName = petName
            this.petType = petType
            this.petDesc = petDesc
    }

    get(){
        return{
            petName: this.petName,
            petType: this.petType,
            petDesc: (this.petDesc == '') ? '-' : this.petDesc,
        }
    }
}

export default AvatarPets