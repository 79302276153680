import React, { useState, useEffect } from 'react'
import { FcSalesPerformance } from "react-icons/fc";
import { BiCoin, BiShield } from "react-icons/bi";
import { PiPokerChipBold } from "react-icons/pi";
import { BsStars } from "react-icons/bs";
import style from '../../styles/Search.module.css'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, fetchUsersByIp, selectUserForDetails, openUserCard, resetUsers } from 'store/modules'

import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import Filters from './Partials/Filters'
import Details from './Partials/Details'
import Modal from 'components/Modal'
import FormNewUser from './Partials/formNewUser'
import { fetchUsersByAvatar } from 'store/modules/users/userActions';
import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';

export default function Users({ tab, avatarFirstName, avatarLastName, avatarId, avatarExact, avatarOnline, userId, userFirstName, userLastName, userEmail, userExact, ip, ipOnline }) {

    const verifyUserList = useUserPermission(SMIPermissionsCode.MOD_USER_BASIC_VIEW);
    const verifyUserEdit = useUserPermission(SMIPermissionsCode.MOD_USER_BASIC_EDIT);
    const verifyUserCreate = useUserPermission(SMIPermissionsCode.MOD_USER_CREATE);

    const [usersHeaders, setUsersHeaders] = useState([
        { title: 'Usuário', key: 'name', selectable: true, appear: true },
        { title: 'ID', key: 'small_id', /*, actions: (<BiCoin className='w-4 h-4 mr-1 text-yellow-500' />), */ copy: 'id', detailable: verifyUserEdit, appear: true },
        { title: 'Avatar', key: 'avatar', copy: 'avatarId', appear: true },
        { title: 'Ouro', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />, appear: true },
        { title: 'Tokens', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />, appear: true },
        { title: 'CP', key: 'cp', icon: <BiShield className='w-4 h-4 mr-1 text-purple-700' />, appear: true },
    ])

    const [usersMomentHeaders, setUsersMomentHeaders] = useState([...usersHeaders])

    const handleAppearHeader = (item) => {
        setUsersHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setUsersMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [filter, setFilter] = useState({})

    const handleFormChange = (form, isValid) => {
        console.log(isValid)
        // handleSetFilter({ form, isValid })
    }

    // Redux
    const users = useSelector((state) => {
        return Array.isArray(state.users.data) ? state.users.data : []
    })

    const skip = useSelector((state) => {
        return (state.users.currentSkip) ? state.users.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.users.hasMore)
    })

    const selectedUser = useSelector((state) => {
        console.log(state.selectedUser);
        return state.selectedUser
    })

    useEffect(() => {
        setLoading(false)
    }, [users]);

    useEffect(() => {
        console.log('MUDOU');
    }, [selectedUser]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (tab) {
            setLoading(true)

            if (tab == '2') {
                dispatch(fetchUsers({ userId, userFirstName, userLastName, userEmail, userExact }, 0))
            }
            else if (tab == '1') {
                dispatch(fetchUsersByAvatar({ avatarFirstName, avatarLastName, avatarId, avatarExact, avatarOnline }, 0))
            }
            else if (tab == '3') {
                dispatch(fetchUsersByIp({ ip, ipOnline }, 0))
            }
        }
    }

    const search = () => {
        const actions = {
            byUser: fetchUsers,
            byAvatar: fetchUsersByAvatar,
            byIp: fetchUsersByIp,
        }

        const action = actions[filter.filter]

        if (action) {
            setLoading(true)
            if (action == fetchUsersByAvatar) {
                const query = new URLSearchParams();
                query.append('tab', 1);

                if (filter.form.avatarFirstName) query.append('avatarFirstName', filter.form.avatarFirstName);
                if (filter.form.avatarLastName) query.append('avatarLastName', filter.form.avatarLastName);
                if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
                if (filter.form.avatarExact) query.append('avatarExact', filter.form.avatarExact);
                if (filter.form.avatarOnline) query.append('avatarOnline', filter.form.avatarOnline);

                window.history.pushState({}, '', `/users?${query.toString()}`);
            }
            if (action == fetchUsers) {
                const query = new URLSearchParams();
                query.append('tab', 2);

                if (filter.form.userId) query.append('userId', filter.form.userId);
                if (filter.form.userFirstName) query.append('userFirstName', filter.form.userFirstName);
                if (filter.form.userLastName) query.append('userLastName', filter.form.userLastName);
                if (filter.form.userEmail) query.append('userEmail', filter.form.userEmail);
                if (filter.form.userExact) query.append('userExact', filter.form.userExact);

                window.history.pushState({}, '', `/users?${query.toString()}`);
            }
            if (action == fetchUsersByIp) {
                const query = new URLSearchParams();
                query.append('tab', 3);

                if (filter.form.ip) query.append('ip', filter.form.ip);
                if (filter.form.ipOnline) query.append('ipOnline', filter.form.ipOnline);

                window.history.pushState({}, '', `/users?${query.toString()}`);
            }
            dispatch(action(filter.form, 0))
        }
    }

    const handleOpenUserCard = (user) => {
        console.log(user);
        dispatch(openUserCard(user))
    }

    const handleUserDetails = (user) => {
        console.log(user);
        dispatch(selectUserForDetails(user))
    }

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleSetFilter = ({ filter, form, isValid }) => {
        console.log('AQUI');
        setFilter({ filter, form, isValid })
    }

    const handleLoadMore = (() => {
        const actions = {
            byUser: fetchUsers,
            byAvatar: fetchUsersByAvatar,
            byIp: fetchUsersByIp,
        }

        const action = actions[filter.filter];

        if (action) {
            setLoading(true);
            dispatch(action(filter.form, skip + 200));
        }
    })

    const handleResetUsers = () => {
        dispatch(resetUsers())
    }

    return (
        <>
            {filter.value}
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-6'>
                    { verifyUserList && <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={usersHeaders}
                        headers={usersMomentHeaders}
                        title='Buscar Usuários'
                        items={users}
                        onSelectRow={handleOpenUserCard}
                        onDetailRow={handleUserDetails}
                        isLoading={isLoading && users.length === 0}
                        slotHeader={<Filters tab={tab} onChangeTab={handleResetUsers} onFilterChange={handleSetFilter} />}
                        slotActions={<div className={style.userHeader}>{verifyUserCreate && <Button text="Novo Usuário" onClick={handleOpenModal} color="cyan" />} <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} /> </div>}
                        slotFooter={
                            <div className={style.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(users.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : users.length} resultados</span>}
                            </div>
                        }
                    />}
                </div>
                <div className='col-span-6'>
                    {selectedUser.id && <Details item={selectedUser} />}
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                header={<h3>Adicionar novo usuário</h3>}
                footer={<Button text="Fechar" onClick={handleCloseModal} color="cyan" />}
            >
                <div>
                    <FormNewUser onInputChange={handleFormChange} onFormChange={handleFormChange} />
                </div>
            </Modal>
        </>
    )
}
