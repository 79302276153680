import Friends from "Pages/Users/Friends";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchFriendsFilters } from "store/modules/users/friends/friendsSlice";

export function FriendsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const senderId = searchParams.get("senderId");
    const receiverId = searchParams.get("receiverId");
    const isAccepted = searchParams.get("isAccepted");

    const dispatch = useDispatch()

    if (senderId || receiverId || isAccepted) {
        fetchFriendsFilters(dispatch, { senderAvatarId: senderId, receiverAvatarId: receiverId, isAccepted: isAccepted })
        return <Friends senderId={senderId ?? null} receiverId={receiverId ?? null} isAccepted={isAccepted ?? null}/>;
    }
    else{
        return <Friends/>;
    }

}