import React from 'react'
import { Link } from 'react-router-dom'
import { PiSpinnerGapBold } from "react-icons/pi";
import style from '../../styles/UserTabs.module.css'

/**
 * A reusable button component that can be customized with various props.
 * @param {Object} props - The props object containing the button's properties.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.title - The title attribute for the button.
 * @param {string} props.link - The URL to link to when the button is clicked.
 * @param {function} props.onClick - The function to execute when the button is clicked.
 * @param {string} props.color - The color of the button.
 * @param {JSX.Element} props.icon - The icon to display on the button.
 * @param {string} props.className - The CSS class name(s) to apply to the button.
 * @param {string} props.type - The type of button (e.g. "submit").
 * @param {boolean} props.onlyIcon - Whether the button should only display the icon.
 * @param {boolean} props.isLoading - Whether the button is in a loading state.
 * @returns {JSX.Element} - The rendered button component.
 */
const ButtonCss = ({ text, title, link, onClick, icon, type, onlyIcon, isLoading, disabled, color, height, width, padding, isRound, imgHeight}) => {

    const buttonOnlyIcon = style.btnIcon
    const buttonClasses = onlyIcon ? buttonOnlyIcon : style.btn


    const classes = buttonClasses

    if (link) {
        // Se tiver um link, renderiza um link externo ou um link de rota dependendo do formato do link
        const isExternalLink = link.startsWith('http')
        if (isExternalLink) {
            return (
                <a href={ link } target="_blank" rel="noopener noreferrer" className={ classes } style={{backgroundColor: `${color}`, padding: `${padding ? padding : ''}`, height: `${height}`, width: `${width}`, borderRadius: `${isRound ? '50%' : ''}`}}>
                    <img style={{height: `${imgHeight}`}} src={icon}></img>
                    <span>{ text }</span>
                </a>
            )
        } else {
            return (
                <Link to={ link } className={ classes } style={{backgroundColor: `${color}`, padding: `${padding ? padding : ''}`, height: `${height}`, width: `${width}`, borderRadius: `${isRound ? '50%' : ''}`}}>
                    <img style={{height: `${imgHeight}`}} src={icon}></img>
                    <span>{ text }</span>
                </Link>
            )
        }
    } else if (onClick) {
        // Se tiver uma função onClick, renderiza um botão com a função de execução
        return (
            <button className={ classes } onClick={ onClick } title={ title } disabled={ isLoading || disabled } style={{backgroundColor: `${color}`, padding: `${padding ? padding : ''}`, height: `${height}`, width: `${width}`, borderRadius: `${isRound ? '50%' : ''}`}}>
                { isLoading && <PiSpinnerGapBold className='w-4 h-4 mr-1 text-white animate-spin' /> }
                <img style={{height: `${imgHeight}`}} src={icon}></img>
                <span>{ text }</span>
            </button>
        )
    } else if (type === 'submit') {
        // Se não tiver link nem onClick, renderiza um botão desabilitado
        return (
            <button className={ classes } style={{backgroundColor: `${color}`, padding: `${padding ? padding : ''}`, height: `${height}`, width: `${width}`, borderRadius: `${isRound ? '50%' : ''}`}}>
                <img style={{height: `${imgHeight}`}} src={icon}></img>
                <span>{ text }</span>
            </button>
        )
    } else {
        // Se não tiver link nem onClick, renderiza um botão desabilitado
        return (
            <button className={ classes } style={{backgroundColor: `${color}`, padding: `${padding ? padding : ''}`, height: `${height}`, width: `${width}`, borderRadius: `${isRound ? '50%' : ''}`}} disabled>
                <img style={{height: `${imgHeight}`}} src={icon}></img>
                <span>{ text }</span>
            </button>
        )
    }
}

export default ButtonCss
