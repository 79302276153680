import React from 'react'
import BasicTable from 'components/BasicTable'
import Modal from 'components/Modal'
import Button from 'components/form/Button'

// dados
import { infractions, staff } from 'data/dashboard'

const atualizar = () => {
    console.log('Atualizou!')
}

export default function Content () {
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-5'>
                    <BasicTable
                        title='Atual'
                        headers={ staff.headers }
                        items={ staff.items }
                        slotActions={ <Button text="Atualizar" onClick={ atualizar } color="cyan" /> }
                    />
                </div>
                <div className='col-span-7'>
                    <BasicTable
                        title='Próximos'
                        headers={ infractions.headers }
                        items={ infractions.items }
                        slotActions={ (
                            <>
                                <Button text="Atualizar" onClick={ atualizar } color="cyan" />
                            </>
                        ) }
                    />
                </div>
            </div>

            <Modal isOpen={ false } />


        </>
    )
}
