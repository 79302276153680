const validator = require('validator')

export function required (value) {
    return value ? null : 'Campo obrigatório'
}

export function isEmail (value) {
    return validator.isEmail(value) ? null : 'E-mail inválido'
}

export function minLength (value, length) {
    return value.length >= length ? null : `Deve ter pelo menos ${ length } caracteres`
}

export function isURL (value) {
    return validator.isURL(value) ? null : 'URL inválido'
}

export function isNumeric (value) {
    return validator.isNumeric(value) ? null : 'Deve ser um número'
}

export function isMobilePhone (value, locale) {
    return validator.isMobilePhone(value, locale) ? null : 'Número de telefone inválido'
}

export function isDate (value) {
    return validator.isDate(value) ? null : 'Data inválida'
}

export function isUUID (value) {
    return validator.isUUID(value) ? null : 'UUID inválido'
}

export function isPostalCode (value, locale) {
    return validator.isPostalCode(value, locale) ? null : 'Código postal inválido'
}
