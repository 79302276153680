import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedMissionDetails } from "store/modules/missions/selectedMissionDetailSlice";

export function UseMission(item){
    const mission = useSelector((state) => {
        return (state.selectedMissionDetails.details) ? state.selectedMissionDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedMissionDetails(dispatch, { id: item.missionId });
    }, [item])

    return mission
}