import BasicTable from "components/BasicTable"
import { useEffect, useState } from "react"
import { BiCoin } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import { fetchCategoryDetails } from "store/modules/items/catalog/categoryDetailsSlice"
import styles from '../../../styles/Search.module.css'
import proofStyle from '../../../styles/Proof.module.css'
import catalogStyle from '../../../styles/CatalogStyle.module.css'
import dndStyle from '../../../styles/DnDStyle.module.css'
import Button from "components/form/Button"
import Modal from "components/Modal"
import { InfinitySpin } from "react-loader-spinner"
import { deleteEntry, deleteInactives, getModelEntries, saveDiscount, saveEntries } from "services/categoryService"
import EntryContainer from "./EntryContainer"
import { toast } from "react-toastify"
import moment from "moment"
import { fetchModelList } from "store/modules/items/modelListSlice"
import Select from 'react-select'
import Tag from '../../../img/tag.png'
import UserTabsStyle from '../../../styles/UserTabs.module.css'

const iconObject = { key: 'icon' }

const categoryStatus = {
    activeIcon: 'ativo',
    inactiveIcon: 'inativo',
    scheduleIcon: 'aguardo',
    hasAction: false
}

const categoryActions = [
    { action: 'edit', icon: null },
    { action: 'delete', icon: null }
]

const categoryHeaders = [
    { title: 'Entrada', key: 'name', icon: () => iconObject },
    { title: 'Preço', key: 'salePriceGold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
    { title: 'Desconto', key: 'displayDiscount' },
    { title: 'Começo', key: 'startDate' },
    { title: 'Fim', key: 'endDate' },
    { title: 'Status', key: 'active', status: categoryStatus },
    { title: 'Ações', key: 'acoes', actions: () => categoryActions }
]


export default function CategoryDetails({ item }) {

    const [isLoading, setLoading] = useState(false)
    const [isControlModalOpen, setIsControlModalOpen] = useState(false)

    const categoryDetails = useSelector((state) => {
        return Array.isArray(state.categoryDetails.data) ? state.categoryDetails.data : []
    })

    const skip = useSelector((state) => {
        return (state.categoryDetails.currentSkip) ? state.categoryDetails.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.categoryDetails.hasMore)
    })

    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        fetchCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)
        console.log(categoryDetails)
    }, [item])

    const handleLoadMore = (() => {
        fetchCategoryDetails(dispatch, { id: item.id }, skip + 200);
    })

    //Manage Entries Development

    const [entries, setEntries] = useState({})
    const [entriesCopy, setEntriesCopy] = useState({})
    const [isEntryLoading, setIsEntryLoading] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [editEntryInput, setEditEntryInput] = useState('')
    const [order, setOrder] = useState([])
    const [isForAll, setIsForAll] = useState(false)

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const modelList = useSelector((state) => {
        return Array.isArray(state.modelList.data) ? state.modelList.data : []
    })

    useEffect(() => {
        if (modelList.length == 0) {
            fetchModelList(dispatch)
        }
    }, [item])

    function isEqual(obj1, obj2) {
        if (obj1 === null && obj2 === null) return true;

        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const handleIsForAll = (e) => {
        setIsForAll((value) => !value)

    }

    const handleCloseControlModal = () => {
        setEntries({})
        setEntriesCopy({})
        setHasChanged(false)
        setOrder([])
        setIsForAll(false)

        setLoading(true)
        fetchCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setIsControlModalOpen(false)
    }

    const handleOpenControlModal = () => {
        setIsControlModalOpen(true)
    }

    const handleAddEntry = async (tableItemModelId) => {

        let tempObject = {}
        if (!order.includes(tableItemModelId)) {

            const modelEntries = await getModelEntries(tableItemModelId)
            setOrder((prev) => [...prev, tableItemModelId])

            let modelId = modelEntries[0].modelId
            tempObject[modelId] = {
                icon: modelEntries[0].icon,
                modelId: modelEntries[0].modelId,
                modelName: modelEntries[0].modelName,
                goldPrice: modelEntries[0].goldPrice,
                discount: modelEntries[0].discount,
                discounted: modelEntries[0].discounted,
                categories: {}
            }

            for (let i = 0; i < modelEntries.length; i++) {
                if (modelEntries[i].categoryName) {
                    tempObject[modelId].categories[modelEntries[i].categoryName] = {
                        entryId: modelEntries[i].entryId,
                        categoryId: modelEntries[i].categoryId,
                        startDate: modelEntries[i].startDate,
                        endDate: modelEntries[i].endDate,
                        categoryName: modelEntries[i].categoryName,
                        categoryComposeName: modelEntries[i].categoryComposeName
                    }
                }
            }

            setEntries((prev) => { return ({ ...JSON.parse(JSON.stringify(tempObject)), ...prev }) })
            setEntriesCopy((prev) => { return ({ ...JSON.parse(JSON.stringify(tempObject)), ...prev }) })

            console.log(entriesCopy)

            setIsEntryLoading(false)

            toast.success('Item Adicionado!')
        }
        else {
            toast.error('Item já adicionado')
        }
    }

    const handleEditItem = (tableItem) => {
        setIsEntryLoading(true)
        handleAddEntry(tableItem.modelId)
        handleOpenControlModal()
    }

    const handleCloseModel = (modelId) => {
        const { [modelId]: _, ...newObj } = entries;
        setEntries(newObj);

        const { [modelId]: __, ...newObjCopy } = entriesCopy;
        setEntriesCopy(newObjCopy);

        let tempArr = [...order]
        tempArr.splice(order.indexOf(modelId), 1)
        setOrder(tempArr)

        if (!isEqual(newObj, newObjCopy)) {
            setHasChanged(true)
        }
        else {
            setHasChanged(false)
        }

    }

    const handleCloseCategory = (categoryName, modelId) => {
        if (isForAll == false) {
            const { [categoryName]: __, ...newObj } = entriesCopy[modelId].categories;

            let tempObj = { ...entriesCopy }

            tempObj[modelId].categories = newObj

            setEntriesCopy(tempObj);

            if (!isEqual(entries[modelId], entriesCopy[modelId])) {
                setHasChanged(true)
            }
            else {
                if ((!isEqual(entries, entriesCopy))) {
                    setHasChanged(true)
                }
                else {
                    setHasChanged(false)
                }
            }
        }
        else {
            let objKeys = Object.keys(entriesCopy)
            objKeys.map((key, i) => {
                const { [categoryName]: __, ...newObj } = entriesCopy[key].categories;

                let tempObj = { ...entriesCopy }

                tempObj[key].categories = newObj

                setEntriesCopy(tempObj);
            })
            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }

        }
    }

    const handleAddCategory = (option, modelId) => {
        if (isForAll == false) {
            if (!Object.keys(entriesCopy[modelId].categories).includes(option.label)) {
                let tempObj = { ...entriesCopy }

                const categoryNames = option.label.split('>')
                const categoryName = categoryNames[categoryNames.length - 1].trim()

                let newCateg = {}

                if (Object.keys(entries[modelId].categories).includes(option.label)) {
                    newCateg = {
                        entryId: entries[modelId].categories[option.label].entryId,
                        categoryId: entries[modelId].categories[option.label].categoryId,
                        startDate: entries[modelId].categories[option.label].startDate,
                        endDate: entries[modelId].categories[option.label].endDate,
                        categoryName: entries[modelId].categories[option.label].categoryName,
                        categoryComposeName: entries[modelId].categories[option.label].categoryComposeName
                    }
                }
                else {
                    newCateg = {
                        categoryId: option.value,
                        startDate: null,
                        endDate: null,
                        categoryName: categoryName,
                        categoryComposeName: option.label
                    }
                }
                tempObj[modelId].categories = { ...entriesCopy[modelId].categories, [categoryName]: newCateg }
                setEntriesCopy(tempObj)

                if (!isEqual(entries[modelId], entriesCopy[modelId])) {
                    setHasChanged(true)
                }
                else {
                    if ((!isEqual(entries, entriesCopy))) {
                        setHasChanged(true)
                    }
                    else {
                        setHasChanged(false)
                    }
                }


                toast.success('Categoria Adicionada com Sucesso!')
            }
            else {
                toast.error('Categoria já Existente!')
            }
        }
        else {

            let objKeys = Object.keys(entriesCopy)
            objKeys.map((key, i) => {
                if (!Object.keys(entriesCopy[key].categories).includes(option.label)) {
                    let tempObj = { ...entriesCopy }

                    const categoryNames = option.label.split('>')
                    const categoryName = categoryNames[categoryNames.length - 1].trim()

                    let newCateg = {}

                    if (Object.keys(entries[key].categories).includes(option.label)) {
                        newCateg = {
                            entryId: entries[key].categories[option.label].entryId,
                            categoryId: entries[key].categories[option.label].categoryId,
                            startDate: entries[key].categories[option.label].startDate,
                            endDate: entries[key].categories[option.label].endDate,
                            categoryName: entries[key].categories[option.label].categoryName,
                            categoryComposeName: entries[key].categories[option.label].categoryComposeName
                        }
                    }
                    else {
                        newCateg = {
                            categoryId: option.value,
                            startDate: null,
                            endDate: null,
                            categoryName: categoryName,
                            categoryComposeName: option.label
                        }
                    }
                    tempObj[key].categories = { ...entriesCopy[key].categories, [categoryName]: newCateg }
                    setEntriesCopy(tempObj)
                }
            })

            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }

        }
    }

    const handleChangeStartDate = (date, categoryName, modelId) => {
        if (isForAll == false) {
            let tempObj = { ...entriesCopy }

            tempObj[modelId].categories[categoryName].startDate = moment(date).toISOString()
            setEntriesCopy(tempObj)

            if (!isEqual(entries[modelId], entriesCopy[modelId])) {
                setHasChanged(true)
            }
            else {
                if ((!isEqual(entries, entriesCopy))) {
                    setHasChanged(true)
                }
                else {
                    setHasChanged(false)
                }
            }
        }
        else {
            let objKeys = Object.keys(entriesCopy)
            objKeys.map((key, i) => {
                let tempObj = { ...entriesCopy }

                if (tempObj[key].categories[categoryName]) {
                    tempObj[key].categories[categoryName].startDate = moment(date).toISOString()
                    setEntriesCopy(tempObj)
                }
            })

            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }

        }

    }

    const handleChangeEndDate = (date, categoryName, modelId) => {
        if (isForAll == false) {
            let tempObj = { ...entriesCopy }

            tempObj[modelId].categories[categoryName].endDate = moment(date).toISOString()
            setEntriesCopy(tempObj)

            if (!isEqual(entries[modelId], entriesCopy[modelId])) {
                setHasChanged(true)
            }
            else {
                if ((!isEqual(entries, entriesCopy))) {
                    setHasChanged(true)
                }
                else {
                    setHasChanged(false)
                }
            }
        }
        else {
            let objKeys = Object.keys(entriesCopy)
            objKeys.map((key, i) => {
                let tempObj = { ...entriesCopy }

                if (tempObj[key].categories[categoryName]) {
                    tempObj[key].categories[categoryName].endDate = moment(date).toISOString()
                    setEntriesCopy(tempObj)
                }
            })
            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }

        }

    }

    const handleChangeDiscountValue = (value, modelId) => {
        if (isForAll == false) {
            if (Number(value) >= 0 && Number(value) <= 100) {
                let tempObj = { ...entriesCopy }
                tempObj[modelId].discount = Number(value)

                if (value == 0) {
                    tempObj[modelId].discounted = false
                }
                else {
                    tempObj[modelId].discounted = true
                }
                setEntriesCopy(tempObj)

                if (!isEqual(entries[modelId], entriesCopy[modelId])) {
                    setHasChanged(true)
                }
                else {
                    if ((!isEqual(entries, entriesCopy))) {
                        setHasChanged(true)
                    }
                    else {
                        setHasChanged(false)
                    }
                }
            }
        }
        else {
            let objKeys = Object.keys(entriesCopy)
            objKeys.map((key, i) => {
                if (Number(value) >= 0 && Number(value) <= 100) {
                    let tempObj = { ...entriesCopy }
                    tempObj[key].discount = Number(value)

                    if (value == 0) {
                        tempObj[key].discounted = false
                    }
                    else {
                        tempObj[key].discounted = true
                    }
                    setEntriesCopy(tempObj)
                }
            })

            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }

        }
    }

    const handleEditEntryInput = (option) => {
        handleAddEntry(option.value);
        setEditEntryInput('')
    }

    const handleSaveEntries = async () => {
        try {
            let changedObj = {}
            let originalObjKeys = Object.keys(entries)
            originalObjKeys.map((key, i) => {
                if (!isEqual(entries[key], entriesCopy[key])) {
                    changedObj[key] = { ...entriesCopy[key] }
                }
            })

            await saveEntries(JSON.stringify(changedObj))

            handleCloseControlModal()

            toast.success("Dados Salvo com Sucesso!")
        } catch (ex) {
            console.log(ex)
            toast.error("Erro ao Salvar Dados!")
        }

    }

    //Delete Inactives Development

    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const handleOpenDeleteModal = () => {
        setIsDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setLoading(true)
        fetchCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setIsDeleteModal(false)
    }

    const handleDeleteInactives = async () => {
        try {

            await deleteInactives(item.id)

            handleCloseDeleteModal()

            toast.success('Operação Realizada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Realizar Operação!')
        }
    }

    //Delete Entry Development

    const [isDeleteEntryModal, setIsDeleteEntryModal] = useState(false)
    const [currentItemToDelete, setCurrentItemToDelete] = useState({})

    const handleOpenDeleteEntryModal = (tableItem) => {
        setIsDeleteEntryModal(true)
        setCurrentItemToDelete(tableItem)
    }

    const handleCloseDeleteEntryModal = () => {
        setLoading(true)
        fetchCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setCurrentItemToDelete({})
        setIsDeleteEntryModal(false)
    }

    const handleDeleteEntry = async () => {
        try {
            await deleteEntry(currentItemToDelete.entryId)

            handleCloseDeleteEntryModal()

            toast.success('Entrada Deletada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Deletar Entrada!')
        }
    }

    //Adjust Discount Development

    const [isAdjustDiscountModal, setIsAdjustDiscountModal] = useState(false);
    const [fromValueInput, setFromValueInput] = useState(0);
    const [toValueInput, setToValueInput] = useState(0);
    const [discountHasChanged, setDiscountHasChanged] = useState(false);

    const handleOpenAdjustDiscountModal = () => {
        setIsAdjustDiscountModal(true);
    }

    const handleCloseAdjustDiscountModal = () => {
        setLoading(true)
        fetchCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setFromValueInput(0);
        setToValueInput(0);
        setDiscountHasChanged(false);

        setIsAdjustDiscountModal(false);
    }

    const handleChangeFromValueInput = (e) => {
        setFromValueInput(Math.round(e.target.value * 10) / 10);
    }

    const handleChangeToValueInput = (e) => {
        setToValueInput(Math.round(e.target.value * 10) / 10);
    }

    useEffect(() => {
        if (fromValueInput == toValueInput) {
            setDiscountHasChanged(false);
        }
        else {
            setDiscountHasChanged(true);
        }
    }, [fromValueInput, toValueInput])

    const handleSaveDiscount = async () => {
        try {
            await saveDiscount(item.id, fromValueInput, toValueInput);

            handleCloseAdjustDiscountModal();

            toast.success('Operação Realizada com Sucesso');
        }
        catch {
            toast.error('Erro ao Realizar Operação!');
        }
    }

    return (
        <div>
            <BasicTable
                onEditItem={handleEditItem}
                onDeleteItem={handleOpenDeleteEntryModal}
                title='Entradas'
                slotActions={
                    <></>
                }
                headers={categoryHeaders}
                items={categoryDetails}
                isLoading={isLoading}
                slotFooter={
                    <div className={catalogStyle.tableFooter}>
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(categoryDetails.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : categoryDetails.length} resultados</span>}
                        </div>
                        <div className={catalogStyle.tableFooterActions}>
                            <Button disabled={false} text="Controlar Entradas" onClick={handleOpenControlModal} color="cyan" />
                            <Button disabled={!(categoryDetails.length > 0)} text="Deletar Inativos" onClick={handleOpenDeleteModal} color="cyan" />
                            <Button disabled={!(categoryDetails.length > 0)} text="Ajustar Descontos" onClick={handleOpenAdjustDiscountModal} color="cyan" />
                        </div>
                    </div>
                }
                height='70vh'
            />
            <Modal
                isOpen={isControlModalOpen}
                onClose={handleCloseControlModal}
                footer={
                    <div className={catalogStyle.modalFooter}>
                        <div>
                            <input
                                type="checkbox"
                                checked={isForAll}
                                onChange={handleIsForAll}
                                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                            />
                            <span className="ml-1">Edição em Massa</span>
                        </div>
                        <Button disabled={!hasChanged} text="Salvar" onClick={handleSaveEntries} color="cyan" />
                    </div>
                }
                header={<div><span>Controlar Entradas</span></div>}
            >
                <div className={catalogStyle.controlModalContainer}>
                    <div>
                        {isEntryLoading && <div className={proofStyle.proofModalContainerSpin}>
                            <InfinitySpin
                                width='200'
                                color="#155f75"
                            />
                        </div>}
                        {!isEntryLoading &&
                            <>
                                <div className={catalogStyle.addEntry}>
                                    <Select
                                        classNamePrefix="select"
                                        onChange={handleEditEntryInput}
                                        value={editEntryInput}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="categories"
                                        options={modelList}
                                        styles={customStyles}
                                        placeholder={"Adicionar Modelo"}
                                    />
                                </div>
                                <div className={catalogStyle.entriesContainer}>
                                    {
                                        order.map((key, i) => {
                                            return (
                                                <EntryContainer prop={key} value={entriesCopy[key]} onCloseModel={handleCloseModel} onCloseCategory={handleCloseCategory} onAddCategory={handleAddCategory} onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} onChangeDiscountValue={handleChangeDiscountValue} />
                                            )
                                        })
                                    }
                                </div>
                            </>}
                    </div>

                </div>
            </Modal>
            <Modal
                header={<div><span>Atenção!</span></div>}
                isOpen={isDeleteModal}
                onClose={handleCloseDeleteModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja excluir definitivamente todas entradas inativas?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteInactives} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteModal} color="cyan" />
                    </div>
                </div>
            </Modal>
            <Modal
                header={<div><span>Atenção!</span></div>}
                isOpen={isDeleteEntryModal}
                onClose={handleCloseDeleteEntryModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja excluir definitivamente essa entrada?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteEntry} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteEntryModal} color="cyan" />
                    </div>
                </div>
            </Modal>
            <Modal
                header={<div><span>Ajuste de Descontos</span></div>}
                isOpen={isAdjustDiscountModal}
                onClose={handleCloseAdjustDiscountModal}
                footer={
                    <div className={dndStyle.modalFooter}>
                        <Button disabled={!discountHasChanged} text="Salvar" onClick={handleSaveDiscount} color="cyan" />
                    </div>
                }
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Ao Editar, o novo ajuste será aplicado em todas as entradas dessa categoria e de outras com o mesmo modelo que comecem com a porcentagem indicada </span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <div className={catalogStyle.adjustDiscountContainer}>
                            <span>De:</span>
                            <img src={Tag} className='w-4 h-4' />
                            <input type='number' className={UserTabsStyle.modalInput} value={fromValueInput} onChange={handleChangeFromValueInput} min="0" max="100" step="0.1" />
                        </div>
                        <div className={catalogStyle.adjustDiscountContainer}>
                            <span>Para:</span>
                            <img src={Tag} className='w-4 h-4' />
                            <input type='number' className={UserTabsStyle.modalInput} value={toValueInput} onChange={handleChangeToValueInput} min="0" max="100" step="0.1" />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}