import React from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import Details from './Partials/Details'
import FormSearch from './Partials/FormSearch'

import { users } from 'data/users'

const atualizar = () => {
    console.log('Atualizou!')
}

export default function Manage () {
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-7'>
                    <BasicTable
                        title='Ofenças'
                        headers={ users.headers }
                        items={ users.items }
                        slotActions={ <Button text="Atualizar" onClick={ atualizar } color="cyan" /> }
                        slotFooter={ <Button text="Nova Ofença" onClick={ atualizar } color="cyan" /> }
                    />
                </div>
                <div className='col-span-5'>
                    <Details />
                </div>
            </div>
        </>
    )
}
