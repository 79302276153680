import React, { useState, useEffect } from 'react'

const RadioButton = ({ name, options, selectedOption, onChange }) => {
    const [optionSelected, setOptionSelected] = useState(selectedOption)

    const handleChange = (event) => {
        onChange({ target: { name: name, value: event.target.value } })
        setOptionSelected(event.target.value)
    }

    useEffect(() => {
        setTimeout(() => {
            onChange({ target: { name: name, value: selectedOption ?? options[0].value } })
        }, 500);
    }, [])

    return (
        <div className='text-sm py-3'>
            { options.map((option) => (
                <label key={ option.id } className='px-2 align-middle'>
                    <input
                        className='mr-2'
                        type="radio"
                        value={ option.value }
                        checked={ optionSelected === option.value }
                        onChange={ handleChange }
                    />
                    { option.label }
                </label>
            )) }
        </div>
    )
}
export default RadioButton
