import { createSlice } from '@reduxjs/toolkit'
import { getSelectedInterstitialGroupDetails } from 'services/interstitialService';

const selectedInterstitialGroupDetailsSlice = createSlice({
    name: 'selectedInterstitialGroupDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedInterstitialGroupDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedInterstitialGroupDetails(dispatch, params){
    try{
        console.log(params)
        const details = await getSelectedInterstitialGroupDetails(params);
        console.log(details)
        dispatch(setSelectedInterstitialGroupDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedInterstitialGroupDetailsDetails } = selectedInterstitialGroupDetailsSlice.actions
export default selectedInterstitialGroupDetailsSlice.reducer