import { getModLogs } from "services/modLogsService";


const { createSlice } = require("@reduxjs/toolkit");

const modLogsSlice = createSlice({
    name: 'modLogs',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            modId: '',
            property: '',
            days: ''
        }
    },
    reducers: {
        setModLogsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addModLogsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setModLogsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchModLogs(dispatch, params, skip) {
    try {
        const LIMIT = 200;
        const modLogs = await getModLogs(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addModLogsData(modLogs))
        }
        else {
            dispatch(setModLogsData(modLogs));
        }

        if (modLogs.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Mod Logs', error)
        dispatch(setCurrentSkip(0));
        dispatch(setModLogsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchModLogsFilters(dispatch, params){
    dispatch(setModLogsFilter(params));
}

export const { setModLogsData, addModLogsData, setCurrentSkip, setHasMore, setModLogsFilter } = modLogsSlice.actions
export default modLogsSlice.reducer