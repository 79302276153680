import { useEffect, useState } from 'react'
import styles from '../../../styles/Search.module.css'
import { useDispatch, useSelector } from 'react-redux'
import FriendsFilter from './FriendsFilter'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { fetchFriends, fetchFriendsFilters } from 'store/modules/users/friends/friendsSlice'

const iconSenderObject = { key: 'senderIcon' }
const iconReceiverObject = { key: 'receiverIcon' }

export default function Friends({ senderId, receiverId, isAccepted }) {

    const [friendsHeaders, setFriendsHeaders] = useState([
        { title: "Nome Avatar Solicitante", key: "senderAvatarName", copy: "senderId", icon: () => iconSenderObject, appear: true },
        { title: "Nome Avatar Recebedor", key: "receiverAvatarName", copy: "receiverId", icon: () => iconReceiverObject, appear: true },
        { title: "Aceito", key: "isAccepted", appear: true }
    ]);

    const [friendsMomentHeaders, setFriendsMomentHeaders] = useState([...friendsHeaders]);

    const handleAppearHeader = (item) => {
        setFriendsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setFriendsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const friends = useSelector((state) => {
        return Array.isArray(state.friends.data) ? state.friends.data : []
    })

    const skip = useSelector((state) => {
        return (state.friends.currentSkip) ? state.friends.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.friends.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.friends.filters) ? state.friends.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [friends]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (senderId || receiverId || isAccepted) {
            setLoading(true)
            fetchFriends(dispatch, { senderAvatarId: senderId, receiverAvatarId: receiverId, isAccepted: isAccepted }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.senderAvatarId) query.append('senderId', filter.form.senderAvatarId);
        if (filter.form.receiverAvatarId) query.append('receiverId', filter.form.receiverAvatarId);
        if (filter.form.isAccepted) query.append('isAccepted', filter.form.isAccepted);
        window.history.pushState({}, '', `/users/friends?${query.toString()}`);
        fetchFriends(dispatch, filter.form, 0);
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchFriends(dispatch, filter.form, skip + 200);
    })

    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <FriendsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
            </div>
            <div className='col-span-12'>
            <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={friendsHeaders}
                    headers={friendsMomentHeaders}
                    title='Buscar Relações de Amizades'
                    isLoading={isLoading && friends.length === 0}
                    slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                    items={friends}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(friends.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : friends.length} resultados</span>}
                        </div>
                    }
                />
            </div>
        </>
    )
}