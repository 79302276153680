import Transaction from 'domains/Transaction/Transaction'
import api, { apiNormalize } from './api'
import { transactionMapping } from 'domains/Transaction/mappings'
import handleRequestError from 'helpers/apiErrorHandler'

export const getTransactions = async (params, skip) => {
    try {
        const response = await api.get('/transactions', { params:{...params, skip} })
        console.log(response.data);
        const transactions = apiNormalize.responseMapping(response.data, transactionMapping)
        console.log('AQUI');
        // console.log(transactions);

        const normalizedTransactions = transactions.map(item => new Transaction(item).get())

        return normalizedTransactions
    } catch (error) {
        handleRequestError(error)
    }
}

export const getTransactionById = async (id) => {
    try {
        const response = await api.get(`/transaction/${id}`)
        return await response.data
    } catch (error) {
        handleRequestError(error)
    }
}