import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedItemDetails } from "store/modules/items/selectedItemDetailSlice";
import { fetchSelectedSpinCategoryDetails } from "store/modules/items/spin/SelectedSpinCategoryDetailsSlice";
import { fetchSelectedSpinPrizes } from "store/modules/items/spin/SelectedSpinPrizesSlice";

export function UseItem(item){
    const res = useSelector((state) => {
        return (state.selectedItemDetails.details) ? state.selectedItemDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedItemDetails(dispatch, { id: item.id });
    }, [item])

    return res
}

export function useSpinCategory(item){
    const spinCategory = useSelector((state) => {
        return (state.selectedSpinCategoryDetails.details) ? state.selectedSpinCategoryDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedSpinCategoryDetails(dispatch, { id: item.id });
    }, [item])

    return spinCategory
}

export function useSpinPrizes(item){
    const selectedSpinPrizes = useSelector((state) => {
        return Array.isArray(state.selectedSpinPrizes.data) ? state.selectedSpinPrizes.data : []
    })

    const dispatch = useDispatch()
    useEffect(()=>{
        fetchSelectedSpinPrizes(dispatch, { id: item.id });
    }, [item])

    console.log(selectedSpinPrizes)
    return selectedSpinPrizes
}