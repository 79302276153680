const userGroupMapping = item => ({
    id: item._id ?? null,
    name: item.name ?? null,
    type: item.type ?? null
})

const userGroupDetailsMapping = item => ({
    id: item._id ?? null,
    name: item.name ?? null,
    type: item.type ?? null,
    permissions: item.permissions ?? null,
})

const permissionCategoriesMapping = item => ({
    id: item._id ?? null,
    name: item.name ?? null,
    product: item.product ?? null,
    children: getChildren(item) ?? null,
})

const permissionsMapping = item => ({
    id: item._id ?? null,
    name: item.name ?? null,
    desc: item.desc ?? null,
    catId: item.catId ?? null
})

const getChildren = (item) => {

    if (item.children) {
        const children = item.children.map(child => permissionsMapping(child))
        console.log(children);
        return children
    }

    return item.children
}

export { userGroupMapping, userGroupDetailsMapping, permissionCategoriesMapping }