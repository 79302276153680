import { getEmailById, getEmails } from "services/emailsService";

const { createSlice, current } = require("@reduxjs/toolkit");

const emailsSlice = createSlice({
    name: "emails",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            emailId: '',
            emailStatus: '',
            emailType: '',
            senderId: '',
            receiverId: '',
            senderType: '',
            receiverType: '',
            emailSubject: '',
            emailText: ''

        }
    },
    reducers: {
        setEmailsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addEmailsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setEmailsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchEmails(dispatch, params, skip) {
    try {
        const LIMIT = 200;
        const emails = await getEmails(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addEmailsData(emails))
        }
        else {
            dispatch(setEmailsData(emails));
        }

        if (emails.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Emails', error)
    }
}

export const fetchEmailById = async (id) => {
    try {
        const email = await getEmailById(id)
        return email
    } catch (error) {
        console.error('Erro ao carregar email:', error)
    }
}

export async function fetchEmailsFilters(dispatch, params){
    dispatch(setEmailsFilter(params));
}

export const { setEmailsData, addEmailsData, setCurrentSkip, setHasMore, setEmailsFilter } = emailsSlice.actions
export default emailsSlice.reducer