import { getStaff } from 'services/staffService'
import { setStaff } from './staffSlice'

export const fetchStaff = (params) => async (dispatch) => {
    try {
        const staff = await getStaff(params)
        dispatch(setStaff(staff))
    } catch (error) {
        console.error('Erro ao obter equipe Online:', error)
    }
}
