import { itemDetailsMapping, itemsAvatarsUserMapping } from 'domains/Item/mappings';
import api, { apiNormalize } from './api'
import Item from 'domains/Item/Item'
import handleRequestError from 'helpers/apiErrorHandler';
import ItemDetail from 'domains/Item/ItemDetail';

export async function getItems(params, skip) {
    try {
        const response = await api.get("/items", { params: { ...params, skip } });
        const items = apiNormalize.responseMapping(response.data, itemsAvatarsUserMapping)
        const normalizedItems = items.map(item => new Item(item).get());
        return normalizedItems
    }
    catch (error) {
        handleRequestError(error)
    }

}

export const getItem = async (params) => {
    try {
        const response = await api.get('/items/byItem', { params })
        const item = apiNormalize.responseMapping(response.data, itemDetailsMapping)
        const normalizedMissions = item.map(item => new Item(item).get());
        return normalizedMissions
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedItemDetails = async (params) => {
    try {
        const response = await api.get('/items/details', { params })

        const detail = itemDetailsMapping(response.data[0])

        const normalizedDetail = new ItemDetail(detail).get()

        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const returnItemToInventory = async (itemId) => {
    try{
        const response = await api.post(`/item/${itemId}/returnItemToInventory`)
        return response.data
    }
    catch(error){
        handleRequestError(error)
    }
}