import Email from "Pages/Users/Email";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchEmailsFilters } from "store/modules/users/emails/emailsSlice";

export function EmailUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const emailId = searchParams.get("emailId");
    const emailStatus = searchParams.get("emailStatus");
    const emailType = searchParams.get("emailType");
    const senderId = searchParams.get("senderId");
    const receiverId = searchParams.get("receiverId");
    const senderType = searchParams.get("senderType");
    const receiverType = searchParams.get("receiverType");
    const emailSubject = searchParams.get("emailSubject");
    const emailText = searchParams.get("emailText");
    
    let propName = {
        N: 'Não lido',
        R: 'Lido',
        S: 'Sistema',
        U: 'Usuário',
        A: 'Avatar'
    }

    

    const dispatch = useDispatch()

    if (emailId || emailStatus || emailType || senderId || receiverId || senderType || receiverType || emailSubject || emailText) {
        fetchEmailsFilters(dispatch, { emailId, emailStatus: emailStatus ? {name: propName[emailStatus], value: emailStatus} : '', emailType: emailType ? {name: propName[emailType], value: emailType} : '', senderId, receiverId, senderType: senderType ? {name: propName[senderType], value: senderType} : '', receiverType: receiverType ? {name: propName[receiverType], value: receiverType} : '', emailSubject, emailText })
        return <Email emailId={emailId ?? null} emailStatus={emailStatus ?? null} emailType={emailType ?? null} senderId={senderId ?? null} receiverId={receiverId ?? null} senderType={senderType ?? null} receiverType={receiverType ?? null} emailSubject={emailSubject ?? null} emailText={emailText ?? null}/>;
    }
    else{
        return <Email/>;
    }

}