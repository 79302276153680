import { createSlice } from '@reduxjs/toolkit'
import { getSelectedSpaceDetails } from 'services/spaceService';

const selectedSpaceDetailsSlice = createSlice({
    name: 'selectedSpaceDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedSpaceDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedSpaceDetails(dispatch, params){
    try{
        const details = await getSelectedSpaceDetails(params);
        console.log(details)
        dispatch(setSelectedSpaceDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedSpaceDetailsDetails } = selectedSpaceDetailsSlice.actions
export default selectedSpaceDetailsSlice.reducer