import React from 'react'

export default function Rewards () {
    return (
        <>
            <h2>Página Rewards</h2>
            <p>Esta página tem um erro que impede que seja visualizada no SMI</p>
        </>
    )
}
