export default function LevelCalc(value, type) {
    let currentXp = Number(value)
    if (type == 'artist') {
        if (currentXp < 120) {
            return 1;
        }
        if (currentXp < 600) {
            return Math.floor(currentXp / 60);
        }
        if (currentXp < 1200) {
            return Math.floor((600 + currentXp - 600) / 60);
        }
        if (currentXp < 2400) {
            return Math.floor((1200 + currentXp - 1200) / 120) + 10;
        }
        if (currentXp < 4800) {
            return Math.floor((2400 + currentXp - 2400) / 240) + 20;
        }
        if (currentXp < 9600) {
            return Math.floor((4800 + currentXp - 4800) / 480) + 30;
        }
        if (currentXp === 100000000) {
            return 700;
        }
        if (currentXp === 200000000) {
            return 800;
        }
        if (currentXp === 300000000) {
            return 900;
        }
        return Math.floor((9600 + currentXp - 9600) / 480) + 40;
    }
    else if (type == 'arena') {
        /*
        if (currentXp < 120) {
            return 1;
        }
        if (currentXp < 600) {
            return Math.floor(currentXp / 60);
        }
        if (currentXp < 1200) {
            return Math.floor((600 + currentXp - 600) / 60);
        }
        if (currentXp < 2400) {
            return Math.floor((1200 + currentXp - 1200) / 120) + 10;
        }
        if (currentXp < 4800) {
            return Math.floor((2400 + currentXp - 2400) / 240) + 20;
        }
        if (currentXp < 9600) {
            return Math.floor((4800 + currentXp - 4800) / 480) + 30;
        }
        if (currentXp === 100000000) {
            return 700;
        }
        if (currentXp === 200000000) {
            return 800;
        }
        if (currentXp === 300000000) {
            return 900;
        }
        return Math.floor((9600 + currentXp - 9600) / 480) + 40;
        */
       return 1
    }
    else if (type == 'crafting') {
        /*
        const SCALING_CONSTANT = 4;
        return Math.floor(Math.sqrt(currentXp / SCALING_CONSTANT) + 1.2) - 1;
        */
       return 1
    }
    else if (type == 'explorer') {
        if (currentXp < 120) {
            return 1;
        }
        if (currentXp < 600) {
            return Math.floor(currentXp / 60);
        }
        if (currentXp < 1200) {
            return Math.floor((600 + currentXp - 600) / 60);
        }
        if (currentXp < 2400) {
            return Math.floor((1200 + currentXp - 1200) / 120) + 10;
        }
        if (currentXp < 4800) {
            return Math.floor((2400 + currentXp - 2400) / 240) + 20;
        }
        if (currentXp < 9600) {
            return Math.floor((4800 + currentXp - 4800) / 480) + 30;
        }
        if (currentXp === 100000000) {
            return 700;
        }
        if (currentXp === 200000000) {
            return 800;
        }
        if (currentXp === 300000000) {
            return 900;
        }
        return Math.floor((9600 + currentXp - 9600) / 480) + 40;
    }
    else if (type == 'farmer') {

        const SCALING_CONSTANT = 4;
        let level = 1;

        while (true) {
            const XPcount = 4 * Math.pow((level - 1), 2);

            if (XPcount > currentXp) {
                level--;
                break;
            }

            level++;
        }

        return level;
    }
    else if (type == 'gamer') {
        if (currentXp < 120) {
            return 1;
        }
        if (currentXp < 600) {
            return Math.floor(currentXp / 60);
        }
        if (currentXp < 1200) {
            return Math.floor((600 + currentXp - 600) / 60);
        }
        if (currentXp < 2400) {
            return Math.floor((1200 + currentXp - 1200) / 120) + 10;
        }
        if (currentXp < 4800) {
            return Math.floor((2400 + currentXp - 2400) / 240) + 20;
        }
        if (currentXp < 9600) {
            return Math.floor((4800 + currentXp - 4800) / 480) + 30;
        }
        if (currentXp === 100000000) {
            return 700;
        }
        if (currentXp === 200000000) {
            return 800;
        }
        if (currentXp === 300000000) {
            return 900;
        }
        return Math.floor((9600 + currentXp - 9600) / 480) + 40;
    }
    else if (type == 'social') {
        if (currentXp < 120) {
            return 1;
        }
        if (currentXp < 600) {
            return Math.floor(currentXp / 60);
        }
        if (currentXp < 1200) {
            return Math.floor((600 + currentXp - 600) / 60);
        }
        if (currentXp < 2400) {
            return Math.floor((1200 + currentXp - 1200) / 120) + 10;
        }
        if (currentXp < 4800) {
            return Math.floor((2400 + currentXp - 2400) / 240) + 20;
        }
        if (currentXp < 9600) {
            return Math.floor((4800 + currentXp - 4800) / 480) + 30;
        }
        if (currentXp === 100000000) {
            return 700;
        }
        if (currentXp === 200000000) {
            return 800;
        }
        if (currentXp === 300000000) {
            return 900;
        }
        return Math.floor((9600 + currentXp - 9600) / 480) + 40;
    }
    else if (type == 'primary') {
        const EXPONENTIAL_SCALE = 4.0;
        const LINEAR_SCALE = 60.0;
        const EXPONENT = 2.25;
        let level = 1;

        while (true) {
            const XPcount = 0.4 * Math.pow((level - 1), EXPONENT) * 10.0 + (LINEAR_SCALE * (level - 1));

            if (XPcount > currentXp) {
                level--;
                break;
            }

            level++;
        }

        return level;
    }
}