import { createSlice } from '@reduxjs/toolkit'
import { getOnlineAvatars } from 'services/avatarService';

const avatarCountSlice = createSlice({
    name: 'avatarCount',
    initialState: 0,
    reducers: {
        setAvatarCount: (state, action) => {
            return action.payload;
        },
    },
})

export async function fetchAvatarCount(dispatch){
    try{
        const avatarCount = await getOnlineAvatars();
        dispatch(setAvatarCount(avatarCount));

    }
    catch(error){
        console.error('Erro ao obter número de avatares:', error)
    }
}
export const { setAvatarCount } = avatarCountSlice.actions
export default avatarCountSlice.reducer
