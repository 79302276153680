import React, { useEffect, useState } from 'react'
import { UseMission } from './missionSearch';
import BasicTable from 'components/BasicTable';
import style from '../../../styles/UserTabs.module.css'
import Button from 'components/form/Button';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';
import { fetchSelectedMissionDetails } from 'store/modules/missions/selectedMissionDetailSlice';
import { saveMission } from 'services/missionsService';
import { toast } from 'react-toastify';
import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';

export default function Details({ item }) {
    const verifyMissionChange = useUserPermission(SMIPermissionsCode.MOD_MISSION_CHANGE);

    const missionsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstMissionData = UseMission(item);
    const [missionData, setMissionData] = useState(firstMissionData) 

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstMissionDataCopy = { ...firstMissionData }
        setMissionData(firstMissionDataCopy)

        setOriginalValues({
            missionId: firstMissionData.missionId,
            missionName: firstMissionData.missionName,
            spaceId: firstMissionData.spaceId,
            avatarId: firstMissionData.avatarId,
            isOfficial: firstMissionData.isOfficial,
            experiencePath: firstMissionData.experiencePath,
            entryFee: firstMissionData.entryFee,
            approved: firstMissionData.approved

        })

        setChangedValues({
            missionId: firstMissionData.missionId,
            missionName: firstMissionData.missionName,
            spaceId: firstMissionData.spaceId,
            avatarId: firstMissionData.avatarId,
            isOfficial: firstMissionData.isOfficial,
            experiencePath: firstMissionData.experiencePath,
            entryFee: firstMissionData.entryFee,
            approved: firstMissionData.approved

        })

    }, [firstMissionData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID Missão" && item.name != "Nome do Avatar" && item.name != "Nome do Espaço") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let missionDataCopy = { ...missionData, tableProperties: [...missionData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < missionData.tableProperties.length; i++) {
                if (missionData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    missionDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    missionDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setMissionData(missionDataCopy);
        }
        if(inputValue == '' && modalTitle == 'ID do Espaço'){
            for (let i = 0; i < missionData.tableProperties.length; i++) {
                if (missionData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    missionDataCopy.tableProperties[i] = { name: currentItem.name, value: "-", key: currentItem.key };
                    missionDataCopy[currentItem.key] = "-";
                    changedValuesCopy[currentItem.key] = "-";
                }
            }
            setChangedValues(changedValuesCopy)
            setMissionData(missionDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setMissionData(firstMissionData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyExperiencePath = {
                Artista: 1,
                Explorador: 2,
                Jogador: 3,
                Social: 4,
                Arena: 5,
                Fazendeiro: 6,
                Artesão: 7,
                Primário: 8,
            }

            const propertyApi = {
                missionName: 'title',
                spaceId: 'pickup_space',
                avatarId: 'avatar_id',
                isOfficial: 'isOfficial',
                experiencePath: 'experiencePath',
                entryFee: 'entryFee',
                approved: 'approved'
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] =  changedValues[propName]
                }
            }

            if(changes['experiencePath']){
                changes['experiencePath'] = propertyExperiencePath[changes['experiencePath']]
            }

            if(changes['isOfficial']){
                changes['isOfficial'] == 'Sim' ? changes['isOfficial'] = true : changes['isOfficial'] = false
            }

            if(changes['approved']){
                changes['approved'] == 'Sim' ? changes['approved'] = true : changes['approved'] = false
            }

            if(changes['pickup_space'] == '-'){
                changes['pickup_space'] = null
            }

            await saveMission(item.missionId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedMissionDetails(dispatch, { id: item.missionId });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    return (
        <>
            <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                <BasicTable
                    onSelectRow={handleSelectRow}
                    headers={missionsHeaders}
                    items={missionData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                />
            </div>
            { verifyMissionChange && <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
            >
                <div className={style.modalContent}>
                    <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                    {modalTitle != "Tipo de Missão" ?
                        (modalTitle != 'Aprovado' && modalTitle != 'Oficial') ? 
                            (modalTitle != 'Custo de Entrada') ? 
                                <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} /> 
                                : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} /> 
                            :
                            <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                <option key={'Sim'} value={'Sim'}>Sim</option>
                                <option key={'Não'} value={'Não'}>Não</option>
                            </select>
                        :
                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                            <option key={'1'} value={'Artista'}>Artista</option>
                            <option key={'2'} value={'Explorador'}>Explorador</option>
                            <option key={'3'} value={'Jogador'}>Jogador</option>
                            <option key={'4'} value={'Social'}>Social</option>
                            <option key={'5'} value={'Arena'}>Arena</option>
                            <option key={'6'} value={'Fazendeiro'}>Fazendeiro</option>
                            <option key={'7'} value={'Artesão'}>Artesão</option>
                            <option key={'8'} value={'Primário'}>Primário</option>
                        </select>
                    }

                    <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                </div>
            </Modal>}
        </>
    )
}
