import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedSpaceDetails } from "store/modules/spaces/selectedSpaceDetailSlice";

export function useSpace(item){
    const space = useSelector((state) => {
        return (state.selectedSpaceDetails.details) ? state.selectedSpaceDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedSpaceDetails(dispatch, { id: item.id });
    }, [item])

    return space
}