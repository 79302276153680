import { fetchSelectedInterstitialGroupDetails } from "store/modules/administration/interstitials/selectedInterstitialGroupDetailSlice";
import { useInterstitialGroup } from "./interstitialSearch";
import { fetchInterstitialGroups } from "store/modules/administration/interstitials/interstitialGroupsSlice";
import BasicTable from "components/BasicTable"
import Modal from "components/Modal"
import Tabs from "components/Tabs"
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react'
import style from '../../../../styles/UserTabs.module.css'
import styles from '../../../../styles/Search.module.css'
import Button from 'components/form/Button';
import { toast } from 'react-toastify';
import moment from "moment";
import { saveInterstitialGroup } from "services/interstitialService";

export default function GroupDetails({ item }) {

    console.log(item)

    const interstitialGroupHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstInterstitialGroupData = useInterstitialGroup(item);
    const [interstitialGroupData, setInterstitialGroupData] = useState(firstInterstitialGroupData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [weeksInput, setWeeksInput] = useState(0);
    const [daysInput, setDaysInput] = useState(0);
    const [hoursInput, setHoursInput] = useState(0);

    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstInterstitialGroupDataCopy = { ...firstInterstitialGroupData }
        setInterstitialGroupData(firstInterstitialGroupDataCopy)

        setOriginalValues({
            groupId: firstInterstitialGroupData.groupId,
            name: firstInterstitialGroupData.name,
            active: firstInterstitialGroupData.active,
            token: firstInterstitialGroupData.token,
            cooldown: firstInterstitialGroupData.cooldown,
            tokenAge: firstInterstitialGroupData.tokenAge
        })

        setChangedValues({
            groupId: firstInterstitialGroupData.groupId,
            name: firstInterstitialGroupData.name,
            active: firstInterstitialGroupData.active,
            token: firstInterstitialGroupData.token,
            cooldown: firstInterstitialGroupData.cooldown,
            tokenAge: firstInterstitialGroupData.tokenAge

        })

    }, [firstInterstitialGroupData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");

        setWeeksInput(0);
        setDaysInput(0);
        setHoursInput(0);

        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name == "Duração do Token") {
            setCurrentItem(item)
            setModalTitle(item.name)

            const duration = moment.duration(changedValues.tokenAge, 'seconds');

            const weeksValue = Math.floor(duration.asWeeks());
            const daysValue = Math.floor(duration.subtract(weeksValue, 'weeks').asDays());
            const hoursValue = Math.floor(duration.subtract(daysValue, 'days').asHours());

            setWeeksInput(weeksValue);
            setDaysInput(daysValue);
            setHoursInput(hoursValue);


            //setInputValue(firstInterstitialGroupData.tokenAge)
            //transformar esses segundos
            handleOpenModal()
        }
        if (item.name != "ID Grupo" && item.name != "Duração do Token") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleOnChangeWeeksInput = (event) => {
        setWeeksInput(event.target.value)
    }

    const handleOnChangeDaysInput = (event) => {
        setDaysInput(event.target.value)
    }

    const handleOnChangeHoursInput = (event) => {
        setHoursInput(event.target.value)
    }

    const handleSubmit = () => {
        let interstitialGroupDataCopy = { ...interstitialGroupData, tableProperties: [...interstitialGroupData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (modalTitle == 'Duração do Token') {
            if (!(weeksInput == 0 && daysInput == 0 && hoursInput == 0)) {
                //valor em segundos
                const totalSeconds = (weeksInput * 7 * 24 * 60 * 60) + (daysInput * 24 * 60 * 60) + (hoursInput * 60 * 60);
                const duration = moment.duration(totalSeconds, 'seconds');
                let temp

                if (duration.asWeeks() >= 1) {
                    temp = `${Math.floor(duration.asWeeks())} semana(s)`;
                } else if (duration.asDays() >= 1) {
                    temp = `${Math.floor(duration.asDays())} dia(s)`;
                } else if (duration.asHours() >= 1) {
                    temp = `${Math.floor(duration.asHours())} hora(s)`;
                } else if (duration.asMinutes() >= 1) {
                    temp = `${Math.floor(duration.asMinutes())} minuto(s)`;
                } else {
                    temp = `${totalSeconds} segundo(s)`;
                }

                //o que vai mudar no tableProperties é cooldown
                for (let i = 0; i < interstitialGroupData.tableProperties.length; i++) {
                    if (interstitialGroupData.tableProperties[i].key == 'cooldown') {
                        interstitialGroupDataCopy.tableProperties[i] = { name: 'Duração do Token', value: temp, key: 'cooldown' };
                        interstitialGroupDataCopy['cooldown'] = temp;
                    }
                }

                //o que vai mudar em changedValue é cooldown e tokenAge (em segundos)
                changedValuesCopy['tokenAge'] = totalSeconds;
                changedValuesCopy['cooldown'] = temp;


                setChangedValues(changedValuesCopy)
                setInterstitialGroupData(interstitialGroupDataCopy);
            }
        }
        else {
            if (inputValue != '') {
                for (let i = 0; i < interstitialGroupData.tableProperties.length; i++) {
                    if (interstitialGroupData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        interstitialGroupDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        interstitialGroupDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setInterstitialGroupData(interstitialGroupDataCopy);
            }
            setInputValue("");
        }

        setCurrentItem({});
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setInterstitialGroupData(firstInterstitialGroupData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                name: 'title',
                active: 'active',
                token: 'token',
                tokenAge: 'tokenAge'
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName] && propName != 'groupName' && propName != 'cooldown') {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            if (changes['active']) {
                changes['active'] == 'Sim' ? changes['active'] = true : changes['active'] = false
            }

            await saveInterstitialGroup(item.groupId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            await fetchSelectedInterstitialGroupDetails(dispatch, { id: item.groupId });

            dispatch(fetchInterstitialGroups())

            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={interstitialGroupHeaders}
                        items={interstitialGroupData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {
                            <div className={style.modalContent}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {
                                    (modalTitle != 'Ativo') ?

                                        (modalTitle != 'Duração do Token') ?
                                            <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            :
                                            <>
                                                <div className="flex gap-2 items-center ">
                                                    <div>
                                                        <input className={style.modalInput} type="number" placeholder='' value={weeksInput} onChange={handleOnChangeWeeksInput} />
                                                    </div>
                                                    <span>Semana(s)</span>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                    <div>
                                                        <input className={style.modalInput} type="number" placeholder='' value={daysInput} onChange={handleOnChangeDaysInput} />
                                                    </div>
                                                    <span>Dia(s)</span>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                    <div>
                                                        <input className={style.modalInput} type="number" placeholder='' value={hoursInput} onChange={handleOnChangeHoursInput} />
                                                    </div>
                                                    <span>Hora(s)</span>
                                                </div>

                                            </>
                                        :
                                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                            <option key={'Sim'} value={'Sim'}>Sim</option>
                                            <option key={'Não'} value={'Não'}>Não</option>
                                        </select>
                                }

                                <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                            </div>
                        }
                    </Modal>
                </>
            )
        },
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm'>
                        <b>{item.name}</b>
                        <p>{item.groupId}</p>
                    </div>
                )}
            />
        </div>
    )
}