import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchItemsFilters } from 'store/modules/items/itemsSlice';

export default function ItemsFilter ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        

        if(name == 'state'){
            let propName = {
                inventory: 'No inventário',
                space: 'No espaço',
            }
            newValue[name] = {name: propName[value], value};
        }
        else{
            newValue[name] = value;
        }

        fetchItemsFilters(dispatch, {...defaultValues, ...newValue})

        console.log(defaultValues)
    }

    const fields = [
        {
            name: 'idUser',
            label: 'ID do Usuário',
            value: defaultValues.idUser,
            placeholder: 'ID do Usuário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'idSpace',
            label: 'ID do Espaço atual',
            value: defaultValues.idSpace,
            placeholder: 'ID do Espaço Atual',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'idItem',
            label: 'ID do Item',
            value: defaultValues.idItem,
            placeholder: 'ID do Item',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'idModel',
            label: 'ID do Modelo',
            value: defaultValues.idModel,
            placeholder: 'ID do Modelo',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'state',
            value: defaultValues.state.value ?? '',
            label: 'Estado do Item',
            placeholder: 'Estado do Item',
            type: 'select',
            items: [
                {
                    value: 'inventory',
                    name: 'No inventário'
                },
                {
                    value: 'space',
                    name: 'No espaço'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.state !=  '') ? defaultValues.state.name : '-- Estado do Item --',
            gridClass: 'col-span-6',
        },
        {
            name: 'forSale',
            value: defaultValues.forSale.value ?? '',
            label: 'Apenas à Venda',
            type: 'select',
            items: [
                {
                    value: true,
                    name: 'Sim'
                },
                {
                    value: false,
                    name: 'Não'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.forSale !=  '') ? defaultValues.forSale.name : '-- Estado do Item --',
            gridClass: 'col-span-6',
        }
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
