import React from 'react'

// Redux
import { useSelector } from 'react-redux'

export default function Profile () {
    const loggedUser = useSelector((state) => {
        return typeof state.loggedUser === 'object' ? state.loggedUser : {}
    })

    return (
        <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">Perfil do usuário</h3>
            { loggedUser && (
                <div>
                    { loggedUser.firstName } { loggedUser.lastName }
                </div>
            )}
        </div>
    )
}
