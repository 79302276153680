import { getTransactionById, getTransactions } from 'services/transactionService'
import { setTransactionsData, addTransactionsData, setHasMore, setCurrentSkip, setTransactionsFilter } from './transactionSlice'

export const fetchTransactions = (params, skip) => async (dispatch) => {
    const LIMIT = 200;
    try {
        const transactions = await getTransactions(params, skip)
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addTransactionsData(transactions))
        }
        else{
            dispatch(setTransactionsData(transactions))
        }
        if(transactions.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    } catch (error) {
        console.error('Erro ao carregar transações:', error)
        dispatch(setCurrentSkip(0));
        dispatch(setTransactionsData([]));
        dispatch(setHasMore(false));
    }
}

export const fetchTransactionById = async (id) => {
    try {
        const transaction = await getTransactionById(id)
        return transaction
    } catch (error) {
        console.error('Erro ao carregar transação:', error)
    }
}

export async function fetchTransactionsFilters(dispatch, params){
    dispatch(setTransactionsFilter(params));

    console.log(params)
}