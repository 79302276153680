import React, { useState } from 'react'
import Button from 'components/form/Button'
import {
    DocumentDuplicateIcon,
    DocumentCheckIcon
} from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
export default function CopyToClipboard ({ text }) {
    const [textoCopiado, setTextoCopiado] = useState(false);

    const copy = () => {
        navigator.clipboard.writeText(text).then(function (x) {
            toast.success("Texto Copiado com Sucesso!");
        })

        setTextoCopiado(true)

        setTimeout(() => {
            setTextoCopiado(false)
        }, 2000)
    };

    return (
        <Button
            onClick={ () => copy(text) }
            onlyIcon
            color="cyan"
            icon={
                textoCopiado ? <DocumentCheckIcon className='h-3 w-3' /> : <DocumentDuplicateIcon className='h-3 w-3' />
            } />
    )
}
