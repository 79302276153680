class AvatarProfileDetail{
    constructor({avatarId, result, texts}){
        this.avatarId = avatarId
        this.result = result
        this.texts = texts
    }

    get(){
        return{
            tableProperties: [
                {
                    key: 'socialStatus',
                    name: "Status Social",
                    value: this.profileDetailValue(1)
                },
                {
                    key: 'language',
                    name: "Idioma",
                    value: this.profileDetailValue(2)
                },
                {
                    key: 'location',
                    name: "Local",
                    value: this.profileDetailValue(3)
                },
                {
                    key: 'occupation',
                    name: "Ocupação",
                    value: this.profileDetailValue(4)
                },
                {
                    key: 'education',
                    name: "Educação",
                    value: this.profileDetailValue(5)
                },
                {
                    key: 'starSign',
                    name: "Signo",
                    value: this.profileDetailValue(6)
                },
                {
                    key: 'aboutMe',
                    name: "Sobre mim",
                    value: this.profileTextDetailValue(0)
                },
                {
                    key: 'contactMeIf',
                    name: "Me contate se...",
                    value: this.profileTextDetailValue(1)
                },
            ],
            socialStatus: this.profileDetailValue(1),
            language: this.profileDetailValue(2),
            location: this.profileDetailValue(3),
            occupation: this.profileDetailValue(4),
            education: this.profileDetailValue(5),
            starSign: this.profileDetailValue(6),
            aboutMe: this.profileTextDetailValue(0),
            contactMeIf: this.profileTextDetailValue(1)
        }
    }

    profileDetailValue(idField){

        let resultValue = '-'

        this.result.map((item, index) => {
            if(item.idField == idField){
                resultValue = item.value
            }

        })

        return resultValue
    }

    profileTextDetailValue(idText){

        let resultValue = '-'

        this.texts.map((item, index) => {
            if(item.datatext_id == idText){
                resultValue = item.datatext_value
            }

        })

        return resultValue
    }
}

export default AvatarProfileDetail