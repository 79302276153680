import Button from 'components/form/Button'
import TableCell from './cell'
import { useState } from 'react'
import rowStyle from '../../styles/RowStyle.module.css'

export default function TableRow({
    headers = [],
    item,
    cellWidth,
    onSelectRow,
    onSelectItem,
    onDetailRow,
    children = null,
    isSelectable,
    isSelected,
    hasButton,
    onClickBtnItem,
    onClickBtnItemInfo,
    isActive,
    onEditItem,
    onDeleteItem,
    isDropDown,
    isChildrenSelectable,
    permissions,
    handlePermissions
}) {

    const handleNewPermissions = (id) => {
        handlePermissions(id)
    }

    const handleEditItem = (item) => {
        onEditItem(item)
    }

    const handleDeleteItem = (item) => {
        onDeleteItem(item)
    }

    const handleSelectRow = (item, cell) => {
        onSelectRow(item, cell)
    }

    const handleDetailRow = (item) => {
        onDetailRow(item)
    }

    const handleSelectItem = () => {
        onSelectItem(item)
    }

    const handleBtnItem = () => {
        onClickBtnItem(item)
    }

    const [chldrenClassName, setChildrenClassName] = useState(rowStyle.initialState)

    const handleSetClassName = () => {
        const newClass = chldrenClassName == rowStyle.initialState ? rowStyle.endState : rowStyle.initialState;
        setChildrenClassName(newClass)
    }

    return (
        <>
            {onSelectItem && !isDropDown &&
                <tr className='flex w-full'>
                    {
                        isSelectable && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`} onClick={handleSelectItem}>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectItem}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                    value={isSelected}
                                    checked={Boolean(isSelected)}
                                />


                            </td>
                        )}
                    {
                        hasButton && !isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        hasButton && isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        headers.map((cell, key) => (
                            cell.key ?
                                <TableCell
                                    key={key}
                                    chain={key}
                                    cell={cell}
                                    item={item}
                                    handleSelectRow={handleSelectRow}
                                    handleDetailRow={handleDetailRow}
                                    cellWidth={cellWidth}
                                    handleEditItem={handleEditItem}
                                    handleDeleteItem={handleDeleteItem}
                                />

                                : Object.entries(item).map((cell, key) => (
                                    <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                        {cell[1]}
                                    </td>
                                ))
                        ))
                    }
                </tr>}
            {!onSelectItem && !isDropDown &&
                <tr className='flex w-full'>
                    {
                        isSelectable && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`}>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectItem}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                    value={isSelected}
                                    checked={Boolean(isSelected)}
                                />


                            </td>
                        )}
                    {
                        hasButton && !isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        hasButton && isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        headers.map((cell, key) => (
                            cell.key ?
                                <TableCell
                                    key={key}
                                    chain={key}
                                    cell={cell}
                                    item={item}
                                    handleSelectRow={handleSelectRow}
                                    handleDetailRow={handleDetailRow}
                                    cellWidth={cellWidth}
                                    handleEditItem={handleEditItem}
                                    handleDeleteItem={handleDeleteItem}
                                />

                                : Object.entries(item).map((cell, key) => (
                                    <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                        {cell[1]}
                                    </td>
                                ))
                        ))
                    }
                </tr>}
            {typeof children === 'function' && !isDropDown && item[children().childrenKey].length > 0 && (
                <tr className='flex w-full'>
                    <td className="text-sm text-gray-900 w-full flex-row" colSpan={headers.length}>
                        {
                            item[children().childrenKey].map((child, key) => (
                                <tr className='flex w-full border-b' style={{ backgroundColor: '#f5f2f2' }}>
                                    {
                                        children().headers?.map((cell, key) => (
                                            <TableCell
                                                key={key}
                                                cell={cell}
                                                item={child}
                                                isChild={true}
                                                handleSelectRow={handleSelectRow}
                                                handleDetailRow={handleDetailRow}
                                                cellWidth={cellWidth}
                                                handleEditItem={handleEditItem}
                                                handleDeleteItem={handleDeleteItem}
                                            />
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </td>
                </tr>
            )}



            {/*DropDown ativado*/}

            {onSelectItem && isDropDown &&
                <tr className='flex w-full' onClick={handleSetClassName}>
                    {
                        isSelectable && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`} onClick={handleSelectItem}>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectItem}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                    value={isSelected}
                                    checked={Boolean(isSelected)}
                                />


                            </td>
                        )}
                    {
                        hasButton && !isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        hasButton && isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        headers.map((cell, key) => (
                            cell.key ?
                                <TableCell
                                    key={key}
                                    chain={key}
                                    cell={cell}
                                    item={item}
                                    handleSelectRow={handleSelectRow}
                                    handleDetailRow={handleDetailRow}
                                    cellWidth={cellWidth}
                                    handleEditItem={handleEditItem}
                                    handleDeleteItem={handleDeleteItem}
                                />

                                : Object.entries(item).map((cell, key) => (
                                    <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                        {cell[1]}
                                    </td>
                                ))
                        ))
                    }
                </tr>}
            {!onSelectItem && isDropDown &&
                <tr className='flex w-full' onClick={handleSetClassName}>
                    {
                        isSelectable && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`}>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectItem}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                    value={isSelected}
                                    checked={Boolean(isSelected)}
                                />


                            </td>
                        )}
                    {
                        hasButton && !isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        hasButton && isActive && (
                            <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                            </td>
                        )}
                    {
                        headers.map((cell, key) => (
                            cell.key ?
                                <TableCell
                                    key={key}
                                    chain={key}
                                    cell={cell}
                                    item={item}
                                    handleSelectRow={handleSelectRow}
                                    handleDetailRow={handleDetailRow}
                                    cellWidth={cellWidth}
                                    handleEditItem={handleEditItem}
                                    handleDeleteItem={handleDeleteItem}
                                />

                                : Object.entries(item).map((cell, key) => (
                                    <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                        {cell[1]}
                                    </td>
                                ))
                        ))
                    }
                </tr>}
            {typeof children === 'function' && isDropDown && item[children().childrenKey].length > 0 && (
                <tr className={chldrenClassName}>
                    <td className="text-sm text-gray-900 w-full flex-row overflow-hidden p-0" colSpan={headers.length}>
                        {
                            item[children().childrenKey].map((child, key) => (
                                <tr className='flex w-full border-b' style={{ backgroundColor: '#f5f2f2' }}>
                                    {isChildrenSelectable &&
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`}>
                                            {/*colocar no value algo que seja do tipo: true se child.id estiver true na lista de permissões*/}
                                            <input
                                                type="checkbox"
                                                onChange={() => handleNewPermissions(child.id)}
                                                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                                value={permissions[child.id]}
                                                checked={Boolean(permissions[child.id])}
                                            />


                                        </td>
                                    }
                                    {
                                        children().headers?.map((cell, key) => (
                                            <TableCell
                                                key={key}
                                                cell={cell}
                                                item={child}
                                                isChild={true}
                                                handleSelectRow={handleSelectRow}
                                                handleDetailRow={handleDetailRow}
                                                cellWidth={cellWidth}
                                                handleEditItem={handleEditItem}
                                                handleDeleteItem={handleDeleteItem}
                                            />
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </td>
                </tr>
            )}



        </>
    )
}
