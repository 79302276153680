import apiNormalize from 'helpers/apiNormalize'

class Category {
    constructor ({ id, name, active, children, icon, parentId, hasSeparator, isManuallyControlled, sortsByOrderIndex }) {
        this.id     = id
        this.name   = name
        this.active = active
        this.children = children,
        this.icon = icon,
        this.parentId = parentId,
        this.hasSeparator = hasSeparator
        this.isManuallyControlled = isManuallyControlled
        this.sortsByOrderIndex = sortsByOrderIndex
    }

    get () {
        return {
            id: this.id,
            name: this.name,
            active: this.active,
            children: this.children,
            icon: this.icon,
            parentId: this.parentId,
            hasSeparator: this.hasSeparator,
            isManuallyControlled: this.isManuallyControlled,
            sortsByOrderIndex: this.sortsByOrderIndex
        }
    }
}

export default Category
