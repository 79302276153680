import User from 'domains/User/User'
import api, { apiNormalize } from './api'
import { userMapping, userByIpMapping, userDetailsMapping, selectedUserDetailsMapping, userLoginMapping, userByAvatarMapping, userSessionsMapping } from 'domains/User/mappings'
import handleRequestError from 'helpers/apiErrorHandler'
import UserDetail from 'domains/User/UserDetail'
import UserLogin from 'domains/User/UserLogin'
import UserSession from 'domains/User/UserSession'

export const getUsers = async (params, skip) => {
    try {
        const response = await api.get('/users/byUser', {params:{...params, skip}})

        const users = apiNormalize.responseMapping(response.data, userMapping)

        const normalizedUsers = users.map(user => new User(user).get());

        return normalizedUsers
    } catch (error) {
        handleRequestError(error)
    }
}

export const getUsersByAvatar = async (params, skip) => {
    try {
        const response = await api.get('/users/byAvatar', {params:{...params, skip}})

        const users = apiNormalize.responseMapping(response.data, userByAvatarMapping)

        const normalizedUsers = users.map(user => new User(user).get());

        return normalizedUsers
    } catch (error) {
        handleRequestError(error)
    }
}

export const getUsersByIp = async (params, skip) => {
    try {
        const response = await api.get('/users/byIp', {params:{...params, skip}})

        const users = apiNormalize.responseMapping(response.data, userByIpMapping)

        const normalizedUsers = users.map(user => new User(user).get());

        return normalizedUsers
    } catch (error) {
        handleRequestError(error)
    }
}

export const getUser = async (params) => {
    try {
        const response = await api.get('/users/byUser', { params })

        const user = apiNormalize.responseMapping(response.data, userDetailsMapping)

        const normalizedUsers = user.map(user => new User(user).get());

        return normalizedUsers
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedUserDetails = async (params, isEmail) => {
    try {
        const response = await api.get('/users/details', { params })

        const detail = selectedUserDetailsMapping(response.data)

        const normalizedDetail = new UserDetail(detail, isEmail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const createUser = async (userData) => {
    try {
        const response = await api.post('/users', userData)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const updateUser = async (userId, userData) => {
    try {
        const response = await api.put(`/users/${ userId }`, userData)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const deleteUser = async (userId) => {
    try {
        const response = await api.delete(`/users/${ userId }`)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const addUserGold = async (userId, reason, newValue) => {
    try{
        const response = await api.post(`/user/${userId}/addGold`, {reason, newValue})
        return response.data
    }
    catch(error){
        handleRequestError(error)
    }
}

export const addUserToken = async (userId, reason, newValue) => {
    try{
        const response = await api.post(`/user/${userId}/addToken`, {reason, newValue})
        return response.data
    }
    catch(error){
        handleRequestError(error)
    }
}

export const addUserCp = async (userId, reason, newValue) => {
    try{
        const response = await api.post(`/user/${userId}/addCp`, {reason, newValue})
        return response.data
    }
    catch(error){
        handleRequestError(error)
    }
}

export const saveUser = async (userId, changes) => {
    try{
        const response = await api.post(`/user/${userId}/saveUser`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const getUserLogins = async (params, skip) => {
    try {
        const response = await api.get('/users/logins', {params:{...params, skip}})

        const logins = apiNormalize.responseMapping(response.data, userLoginMapping)

        const normalizedLogins = logins.map(login => new UserLogin(login).get());

        return normalizedLogins
    } catch (error) {
        handleRequestError(error)
    }
}

export const removeUserSessions = async (userId) => {
    try{
        const response = await api.post(`/user/${userId}/removeUserSessions`)
        return response.data
    }
    catch(error){
        handleRequestError(error)
    }
}

export const getUserSessions = async (userId) => {
    const response = await api.get(`/user/${userId}/getUserSessions`);
    return response.data[0];
}
