import { createSlice } from '@reduxjs/toolkit'

const infractionsSlice = createSlice({
    name: 'infractions',
    initialState: [],
    reducers: {
        setInfractions: (state, action) => {
            return action.payload
        },
    },
})

export const { setInfractions } = infractionsSlice.actions
export default infractionsSlice.reducer
