import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import Details from './Partials/Details'
import FormSearch from './Partials/FormSearch'
import style from '../../../styles/Search.module.css'

import { users } from 'data/users'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAvatars } from 'store/modules/users/avatars/avatarsSlice'
import { useEffect, useState } from 'react'
import { BiCoin, BiShield } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { selectAvatarForDetails } from 'store/modules/users/avatars/selectedAvatarSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

const avatarIconObject = { key: 'avatarIcon' }

export default function Avatars({ userId, avatarId, avatarOnline, avatarFirstName, avatarLastName, avatarNameInstance }) {
    const verifyAvatarList = useUserPermission(SMIPermissionsCode.MOD_AVATAR_VIEW);
    const verifyAvatarChange = useUserPermission(SMIPermissionsCode.MOD_AVATAR_CHANGE);

    const [avatarsHeaders, setAvatarsHeaders] = useState([
        { title: 'Usuário', key: 'userName', copy: 'userId', appear: true },
        { title: 'Avatar', key: 'avatarName', copy: 'avatarId', icon: () => avatarIconObject, detailable: verifyAvatarChange, appear: true },
    ])

    const [avatarsMomentHeaders, setAvatarsMomentHeaders] = useState([...avatarsHeaders]);

    const handleAppearHeader = (item) => {
        setAvatarsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setAvatarsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const avatars = useSelector((state) => {
        return Array.isArray(state.avatars.data) ? state.avatars.data : []
    })

    const skip = useSelector((state) => {
        return (state.avatars.currentSkip) ? state.avatars.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.avatars.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.avatars.filters) ? state.avatars.filters : {}
    })
    const selectedAvatar = useSelector((state) => {
        return state.selectedAvatar
    })

    useEffect(() => {
        setLoading(false)
    }, [avatars]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (userId || avatarId || avatarOnline || avatarFirstName || avatarLastName || avatarNameInstance) {
            setLoading(true)
            fetchAvatars(dispatch, { userId, avatarId, avatarOnline, avatarFirstName, avatarLastName, avatarNameInstance }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
        if (filter.form.avatarOnline) query.append('avatarOnline', filter.form.avatarOnline);
        if (filter.form.avatarFirstName) query.append('avatarFirstName', filter.form.avatarFirstName);
        if (filter.form.avatarLastName) query.append('avatarLastName', filter.form.avatarLastName);
        if (filter.form.avatarNameInstance) query.append('avatarNameInstance', filter.form.avatarNameInstance);

        window.history.pushState({}, '', `/users/avatars?${query.toString()}`);
        fetchAvatars(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchAvatars(dispatch, filter.form, skip + 200);
    })

    const handleAvatarDetails = (item) => {
        dispatch(selectAvatarForDetails({ avatarId: item.avatarId }))
    }

    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                {verifyAvatarList && <div className='col-span-7'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={avatarsHeaders}
                        headers={avatarsMomentHeaders}
                        slotHeader={<FormSearch onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Avatar'
                        onDetailRow={handleAvatarDetails}
                        isLoading={isLoading && avatars.length === 0}
                        items={avatars}
                        slotActions={<div className={style.userHeader}><Button text="Novo Usuário" onClick={() => { }} color="cyan" /> <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} /> </div>}
                        slotFooter={
                            <div className={style.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(avatars.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : avatars.length} resultados</span>}
                            </div>
                        }
                    />
                </div>}
                <div className='col-span-5'>
                    {selectedAvatar.avatarId && <Details item={selectedAvatar} />}
                </div>
            </div>
        </>
    )
}
