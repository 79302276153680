import { getXpLogs } from "services/avatarsService";

const { createSlice, current } = require("@reduxjs/toolkit");

const xpLogsSlice = createSlice({
    name: "xpLogs",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            xpType: '',
            dateFrom: '',
            dateTo: '',
        }
    },
    reducers: {
        setXpLogsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addXpLogsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setXpLogsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchXpLogs(dispatch, params, skip, userId, avatarId) {
    try {
        const LIMIT = 200;
        const xpLogs = await getXpLogs(params, skip, userId, avatarId);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addXpLogsData(xpLogs))
        }
        else {
            dispatch(setXpLogsData(xpLogs));
        }

        if (xpLogs.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Logs de XP', error)
    }
}

export async function fetchXpLogsFilters(dispatch, params){
    dispatch(setXpLogsFilter(params));
}

export const { setXpLogsData, addXpLogsData, setCurrentSkip, setHasMore, setXpLogsFilter } = xpLogsSlice.actions
export default xpLogsSlice.reducer