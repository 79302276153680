import InputField from 'components/form/InputField'
import React, { useState } from 'react'
import { login } from 'services/auth'
import Button from 'components/form/Button'

export default function Form2Fa ({ email, password, back }) {
    const [token, setToken] = useState('')

    const handleTokenChange = (event) => {
        setToken(event.target.value)
    }

    const handleBack = () => {
        back()
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        login(email, password, token)
            .then(response => {
                if (response.data.needAuth) {
                    console.log('TOKEN')
                } if (response.data.swsid) {
                    localStorage.setItem('token', response.data.swsid)
                    window.location.href = '/'
                }
                console.log(response.data)
            })
            .catch(error => {
                // Manipular erros aqui
                console.error(error)
            })
    }

    return (
        <form onSubmit={ handleSubmit }>
            <input type="text" onChange={ handleTokenChange } className='w-full border border-gray-300 rounded px-2 py-3 focus:outline-none focus:border-cyan-800' />
            {/* <InputField
                id="token"
                name="token"
                type="token"
                autoComplete="token"
                required
                label="Token"
                className="w-full block p-3"
                placeholder="Token"
                value={ token }
                onChange={ handleTokenChange } /> */}

            <div className='flex justify-between gap-x-2'>
                <Button text="Voltar" onClick={ handleBack } className="bg-yellow-600 hover:bg-yellow-500 w-full px-3 py-3 mt-5" />
                <Button type="submit" text="Enviar" className="bg-yellow-600 hover:bg-yellow-500 w-full px-3 py-3 mt-5" />
            </div>
        </form>
    )
}
