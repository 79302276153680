import { createSlice } from '@reduxjs/toolkit'

const SelectedInterstitialItemOrGroupSlice = createSlice({
    name: 'SelectedInterstitialItemOrGroup',
    initialState: [],
    reducers: {
        setSelectedInterstitialItemOrGroup: (state, action) => {
            return action.payload
        },
    },
})

export const fetchSelectedInterstitialItemOrGroup = (params) => async (dispatch) => {
    try {
        dispatch(setSelectedInterstitialItemOrGroup(params))
    } catch (error) {
        console.error('Erro ao carregar:', error)
    }
}

export const fetchClearSelectedInterstitialItemOrGroup = () => async (dispatch) => {
    dispatch(setSelectedInterstitialItemOrGroup([]))
}

export const { setSelectedInterstitialItemOrGroup } = SelectedInterstitialItemOrGroupSlice.actions
export default SelectedInterstitialItemOrGroupSlice.reducer