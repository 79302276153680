import React, { useEffect } from 'react'

const Checkbox = ({ label, checked, name, onChange }) => {

    const handleChange = (event) => {
        typeof onChange == 'function' ? onChange(event) : {}
    }

    useEffect(() => {
        //setTimeout(() => {
           // handleChange({ target: { name: name, value: checked } })
       // }, 500);
    }, [])

    return (
        <div className="flex items-center px-2">
            <input
                type="checkbox"
                checked={ checked }
                name={ name }
                onChange={ handleChange }
                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
            />
            <label className="ml-2">{ label }</label>
        </div>
    )
}

export default Checkbox
