const userMapping = item => ({
    id: getId(item._id) ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    user: item.firstName ?? null,
    email: item.email ?? null,
    avatarId: item.avatar._id ?? null,
    avatarFirstName: item.avatar.firstName ?? null,
    avatarLastName: item.avatar.lastName ?? null,
    avatarNameInstance: item.avatar.nameInstance ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    kudos: item.id ?? null,
})

const userMappingByAvatar = item => ({
    id: getId(item._id) ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    user: item.firstName ?? null,
    email: item.email ?? null,
    avatarFirstName: item.avatar.firstName ?? null,
    avatarLastName: item.avatar.lastName ?? null,
    avatarNameInstance: item.avatar.nameInstance ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    kudos: item.id ?? null,
})

const userDetailsMapping = item => ({
    id: getId(item._id) ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    user: item.firstName ?? null,
    email: item.email ?? null,
    avatarFirstName: item.avatar.firstName ?? null,
    avatarLastName: item.avatar.lastName ?? null,
    avatarNameInstance: item.avatar.nameInstance ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    kudos: item.id ?? null,
})

const selectedUserDetailsMapping = item => ({
    id: item.id ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    dateOfBirth: item.dateOfBirth ?? null,
    gender: item.gender ?? null,
    email: item.email ?? null,
    avatar: item.avatar ?? null,
    authenticator: item.authenticator ?? null,
    dateOfBirth: item.dateOfBirth ?? null,
    gender: item.gender ?? null,
    primaryGroupId: item.primaryGroupId ?? null,
    secondaryGroups: item.secondaryGroups ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    modNotes : item.modNotes ?? null

})

const userByIpMapping = item => ({
    id: getId(item._id) ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    user: item.firstName ?? null,
    email: item.email ?? null,
    avatar: item.avatar?.firstName ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    kudos: item.id ?? null,
    avatar: item.avatar.firstName ?? null,
    avatarId: item.avatar._id ?? null,
    avatarFirstName: item.avatar.firstName ?? null,
    avatarLastName: item.avatar.lastName ?? null,
    avatarNameInstance: item.avatar.nameInstance ?? null,
})

const userByAvatarMapping = item => ({
    id: getId(item._id) ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    user: item.firstName ?? null,
    email: item.email ?? null,
    avatarId: item.avatar._id ?? null,
    avatarFirstName: item.avatar.firstName ?? null,
    avatarLastName: item.avatar.lastName ?? null,
    avatarNameInstance: item.avatar.nameInstance ?? null,
    gold: item.goldBalance ?? null,
    tokens: item.tokenBalance ?? null,
    cp: item.citizenPoints ?? null,
    kudos: item.id ?? null,
    avatar: item.avatar.firstName ?? null,
})

const userLoginMapping = item => ({
    timestamp: item.timestamp ?? null,
    ip: item.ip ?? null
})

const getId = (id) => {
    return typeof id === 'object' ? id.oid : id
}

const userSessionsMapping = item => ({
    swid: item.swid ?? null
})

export { userMapping, userByAvatarMapping, userByIpMapping, userDetailsMapping, selectedUserDetailsMapping, userLoginMapping, userSessionsMapping}
