import moment from "moment"
import { getSmallID } from 'helpers'

class ChatLog {
    constructor({ messageId, message, date, spaceId, spaceName, instanceId, avatarId, avatarInstance, avatarLastName, avatarFirstName, avatarIcon, headPostfix }) {
        this.messageId = messageId
        this.message = message
        this.date = date
        this.spaceId = spaceId
        this.spaceName = spaceName
        this.instanceId = instanceId
        this.avatarId = avatarId
        this.avatarInstance = avatarInstance
        this.avatarLastName = avatarLastName
        this.avatarFirstName = avatarFirstName
        this.avatarIcon = avatarIcon
        this.headPostfix = headPostfix
    }

    get() {
        return {
            messageId: this.messageId,
            message: this.message,
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm'),
            spaceId: this.spaceId,
            spaceName: this.spaceName,
            instanceId: this.instanceId,
            avatarId: this.avatarId,
            avatarInstance: this.avatarInstance,
            avatarLastName: this.avatarLastName,
            avatarFirstName: this.avatarFirstName,
            avatarIcon: this.avatarIcon,
            avatarName: this.avatarFullName(this.avatarInstance, this.avatarFirstName, this.avatarLastName),
            headPostfix: this.headPostfix,
            smallId: getSmallID(this.messageId, 7),
        }
    }

    smallID () {
        return getSmallID(this.messageId, 7)
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName){
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if(avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }
}

export default ChatLog