import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { fetchPermissionCategories } from "store/modules/administration/userGroups/permissionCategoriesSlice";
import style from '../../../../styles/Search.module.css'
import catalogStyle from '../../../../styles/CatalogStyle.module.css'
import Tabs from "components/Tabs";
import { savePermissions } from "services/userGroupsService";
import { toast } from "react-toastify";

export default function GroupDetails({ item }) {
    //listar permissions categories com permissions como filhos
    //colocar select nos filhos,
    //iniciar select dos filhos de acordo com item.permissions

    const tabs = [
        {
            id: 1,
            tabTitle: 'MiniMania',
        },
        {
            id: 2,
            tabTitle: 'SMI',
        },
    ]

    const [isLoading, setLoading] = useState(false)

    const permissionCategoryHeaders = [
        { title: 'Nome', key: 'name' },
    ]

    const permissionCategoryChildrenConfig = {
        headers: [
            { title: 'Nome', key: 'name' },
        ],
        childrenKey: 'children'
    }

    const tab = useSelector((state) => {
        return (state.permissionCategories.tab) ? state.permissionCategories.tab : 1
    })

    const permissionCategories = useSelector((state) => {
        return Array.isArray(state.permissionCategories.data) ? state.permissionCategories.data : []
    })

    const dispatch = useDispatch();

    const getPermissionCategories = () => {
        setLoading(true);
        dispatch(fetchPermissionCategories(tab));
    }

    useEffect(() => {
        if (permissionCategories.length == 0) {
            getPermissionCategories();
        }
    }, [])

    const search = (id) => {
        setLoading(true)
        dispatch(fetchPermissionCategories(id));
    }

    const [originalPermissions, setOriginalPermissions] = useState(item.permissions);
    const [newPermissions, setNewPermissions] = useState(item.permissions);

    const handleNewPermissions = (index) => {
        const newPermissionsCopy = [...newPermissions];
        if(newPermissionsCopy[index] == true){
            newPermissionsCopy[index] = null
        }
        else{
            newPermissionsCopy[index] = true;
        }
        setNewPermissions(newPermissionsCopy);
    }

    useEffect(() => {
        setOriginalPermissions(item.permissions)
        setNewPermissions(item.permissions)
    }, [item])

    const areEqual = (array1, array2) => {
    
        if (array1.length !== array2.length) {
          return false;
        }
    
        for (let i = 0; i < array1.length; i++) {
          if (array1[i] !== array2[i]) {
            return false;
          }
        }

        return true;
      }

      const saveNewPermissions = async () => {
        try{
            await savePermissions(newPermissions, item.id)
            setNewPermissions(newPermissions)
            setOriginalPermissions(newPermissions)
            toast.success('Permissões Alteradas com Sucesso!')
        }
        catch{
            toast.error('Erro ao Alterar Permissões!')
        }
      }


      if(originalPermissions == undefined || newPermissions == undefined){
        return null
      }
      else{
        return (
            <>
                <BasicTable
                    title='Permissões'
                    isChildrenSelectable={true}
                    headers={permissionCategoryHeaders}
                    items={permissionCategories}
                    isLoading={permissionCategories.length == 0 && isLoading}
                    children={() => permissionCategoryChildrenConfig}
                    slotFooter={
                        <div className={catalogStyle.basicTableFooter}>
                            <Button disabled={(areEqual(newPermissions, originalPermissions))} text="Atualizar Permissões de Grupo" onClick={saveNewPermissions} color="cyan" />
                        </div>
                    }
                    slotHeader={<>
                        <Tabs tabs={tabs} onChangeTab={search} tab={tab} />
                    </>}
                    isDropDown={true}
                    height="60vh"
                    permissions={newPermissions}
                    handlePermissions={handleNewPermissions}
                />
            </>
        )
    }
      }