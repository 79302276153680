import AbuseReports from "Pages/Moderation";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAbuseReportsFilters } from "store/modules/moderation/abuseReports/abuseReportSlice";

export function AbuseReportsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");
    const reportType = searchParams.get("reportType");
    const categoryId = searchParams.get("categoryId");
    const reporterId = searchParams.get("reporterId");
    const reportedId = searchParams.get("reportedId");
    const assignedTo = searchParams.get("assignedTo");
    const status = searchParams.get("status");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    
    let typePropName = {
        A: 'Enviada pelo Usuário',
        S: 'Gerado pelo Sistema',
    }

    let reportTypePropName = {
        A: 'Denúncia de Avatar',
        U: 'Denúncia de Usuário',
        S: 'Denúncia de Espaço',
        E: 'Denúncia In-World Mail',
        M: 'Denúncia de Missão'
    }

    let categoryIdPropName = {
        '': 'Todas',
        1: 'Trapaça/Mentira',
        2: 'Abuso Sexual/Racial/Mau Comportamento',
        3: 'Textos ou Imagens Pornográficas',
        4: 'Exploração/Fraude',
        5: 'Spam',
        6: 'Usuário menor de idade',
        7: 'Passando-se por membro da equipe',
        9: 'Outro',
    }

    let statusPropName = {
        A: 'Atribuída',
        U: 'Não lida',
        R: 'Resolvida',
        B: 'Fechada',
        '': 'Todas'
    }

    

    const dispatch = useDispatch()

    if (type || reportType || categoryId || reporterId || reportedId || assignedTo || status || dateFrom || dateTo) {
        fetchAbuseReportsFilters(dispatch, { reporterId, type: type ? {name: typePropName[type], value: type} : '', reportType: reportType ? {name: reportTypePropName[reportType], value: reportType} : '', reportedId, assignedTo, categoryId: categoryId ? {name: categoryIdPropName[categoryId], value: categoryId} : '', status: status ? {name: statusPropName[status], value: status} : '', dateFrom, dateTo })
        return <AbuseReports reporterId={reporterId ?? null} type={type ?? null} reportType={reportType ?? null} reportedId={reportedId ?? null} assignedTo={assignedTo ?? null} categoryId={categoryId ?? null} status={status ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null}/>;
    }
    else{
        return <AbuseReports/>;
    }

}