const points = {
    headers: [
        { title: 'Timestamp' },
        { title: 'Fonte' },
        { title: 'Tipo' },
        { title: 'ID' },
        { title: 'Quantidade' },
    ],
    items: [
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
        { timestamp: '2023-04-06 18:35:05', source: 'Test Source', type: 'Test Type', id: '54ew6g54tg4eg', amount: 525 },
    ]
}

export default points
