import Logs from "Pages/Administration/Logs";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchModLogsFilters } from "store/modules/administration/modLogs/modLogsSlice";

export function LogsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const modId = searchParams.get("modId");
    const property = searchParams.get("property");
    const days = searchParams.get("days");
    let propName = {
        '': 'Todos',
        '1': 'Último dia',
        '7': 'Últimos 7 dias',
        '30': 'Últimos 30 dias',
    }

    const dispatch = useDispatch()

    if (modId || property || days) {
        fetchModLogsFilters(dispatch, {modId, property, days: days ? {name: propName[days], value: days} : ''})
        return <Logs modId={modId ?? null} property={property ?? null} days={days ?? null}/>;
    }
    else{
        return <Logs/>;
    }

}