import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Modal from 'components/Modal'
import Button from 'components/form/Button'
import style from '../../../styles/Search.module.css'
import styleUserTabs from '../../../styles/UserTabs.module.css'
import catalogStyle from '../../../styles/CatalogStyle.module.css'
import dndStyle from '../../../styles/DnDStyle.module.css'
//import Select from 'react-select'

// dados
import { infractions, staff } from 'data/dashboard'
import { categories, items } from 'data/items'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSpaceCategories } from 'store/modules/spaces/categories/categoryActions'
import { toast } from 'react-toastify'
import BasicTableDnD from 'components/BasicTableDnD'
import { createCategory, deleteCategory, saveEditCategory, saveReorderCategories } from 'services/categoryService'
import { Paths } from 'paths/Paths'
import { selectSpaceCategoryForDetails } from 'store/modules/spaces/categories/selectedSpaceCategorySlice'
import SpaceCategoryDetails from './Partials/SpaceCategoryDetails'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'

const categoryStatus = {
    activeIcon: 'ativo',
    inactiveIcon: 'inativo',
    hasAction: false
}

const categoryActions = [
    { action: 'edit', icon: null },
    { action: 'delete', icon: null }
]


const iconObject = { key: 'icon' }

const categoryHeaders = [
    { title: 'Nome', key: 'name', icon: () => iconObject, detailable: true },
    { title: 'Status', key: 'active', status: categoryStatus },
    { title: 'Ações', key: 'acoes', actions: () => categoryActions }
]

const categoryHeadersReorder = [
    { title: 'Nome', key: 'name', icon: () => iconObject },
    { title: 'Status', key: 'active', status: categoryStatus },
]

const categoryChildrenConfig = {
    headers: [
        { title: 'Nome', key: 'name', detailable: true },
        { title: 'Status', key: 'active', status: categoryStatus },
        { title: 'Ações', key: 'acoes', actions: () => categoryActions }
    ],
    childrenKey: 'children'
}

const categoryChildrenConfigReorder = {
    headers: [
        { title: 'Nome', key: 'name' },
        { title: 'Status', key: 'active', status: categoryStatus },
    ],
    childrenKey: 'children'
}

export default function Catalog() {
    const verifySpaceCatalog = useUserPermission(SMIPermissionsCode.MOD_SPACE_CATALOG);

    const [isLoading, setLoading] = useState(false)
    const [categoriesItems, setCategoriesItems] = useState([])

    const dispatch = useDispatch()

    const getCategories = () => {
        setLoading(true)
        dispatch(fetchSpaceCategories())
    }

    const selectedSpaceCategory = useSelector((state) => {
        return state.selectedSpaceCategory
    })

    // Redux
    const spaceCategories = useSelector((state) => {
        return Array.isArray(state.spaceCategories) ? state.spaceCategories : []
    })

    useEffect(() => {
        setLoading(false)
        setCategoriesItems(spaceCategories)
        console.log(spaceCategories)
    }, [spaceCategories]);

    useEffect(() => {
        getCategories()
    }, [])

    // Reorder development

    const [isReorderOpen, setIsReorderOpen] = useState(false)

    const handleOpenReorederModal = () => {
        setIsReorderOpen(true)
    }

    const handleCloseReorederModal = () => {
        setCategoriesItems(categories)
        setIsReorderOpen(false)
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const items = reorder(categoriesItems, result.source.index, result.destination.index);
        setCategoriesItems(items)
        console.log(items)
    }

    const handleOnDragEndChild = (result, item) => {
        let tempCategoriesItems = [...categoriesItems]
        if (!result.destination) {
            return
        }
        const index = categoriesItems.indexOf(item)
        const items = reorder(categoriesItems[index].children, result.source.index, result.destination.index);
        let modifiedItem = { ...categoriesItems[index] };
        modifiedItem.children = items
        tempCategoriesItems[index] = modifiedItem
        setCategoriesItems(tempCategoriesItems)
        console.log(tempCategoriesItems)
    }

    const handleSaveReorder = async () => {
        console.log(categoriesItems)

        try {

            let tempArr = []
            for (let i = 0; i < categoriesItems.length; i++) {
                if (categoriesItems[i].children.length > 0) {
                    for (let j = 0; j < categoriesItems[i].children.length; j++) {
                        tempArr.push({
                            name: categoriesItems[i].children[j].name,
                            categoryId: categoriesItems[i].children[j].id,
                            priorityId: j + 1
                        })
                    }
                }

                tempArr.push({
                    name: categoriesItems[i].name,
                    categoryId: categoriesItems[i].id,
                    priorityId: i + 1
                })
            }

            console.log(tempArr)

            await saveReorderCategories(tempArr)

            getCategories()
            handleCloseReorederModal()
            toast.success('Nova Organização Salva com Sucesso')

        }
        catch {
            toast.error('Erro ao Salvar Nova Organização!')
        }
    }

    // Item actions development

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [categoryNameInput, setCategoryNameInput] = useState("");
    const [categoryIconPathInput, setCategoryIconPathInput] = useState("");
    const [categoryIconUrl, setCategoryIconUrl] = useState("");
    const [categoryEditList, setCategoryEditList] = useState([]);
    const [separatorCheckInput, setSeparatorCheckInput] = useState(false);
    const [activeCheckInput, setActiveCheckInput] = useState(false);
    const [originalCategoryValues, setOriginalCategoryValues] = useState({});
    const [changedCategoryValues, setChangedCategoryValues] = useState({});
    const [hasChanged, setHasChanged] = useState(false)
    const [controlInputValue, setControlInputValue] = useState(false)
    const [orderInputValue, setOrderInputValue] = useState(false)

    //Edit Development

    const handleOpenEditModal = () => {
        setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
        setCategoryNameInput("");
        setCategoryIconPathInput("");
        setCategoryIconUrl("");
        setActiveCheckInput(false)
        setSeparatorCheckInput(false)
        setHasChanged(false);
        setOriginalCategoryValues({});
        setChangedCategoryValues({});
        setControlInputValue(false);
        setOrderInputValue(false);

        setIsEditModalOpen(false);
    }

    const handleEditItem = (tableItem) => {
        console.log(tableItem);
        let tempObj = {
            id: tableItem.id,
            isManuallyControlled: tableItem.isManuallyControlled,
            sortsByOrderIndex: tableItem.sortsByOrderIndex,
            name: tableItem.name,
            iconUrl: tableItem.icon == null ? null : tableItem.icon.replace(`${Paths.libraryPath}`, ''),
            active: tableItem.active,
            hasSeparator: tableItem.hasSeparator
        }

        setOriginalCategoryValues(tempObj);
        setChangedCategoryValues(tempObj);

        setControlInputValue(tableItem.isManuallyControlled);
        setOrderInputValue(tableItem.sortsByOrderIndex);

        setCategoryNameInput(tableItem.name)

        if (tableItem.icon != null) {
            setCategoryIconUrl(tableItem.icon);
            setCategoryIconPathInput(tableItem.icon.replace(`${Paths.libraryPath}`, ''));
        }

        setActiveCheckInput(tableItem.active)
        setSeparatorCheckInput(tableItem.hasSeparator)

        handleOpenEditModal()
    }

    const handleOnChangeControlInput = () => {
        let tempObj = { ...changedCategoryValues };
        tempObj.isManuallyControlled = !controlInputValue;
        setChangedCategoryValues(tempObj);

        setControlInputValue((value) => !value)
    }

    const handleOnChangeOrderInput = () => {
        let tempObj = { ...changedCategoryValues };
        tempObj.sortsByOrderIndex = !orderInputValue;
        setChangedCategoryValues(tempObj);

        setOrderInputValue((value) => !value)
    }

    const handleCategoryNameInput = (event) => {
        setCategoryNameInput(event.target.value);

        let tempObj = { ...changedCategoryValues };
        tempObj.name = event.target.value.trim() == '' ? null : event.target.value;
        setChangedCategoryValues(tempObj);
    }

    const handleCategoryIconPathInput = (event) => {
        setCategoryIconPathInput(event.target.value)
        if (event.target.value.trim() != '') {
            setCategoryIconUrl(`${Paths.libraryPath}${event.target.value}`)
        }
        else {
            setCategoryIconUrl("")
        }
        let tempObj = { ...changedCategoryValues };
        tempObj.iconUrl = event.target.value.trim() == '' ? null : event.target.value;
        setChangedCategoryValues(tempObj);
    }

    const handleSeparatorCheck = () => {
        let tempObj = { ...changedCategoryValues };
        tempObj.hasSeparator = !separatorCheckInput;
        setChangedCategoryValues(tempObj);

        setSeparatorCheckInput((value) => !value)
    }

    const handleActiveCheck = () => {
        let tempObj = { ...changedCategoryValues };
        tempObj.active = !activeCheckInput;
        setChangedCategoryValues(tempObj);

        setActiveCheckInput((value) => !value)
    }

    useEffect(() => {
        console.log(originalCategoryValues)
        console.log(changedCategoryValues)

        let check;
        if (changedCategoryValues.name == null) {
            check = false;
        }
        else {
            const areEqual = JSON.stringify(changedCategoryValues) === JSON.stringify(originalCategoryValues);
            check = !areEqual
        }

        setHasChanged(check)


    }, [changedCategoryValues])

    const handleSaveEditCategory = async () => {
        try {
            let changes = {}
            const keys = Object.keys(originalCategoryValues)
            keys.map((item) => {
                if (originalCategoryValues[item] != changedCategoryValues[item]) {
                    changes[item] = changedCategoryValues[item]
                }
            })

            //importar esse save everificar na api como que salva
            await saveEditCategory(originalCategoryValues.id, changes)

            handleCloseEditModal();
            getCategories();

            toast.success("Salvo com Sucesso!");
        }
        catch {
            toast.error("Erro ao Salvar!");
        }
    }

    //Delete Development

    const [isDeleteCategory, setIsDeleteCategory] = useState(false)
    const [currentItemToDelete, setCurrentItemToDelete] = useState({})

    const handleDeleteItem = (item) => {
        setCurrentItemToDelete(item)
        handleOpenDeleteCategoryModal()
    }

    const handleOpenDeleteCategoryModal = () => {
        setIsDeleteCategory(true)
    }

    const handleCloseDeleteCategoryModal = () => {
        setIsDeleteCategory(false)
    }

    const handleDeleteCategory = async () => {
        try {

            await deleteCategory(currentItemToDelete.id)

            handleCloseDeleteCategoryModal();
            getCategories();

            toast.success("Categoria Excluída com Sucesso!");
        }
        catch {
            toast.error("Erro ao Excluir Categoria!");
        }
    }

    //Create Development

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [createCategoryNameInput, setCreateCategoryNameInput] = useState("");
    const [createCategoryIconPathInput, setCreateCategoryIconPathInput] = useState("");
    const [createCategoryIconUrl, setCreateCategoryIconUrl] = useState("")
    const [checkCreate, setCheckCreate] = useState(false)

    const handleCloseCreateModal = () => {
        setCreateCategoryNameInput("");
        setCreateCategoryIconPathInput("");
        setCreateCategoryIconUrl("");
        setCheckCreate(false);

        setIsCreateModalOpen(false);
    }

    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    }

    const addCategory = () => {
        handleOpenCreateModal();
    }

    const handleCreateCategoryNameInput = (event) => {
        setCreateCategoryNameInput(event.target.value);
        if (event.target.value.trim() != '') {
            setCheckCreate(true);
        }
        else {
            setCheckCreate(false);
        }
    }

    const handleCreateCategoryIconPathInput = (event) => {
        setCreateCategoryIconPathInput(event.target.value);
        if (event.target.value.trim() != '') {
            setCreateCategoryIconUrl(`${Paths.libraryPath}${event.target.value}`);
        }
        else {
            setCreateCategoryIconUrl("");
        }
    }

    const handleSaveCreateCategory = async () => {
        try {
            if (createCategoryIconPathInput.trim() == '') {
                await createCategory(createCategoryNameInput, null, 'space')
            }
            else {
                await createCategory(createCategoryNameInput, createCategoryIconPathInput, 'space')
            }

            handleCloseCreateModal();
            getCategories();

            toast.success("Categoria Criada com Sucesso!");
        }
        catch {
            toast.error("Erro ao Criar Categoria!");
        }
    }

    //Category Detail Development

    const handleCategoryDetails = (item) => {
        dispatch(selectSpaceCategoryForDetails({ id: item.id }))
    }


    return (
        <>
            {verifySpaceCatalog && <div>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-5'>
                        <BasicTable
                            onEditItem={handleEditItem}
                            onDeleteItem={handleDeleteItem}
                            title='Categorias'
                            headers={categoryHeaders}
                            items={spaceCategories}
                            isLoading={isLoading}
                            //onSelectRow={handleSelectRow}
                            onDetailRow={handleCategoryDetails}
                            children={() => categoryChildrenConfig}
                            slotFooter={
                                <div className={catalogStyle.basicTableFooter}>
                                    <Button text="Adicionar Categoria" onClick={addCategory} color="cyan" />
                                </div>
                            }
                            slotActions={
                                <div className={style.slotAbuseReportBtnsFooter}>
                                    <Button text="Atualizar" onClick={getCategories} color="cyan" />
                                    <Button text="Reordenar" onClick={handleOpenReorederModal} color="cyan" />
                                </div>
                            }
                            height='70vh'
                        />
                    </div>
                    <div className='col-span-7'>
                        {selectedSpaceCategory.id && <SpaceCategoryDetails item={selectedSpaceCategory} />}
                    </div>

                </div>
                <Modal
                    isOpen={isReorderOpen}
                    onClose={handleCloseReorederModal}
                    footer={
                        <div className={dndStyle.modalFooter}>
                            <Button disabled={(JSON.stringify(spaceCategories) == JSON.stringify(categoriesItems))} text="Salvar" onClick={handleSaveReorder} color="cyan" />
                        </div>
                    }
                >
                    <div className={dndStyle.modalContainer}>
                        <div className={dndStyle.tableContainer}>

                            {

                                <BasicTableDnD
                                    title='Categorias'
                                    headers={categoryHeadersReorder}
                                    items={categoriesItems}
                                    isLoading={isLoading}
                                    children={() => categoryChildrenConfigReorder}
                                    onDragEnd={handleOnDragEnd}
                                    onDragEndChild={handleOnDragEndChild}
                                    height='100%'
                                    round={false}
                                />

                            }
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={isEditModalOpen}
                    onClose={handleCloseEditModal}
                    footer={
                        <div className={dndStyle.modalFooter}>
                            <Button disabled={!hasChanged} text="Salvar" onClick={handleSaveEditCategory} color="cyan" />
                        </div>
                    }
                    header={<div><span>Editar Categoria</span></div>}
                >
                    <div className={catalogStyle.createModalContainer}>
                        <div className={catalogStyle.selectEditModalContainer}>
                            <span>Nome da Categoria:</span>
                            <input value={categoryNameInput} className={styleUserTabs.modalInput} onChange={handleCategoryNameInput} placeholder='Nome da Categoria' />
                        </div>

                        <div className={catalogStyle.iconModalContainer}>
                            <span>Caminho do Ícone:</span>
                            <div className={catalogStyle.iconModalContainerContent}>
                                <input value={categoryIconPathInput} className={styleUserTabs.modalInput} onChange={handleCategoryIconPathInput} placeholder='Caminho do Ícone' />
                                <div className={catalogStyle.iconContainer}>
                                    <img className={catalogStyle.iconModalContainerIcon} src={categoryIconUrl} />
                                </div>
                            </div>
                        </div>

                        <div className={catalogStyle.selectContainer}>
                            <div>
                                <span>Controle:</span>
                                <select value={controlInputValue} className={styleUserTabs.select} onChange={handleOnChangeControlInput}>
                                    <option key={'Sim'} value={true}>Manual</option>
                                    <option key={'Não'} value={false}>Popularidade</option>
                                </select>
                            </div>
                            <div>
                                <span>Ordenação:</span>
                                <select value={orderInputValue} className={styleUserTabs.select} onChange={handleOnChangeOrderInput}>
                                    <option key={'Sim'} value={true}>Index</option>
                                    <option key={'Não'} value={false}>Alfabética</option>
                                </select>
                            </div>
                        </div>

                        <div className={catalogStyle.checkboxContainer}>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={separatorCheckInput}
                                    onChange={handleSeparatorCheck}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                />
                                <label className="ml-2">{"Separador"}</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={activeCheckInput}
                                    onChange={handleActiveCheck}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                />
                                <label className="ml-2">{"Ativo"}</label>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    header={<div><span>Atenção!</span></div>}
                    isOpen={isDeleteCategory}
                    onClose={handleCloseDeleteCategoryModal}
                >
                    <div className={style.modalModLogContainer}>
                        <div className={style.modalSlotHeaderTitle}>
                            <span className={style.modalSubtitle}>Deseja excluir definitivamente a categoria?</span>
                        </div>
                        <div className={style.modalSlotHeaderBtn}>
                            <Button disabled={false} text="Sim" onClick={handleDeleteCategory} color="cyan" />
                            <Button disabled={false} text="Não" onClick={handleCloseDeleteCategoryModal} color="cyan" />
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    footer={
                        <div className={dndStyle.modalFooter}>
                            <Button disabled={!checkCreate} text="Salvar" onClick={handleSaveCreateCategory} color="cyan" />
                        </div>
                    }
                    header={<div><span>Criar Categoria</span></div>}
                >
                    <div className={catalogStyle.createModalContainer}>
                        <div className={catalogStyle.selectEditModalContainer}>
                            <span>Nome da Categoria:</span>
                            <input value={createCategoryNameInput} className={styleUserTabs.modalInput} onChange={handleCreateCategoryNameInput} placeholder='Nome da Categoria' />
                        </div>

                        <div className={catalogStyle.iconModalContainer}>
                            <span>Caminho do Ícone:</span>
                            <div className={catalogStyle.iconModalContainerContent}>
                                <input value={createCategoryIconPathInput} className={styleUserTabs.modalInput} onChange={handleCreateCategoryIconPathInput} placeholder='Caminho do Ícone' />
                                <div className={catalogStyle.iconContainer}>
                                    <img className={catalogStyle.iconModalContainerIcon} src={createCategoryIconUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>}
        </>
    )
}
