import React, { useState } from 'react'
import InputField from 'components/form/InputField'
import Checkbox from 'components/form/Checkbox'
export default function FormByAvatar () {
    const [ownerId, setOwnerID] = useState('')
    const [active, setActive] = useState(true)
    const [online, setOnline] = useState(false)
    const [avatarId, setAvatarID] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const handleOwnerIDChange = (event) => {
        setOwnerID(event.target.value)
    }
    const handleActiveChange = (event) => {
        setActive(event.target.checked)
    }
    const handleOnlineChange = (event) => {
        setOnline(event.target.checked)
    }
    const handleAvatarIDChange = (event) => {
        setAvatarID(event.target.value)
    }
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    return (
        <div className='border-b py-2'>
            <div className='flex'>
                <InputField type="text" label="ID do Dono" placeholder="ID do Dono" value={ ownerId } onChange={ handleOwnerIDChange } />

                <Checkbox label="Apenas Online" checked={ online } onChange={ handleOnlineChange } />
                <Checkbox label="Apenas Ativos" checked={ active } onChange={ handleActiveChange } />
            </div>
            <div className="flex">
                <InputField type="text" label="ID do Avatar" placeholder="ID do Avatar" value={ avatarId } onChange={ handleAvatarIDChange } />
                <InputField type="text" label="Nome do Avatar" placeholder="Nome do Avatar" value={ firstName } onChange={ handleFirstNameChange } />
                <InputField type="text" label="Sobrenome do Avatar" disabled placeholder="Sobrenome do Avatar" value={ lastName } onChange={ handleLastNameChange } />
            </div>
        </div>
    )
}
