import { missionDetailsMapping, missionsMapping } from 'domains/Missions/mapping';
import api, { apiNormalize } from './api'
import Mission from 'domains/Missions/Mission';
import MissionDetail from 'domains/Missions/MissionDetail';

export async function getMissions(params, skip){
    const response = await api.get("/missions", {params:{...params,skip}});
    const missions = apiNormalize.responseMapping(response.data, missionsMapping)
    const normalizedMissions = missions.map(mission => new Mission(mission).get());
    return normalizedMissions
}

export const getMission = async (params) => {
    try {
        const response = await api.get('/missions/byMission', { params })
        const mission = apiNormalize.responseMapping(response.data, missionDetailsMapping)
        const normalizedMissions = mission.map(mission => new Mission(mission).get());
        return normalizedMissions
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedMissionDetails = async (params) => {
    try {
        const response = await api.get('/missions/details', { params })

        const detail = missionDetailsMapping(response.data)

        const normalizedDetail = new MissionDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveMission = async (missionId, changes) => {
    try{
        const response = await api.post(`/mission/${missionId}/saveMission`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}