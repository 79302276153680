import { Paths } from "paths/Paths"

class CategorySpaceEntry {
    constructor({ spaceId, spaceIcon, spaceName, entryId, categoryId, categoryName, startDate, endDate }) {
        this.spaceId = spaceId
        this.spaceIcon = spaceIcon
        this.spaceName = spaceName
        this.entryId = entryId
        this.categoryId = categoryId
        this.categoryName = categoryName
        this.startDate = startDate
        this.endDate = endDate
    }

    get() {
        return {
            spaceId: this.spaceId,
            icon: Paths.getSpaceThumbnailURL(this.spaceIcon),
            spaceName: this.spaceName,
            entryId: this.entryId,
            categoryId: this.categoryId,
            categoryName: this.categoryName,
            startDate: this.startDate,
            endDate: this.endDate,
        }
    }
}

export default CategorySpaceEntry