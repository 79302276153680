import React from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'

import { itemsSearch } from '../../data/items'

export default function Director () {
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable
                        title='Director'
                        headers={ itemsSearch.headers }
                        items={ itemsSearch.items }
                        slotActions={ <Button text="Atualizar" color="cyan" /> }
                    />
                </div>
            </div>
        </>
    )
}
