import { createSlice } from '@reduxjs/toolkit'
import { getSelectedtUserGroup } from 'services/userGroupsService';

const selectedUserGroupSlice = createSlice({
    name: 'selectedUserGroup',
    initialState: {},
    reducers: {
        setSelectedUserGroup: (state, action) => {
            let category;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                category = action.payload[0]
            } else {
                category = {}
            }            

            return category
        },
    },
})

export const selectedUserGroupForDetails = (params) => async (dispatch) => {
    try {
        const selectedUserGroup = await getSelectedtUserGroup(params)
        dispatch(setSelectedUserGroup(selectedUserGroup))
    } catch (error) {
        console.error('Erro ao carregar grupo de usuários:', error)
    }
}

export const { setSelectedUserGroup } = selectedUserGroupSlice.actions
export default selectedUserGroupSlice.reducer
