import React, { useEffect, useState } from 'react'

const Tabs = ({ tabs, slotHeader = null, slotFooter = null, onChangeTab, tab }) => {

    const [currentTab, setCurrentTab] = useState('1')

    useEffect( () => {
        if(tab){
            setCurrentTab(tab.toString())
        }
    }, [])

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id)
        typeof onChangeTab == 'function' ? onChangeTab(e.target.id) : {}
    }

    function classNames (...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>

            <div className='tabs-container'>
                <div className='border-b border-gray-200 bg-gradient-to-t from-gray-300'>
                    { slotHeader && (
                        <div>
                            { slotHeader }
                        </div>
                    ) }
                    <nav className="-mb-px flex">
                        { tabs.map((tab, i) =>
                            <button
                                key={ i }
                                id={ tab.id }
                                disabled={ currentTab === `${ tab.id }` || tab.disabled }
                                onClick={ (handleTabClick) }
                                className={ classNames(
                                    currentTab === `${ tab.id }`
                                        ? 'border-cyan-800 text-cyan-800 bg-white bg-opacity-80'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'flex-1 group inline-flex items-center border-b-2 py-3 px-2 text-sm font-medium'
                                ) }>
                                { tab.icon && (
                                    <tab.icon
                                        className={ classNames(
                                            currentTab === `${ tab.id }` ? 'text-cyan-800' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5'
                                        ) }
                                    />
                                ) }

                                { tab.tabTitle }
                            </button>
                        ) }
                    </nav>
                </div>
                <div className='content'>
                    { tabs.map((tab, i) =>
                        <div key={ i }>
                            { currentTab === `${ tab.id }` && <div>
                                { tab.content }
                            </div> }
                        </div>
                    ) }
                </div>
                { slotFooter && (
                    <div>
                        { slotFooter }
                    </div>
                ) }
            </div>
        </>

    )
}

export default Tabs
