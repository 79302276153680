class Friend {
    constructor({ senderId, senderFirstName, senderLastName, senderNameInstance, senderIcon, receiverId, receiverFirstName, receiverLastName, receiverNameInstance, receiverIcon, isAccepted }) {
        this.senderId = senderId
        this.senderFirstName = senderFirstName
        this.senderLastName = senderLastName
        this.senderNameInstance = senderNameInstance
        this.senderIcon = senderIcon
        this.receiverId = receiverId
        this.receiverFirstName = receiverFirstName
        this.receiverLastName = receiverLastName
        this.receiverNameInstance = receiverNameInstance
        this.receiverIcon = receiverIcon
        this.isAccepted = isAccepted
    }

    get() {
        return {
            senderId: this.senderId,
            senderFirstName: this.senderFirstName,
            senderLastName: this.senderLastName,
            senderNameInstance: this.senderNameInstance,
            senderAvatarName: this.avatarFullName(this.senderNameInstance, this.senderFirstName, this.senderLastName),
            senderIcon: this.senderIcon,
            receiverId: this.receiverId,
            receiverFirstName: this.receiverFirstName,
            receiverLastName: this.receiverLastName,
            receiverNameInstance: this.receiverNameInstance,
            receiverAvatarName: this.avatarFullName(this.receiverNameInstance, this.receiverFirstName, this.receiverLastName),
            receiverIcon: this.receiverIcon,
            isAccepted: this.isAccepted,
        }
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if(avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }
}

export default Friend