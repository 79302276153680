export function emailsMapping(item){
    return {
        date: item.date ?? null,
        emailId: item.emailId ?? null,
        senderId: item.senderId ?? null,
        userSenderFirstName: item.userSenderFirstName ?? null,
        userSenderLastName: item.userSenderLastName ?? null,
        receiverId: item.receiverId ?? null,
        userReceiverFirstName: item.userReceiverFirstName ?? null,
        userReceiverLastName: item.userReceiverLastName ?? null,
        emailType: item.emailType ?? null,
        emailStatus: item.emailStatus ?? null,
        emailSubject: item.emailSubject ?? null,
        isDelletedSender: (item.isDelletedSender) ? 'Sim' : 'Não',
        isDelletedReceiver: (item.isDelletedReceiver) ? 'Sim' : 'Não',
        senderType: item.senderType ?? null,
        receiverType: item.receiverType ?? null,
        avatarSenderFirstName: item.avatarSenderFirstName ?? null,
        avatarSenderLastName: item.avatarSenderLastName ?? null,
        avatarSenderInstance: item.avatarSenderInstance ?? null,
        avatarReceiverFirstName: item.avatarReceiverFirstName ?? null,
        avatarReceiverLastName: item.avatarReceiverLastName ?? null,
        avatarReceiverInstance: item.avatarReceiverInstance ?? null,
        senderIcon: item.senderIcon ?? null,
        receiverIcon: item.receiverIcon ?? null
    }
}