import { useEffect, useState } from "react";
import { useSpinCategory, useSpinPrizes } from "./itemSearch";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import Modal from "components/Modal";
import style from '../../../styles/UserTabs.module.css'
import searchStyle from '../../../styles/Search.module.css'
import catalogStyle from '../../../styles/CatalogStyle.module.css'
import { deleteSpinPrize, saveSpinCategory, saveSpinCategoryPrize } from "services/spinService";
import { toast } from "react-toastify";
import Tabs from "components/Tabs";
import { fetchSelectedSpinCategoryDetails } from "store/modules/items/spin/SelectedSpinCategoryDetailsSlice";
import { fetchSelectedSpinPrizes } from "store/modules/items/spin/SelectedSpinPrizesSlice";
import { fetchModelList } from "store/modules/items/modelListSlice";
import Select from 'react-select'

export function SpinDetails({ item, slicesCount }) {

    //ABA PROPRIEDADES

    const spinBackgroundColors = [
        { id: 1, name: 'Purple' },
        { id: 2, name: 'Orange' },
        { id: 3, name: 'Blue' },
        { id: 4, name: 'Peach' },
        { id: 5, name: 'Pink' },
        { id: 6, name: 'Green' },
        { id: 7, name: 'Lime' },
        { id: 8, name: 'Rainbow' },
        { id: 9, name: 'Gold' },
        { id: 10, name: 'Gold Stripe' },
        { id: 11, name: 'Yellow Stripe' },
        { id: 12, name: 'Purple Stars' },
        { id: 13, name: 'Mystery' },
        { id: 14, name: 'Booby' },
    ];

    const spinCategoriesHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstSpinCategoryData = useSpinCategory(item);
    const [spinCategoryData, setSpinCategoryData] = useState(firstSpinCategoryData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstSpinCategoryDataCopy = { ...firstSpinCategoryData }
        setSpinCategoryData(firstSpinCategoryDataCopy)

        setOriginalValues({
            id: firstSpinCategoryData.id,
            name: firstSpinCategoryData.name,
            active: firstSpinCategoryData.active,
            weighting: firstSpinCategoryData.weighting,
            count: firstSpinCategoryData.count,
            background: firstSpinCategoryData.background
        })

        setChangedValues({
            id: firstSpinCategoryData.id,
            name: firstSpinCategoryData.name,
            active: firstSpinCategoryData.active,
            weighting: firstSpinCategoryData.weighting,
            count: firstSpinCategoryData.count,
            background: firstSpinCategoryData.background

        })

    }, [firstSpinCategoryData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let spinCategoryDataCopy = { ...spinCategoryData, tableProperties: [...spinCategoryData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (modalTitle == 'Peso' || modalTitle == 'Contador') {
            if (inputValue != '' && inputValue != 0) {
                for (let i = 0; i < spinCategoryData.tableProperties.length; i++) {
                    if (spinCategoryData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        spinCategoryDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        spinCategoryDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setSpinCategoryData(spinCategoryDataCopy);
            }
        }
        else {
            if (inputValue != '') {
                for (let i = 0; i < spinCategoryData.tableProperties.length; i++) {
                    if (spinCategoryData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        spinCategoryDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        spinCategoryDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                    console.log(inputValue)
                }
                console.log(changedValuesCopy)
                setChangedValues(changedValuesCopy)
                setSpinCategoryData(spinCategoryDataCopy);
            }
        }

        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setSpinCategoryData(firstSpinCategoryData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        if (!(originalValues.active == 'Não' && changedValues.active == 'Sim' && slicesCount == 32)) {
            if (!((changedValues.count - originalValues.count) + slicesCount > 32 && changedValues.active == 'Sim')) {
                try {
                    let originalValuesCopy = { ...originalValues }
                    let changedValuesCopy = { ...changedValues }
                    let originalValuesProps = Object.getOwnPropertyNames(originalValues);

                    const propertyBackgroundColor = {
                        'Purple': 1,
                        'Orange': 2,
                        'Blue': 3,
                        'Peach': 4,
                        'Pink': 5,
                        'Green': 6,
                        'Lime': 7,
                        'Rainbow': 8,
                        'Gold': 9,
                        'Gold Stripe': 10,
                        'Yellow Stripe': 11,
                        'Purple Stars': 12,
                        'Mystery': 13,
                        'Booby': 14,
                    }

                    const propertyApi = {
                        name: 'desc',
                        active: 'active',
                        weighting: 'weighting',
                        count: 'count',
                        background: 'background',
                    }

                    let changes = {}

                    for (let i = 0; i < originalValuesProps.length; i++) {
                        let propName = originalValuesProps[i];
                        console.log(propName)
                        console.log(originalValues[propName])
                        console.log(changedValues[propName])
                        if (originalValues[propName] !== changedValues[propName]) {
                            changes[propertyApi[propName]] = changedValues[propName]
                        }
                    }

                    if (changes['background']) {
                        changes['background'] = propertyBackgroundColor[changes['background']]
                    }

                    if (changes['active']) {
                        changes['active'] == 'Sim' ? changes['active'] = true : changes['active'] = false
                    }


                    await saveSpinCategory(item.id, changes)

                    setChangedValues(changedValuesCopy)
                    setOriginalValues(changedValuesCopy)

                    fetchSelectedSpinCategoryDetails(dispatch, { id: item.id });
                    toast.success('Dados salvos com Sucesso!')
                }
                catch {
                    toast.error('Erro ao salvar!')
                }

            }

            else {
                toast.error('Erro ao salvar, número de Slices ultrapassando limite (32) !')
            }
        }
        else {
            toast.error('Erro ao salvar, lista no limite de Slices Ativos (32) !')
        }



    }

    //ABA PRÊMIOS

    const firstSpinPrizesData = useSpinPrizes(item);
    const [spinPrizesData, setSpinPrizesData] = useState(firstSpinPrizesData)
    const [goldInput, setGoldInput] = useState(0)
    const [isGoldModalOpen, setIsGoldModalOpen] = useState(false)
    const [tokenInput, setTokenInput] = useState(0)
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)
    const [itemInput, setItemInput] = useState({})
    const [isItemModalOpen, setIsItemModalOpen] = useState(false)
    const [checkSavePrize, setCheckSavePrize] = useState(false)
    const [itemQuantityInput, setItemQuantityInput] = useState(0)



    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const modelList = useSelector((state) => {
        return Array.isArray(state.modelList.data) ? state.modelList.data : []
    })

    useEffect(() => {
        if (modelList.length == 0) {
            fetchModelList(dispatch)
        }
    }, [item])

    useEffect(() => {
        let firstSpinPrizesDataCopy = [...firstSpinPrizesData]
        setSpinPrizesData(firstSpinPrizesDataCopy)

    }, [firstSpinPrizesData])

    const iconObject = { key: 'icon' }

    const categoryActions = [
        { action: 'delete', icon: null }
    ]

    const prizeHeaders = [
        { title: 'Tipo', key: 'type' },
        { title: 'Descrição', key: 'value', icon: () => iconObject },
        { title: 'Ações', key: 'acoes', actions: () => categoryActions }
    ]

    const handleOpenGoldModal = () => {
        setIsGoldModalOpen(true)
    }

    const handleCloseGoldModal = () => {
        setGoldInput(0);
        setCheckSavePrize(false)

        setIsGoldModalOpen(false);
    }

    const handleOnChangeGoldInput = (e) => {
        setGoldInput(e.target.value);
        if (e.target.value.trim() != '' && e.target.value != 0) {
            setCheckSavePrize(true)
        }
        else {
            setCheckSavePrize(false)
        }
    }

    const handleOpenTokenModal = () => {
        setIsTokenModalOpen(true)
    }

    const handleCloseTokenModal = () => {
        setTokenInput(0);
        setCheckSavePrize(false)

        setIsTokenModalOpen(false);
    }

    const handleOnChangeTokenInput = (e) => {
        setTokenInput(e.target.value);
        if (e.target.value.trim() != '' && e.target.value != 0) {
            setCheckSavePrize(true)
        }
        else {
            setCheckSavePrize(false)
        }
    }

    const handleOpenItemModal = () => {
        setIsItemModalOpen(true)
    }

    const handleCloseItemModal = () => {
        setItemInput({});
        setItemQuantityInput(0);
        setCheckSavePrize(false)

        setIsItemModalOpen(false);
    }

    const handleOnChangeItemInput = (option) => {
        setItemInput(option);
        if (String(itemQuantityInput).trim() != '' && itemQuantityInput != 0 && Object.keys(option).length != 0) {
            setCheckSavePrize(true)
        }
        else {
            setCheckSavePrize(false)
        }
    }

    const handleOnChangeItemQuantityInput = (e) => {
        setItemQuantityInput(e.target.value)
        if (e.target.value.trim() != '' && e.target.value != 0 && Object.keys(itemInput).length != 0) {
            setCheckSavePrize(true)
        }
        else {
            setCheckSavePrize(false)
        }
    }

    const handleSaveNewPrize = async (type) => {
        try {
            if (type == 'gold') {
                const apiInfo = {
                    idCategory: item.id,
                    gold: goldInput,
                    tokens: null,
                    modelId: null,
                    modelQuantity: null,
                }

                await saveSpinCategoryPrize(apiInfo);

                fetchSelectedSpinPrizes(dispatch, { id: item.id });

                handleCloseGoldModal();

                toast.success('Prêmio Adicionado com Sucesso!')
            }
            if (type == 'tokens') {
                const apiInfo = {
                    idCategory: item.id,
                    gold: null,
                    tokens: tokenInput,
                    modelId: null,
                    modelQuantity: null,
                }

                await saveSpinCategoryPrize(apiInfo);

                fetchSelectedSpinPrizes(dispatch, { id: item.id });

                handleCloseTokenModal();

                toast.success('Prêmio Adicionado com Sucesso!')
            }
            if (type == 'item') {
                const apiInfo = {
                    idCategory: item.id,
                    gold: null,
                    tokens: null,
                    modelId: itemInput.value,
                    modelQuantity: itemQuantityInput,
                }

                await saveSpinCategoryPrize(apiInfo);

                fetchSelectedSpinPrizes(dispatch, { id: item.id });

                handleCloseItemModal();

                toast.success('Prêmio Adicionado com Sucesso!')
            }
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Adicionar Prêmio!')
        }
    }

    //delete 

    const [currentSpinPrizeToDelete, setCurrentSpinPrizeToDelete] = useState({});
    const [isDeleteSpinPrize, setIsDeleteSpinPrize] = useState(false);

    const handleOpenDeleteSpinPrizeModal = (tableItem) => {
        setCurrentSpinPrizeToDelete(tableItem);
        setIsDeleteSpinPrize(true);
    }

    const handleCloseDeleteSpinPrizeModal = () => {
        setCurrentSpinPrizeToDelete({})
        setIsDeleteSpinPrize(false);
    }

    const handleDeleteSpinPrize = async () => {
        try {
            await deleteSpinPrize(currentSpinPrizeToDelete.id);
            handleCloseDeleteSpinPrizeModal()
            fetchSelectedSpinPrizes(dispatch, { id: item.id });
            toast.success("Prêmio Deletado com Sucesso");
        }
        catch {
            toast.error("Erro ao Deletar Prêmio");
        }
    }




    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onSelectRow={handleSelectRow}
                        headers={spinCategoriesHeaders}
                        items={spinCategoryData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {
                            <div className={style.modalContent}>
                                <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                {
                                    (modalTitle != 'Ativo') ?
                                        (modalTitle != 'Cor') ?
                                            (modalTitle != 'Peso' && modalTitle != 'Contador') ?
                                                <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                                : <input className={style.modalInput} min={0} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            :
                                            <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                                {spinBackgroundColors != [] && spinBackgroundColors.map((item, i) => {
                                                    return (
                                                        <option key={item.id} value={item.name}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        :
                                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                            <option key={'Sim'} value={'Sim'}>Sim</option>
                                            <option key={'Não'} value={'Não'}>Não</option>
                                        </select>
                                }

                                <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                            </div>




                        }
                    </Modal>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Prêmios',
            content: (
                <>
                    <BasicTable
                        round={false}
                        height='58.5vh'
                        onDeleteItem={handleOpenDeleteSpinPrizeModal}
                        headers={prizeHeaders}
                        items={spinPrizesData}
                        slotFooter={<div className="flex gap-1 justify-end w-full">
                            <Button text="Adicionar Moedas" onClick={handleOpenGoldModal} color="cyan" />
                            <Button text="Adicionar Fichas" onClick={handleOpenTokenModal} color="cyan" />
                            <Button text="Adicionar Item" onClick={handleOpenItemModal} color="cyan" />
                        </div>}
                    />
                    <Modal
                        header={<div><span>Atenção!</span></div>}
                        isOpen={isDeleteSpinPrize}
                        onClose={handleCloseDeleteSpinPrizeModal}
                    >
                        <div className={searchStyle.modalModLogContainer}>
                            <div className={searchStyle.modalSlotHeaderTitle}>
                                <span className={searchStyle.modalSubtitle}>Deseja excluir definitivamente o Prêmio?</span>
                            </div>
                            <div className={searchStyle.modalSlotHeaderBtn}>
                                <Button disabled={false} text="Sim" onClick={handleDeleteSpinPrize} color="cyan" />
                                <Button disabled={false} text="Não" onClick={handleCloseDeleteSpinPrizeModal} color="cyan" />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={isGoldModalOpen}
                        onClose={handleCloseGoldModal}
                        footer={<>
                            <Button disabled={!checkSavePrize} text="Salvar" onClick={() => handleSaveNewPrize('gold')} color="cyan" />
                        </>}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Quantidade de Moedas`}</span>
                            <input className={style.modalInput} min={0} type="number" placeholder={`Valor`} value={goldInput} onChange={handleOnChangeGoldInput} />
                        </div>

                    </Modal>
                    <Modal
                        isOpen={isTokenModalOpen}
                        onClose={handleCloseTokenModal}
                        footer={<>
                            <Button disabled={!checkSavePrize} text="Salvar" onClick={() => handleSaveNewPrize('tokens')} color="cyan" />
                        </>}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Quantidade de Fichas`}</span>
                            <input className={style.modalInput} min={0} type="number" placeholder={`Valor`} value={tokenInput} onChange={handleOnChangeTokenInput} />
                        </div>

                    </Modal>
                    <Modal
                        isOpen={isItemModalOpen}
                        onClose={handleCloseItemModal}
                        footer={<>
                            <Button disabled={!checkSavePrize} text="Salvar" onClick={() => handleSaveNewPrize('item')} color="cyan" />
                        </>}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Item`}</span>
                            <div className={catalogStyle.addSelect}>
                                <Select
                                    classNamePrefix="select"
                                    onChange={handleOnChangeItemInput}
                                    value={itemInput}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="categories"
                                    options={modelList}
                                    styles={customStyles}
                                    placeholder={"Adicionar Modelo"}
                                />

                                <div className={style.modalContent}>
                                    <span className={style.modalLabel}>{`Quantidade`}</span>
                                    <input className={style.modalInput} min={0} type="number" placeholder={`Valor`} value={itemQuantityInput} onChange={handleOnChangeItemQuantityInput} />
                                </div>
                            </div>
                        </div>

                    </Modal>






                </>
            )
        }
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm'>
                        <b>{item.name}</b>
                        <p>{item.id}</p>
                    </div>
                )}
            />
        </div>
    )
}