import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { twMerge } from 'tailwind-merge'

const InputField = ({ type, label, mask, maskChar = null, placeholder, value = '', onChange, className, name, errors }) => {
    let inputElement = null
    const classes = twMerge(`w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800 ${ className ?? '' } ${ errors ? 'border-red-500' : '' }`)

    const handleChange = (event) => {
        onChange(event)
    }

    useEffect(() => {
        setTimeout(() => {
            handleChange({ target: { name: name, value: value } })
        });
    }, [value])

    switch (type) {
        case 'text':
        case 'textarea':
        case 'password':
        case 'date':
        case 'email':
        case 'number':
        case 'url':
        case 'hidden':
        case 'month':
        case 'tel':
        case 'time':
            inputElement = (
                <InputMask
                    type={ type }
                    name={ name }
                    mask={ mask }
                    maskChar={ maskChar }
                    placeholder={ placeholder }
                    defaultValue={ value }
                    onChange={ handleChange }
                    className={ classes }
                />
            )
            break
        default:
            inputElement = (
                <InputMask
                    type={ type }
                    name={ name }
                    mask={ mask }
                    placeholder={ placeholder }
                    defaultValue={ value }
                    onChange={ handleChange }
                    className={ classes }
                />
            )
    }

    return (
        <div className='py-2'>
            { label && <label className='block text-sm font-medium leading-6 text-gray-900 text-truncate overflow-hidden'>{ label }</label> }
            { inputElement }
        </div>
    )
}

export default InputField
