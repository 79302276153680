import React, { useState } from 'react'
import Button from 'components/form/Button'
import Modal from 'components/Modal'

export default function ConvertXML () {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Modal
                isOpen={ isModalOpen }
                onClose={ handleCloseModal }
                header={ <h3>Converter Configurações XML</h3> }
                footer={ <Button text="Fechar" onClick={ handleCloseModal } color="cyan" /> }
            >
                <p>Conteúdo do Modal</p>
            </Modal>
            <button onClick={ handleOpenModal } className='block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200 w-full text-left'>Converter XML</button>
        </>
    )
}
