class Item {
    constructor ({id, firstName, lastName, modelName, forSale, spaceId, userId, spaceName, modelIcon, goldPrice, tokenPrice, modelId}) {
        this.id = id
        this.firstName = firstName
        this.lastName = lastName
        this.modelName = modelName
        this.forSale = forSale
        this.spaceId = spaceId
        this.userId = userId
        this.spaceName = spaceName
        this.modelIcon = modelIcon
        this.goldPrice = goldPrice
        this.tokenPrice = tokenPrice
        this.modelId = modelId
    }

    get() {
        return {
            id: this.id,
            smallItemId: this.smallID(this.id),
            modelName: this.modelName,
            firstName: this.firstName,
            lastName: this.lastName,
            owner: this.fullName,
            spaceId: this.spaceId,
            spaceName: this.spaceName,
            forSale: this.forSale,
            userId: this.userId,
            smallUserId: this.smallID(this.userId),
            modelIcon: this.modelIcon,
            goldPrice: this.goldPrice,
            tokenPrice: this.tokenPrice,
            modelId: this.modelId,
        }
    }

    smallID(id) {
        return id?.slice(0, 7)
    }

    get fullName(){
        return `${this.firstName} ${this.lastName}`
    }

}

export default Item