import React, { useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import Details from './Partials/Details'
import Modal from 'components/Modal'

import { users } from 'data/users'

export default function Filters () {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-7'>
                    <BasicTable
                        title='Filtros de comunicação'
                        headers={ users.headers }
                        items={ users.items }
                        slotActions={ (
                            <>
                                <Button text="Atualizar" color="cyan" />
                            </>
                        ) }
                        slotFooter={ (
                            <>
                                <Button text="Novo Filtro" onClick={ handleOpenModal } color="cyan" />
                            </>
                        ) }
                    />
                </div>
                <div className='col-span-5'>
                    <Details />
                </div>
            </div>

            <Modal
                isOpen={ isModalOpen }
                onClose={ handleCloseModal }
                header={ <h3>Novo Filtro</h3> }
                footer={ <Button text="Fechar" onClick={ handleCloseModal } color="cyan" /> }
            >
                <p>Conteúdo do Modal</p>
            </Modal>
        </>
    )
}
