import React, { useRef } from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { required, minLength, isEmail } from 'helpers/validation'
import { useDispatch } from 'react-redux'
import { fetchUsersFilters } from 'store/modules/users/userActions'

const FormByIp = ({ onFormChange, defaultValues }) => {
    const formRef = useRef()

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFormChange(formData, isValid)
        
        let newValue= {};
        newValue[name] = value;

        console.log(newValue)

        fetchUsersFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'ip',
            label: 'Endereço IP',
            value: '',
            placeholder: 'Endereço IP',
            type: 'text',
            value: defaultValues.ip,
            validate: [
                { rule: minLength, val: 3 }
            ],
            gridClass: 'col-span-4'
        },
        {
            name: 'ipOnline',
            checked: true,
            label: 'Apenas Online',
            type: 'checkbox',
            value: defaultValues.ipOnline,
            gridClass: 'col-span-4'
        },
    ]

    return (
        <>
            <DefaultForm ref={ formRef } fields={ fields } onChange={ handleSubmit } />
        </>
    )
}

export default FormByIp

