import { isAuthenticated } from 'services/auth'
import axios from 'axios'
import apiNormalize from 'helpers/apiNormalize'
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API
})

api.interceptors.request.use(
    (config) => {
        const token = isAuthenticated()

        if (token) {
            config.headers['swsid'] = `${ token }`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => {
        //toast.success('Requisição bem sucedida!')
        return response
    },
    (error) => {
        //toast.error('Ocorreu um erro na requisição!')
        return Promise.reject(error)
    }
);

export { apiNormalize }

export default api
