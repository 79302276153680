import style from '../../../styles/Message.module.css'
import { Paths } from 'paths/Paths'
import {useRef, useEffect} from 'react'
import Info from '../../../img/info.png'

const ChatMessage = ({avatarIcon, avatarFirstName, message}) => {
    const canvasRef = useRef();

    useEffect(() => {
        if(canvasRef.current && avatarIcon) {
            canvasRef.current.width = 87;
            canvasRef.current.height = 97;

            const ctx = canvasRef.current.getContext("2d");

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            const img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 87 * 3, 97 * 1, 87, 97, 0, 0, 87, 97);
            }
            img.crossOrigin = "anonymous";
            img.src = avatarIcon;
        }
    }, [avatarIcon, canvasRef])

    return(
        <div className={style.generalContainer}>
            <canvas ref={canvasRef} className={style.avatarIcon} />
            <div className={style.textContainer}>
                <div className={style.avatarNameContainer}>
                    <span className={style.avatarName}>{avatarFirstName}</span>
                </div>
                <div className={style.avatarMessageContainer}>
                    <span className={style.avatarMessage}>{message}</span>
                </div>
            </div>
        </div>
    )
}

const AvatarInteraction = ({avatarFirstName, message}) => {
    return(
        <div className={style.generalContainer}>
            <div className={style.avatarIcon}>
                <img className={style.infoIcon} src={Info}/>
            </div>
            <div className={style.textContainerInteraction}>
                <span className={style.avatarName}>{avatarFirstName}</span>
                <div className={style.avatarMessageContainer}>
                    <span className={style.avatarMessage}>{message}</span>
                </div>
            </div>
        </div>
    )
}

const EmoteMessage = ({avatarIcon, avatarFirstName, message}) => {
    const canvasRef = useRef();

    useEffect(() => {
        if(canvasRef.current && avatarIcon) {
            canvasRef.current.width = 87;
            canvasRef.current.height = 97;

            const ctx = canvasRef.current.getContext("2d");

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            const img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 87 * 3, 97 * 1, 87, 97, 0, 0, 87, 97);
            }
            img.crossOrigin = "anonymous";
            img.src = avatarIcon;
        }
    }, [avatarIcon, canvasRef])

    return(
        <div className={style.generalContainer}>
            <canvas ref={canvasRef} className={style.avatarIcon} />
            <div className={style.textContainer}>
                <div className={style.avatarNameContainer}>
                    <span className={style.avatarName}>{avatarFirstName}</span>
                </div>
                <div className={style.avatarMessageContainer}>
                    <img height={24} style={{display: "flex", height: "24px"}} src={Paths.libraryPath + message} />
                </div>
            </div>
        </div>
    )
}

export {ChatMessage, AvatarInteraction, EmoteMessage}