import moment from "moment"

class AllInfraction {
    constructor({ userInfractionId, date, userId, userFirstName, userLastName, offenceName, offencePoints, chainName, punishmentName, modId, modFirstName, modLastName, active, abuseReportId, proofList }) {
        this.userInfractionId = userInfractionId
        this.date = date
        this.userId = userId
        this.userFirstName = userFirstName
        this.userLastName = userLastName
        this.offenceName = offenceName
        this.offencePoints = offencePoints
        this.chainName = chainName
        this.punishmentName = punishmentName
        this.modId = modId
        this.modFirstName = modFirstName
        this.modLastName = modLastName
        this.active = active
        this.abuseReportId = abuseReportId
        this.proofList = proofList
    }

    get() {
        return {
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm'),
            userInfractionId: this.userInfractionId,
            userName: this.fullName(this.userFirstName, this.userLastName),
            userId: this.userId,
            offence: `${this.offenceName} (${this.offencePoints} pontos)`,
            punishment: `${this.chainName} > ${this.punishmentName}`,
            modName: this.fullName(this.modFirstName, this.modLastName),
            modId: this.modId,
            active: this.active,
            abuseReportId: this.abuseReportId,
            proofList: this.proofList,
            isProofList: this.isProofList,
        }
    }

    get isProofList() {
        if (this.proofList) {
            if (this.proofList.length == 0 || this.proofList == null) {
                return 'Não'
            }
            else {
                return 'Sim'
            }
        }
        else {
            return 'Não'
        }
    }

    fullName(userFirstName, userLastName) {
        if (userFirstName === null || userLastName === null) return "Usuário Apagado"
        else return `${userFirstName} ${userLastName}`
    }
}

export default AllInfraction