import { getItems } from "services/itemService";
const { createSlice } = require("@reduxjs/toolkit");

const itemsSlice = createSlice({
    name:"items",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            idUser: '',
            idSpace: '',
            idItem: '',
            idModel: '',
            state: '',
            forSale: '',
        }
    },
    reducers: {
        setItemsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addItemsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setItemsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchItems(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const items = await getItems(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addItemsData(items))
        }
        else{
            dispatch(setItemsData(items));
        }
        
        if(items.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Items', error)
        dispatch(setCurrentSkip(0));
        dispatch(setItemsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchItemsFilters(dispatch, params){
    dispatch(setItemsFilter(params));
}

export const { setItemsData, setCurrentSkip, setHasMore, addItemsData, setItemsFilter } = itemsSlice.actions
export default itemsSlice.reducer