import { createSlice } from '@reduxjs/toolkit'
import { getItem } from 'services/itemService';

const selectedItemSlice = createSlice({
    name: 'selectedItem',
    initialState: {},
    reducers: {
        setSelectedItem: (state, action) => {
            let mission;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                mission = action.payload[0]
            } else {
                mission = {}
            }            

            return mission
        },
    },
})

export const selectItemForDetails = (params) => async (dispatch) => {
    try {
        const selectedItem = await getItem(params)
        dispatch(setSelectedItem(selectedItem))
    } catch (error) {
        console.error('Erro ao carregar Item:', error)
    }
}

export const { setSelectedItem } = selectedItemSlice.actions
export default selectedItemSlice.reducer
