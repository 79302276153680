import CopyToClipboard from 'components/CopyToClipboard'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, XCircleIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid'
import Button from 'components/form/Button'

export default function TableCell({ cell, item, handleSelectRow, handleDetailRow, cellWidth, chain }) {
    const DetailsButton = ({ cell, item }) => (
        <>
            {cell.detailable && (
                <Button onClick={() => handleDetailRow(item)} onlyIcon color="cyan" icon={<MagnifyingGlassIcon className='h-3 w-3' />} />
            )}
        </>
    );

    const ClickableText = ({ cell, item }) => (
        <>
            {item[cell.key] !== null && (
                cell.selectable ? (
                    <span className='cursor-pointer' onClick={() => handleSelectRow(item, cell)} title={item[cell.key]}>
                        {item[cell.key]}
                    </span>
                ) : item[cell.key]
            )}
        </>
    );

    const CellIcon = ({ cell, item }) => (
        <>
            {
                cell.icon && (
                    <>
                        {
                            cell.icon && typeof cell.icon === 'function' && cell.icon().key ? (
                                item[cell.icon().key] != null ?
                                    <>
                                        <img src={item[cell.icon().key]} alt='icon' className='w-4 h-4 mr-2' />
                                    </> : null
                            ) :
                                (
                                    <>
                                        {cell.icon}
                                    </>
                                )
                        }
                    </>
                )
            }
        </>
    );

    const CopyToClipboardButton = ({ cell, item }) => (
        <>
            {cell.copy && item[cell.copy] && <CopyToClipboard text={item[cell.copy]} />}
        </>
    );

    const StatusButton = ({ cell, item }) => {
        const activeStatus = typeof cell.status?.activeStatus === 'undefined' ? true : cell.status?.activeStatus
        const inactiveStatus = typeof cell.status?.activeStatus === 'undefined' ? false : cell.status?.inactiveStatus

        return (
            <>
                {cell.status && (
                    <>
                        {item[cell.key] === activeStatus && <CheckCircleIcon className='h-5 w-5 text-green-500' />}
                        {item[cell.key] === inactiveStatus && <XCircleIcon className='h-5 w-5 text-rose-500' />}
                    </>
                )}
            </>
        )
    };

    const CellActions = ({ cell, item }) => {
        return (
            <>
                {
                    cell.actions && cell.actions().map((action) =>
                        <>
                            {
                                action.action === 'edit' && (
                                    <>
                                        <Button
                                            onClick={() => console.log('edit')}
                                            color="cyan"
                                            onlyIcon
                                            icon={<PencilIcon className='h-3 w-3 text-cyan-600' />}
                                        />
                                    </>
                                )
                            }
                            {
                                action.action === 'delete' && (
                                    <>
                                        <Button
                                            onClick={() => console.log('delete')}
                                            color="cyan"
                                            onlyIcon
                                            icon={<TrashIcon className='h-3 w-3 text-rose-600' />}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }
            </>
        )
    };

    return (
        <td key={chain} className={`overflow-hidden overflow-ellipsis whitespace-wrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`} style={{ wordBreak: 'break-word' }}>
            <CellIcon cell={cell} item={item} />
            <ClickableText cell={cell} item={item} />
            <CellActions cell={cell} item={item} />
            <StatusButton cell={cell} item={item} />
            <CopyToClipboardButton cell={cell} item={item} />
            <DetailsButton cell={cell} item={item} />
        </td>
    );
}
