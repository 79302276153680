import Tabs from 'components/Tabs'
import React, { useState } from 'react'
import { IdentificationIcon, UserCircleIcon, HashtagIcon } from '@heroicons/react/20/solid'
import FormByAvatar from './formByAvatar'
import FormByUser from './formByUser'
import FormByUserNew from './formByUserNew'
import FormByIP from './formByIP'
import { useSelector } from 'react-redux'

export default function Filters ({ onFilterChange, onChangeTab, tab }) {
    const [formData, setFormData] = useState({})

    const handleFormByAvatarChange = (form, isValid) => {
        console.log(isValid)
        setFormData(form)
        onFilterChange({ filter: 'byAvatar', form: form, isValid: isValid })
    }

    const handleFormByUserChange = (form, isValid) => {
        console.log(isValid);
        setFormData(form)
        onFilterChange({ filter: 'byUser', form: form, isValid: isValid })
    }

    const handleFormByIpChange = (form, isValid) => {
        console.log(isValid);
        setFormData(form)
        onFilterChange({ filter: 'byIp', form: form, isValid: isValid })
    }

    const handleChangeTab = () => {
        onChangeTab()
    }

    const filters = useSelector((state) => {
        return Object(state.users.filters) ? state.users.filters : {}
    })

    const tabs = [
        {
            id: 1,
            tabTitle: 'Por Avatar',
            content: (
                <div>
                    <FormByAvatar onInputChange={ handleFormByAvatarChange } onFormChange={ handleFormByAvatarChange } defaultValues={filters}/>
                </div>
            ),
            icon: UserCircleIcon
        },
        {
            id: 2,
            tabTitle: 'Por Usuário',
            content: (
                <FormByUserNew onInputChange={ handleFormByUserChange } onFormChange={ handleFormByUserChange } defaultValues={filters}/>
            ),
            icon: IdentificationIcon
        },
        {
            id: 3,
            tabTitle: 'Por IP',
            content: (
                <div>
                    <FormByIP onInputChange={ handleFormByIpChange } onFormChange={ handleFormByIpChange } defaultValues={filters}/>
                </div>
            ),
            icon: HashtagIcon
        },
    ]

    return (
        <Tabs tabs={ tabs } onChangeTab={ handleChangeTab } tab={tab}/>
    )
}
