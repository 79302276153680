import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { fetchSpacesFilters } from "store/modules/spaces/spacesSlice"

export default function SpacesFilter({onFilterChange, defaultValues}){

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;
        fetchSpacesFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'idSpace',
            label: 'ID do Espaço',
            value: defaultValues.idSpace,
            placeholder: 'ID do Espaço',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'nameSpace',
            label: 'Nome do Espaço',
            value: defaultValues.nameSpace,
            placeholder: 'Nome do Espaço',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'idAvatar',
            label: 'ID do Avatar',
            value: defaultValues.idAvatar,
            placeholder: 'ID do Avatar',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'idModel',
            label: 'ID do Model',
            value: defaultValues.idModel,
            placeholder: 'ID do Model',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        }, 
    ]

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}