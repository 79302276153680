import api from "./api"

export async function getNewUserStats(days){
    const response = await api.get("/stats/newUsers", {params:{days}});
    return response.data;
}
export async function getNewInfractionStats(days){
    const response = await api.get("/stats/newInfractions", {params:{days}});
    return response.data;
}
export async function getNewAbuseReportStats(days){
    const response = await api.get("/stats/newAbuseReports", {params:{days}});
    return response.data;
}
export async function getNewTransactionStats(days){
    const response = await api.get("/stats/newTransactions", {params:{days}});
    return response.data;
}