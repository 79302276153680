import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import styles from '../../../styles/Search.module.css'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import IpsFilter from './IpsFilter'
import { fetchIps } from 'store/modules/users/ips/ipsSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Ips({ idUser, ip }) {
    const verifyIpList = useUserPermission(SMIPermissionsCode.MOD_USER_IP_VIEW);

    const [ipsHeaders, setIpsHeaders] = useState([
        { title: 'Data do Registro', key: 'date', appear: true },
        { title: 'Usuário', key: 'userName', copy: 'userId', appear: true },
        { title: 'IP de Endereço', key: 'ip', copy: 'ip', appear: true }
    ])

    const [ipsMomentHeaders, setIpsMomentHeaders] = useState([...ipsHeaders]);

    const handleAppearHeader = (item) => {
        setIpsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setIpsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const ips = useSelector((state) => {
        return Array.isArray(state.ips.data) ? state.ips.data : []
    })

    const skip = useSelector((state) => {
        return (state.ips.currentSkip) ? state.ips.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.ips.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.ips.filters) ? state.ips.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [ips]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (idUser || ip) {
            setLoading(true)
            fetchIps(dispatch, { idUser, ip }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.idUser) query.append('idUser', filter.form.idUser);
        if (filter.form.ip) query.append('ip', filter.form.ip);
        window.history.pushState({}, '', `/users/ips?${query.toString()}`);
        fetchIps(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchIps(dispatch, filter.form, skip + 200);
    })

    return (
        <>
            {verifyIpList && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <IpsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={ipsHeaders}
                        headers={ipsMomentHeaders}
                        title='Buscar IPs'
                        isLoading={isLoading && ips.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={ips}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(ips.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : ips.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
            </div>}
        </>
    )
}
