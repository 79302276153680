import React, { useEffect, useState } from 'react'
import Button from 'components/form/Button'
import Modal from 'components/Modal'
import style from '../../../../styles/Proof.module.css'
import styles from '../../../../styles/UserTabs.module.css'
import { uuidMask } from 'helpers/masks'
import { getInfractionOptions, getUserInfractionPoints, getUserInfractionPointsByUserId, getUserStep, getUserStepByUserId, saveUserInfractionByUserId } from 'services/infractionService'
import { toast } from 'react-toastify'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'

export default function RegisterInfraction() {
    const verifyInfract = useUserPermission(SMIPermissionsCode.MOD_GLOBALLY_INFRACT_USERS);

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setUserIdInputValue("")
        setUserPoints(0)
        setNewPoints(0)
        setPunishment("")
        setFoundUser(false)
        setIsModalOpen(false)
    }

    const [infractionOptions, setInfractionOptions] = useState([])
    const [inputValue, setInputValue] = useState("")
    const [userIdInputValue, setUserIdInputValue] = useState("")
    const [userPoints, setUserPoints] = useState(0)
    const [newPoints, setNewPoints] = useState(0)
    const [punishment, setPunishment] = useState("")
    const [foundUser, setFoundUser] = useState(false)

    useEffect(() => {
        handleInfractionOptions()
    }, [])

    const handleInfractionOptions = async () => {
        let apiInfractionOptions = await getInfractionOptions()
        setInputValue(JSON.stringify({ typeId: apiInfractionOptions[0].typeId, points: apiInfractionOptions[0].points }))
        setInfractionOptions(apiInfractionOptions)
    }

    const handleOnChangeInput = async (event) => {
        const selectedValue = JSON.parse(event.target.value);
        const typeId = selectedValue.typeId;
        const points = selectedValue.points;

        handleSetStep(typeId)

        setInputValue(event.target.value)

        setNewPoints(userPoints + points)
    }

    const handleSetStep = async (typeId) => {
        if (foundUser) {
            const tempStep = await getUserStepByUserId(typeId, userIdInputValue)
            setPunishment(tempStep.step.name)
        }
    }

    const handleOnChangeUserIdInput = async (event) => {
        setUserIdInputValue(event.target.value)
    }

    const handleSearchUser = async () => {
        try {
            let userInfractionPoints = await getUserInfractionPointsByUserId(userIdInputValue)

            const selectedValue = JSON.parse(inputValue);
            const typeId = selectedValue.typeId;
            const points = selectedValue.points;
            const tempStep = await getUserStepByUserId(typeId, userIdInputValue)
            setPunishment(tempStep.step.name)
            setUserPoints(userInfractionPoints.currentPoints)
            setNewPoints(userInfractionPoints.currentPoints + points)

            setFoundUser(true)
            toast.success("Usuário Encontrado com Sucesso!")
        }
        catch (err) {
            console.log(err)
            setFoundUser(false)
            toast.error("Erro ao Encontrar Usuário!")
        }
    }

    const handleSaveUserInfraction = async () => {
        try {
            const selectedValue = JSON.parse(inputValue);
            await saveUserInfractionByUserId(selectedValue.typeId, userIdInputValue);

            handleCloseModal()
            toast.success('Infração Aplicada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Aplicar Infração!')
        }
    }

    return (
        <>
            {verifyInfract && <div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                header={<h3>Registrar Infração</h3>}
                footer={
                    <div className={style.userInfractionModalControler}>
                        <Button disabled={!foundUser} text="Salvar" onClick={handleSaveUserInfraction} color="cyan" />
                        <Button text="Fechar" onClick={handleCloseModal} color="cyan" />
                    </div>}
            >
                <div className={style.infractionModalContainer}>
                    <div className={style.infractionModalContainerUser}>
                        <input value={userIdInputValue} className={styles.modalInput} onChange={handleOnChangeUserIdInput} placeholder='ID do Usuário' />
                        <Button text="Buscar" onClick={handleSearchUser} color="cyan" />
                    </div>
                    <select value={inputValue} className={styles.select} onChange={handleOnChangeInput}>
                        {infractionOptions && infractionOptions.length > 0 ? (
                            infractionOptions.map((item, index) => (
                                <option key={item.typeId} value={JSON.stringify({ typeId: item.typeId, points: item.points })}>
                                    {`${item.desc} (${item.points} pontos)`}
                                </option>
                            ))
                        ) : (
                            <option disabled>Sem opções</option>
                        )}
                    </select>
                    {foundUser &&
                        <div className={style.pointsInfo}>
                            <span>Pontos Atuais: {userPoints}</span>
                            <span>Nova Pontuação: {newPoints}</span>
                            <span>Punição: {punishment}</span>
                        </div>
                    }
                </div>
            </Modal>
            <button onClick={handleOpenModal} className='block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200 w-full text-left'>Registrar Infração</button>
            </div>}
        </>
    )
}
