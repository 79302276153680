import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Modal from 'components/Modal'
import Button from 'components/form/Button'
import Select from 'components/form/Select'
import interstitialStyle from '../../../styles/Interstitial.module.css'
import dndStyle from '../../../styles/DnDStyle.module.css'
import styleUserTabs from '../../../styles/UserTabs.module.css'
import searchStyle from '../../../styles/Search.module.css'

// dados
import { infractions, staff } from 'data/dashboard'
import { categories, items } from 'data/items'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserGroups } from 'store/modules/administration/userGroups/userGroupsSlice'
import { createUserGroup, deleteUserGroup } from 'services/userGroupsService'
import { toast } from 'react-toastify'
import handleRequestError from 'helpers/apiErrorHandler'
import GroupDetails from './Partials/GroupDetails'
import { selectedUserGroupForDetails } from 'store/modules/administration/userGroups/selectedUserGroupSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

const sGroupHeaders = [
    { title: 'Grupos de Sistema (ID)', key: 'name', detailable: true },
]
const bGroupHeaders = [
    { title: 'Grupos de Ban (ID)', key: 'name', detailable: true },
]
const fGroupHeaders = [
    { title: 'Grupos de Fraude (ID)', key: 'name', detailable: true },
]

const categoryActions = [
    { action: 'delete', icon: null }
]

const cGroupHeaders = [
    { title: 'Grupos Customizados (ID)', key: 'name', detailable: true },
    { title: 'Ações', key: 'acoes', actions: () => categoryActions }
]

export default function UserGroups() {
    const verifyGroup = useUserPermission(SMIPermissionsCode.MOD_GROUP_MANAGE);

    const sGroup = useSelector((state) => {
        return Array.isArray(state.userGroups.s) ? state.userGroups.s : []
    })
    const bGroup = useSelector((state) => {
        return Array.isArray(state.userGroups.b) ? state.userGroups.b : []
    })
    const fGroup = useSelector((state) => {
        return Array.isArray(state.userGroups.f) ? state.userGroups.f : []
    })
    const cGroup = useSelector((state) => {
        return Array.isArray(state.userGroups.c) ? state.userGroups.c : []
    })

    const selectedUserGroup = useSelector((state) => {
        return state.selectedUserGroup
    })

    const dispatch = useDispatch()

    const atualizar = () => {
        fetchUserGroups(dispatch);
    }

    useEffect(() => {
        atualizar();
    }, [])

    //Delete Group

    const [currentGroupToDelete, setCurrentGroupToDelete] = useState({});
    const [isDeleteGroup, setIsDeleteGroup] = useState(false);

    const handleOpenDeleteGroupModal = (tableItem) => {
        setCurrentGroupToDelete(tableItem);
        setIsDeleteGroup(true);
    }

    const handleCloseDeleteGroupModal = () => {
        setCurrentGroupToDelete({})
        setIsDeleteGroup(false);
    }

    const handleDeleteGroup = async () => {
        try {
            await deleteUserGroup(currentGroupToDelete.id);
            handleCloseDeleteGroupModal()
            fetchUserGroups(dispatch);
            toast.success("Grupo Deletado com Sucesso");
        }
        catch (err) {
            console.log(err)
            toast.error("Erro ao Deletar Grupo");
        }
    }

    //Create Group

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [descInput, setDescInput] = useState('');
    const [idInput, setIdInput] = useState(0);

    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true)
    }

    const handleCloseCreateModal = () => {
        setNameInput('')
        setDescInput('')
        setIdInput(0)

        setIsCreateModalOpen(false)
    }

    useEffect(() => {
        if ((nameInput.trim() == '') || (String(idInput).trim() == '')) {
            setCheck(false);
        }
        else {
            setCheck(true)
        }
    }, [nameInput, descInput, idInput])

    const handleSave = async () => {
        try {

            const name = nameInput;
            const description = descInput;
            const id = idInput;


            await createUserGroup({ id, name, description });
            atualizar()
            handleCloseCreateModal()
            toast.success('Dados Salvos com Sucesso!')

        }
        catch (err) {
            if (err.response?.status === 400) {
                return toast.error("ID já existente");
            }
            else {
                toast.error('Erro ao Salvar Dados!')
            }
            console.log(err)
        }
    }

    // selected group

    const handleSelectedUserGroup = (tableItem) => {
        dispatch(selectedUserGroupForDetails({ id: tableItem.id }))
    }

    return (
        <>
            {verifyGroup &&<div className='grid grid-cols-12 gap-4'>
                <div className='col-span-5 pb-5'>
                    <BasicTable
                        title='Grupos de usuários'
                        onDetailRow={handleSelectedUserGroup}
                        headers={sGroupHeaders}
                        items={sGroup}
                        height='100%'
                        round={false}
                        slotActions={<Button text="Atualizar" onClick={atualizar} color="cyan" />}
                    />
                    <BasicTable
                        headers={bGroupHeaders}
                        onDetailRow={handleSelectedUserGroup}
                        items={bGroup}
                        height='100%'
                        round={false}
                    />
                    <BasicTable
                        headers={fGroupHeaders}
                        items={fGroup}
                        onDetailRow={handleSelectedUserGroup}
                        height='100%'
                        round={false}
                    />
                    <BasicTable
                        headers={cGroupHeaders}
                        onDeleteItem={handleOpenDeleteGroupModal}
                        onDetailRow={handleSelectedUserGroup}
                        items={cGroup}
                        height='100%'
                        round={false}
                        slotFooter={
                            <Button text="Novo grupo" onClick={handleOpenCreateModal} color="cyan" />
                        }
                    />
                    <Modal
                        header={<div><span>Atenção!</span></div>}
                        isOpen={isDeleteGroup}
                        onClose={handleCloseDeleteGroupModal}
                    >
                        <div className={searchStyle.modalModLogContainer}>
                            <div className={searchStyle.modalSlotHeaderTitle}>
                                <span className={searchStyle.modalSubtitle}>Deseja excluir definitivamente o Grupo?</span>
                            </div>
                            <div className={searchStyle.modalSlotHeaderBtn}>
                                <Button disabled={false} text="Sim" onClick={handleDeleteGroup} color="cyan" />
                                <Button disabled={false} text="Não" onClick={handleCloseDeleteGroupModal} color="cyan" />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={isCreateModalOpen}
                        onClose={handleCloseCreateModal}
                        footer={
                            <div className={dndStyle.modalFooter}>
                                <Button disabled={!check} text="Salvar" onClick={handleSave} color="cyan" />
                            </div>
                        }
                        header={<div><span>Criar Grupo de Usuário</span></div>}
                    >
                        <div className={interstitialStyle.createModalContainer}>

                            <div className={interstitialStyle.createGroupNameContainer}>



                                <div className={interstitialStyle.createGroupInfoContainer}>
                                    <div style={{width: '70%'}}  className={interstitialStyle.createGroupNameContainer}>
                                        <span style={{ fontWeight: 'bold' }}>Nome:</span>
                                        <input value={nameInput} className={styleUserTabs.modalInput} onChange={(e) => setNameInput(e.target.value)} placeholder='Nome' />
                                    </div>
                                    <div style={{width: '30%'}}  className={interstitialStyle.createGroupNameContainer}>
                                        <span style={{ fontWeight: 'bold' }}>ID:</span>
                                        <input type='number' style={{width: '100%'}} className={interstitialStyle.modalInput} value={idInput} onChange={(e) => setIdInput(e.target.value)} min="0" />
                                    </div>
                                </div>


                            </div>



                            <div className={interstitialStyle.createGroupNameContainer}>
                                <span style={{ fontWeight: 'bold' }}>Descrição:</span>
                                <input value={descInput} className={styleUserTabs.modalInput} onChange={(e) => setDescInput(e.target.value)} placeholder='Descrição' />
                            </div>

                        </div>
                    </Modal>
                </div>
                <div className='col-span-7'>
                    {selectedUserGroup && <GroupDetails item={selectedUserGroup}/>}
                </div>
            </div>}

            <Modal isOpen={false} />


        </>
    )
}
