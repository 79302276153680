import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux'
import { fetchAvatarsFilters } from 'store/modules/users/avatars/avatarsSlice'

const FromSearch = ({ onFilterChange, defaultValues }) => {
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        fetchAvatarsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'userId',
            label: 'ID do Usuário',
            placeholder: 'ID do Usuário',
            type: 'text',
            value: defaultValues.userId,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarId',
            label: 'ID do Avatar',
            placeholder: 'ID do Avatar',
            type: 'text',
            value: defaultValues.avatarId,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarOnline',
            label: 'Apenas Online',
            type: 'checkbox',
            value: defaultValues.avatarOnline,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarFirstName',
            label: 'Nome do Avatar',
            placeholder: 'Nome do Avatar',
            type: 'text',
            value: defaultValues.avatarFirstName,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarLastName',
            label: 'Sobrenome do Avatar',
            placeholder: 'Sobrenome do Avatar',
            type: 'text',
            value: defaultValues.avatarLastName,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarNameInstance',
            label: 'Instância do Avatar',
            placeholder: 'Instância do Avatar',
            type: 'text',
            value: defaultValues.avatarNameInstance,
            validate: [],
            gridClass: 'col-span-4'
        },
    ]

    return (
        <>
            <DefaultForm fields={ fields } onChange={ handleSubmit } />
        </>
    )
}

export default FromSearch

