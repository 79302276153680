const staffMapping = item => ({
    id: item.avatarId,
    group: getGroupName(item),
    user: item.avatarName ?? null,
    avatar: getAvatarFullName(item) ?? null,
    space: item.location.space_name ?? null,
})

const getId = (id) => {
    return typeof id === 'object' ? id.oid : id
}

const getAvatarFullName = (item) => {
    return `${ item.avatarName } ${ item.avatarLastName}`
}

const getGroupName = (item) => {
    return item.group?.name
}

export { staffMapping }
