import { getAbuseReports } from "services/abuseReportService";

const { createSlice } = require("@reduxjs/toolkit");

const abuseReportsSlice = createSlice({
    name:"abuseReports",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            type: '',
            reportType: '',
            categoryId: '',
            status: '',
            reporterId: '',
            reportedId: '',
            assignedTo: '',
            dateFrom: '',
            dateTo: '',
        }
    },
    reducers: {
        setAbuseReportsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addAbuseReportsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setAbuseReportsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchAbuseReports(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const items = await getAbuseReports(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addAbuseReportsData(items))
        }
        else{
            dispatch(setAbuseReportsData(items));
        }
        
        if(items.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter AbuseReports', error)
        dispatch(setCurrentSkip(0));
        dispatch(setAbuseReportsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchAbuseReportsFilters(dispatch, params){
    dispatch(setAbuseReportsFilter(params));

    console.log(params)
}

export const { setAbuseReportsData, setCurrentSkip, setHasMore, addAbuseReportsData, setAbuseReportsFilter } = abuseReportsSlice.actions
export default abuseReportsSlice.reducer