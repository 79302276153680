import Tabs from 'components/Tabs'
import React from 'react'
import { IdentificationIcon, UserCircleIcon, HashtagIcon } from '@heroicons/react/20/solid'
import ShiftLead from './Partials/ShiftLead'
import Escalation from './Partials/Escalation'
import Content from './Partials/Content'
import Card from 'components/Card'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Home() {

    const verifyShiftLead = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_SHIFTLEAD);
    const verifyEscalation = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_ESCALATION);
    const verifyContent = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_CONTENT);
    const tabs = [];

    if (verifyShiftLead) {
        tabs.push({
            id: 1,
            tabTitle: 'Líder de turno',
            content: (
                <div className='py-4 px-2'>
                    {verifyShiftLead && <ShiftLead />}
                </div>
            ),
            icon: UserCircleIcon
        })
    }
    if (verifyEscalation) {
        tabs.push({
            id: 2,
            tabTitle: 'Escalação',
            content: (
                <div className='py-4 px-2'>
                    <Escalation />
                </div>
            ),
            icon: IdentificationIcon,
            disabled: true
        })
    }
    if (verifyContent) {
        tabs.push({
            id: 3,
            tabTitle: 'Conteúdo',
            content: (
                <div className='py-4 px-2'>
                    <Content />
                </div>
            ),
            icon: HashtagIcon,
            disabled: true
        })
    }

    return (
        <>
            <Card>
                <Tabs tabs={tabs} />
            </Card>
        </>
    )
}
