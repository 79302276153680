import Missions from "Pages/Missions";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchMissionsFilters } from "store/modules/missions/missionsSlice";

export function MissionsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const missionId = searchParams.get("missionId");
    const missionName = searchParams.get("missionName");
    const avatarId = searchParams.get("avatarId");
    const spaceId = searchParams.get("spaceId");
    

    const dispatch = useDispatch()

    if (missionId || missionName || avatarId || spaceId) {
        fetchMissionsFilters(dispatch, {missionId, missionName, avatarId, spaceId})
        return <Missions missionId={missionId ?? null} missionName={missionName ?? null} avatarId={avatarId ?? null} spaceId={spaceId ?? null}/>;
    }
    else{
        return <Missions/>;
    }

}