import { Paths } from "paths/Paths"

const categoryMapping = item => ({
    id: item.id,
    name: item.name ?? null,
    active: item.active ?? null,
    children: getChildren(item) ?? null,
    icon: item.iconUrl != null && item.iconUrl != '' ?`https://cdn.minimania.app/content/content-v102/assets/${item.iconUrl}` : null,
    parentId: item.parentId ?? null,
    hasSeparator: item.hasSeparator ? true : false,
    isManuallyControlled: item.isManuallyControlled ? true : false,
    sortsByOrderIndex: item.sortsByOrderIndex ? true : false,
})

const categoryDetailMapping = item => ({
    id: item._id,
    name: item.name ?? null,
    active: item.active ?? null,
    children: getChildren(item) ?? null,
    icon: item.iconUrl != null && item.iconUrl != '' ?`https://cdn.minimania.app/content/content-v102/assets/${item.iconUrl}` : null,
    parentId: item.parentId ?? null,
    hasSeparator: item.hasSeparator ? true : false,
    isManuallyControlled: item.isManuallyControlled ? true : false,
    sortsByOrderIndex: item.sortsByOrderIndex ? true : false,
})

const categoryEntryMapping = item => ({
    entryId: item._id ?? null,
    modelId: item.modelId ?? null,
    modelName: item.modelName ?? null,
    icon: `${Paths.libraryPath}${item.modelUrl}` ?? null,
    salePriceGold: item.salePriceGold ?? null,
    startDate: item.startDate ?? null,
    endDate: item.endDate ?? null,
    shareDiscount: item.shareDiscount ?? null,
    discounted: item.discounted ?? null,
    active: item.active ?? null,
})

const categoryModelEntryMapping = item => ({
    modelId: item.modelId ?? null,
    icon: `${Paths.libraryPath}${item.icon}` ?? null,
    modelName: item.modelName ?? null,
    goldPrice: item.goldPrice ?? null,
    discount: item.discount ?? null,
    discounted: item.discounted ?? null,
    entryId: item.entryId ?? null,
    categoryId: item.categoryId ?? null,
    categoryName: item.categoryName ?? null,
    startDate: item.startDate ?? null,
    endDate: item.endDate ?? null,
    parentCategoryName: item.parentCategoryName ?? null
})

const modelListItemsMapping = (item) => ({
    modelId: item.modelId ?? null,
    modelName: item.modelName ?? null,
    modelIcon: `${Paths.libraryPath}${item.modelIcon}` ?? null,
})

const getChildren = (item) => {

    if (item.children) {
        const children = item.children.map(child => categoryMapping(child))
        console.log(children);
        return children
    }

    return item.children
}

export { categoryMapping, categoryDetailMapping, categoryEntryMapping, categoryModelEntryMapping, modelListItemsMapping }
