import { Paths } from "paths/Paths"
import { BiCoin } from "react-icons/bi"
import { PiPokerChipBold } from "react-icons/pi"

class SpinPrize {
    constructor({ id, idCategory, gold, tokens, modelId, modelQuantity, modelName, modelIcon }) {
        this.id = id
        this.idCategory = idCategory
        this.gold = gold
        this.tokens = tokens
        this.modelId = modelId
        this.modelQuantity = modelQuantity
        this.modelName = modelName
        this.modelIcon = modelIcon
    }

    get() {
        return {
            id: this.id,
            idCategory: this.idCategory,
            gold: this.gold,
            tokens: this.tokens,
            modelId: this.modelId,
            modelName: this.modelName,
            modelQuantity: this.modelQuantity,
            type: this.type,
            value: this.value,
            icon: this.icon
        }
    }

    get type(){
        if(this.modelId != null){
            return 'Item'
        }
        if(this.gold != null){
            return 'Moedas'
        }
        if(this.tokens != null){
            return 'Fichas'
        }
    }

    get value(){
        if(this.modelId != null){
            return this.modelQuantity > 1 ? `${this.modelQuantity}x ${this.modelName}` : this.modelName
        }
        if(this.gold != null){
            return this.gold
        }
        if(this.tokens != null){
            return this.tokens
        }
    }

    get icon(){
        if(this.modelId != null){
            return `${Paths.libraryPath}${this.modelIcon}`
        }
        if(this.gold != null){
            return 'gold'
        }
        if(this.tokens != null){
            return 'token'
        }
    }
}

export default SpinPrize