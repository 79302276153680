const itemsAvatarsUserMapping = item => ({
    id: item._id ?? null,
    spaceId: item.space_id ?? null,
    spaceName: item.spaceName ?? "Item no Inventário",
    userId: item.user_id ?? null,
    firstName: item.firstName ?? null,
    lastName: item.lastName ?? null,
    modelName: item.model_desc ?? null,
    forSale: handleForSale(item.forSale),
    modelIcon: `https://cdn.minimania.app/content/content-v102/assets/${ item.model_icon }`,
    goldPrice: item.price_gold ?? 0,
    tokenPrice: item.price_tokens ?? 0,
    modelId: item.model_id ?? null,
})

const itemDetailsMapping = item => ({
    id: item._id ?? null,
    modelId: item.model_id ?? null,
    modelName: item.model_desc ?? null,
    spaceId: item.space_id ?? '-',
    spaceName: item.spaceName ?? "Item no Inventário",

})

const handleForSale = (forSale) => {
    const values = {
        N: "Item não à venda",
        M: "Model Sale",
        I: "Instance Sale",
        Y: "Instance Sale"
    }

    return values[forSale]?? values.N
}
export {itemsAvatarsUserMapping, itemDetailsMapping}