import MainMenu from './MainMenu'
import ExpandableMenu from './ExpandableMenu'
import { Link } from 'react-router-dom'


export default function SidebarDesktop ({ navigation }) {
    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-40 lg:flex-col bg-cyan-800">
            <div className="flex h-16 shrink-0 items-center">
                <Link to="/" className="mx-auto mt-2">
                    <img
                        className="h-16 w-auto mx-auto"
                        src="/logotipo.png"
                        alt="Mini Mania"
                    />
                </Link>
            </div>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-2 py-4 sidebar">
                {/* <MainMenu navigation={ navigation } /> */ }
                <ExpandableMenu />
            </div>
        </div>
    )
}
