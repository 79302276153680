import Transactions from "Pages/Revenue/Transactions";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchTransactionsFilters } from "store/modules/transactions/transactionActions";

export function TransactionsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const sender = searchParams.get("sender");
    const receiver = searchParams.get("receiver");
    const space_id = searchParams.get("space_id");
    const space_instance_id = searchParams.get("space_instance_id");
    const item_id = searchParams.get("item_id");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    const transactionType = searchParams.get("transactionType");
    const desc = searchParams.get("desc");
    let propName = {
        'catalog': 'Catalog',
        'gift': 'Gift',
        'gold': 'Gold',
        'item.instance': 'Item Instance',
        'marketplace': 'Marketplace',
        'mission': 'Mission',
        'refund': 'Refund',
        'seed': 'Seed',
        'space': 'Space',
        'spintowin': 'Spin to Win',
        'trade': 'Trade',
    }

    const dispatch = useDispatch()

    if (sender || receiver || space_id || space_instance_id || item_id || dateFrom || dateTo || transactionType || desc) {
        fetchTransactionsFilters(dispatch, {sender, receiver, space_id, space_instance_id, desc, item_id, dateFrom, dateTo, transactionType: transactionType ? {name: propName[transactionType], value: transactionType} : ''})
        return <Transactions sender={sender ?? null} receiver={receiver ?? null} space_id={space_id ?? null} space_instance_id={space_instance_id ?? null} item_id={item_id ?? null} des={desc ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null} transactionType={transactionType ?? null}/>;
    }
    else{
        return <Transactions/>;
    }

}