import BasicTable from "components/BasicTable"
import Button from "components/form/Button"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchInterstitialGroups } from "store/modules/administration/interstitials/interstitialGroupsSlice"
import style from '../../../styles/Search.module.css'
import catalogStyle from '../../../styles/CatalogStyle.module.css'
import dndStyle from '../../../styles/DnDStyle.module.css'
import interstitialStyle from '../../../styles/Interstitial.module.css'
import styleUserTabs from '../../../styles/UserTabs.module.css'
import { fetchClearInterstitials, fetchInterstitials } from "store/modules/administration/interstitials/interstitialsSlice"
import { fetchClearSelectedInterstitialItemOrGroup, fetchSelectedInterstitialItemOrGroup } from "store/modules/administration/interstitials/selectedInterstitialItemOrGroupSlice"
import GroupDetails from "./Partials/GroupDetails"
import ItemDetails from "./Partials/ItemDetails"
import Modal from "components/Modal"
import { toast } from "react-toastify"
import { createInterstitial, createInterstitialGroup, deleteGroup, deleteInterstitial } from "services/interstitialService"
import moment from "moment"
import { fetchClearInterstitialGroup, fetchSelectedInterstitialGroup } from "store/modules/administration/interstitials/selectedInterstitialGroup"
import useUserPermission from "domains/Permissions/useUserPermission"
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode"

export default function Interstitials() {
    const verifyModAdmin = useUserPermission(SMIPermissionsCode.MOD_ADMIN);

    const categoryStatus = {
        activeIcon: 'ativo',
        inactiveIcon: 'inativo',
        scheduleIcon: 'aguardo',
        hasAction: false
    }

    const categoryActions = [
        { action: 'delete', icon: null }
    ]

    const [categoryHeaders, setCategoryHeaders] = useState([
        { title: 'Nome', key: 'name', detailable: true, appear: true },
        { title: 'Cooldown', key: 'cooldown', appear: true },
        { title: 'Status', key: 'active', status: categoryStatus, appear: true },
        { title: 'Ações', key: 'acoes', actions: () => categoryActions, appear: true }
    ])

    const [categoryMomentHeaders, setCategoryMomentHeaders] = useState([...categoryHeaders]);

    const handleAppearHeader = (item) => {
        setCategoryHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setCategoryMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [categoryItemHeaders, setCategoryItemHeaders] = useState([
        { title: 'Nome', key: 'name', detailable: true, appear: true },
        { title: 'Cooldown', key: 'cooldown', appear: true },
        { title: 'Status', key: 'active', status: categoryStatus, appear: true },
        { title: 'Ações', key: 'acoes', actions: () => categoryActions, appear: true }
    ])

    const [categoryItemMomentHeaders, setCategoryItemMomentHeaders] = useState([...categoryItemHeaders]);

    const handleAppearHeaderItem = (item) => {
        setCategoryItemHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setCategoryItemMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }



    const [isGroupLoading, setGroupLoading] = useState(false)
    const [isInterstitialLoading, setInterstitialLoading] = useState(false)
    //const [currentGroup, setCurrentGroup] = useState({})

    const dispatch = useDispatch()

    const getCategories = () => {
        setGroupLoading(true)
        dispatch(fetchInterstitialGroups())
    }

    const getInterstitials = () => {
        if (Object.keys(selectedInterstitialGroup).length != 0) {
            setInterstitialLoading(true)
            dispatch(fetchInterstitials(selectedInterstitialGroup.groupId))
        }
    }

    const selectedInterstitialItemOrGroup = useSelector((state) => {
        return state.selectedInterstitialItemOrGroup
    })

    const selectedInterstitialGroup = useSelector((state) => {
        return state.selectedInterstitialGroup
    })

    const interstitialGroups = useSelector((state) => {
        return Array.isArray(state.interstitialGroups) ? state.interstitialGroups : []
    })

    const interstitials = useSelector((state) => {
        return Array.isArray(state.interstitials) ? state.interstitials : []
    })

    useEffect(() => {
        getCategories()
    }, [])

    const handleDetailGroup = (item) => {
        setInterstitialLoading(true)
        //setCurrentGroup(item)
        dispatch(fetchSelectedInterstitialGroup(item))
        dispatch(fetchInterstitials(item.groupId))

        dispatch(fetchSelectedInterstitialItemOrGroup(item))
        console.log(selectedInterstitialItemOrGroup)
    }

    const handleDetailItem = (item) => {
        dispatch(fetchSelectedInterstitialItemOrGroup(item))
        console.log(selectedInterstitialItemOrGroup)
    }

    //Create Group Development

    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
    const [checkCreateGroup, setCheckCreateGroup] = useState(false);
    const [createGroupNameInput, setCreateGroupNameInput] = useState('');
    const [createGroupTokenInput, setCreateGroupTokenInput] = useState('');
    const [createGroupActiveInput, setCreateGroupActiveInput] = useState(false);
    const [createGroupWeekTokenInput, setCreateGroupWeekTokenInput] = useState(0);
    const [createGroupDayTokenInput, setCreateGroupDayTokenInput] = useState(0);
    const [createGroupHourTokenInput, setCreateGroupHourTokenInput] = useState(0);

    const handleCloseCreateGroupModal = () => {
        setCheckCreateGroup(false);
        setCreateGroupNameInput('');
        setCreateGroupTokenInput('');
        setCreateGroupActiveInput(false);
        setCreateGroupWeekTokenInput(0);
        setCreateGroupDayTokenInput(0);
        setCreateGroupHourTokenInput(0);

        dispatch(fetchClearInterstitialGroup())
        dispatch(fetchClearInterstitials())

        getCategories();

        setIsCreateGroupModalOpen(false);
    }

    const handleOepenCreateGroupModal = () => {
        setIsCreateGroupModalOpen(true);
    }

    useEffect(() => {
        if (createGroupNameInput.trim() != '') {
            if (createGroupTokenInput.trim() != '') {
                if (createGroupWeekTokenInput == 0 && createGroupDayTokenInput == 0 && createGroupHourTokenInput == 0) {
                    setCheckCreateGroup(false)
                }
                else {
                    setCheckCreateGroup(true)
                }
            }
            else {
                setCheckCreateGroup(false)
            }
        }
        else {
            setCheckCreateGroup(false)
        }
    }, [createGroupNameInput, createGroupTokenInput, createGroupWeekTokenInput, createGroupDayTokenInput, createGroupHourTokenInput])

    const handleSaveCreateGroup = async () => {
        try {
            const tokenAge = moment.duration({
                weeks: parseInt(createGroupWeekTokenInput, 10),
                days: parseInt(createGroupDayTokenInput, 10),
                hours: parseInt(createGroupHourTokenInput, 10),
            }).asSeconds();

            const title = createGroupNameInput;
            const token = createGroupTokenInput;
            const active = createGroupActiveInput;


            await createInterstitialGroup({ title, token, tokenAge, active });

            handleCloseCreateGroupModal();

            toast.success('Grupo Criado com Sucesso!');
        }
        catch {
            toast.error('Erro ao Criar Grupo!');
        }
    }

    //Delete Group Development
    const [currentGroupToDelete, setCurrentGroupToDelete] = useState({});
    const [isDeleteGroup, setIsDeleteGroup] = useState(false);

    const handleOpenDeleteGroupModal = (item) => {
        setCurrentGroupToDelete(item);
        setIsDeleteGroup(true);
    }

    const handleCloseDeleteGroupModal = () => {
        dispatch(fetchClearInterstitialGroup())
        dispatch(fetchClearInterstitials())
        dispatch(fetchClearSelectedInterstitialItemOrGroup())

        getCategories();

        setIsDeleteGroup(false);
    }

    const handleDeleteGroup = async () => {
        try {
            await deleteGroup(currentGroupToDelete.groupId);
            handleCloseDeleteGroupModal()
            toast.success("Grupo Deletado com Sucesso");
        }
        catch {
            toast.error("Erro ao Deletar Grupo");
        }
    }

    //Create Interstitial Development

    const [isCreateInterstitialModalOpen, setIsCreateInterstitialModalOpen] = useState(false);
    const [checkCreateInterstitial, setCheckCreateInterstitial] = useState(false);
    const [createInterstitialNameInput, setCreateInterstitialNameInput] = useState('');
    const [createInterstitialTokenInput, setCreateInterstitialTokenInput] = useState('');
    const [createInterstitialActiveInput, setCreateInterstitialActiveInput] = useState(false);
    const [createInterstitialWeekTokenInput, setCreateInterstitialWeekTokenInput] = useState(0);
    const [createInterstitialDayTokenInput, setCreateInterstitialDayTokenInput] = useState(0);
    const [createInterstitialHourTokenInput, setCreateInterstitialHourTokenInput] = useState(0);
    const [createInterstitialGroupInput, setCreateInterstitialGroupInput] = useState(interstitialGroups.length != 0 ? interstitialGroups[0].groupId : {});
    const [createInterstitialUrlInput, setCreateInterstitialUrlInput] = useState('');
    const [createInterstitialWindowInput, setCreateInterstitialWindowInput] = useState(false);
    const [createInterstitialStartInput, setCreateInterstitialStartInput] = useState('');
    const [createInterstitialEndInput, setCreateInterstitialEndInput] = useState('');
    const [createInterstitialFile, setCreateInterstitialFile] = useState(null);

    const handleFileSubmit = (e) => {
        setCreateInterstitialFile(e.target.files[0]);
    }

    const handleOpenCreateInterstitialModal = () => {
        setIsCreateInterstitialModalOpen(true)
    }

    const handleCloseCreateInterstitialModal = () => {
        setCheckCreateInterstitial(false)
        setCreateInterstitialNameInput('');
        setCreateInterstitialTokenInput('');
        setCreateInterstitialActiveInput(false)
        setCreateInterstitialWeekTokenInput(0);
        setCreateInterstitialDayTokenInput(0);
        setCreateInterstitialHourTokenInput(0);
        setCreateInterstitialGroupInput(interstitialGroups.length != 0 ? interstitialGroups[0].groupId : {});
        setCreateInterstitialUrlInput('');
        setCreateInterstitialWindowInput(false);
        setCreateInterstitialStartInput('');
        setCreateInterstitialEndInput('');
        setCreateInterstitialFile(null);

        getInterstitials();


        setIsCreateInterstitialModalOpen(false);
    }

    useEffect(() => {
        if (createInterstitialNameInput.trim() != '') {
            if (createInterstitialTokenInput.trim() != '') {
                if (createInterstitialWeekTokenInput == 0 && createInterstitialDayTokenInput == 0 && createInterstitialHourTokenInput == 0) {
                    setCheckCreateInterstitial(false)
                    console.log('false second')
                }
                else {
                    if (createInterstitialGroupInput != '') {
                        if (createInterstitialStartInput != '' && createInterstitialEndInput != '') {
                            if (createInterstitialFile != null) {
                                setCheckCreateInterstitial(true)
                                console.log('true')
                                console.log(createInterstitialFile)
                            }
                            else {
                                setCheckCreateInterstitial(false)
                                console.log('false file')

                            }
                        }
                        else {
                            setCheckCreateInterstitial(false)
                            console.log('false dates')
                        }

                    }
                    else {
                        setCheckCreateInterstitial(false)
                        console.log('false group')
                    }
                }
            }
            else {
                setCheckCreateInterstitial(false)
                console.log('false token')
            }
        }
        else {
            setCheckCreateInterstitial(false)
            console.log('false name')

        }
    }, [
        createInterstitialNameInput,
        createInterstitialTokenInput,
        createInterstitialActiveInput,
        createInterstitialWeekTokenInput,
        createInterstitialDayTokenInput,
        createInterstitialHourTokenInput,
        createInterstitialGroupInput,
        createInterstitialUrlInput,
        createInterstitialWindowInput,
        createInterstitialStartInput,
        createInterstitialEndInput,
        createInterstitialFile
    ])

    const handleSaveCreateInterstitial = async () => {
        try {
            const tokenAge = moment.duration({
                weeks: parseInt(createInterstitialWeekTokenInput, 10),
                days: parseInt(createInterstitialDayTokenInput, 10),
                hours: parseInt(createInterstitialHourTokenInput, 10),
            }).asSeconds();


            await createInterstitial(
                createInterstitialNameInput,
                createInterstitialTokenInput,
                createInterstitialActiveInput,
                tokenAge,
                createInterstitialGroupInput,
                createInterstitialUrlInput,
                createInterstitialWindowInput,
                createInterstitialStartInput,
                createInterstitialEndInput,
                createInterstitialFile
            )

            handleCloseCreateInterstitialModal()

            toast.success('Interstitial Criada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Criar Interstitial!')
        }
    }

    //Delete Interstitial Development
    const [currentInterstitialToDelete, setCurrentInterstitialToDelete] = useState({});
    const [isDeleteInterstitial, setIsDeleteInterstitial] = useState(false);

    const handleOpenDeleteInterstitialModal = (item) => {
        setCurrentInterstitialToDelete(item);
        setIsDeleteInterstitial(true);
    }

    const handleCloseDeleteInterstitialModal = () => {
        setCurrentInterstitialToDelete({});
        dispatch(fetchClearSelectedInterstitialItemOrGroup())

        getInterstitials();

        setIsDeleteInterstitial(false);
    }

    const handleDeleteInterstitial = async () => {
        try {
            await deleteInterstitial(currentInterstitialToDelete.interstitialId);
            handleCloseDeleteInterstitialModal()
            toast.success("Interstitial Deletado com Sucesso");
        }
        catch {
            toast.error("Erro ao Deletar Interstitial");
        }
    }


    return (
        <>
            {verifyModAdmin && <div className='grid grid-cols-12 gap-4'>
                <div className="flex flex-col col-span-7 gap-5">
                    <div className='flex-1'>
                        <BasicTable
                            onAppearHeader={handleAppearHeader}
                            headerControl={true}
                            originalHeaders={categoryHeaders}
                            headers={categoryMomentHeaders}
                            title='Grupos'
                            onDeleteItem={handleOpenDeleteGroupModal}
                            onDetailRow={handleDetailGroup}
                            isLoading={isGroupLoading && interstitialGroups.length === 0 && interstitialGroups == []}
                            items={interstitialGroups}
                            slotFooter={
                                <div className={catalogStyle.basicTableFooter}>
                                    <Button text="Adicionar Grupo" onClick={handleOepenCreateGroupModal} color="cyan" />
                                </div>
                            }
                            slotActions={
                                <div className={style.slotAbuseReportBtnsFooter}>
                                    <Button text="Atualizar" onClick={getCategories} color="cyan" />
                                </div>
                            }
                            height="25vh"
                        />
                    </div>
                    <div className="flex-1">
                        <BasicTable
                            onAppearHeader={handleAppearHeaderItem}
                            headerControl={true}
                            originalHeaders={categoryItemHeaders}
                            headers={categoryItemMomentHeaders}
                            title='Interstitials'
                            onDeleteItem={handleOpenDeleteInterstitialModal}
                            onDetailRow={handleDetailItem}
                            isLoading={isInterstitialLoading && interstitials.length === 0 && interstitials == []}
                            items={interstitials}
                            slotFooter={
                                <div className={catalogStyle.basicTableFooter}>
                                    <Button text="Adicionar Interstitial" disabled={Object.keys(selectedInterstitialGroup).length == 0} onClick={handleOpenCreateInterstitialModal} color="cyan" />
                                </div>
                            }
                            slotActions={
                                <div className={style.slotAbuseReportBtnsFooter}>
                                    <Button text="Atualizar" disabled={Object.keys(selectedInterstitialGroup).length == 0} onClick={getInterstitials} color="cyan" />
                                </div>
                            }
                            height="25vh"
                        />
                    </div>
                </div>
                <div className='col-span-5'>
                    {selectedInterstitialItemOrGroup && selectedInterstitialItemOrGroup.interstitialId && <ItemDetails item={selectedInterstitialItemOrGroup} />}
                    {selectedInterstitialItemOrGroup && !selectedInterstitialItemOrGroup.interstitialId && selectedInterstitialItemOrGroup.length != 0 && <GroupDetails item={selectedInterstitialItemOrGroup} />}
                </div>
                <Modal
                    isOpen={isCreateGroupModalOpen}
                    onClose={handleCloseCreateGroupModal}
                    footer={
                        <div className={dndStyle.modalFooter}>
                            <Button disabled={!checkCreateGroup} text="Salvar" onClick={handleSaveCreateGroup} color="cyan" />
                        </div>
                    }
                    header={<div><span>Criar Grupo</span></div>}
                >
                    <div className={interstitialStyle.createModalContainer}>

                        <div className={interstitialStyle.createGroupInfoContainer}>
                            <div className={interstitialStyle.createGroupNameContainer}>
                                <span style={{ fontWeight: 'bold' }}>Nome do Grupo:</span>
                                <input value={createGroupNameInput} className={styleUserTabs.modalInput} onChange={(e) => setCreateGroupNameInput(e.target.value)} placeholder='Nome do Grupo' />
                            </div>
                            <div className={interstitialStyle.createGroupCheckboxContainer}>
                                <input
                                    type="checkbox"
                                    checked={createGroupActiveInput}
                                    onChange={() => setCreateGroupActiveInput(!createGroupActiveInput)}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                />
                                <label className="ml-2">{"Ativo"}</label>
                            </div>

                        </div>

                        <div className={interstitialStyle.createGroupTokenContainer}>

                            <span style={{ fontWeight: 'bold' }}>Token:</span>
                            <input value={createGroupTokenInput} className={styleUserTabs.modalInput} onChange={(e) => setCreateGroupTokenInput(e.target.value)} placeholder='Token' />

                        </div>

                        <div className={interstitialStyle.createGroupTokenDurationContainer}>
                            <span style={{ fontWeight: 'bold' }}>Token Duration:</span>
                            <div className={interstitialStyle.createGroupDurationContainer}>
                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createGroupWeekTokenInput} onChange={(e) => setCreateGroupWeekTokenInput(e.target.value)} min="0" />
                                    <span>Semanas</span>
                                </div>

                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createGroupDayTokenInput} onChange={(e) => setCreateGroupDayTokenInput(e.target.value)} min="0" />
                                    <span>Dias</span>
                                </div>

                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createGroupHourTokenInput} onChange={(e) => setCreateGroupHourTokenInput(e.target.value)} min="0" />
                                    <span>Horas</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    header={<div><span>Atenção!</span></div>}
                    isOpen={isDeleteGroup}
                    onClose={handleCloseDeleteGroupModal}
                >
                    <div className={style.modalModLogContainer}>
                        <div className={style.modalSlotHeaderTitle}>
                            <span className={style.modalSubtitle}>Deseja excluir definitivamente o grupo?</span>
                        </div>
                        <div className={style.modalSlotHeaderBtn}>
                            <Button disabled={false} text="Sim" onClick={handleDeleteGroup} color="cyan" />
                            <Button disabled={false} text="Não" onClick={handleCloseDeleteGroupModal} color="cyan" />
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={isCreateInterstitialModalOpen}
                    onClose={handleCloseCreateInterstitialModal}
                    footer={
                        <div className={dndStyle.modalFooter}>
                            <Button disabled={!checkCreateInterstitial} text="Salvar" onClick={handleSaveCreateInterstitial} color="cyan" />
                        </div>
                    }
                    header={<div><span>Criar Interstitial</span></div>}
                >
                    <div className={interstitialStyle.createModalContainer}>

                        <div className={interstitialStyle.createGroupInfoContainer}>
                            <div className={interstitialStyle.createGroupNameContainer}>
                                <span style={{ fontWeight: 'bold' }}>Título:</span>
                                <input value={createInterstitialNameInput} className={styleUserTabs.modalInput} onChange={(e) => setCreateInterstitialNameInput(e.target.value)} placeholder='Título' />
                            </div>
                            <div className={interstitialStyle.createGroupCheckboxContainer}>
                                <input
                                    type="checkbox"
                                    checked={createInterstitialActiveInput}
                                    onChange={() => setCreateInterstitialActiveInput(!createInterstitialActiveInput)}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                />
                                <label className="ml-2">{"Ativo"}</label>
                            </div>
                        </div>


                        <div className={interstitialStyle.createGroupInfoContainer}>
                            <div className={interstitialStyle.createGroupNameContainer}>
                                <span style={{ fontWeight: 'bold' }}>Grupo:</span>
                                <select value={createInterstitialGroupInput} className={styleUserTabs.select} onChange={(e) => setCreateInterstitialGroupInput(e.target.value)}>
                                    {interstitialGroups != [] && interstitialGroups.map((item, i) => {
                                        return (
                                            <option key={item.groupId} value={item.groupId}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className={interstitialStyle.createGroupCheckboxContainer}>
                                <input
                                    type="checkbox"
                                    checked={createInterstitialWindowInput}
                                    onChange={() => setCreateInterstitialWindowInput(!createInterstitialWindowInput)}
                                    className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                />
                                <label className="ml-2">{"Nova Janela"}</label>
                            </div>

                        </div>

                        <div className={interstitialStyle.createInterstitialDatesContainer}>
                            <div className={interstitialStyle.createInterstitialDateContainer}>
                                <span style={{ fontWeight: 'bold' }}>Data de Início:</span>
                                <input value={createInterstitialStartInput} onChange={(e) => setCreateInterstitialStartInput(e.target.value)} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                            </div>
                            <div className={interstitialStyle.createInterstitialDateContainer}>
                                <span style={{ fontWeight: 'bold' }}>Data de Término:</span>
                                <input value={createInterstitialEndInput} onChange={(e) => setCreateInterstitialEndInput(e.target.value)} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                            </div>
                        </div>

                        <div className={interstitialStyle.createInterstitialUrlContainer}>
                            <div className={interstitialStyle.createGroupNameContainer}>
                                <span style={{ fontWeight: 'bold' }}>Url:</span>
                                <input value={createInterstitialUrlInput} className={styleUserTabs.modalInput} onChange={(e) => setCreateInterstitialUrlInput(e.target.value)} placeholder='Url' />
                            </div>

                            <div className={interstitialStyle.createGroupTokenContainer}>
                                <span style={{ fontWeight: 'bold' }}>Token:</span>
                                <input value={createInterstitialTokenInput} className={styleUserTabs.modalInput} onChange={(e) => setCreateInterstitialTokenInput(e.target.value)} placeholder='Token' />
                            </div>
                        </div>

                        <div className={interstitialStyle.createGroupTokenDurationContainer}>
                            <span style={{ fontWeight: 'bold' }}>Token Duration:</span>
                            <div className={interstitialStyle.createGroupDurationContainer}>
                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createInterstitialWeekTokenInput} onChange={(e) => setCreateInterstitialWeekTokenInput(e.target.value)} min="0" />
                                    <span>Semanas</span>
                                </div>

                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createInterstitialDayTokenInput} onChange={(e) => setCreateInterstitialDayTokenInput(e.target.value)} min="0" />
                                    <span>Dias</span>
                                </div>

                                <div className={interstitialStyle.createGroupDuration}>
                                    <input type='number' className={interstitialStyle.modalInput} value={createInterstitialHourTokenInput} onChange={(e) => setCreateInterstitialHourTokenInput(e.target.value)} min="0" />
                                    <span>Horas</span>
                                </div>
                            </div>
                        </div>

                        <div className={interstitialStyle.createInterstitialImgContainer}>
                            <span style={{ fontWeight: 'bold' }}>Imagem:</span>
                            <input className={styleUserTabs.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />
                        </div>

                    </div>
                </Modal>
                <Modal
                    header={<div><span>Atenção!</span></div>}
                    isOpen={isDeleteInterstitial}
                    onClose={handleCloseDeleteInterstitialModal}
                >
                    <div className={style.modalModLogContainer}>
                        <div className={style.modalSlotHeaderTitle}>
                            <span className={style.modalSubtitle}>Deseja excluir definitivamente a Interstitial?</span>
                        </div>
                        <div className={style.modalSlotHeaderBtn}>
                            <Button disabled={false} text="Sim" onClick={handleDeleteInterstitial} color="cyan" />
                            <Button disabled={false} text="Não" onClick={handleCloseDeleteInterstitialModal} color="cyan" />
                        </div>
                    </div>
                </Modal>
            </div>}
        </>
    )
}