export const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = ''
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export const getInitials = (name) => {
    const words = name.split(' ')
    if (words.length === 1) {
        return words[0].substring(0, 2).toUpperCase()
    } else {
        return (words[0][0] + words[words.length - 1][0]).toUpperCase()
    }
}

export const getAvatarUrl = (user) => {
    const initials = getInitials(`${ user.firstName } ${ user.lastName }`)
    const backgroundColor = stringToColor(`${user.firstName}${user.lastName}`)
    return `https://ui-avatars.com/api/?name=${ initials }&background=${ backgroundColor }&color=fff`
}

export function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export const getSmallID = (id, size) => {
    return id?.slice(0, size) || ''
}
