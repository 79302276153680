export default function LoginBanner () {
    return (
        <div className="relative hidden w-0 flex-1 lg:block">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="/brasil.png"
                alt=""
            />
        </div>
    )
}
