import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
export default function Select ({ items, order, onChange, name, label, selectedItem, defaultItem }) {
    const [selected, setSelected] = useState(selectedItem ?? (defaultItem ? { name: defaultItem, value: '' } : items[0]))
    const [sortedItems, setSortedItems] = useState(items ?? [])

    const handleChange = (event) => {
        typeof onChange == 'function' ? onChange({ target: { name: name, value: event.value } }): {}
        setSelected(event)
    }

    useEffect(() => {
        handleSort(order)
        
    }, [items])

    useEffect(() => {
        setSelected(
            defaultItem ? { name: defaultItem, value: '' } : items[0]
        )
    }, [defaultItem])

    const handleSort = (option) => {
        let sorted = [...items];
        switch (option) {
            case 'A-Z':
                sorted.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case 'Z-A':
                sorted.sort((a, b) => b.name.localeCompare(a.name));
                break;
            case 'Value':
                sorted.sort((a, b) => a.value.localeCompare(b.value));
                break;
            default:
                sorted.sort((a, b) => a.name.localeCompare(b.name)); // A-Z
        }
        if (defaultItem && !selectedItem) {
            sorted.unshift({ name: defaultItem, value: '' });
        }
        setSortedItems(sorted);
    };

    useEffect(() => {
        setSortedItems(items)
    }, [items])

    return (
        <div className="w-full py-2">
            <label>{label}</label>
            <Listbox value={ () => target.value } onChange={ handleChange }>
                <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default border border-cyan-800 border-opacity-30 rounded-md bg-white py-1 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-cyan-800 focus-visible:ring-2 focus-visible:ring-cyan-800 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-cyan-800 sm:text-sm">
                        {selected != undefined ? <span className="block truncate">{ selected.name }</span>: <span>&nbsp;</span>}
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={ Fragment }
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-20 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm max-h-48 overflow-y-auto">
                            { sortedItems.map((item, i) => (
                                <Listbox.Option
                                    key={ i }
                                    className={ ({ active }) =>
                                        `relative cursor-default select-none py-1 pl-6 pr-1 ${ active ? 'bg-cyan-100 text-grey-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={ item }
                                >
                                    { ({ selected }) => (
                                        <>
                                            <span
                                                className={ `block truncate ${ selected ? 'font-medium' : 'font-normal'
                                                    }` }
                                            >
                                                { item.name }
                                            </span>
                                            { selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-cyan-600">
                                                    <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                                </span>
                                            ) : null }
                                        </>
                                    ) }
                                </Listbox.Option>
                            )) }
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}
