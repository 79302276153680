import FormLogin from './Partials/FormLogin'
import LoginBanner from './Partials/LoginBanner'
import { Link } from 'react-router-dom'
export default function LoginPage () {
    return (
        <>
            <div className="flex min-h-full flex-1 bg-cyan-800">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Link to="/">
                                <img
                                    className="h-50 w-auto mx-auto"
                                    src="/logotipo.png"
                                    alt="Mini Mania"
                                />
                            </Link>
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
                                Entre em sua conta SMI
                            </h2>
                        </div>

                        <div className="mt-10">
                            <FormLogin />
                        </div>
                    </div>
                </div>
                <LoginBanner />
            </div>
        </>
    )
}
