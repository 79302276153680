import { createSlice } from '@reduxjs/toolkit'

const SelectedSpinCategorySlice = createSlice({
    name: 'SelectedSpinCategory',
    initialState: [],
    reducers: {
        setSelectedSpinCategory: (state, action) => {
            return action.payload
        },
    },
})

export const fetchSelectedSpinCategory = (params) => async (dispatch) => {
    try {
        dispatch(setSelectedSpinCategory(params))
    } catch (error) {
        console.error('Erro ao carregar:', error)
    }
}

export const fetchClearSelectedSpinCategory = () => async (dispatch) => {
    dispatch(setSelectedSpinCategory([]))
}

export const { setSelectedSpinCategory } = SelectedSpinCategorySlice.actions
export default SelectedSpinCategorySlice.reducer