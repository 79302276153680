import React, { useState, useRef, useImperativeHandle } from 'react'
import InputField from 'components/form/InputField'
import CheckboxField from 'components/form/Checkbox'
import RadioButton from './RadioButton'
import SelectField from './Select'

const DefaultForm = React.forwardRef(({ fields, onChange }, ref) => {
    const initialFormState = Object.fromEntries(fields.map(field => [field.name, field.value || '']))
    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState({})
    const formRef = useRef()

    const handleChange = (event) => {
        let name
        let value

        if (event.target.type === 'checkbox') {
            name = event.target.name
            value = event.target.checked

        } else {
            name = event.target.name
            value = event.target.value
        }
        setFormData({ ...formData, [name]: value })
        const formIsValid = validateForm({ ...formData, [name]: value })
        onChange({ ...formData, [name]: value }, formIsValid, name, value)
    }

    const validateForm = (formData) => {
        const newErrors = {}
        let formIsValid = true

        fields.forEach((field) => {
            if (field.validate) {
                field.validate.forEach((validationFunction) => {
                    const validationRule = validationFunction.rule
                    let errorMessage = null
                    if (validationFunction.val) {
                        errorMessage = validationRule(formData[field.name], validationFunction.val)
                    } else {
                        errorMessage = validationRule(formData[field.name])
                    }

                    if (errorMessage) {
                        newErrors[field.name] = errorMessage
                        formIsValid = false
                    }
                })
            }
        })

        setErrors(newErrors)
        return formIsValid
    }

    const renderField = (field) => {
        switch (field.type) {
            case 'select':
                return (
                    <SelectField
                        name={ field.name }
                        label={ field.label }
                        items={ field.items }
                        order={ field.order }
                        defaultItem={ field.defaultItem }
                        selectedItem={ field.selectedItem }
                        onChange={ handleChange }
                    />
                )
            case 'radio':
                return (
                    <RadioButton
                        name={ field.name }
                        label={ field.label }
                        options={ field.options }
                        selectedOption={ field.selectedOption }
                        onChange={ handleChange }
                    />
                )
            case 'checkbox':
                return (
                    <CheckboxField
                        name={ field.name }
                        label={ field.label }
                        checked={ formData[field.name] }
                        validate={ field.validate }
                        errors={ errors[field.name] }
                        onChange={ handleChange } />
                )
            default:
                return (

                    <InputField
                        type={ field.type }
                        name={ field.name }
                        label={ field.label }
                        mask={ field.mask }
                        validate={ field.validate }
                        value={ field.value }
                        placeholder={ field.placeholder }
                        errors={ errors[field.name] }
                        onChange={ handleChange } />
                )
        }
    }

    return (
        <div className='py-2 px-3'>
            <form ref={ formRef } className='grid grid-cols-12 gap-x-3'>
                { fields.map((field) => (
                    <div key={ field.name } className={ `grid ${ field.gridClass }` }>
                        { renderField(field) }
                    </div>
                )) }
            </form>
        </div>
    )
})

export default DefaultForm
