class UserGroupDetails {
    constructor({ id, name, type, permissions }) {
        this.id = id
        this.name = name
        this.type = type
        this.permissions = permissions
    }

    get() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            permissions: this.permissions
        }
    }
}

export default UserGroupDetails