import InputField from 'components/form/InputField'
import React, { useState } from 'react'
import { login } from 'services/auth'
import Button from 'components/form/Button'
import Form2Fa from './Form2Fa'

export default function FormLogin () {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [needAuthToken, setNeedAuthToken] = useState(false)

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        login(email, password)
            .then(response => {
                if (response.data.needAuth) {
                    console.log('TOKEN')
                    setNeedAuthToken(true)
                } else if (response.data.swsid) {
                    localStorage.setItem('token', response.data.swsid)
                    window.location.href = '/'
                }
                console.log(response.data)
            })
            .catch(error => {
                // Manipular erros aqui
                console.error(error)
            })
    }

    return (
        <>
            { needAuthToken ? (
                <Form2Fa email={ email } password={ password } back={ () => setNeedAuthToken(false) } />
            ) : (
                <form onSubmit={ handleSubmit }>
                    <input onChange={ handleEmailChange } className='w-full border border-gray-300 rounded px-2 py-3 focus:outline-none focus:border-cyan-800' placeholder="Email" type="email" required />

                    <input onChange={ handlePasswordChange } className='w-full mt-4 border border-gray-300 rounded px-2 py-3 focus:outline-none focus:border-cyan-800' placeholder="Senha" type="password" required />
                    {/* <InputField
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        label="Email"
                        className="w-full block p-3"
                        placeholder="Email"
                        value={ email }
                        onChange={ handleEmailChange } /> */}

                    {/* <InputField
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        required
                        label="Senha"
                        className="w-full block p-3"
                        placeholder="Senha"
                        value={ password }
                        onChange={ handlePasswordChange } /> */}

                    <Button type="submit" text="Entrar" className="bg-yellow-600 hover:bg-yellow-500 w-full px-3 py-3 mt-5" />
                </form >
            ) }




            {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-cyan-300">
                        Lembrar-me
                    </label>
                </div>

                <div className="text-sm leading-6">
                    <a href="#" className="font-semibold text-cyan-100 hover:text-cyan-500">
                        Perdeu sua senha?
                    </a>
                </div>
            </div> */}

        </>

    )
}
