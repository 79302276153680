import { createSlice } from '@reduxjs/toolkit'
import { getMission } from 'services/missionsService';

const selectedMissionSlice = createSlice({
    name: 'selectedMission',
    initialState: {},
    reducers: {
        setSelectedMission: (state, action) => {
            let mission;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                mission = action.payload[0]
            } else {
                mission = {}
            }            

            return mission
        },
    },
})

export const selectMissionForDetails = (params) => async (dispatch) => {
    try {
        const selectedMission = await getMission(params)
        dispatch(setSelectedMission(selectedMission))
    } catch (error) {
        console.error('Erro ao carregar missão:', error)
    }
}

export const { setSelectedMission } = selectedMissionSlice.actions
export default selectedMissionSlice.reducer
