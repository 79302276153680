import Tabs from '../../components/Tabs'
import React, { useEffect, useState } from 'react'
import BasicTable from '../../components/BasicTable'
import Button from '../../components/form/Button'
import { CreditCardIcon } from '@heroicons/react/20/solid'
import style from '../../styles/Search.module.css'
import interstitialStyle from '../../styles/Interstitial.module.css'
import dndStyle from '../../styles/DnDStyle.module.css'
import styleUserTabs from '../../styles/UserTabs.module.css'

import { users, logins, flag, changes, connections } from '../../data/users'
import { spin, properties, prizes } from '../../data/items'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSpinCategories } from 'store/modules/items/spin/spinCategoriesSlice'
import Modal from 'components/Modal'
import { toast } from 'react-toastify'
import { createSpinCategory, deleteSpinCategory } from 'services/spinService'
import { fetchSelectedSpinCategory } from 'store/modules/items/spin/SelectedSpinCategorySlice'
import { SpinDetails } from './Partials/SpinDetails'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

const atualizar = () => {
    console.log('Atualizou!')
}

const tabs = [
    {
        id: 1,
        tabTitle: 'Normal',
    },
    {
        id: 2,
        tabTitle: 'Deluxe',
    },
]



export default function Spin() {
    const verifySpin = useUserPermission(SMIPermissionsCode.ITEMS_MANAGE_SPINTOWIN);
    const [isLoading, setLoading] = useState(false)

    const categoryStatus = {
        activeIcon: 'ativo',
        inactiveIcon: 'inativo',
        hasAction: false
    }

    const categoryActions = [
        { action: 'delete', icon: null }
    ]

    const [spinCategoriesHeaders, setSpinCategoriesHeaders] = useState([
        { title: 'Categoria', key: 'name', detailable: true, appear: true },
        { title: 'Peso', key: 'weighting', appear: true },
        { title: 'Status', key: 'active', status: categoryStatus, appear: true },
        { title: 'Ações', key: 'acoes', actions: () => categoryActions, appear: true }
    ])

    const [spinCategoriesMomentHeaders, setSpinCategoriesMomentHeaders] = useState([...spinCategoriesHeaders]);

    const handleAppearHeader = (item) => {
        setSpinCategoriesHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setSpinCategoriesMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const spinCategories = useSelector((state) => {
        return Array.isArray(state.spinCategories.data) ? state.spinCategories.data : []
    })

    const isDeluxe = useSelector((state) => {
        return (state.spinCategories.isDeluxe) ? state.spinCategories.isDeluxe : false
    })

    const tab = useSelector((state) => {
        return (state.spinCategories.tab) ? state.spinCategories.tab : 1
    })

    const selectedSpinCategory = useSelector((state) => {
        return state.selectedSpinCategory
    })

    const [slicesCount, setSlicesCount] = useState(0);

    useEffect(() => {
        let totalSlicesCount = 0
        spinCategories.map((item, i) => {if(item.active == true){totalSlicesCount += item.count}});
        setSlicesCount(totalSlicesCount);
    }, [spinCategories])

    const dispatch = useDispatch()

    const getSpinCategories = () => {
        setLoading(true)
        fetchSpinCategories(dispatch, isDeluxe);
    }

    useEffect(() => {
        getSpinCategories()
    }, [])

    const search = (id) => {
        console.log(id)
        setLoading(true)
        if (id == 1) {
            fetchSpinCategories(dispatch, false, id);
        }
        else {
            fetchSpinCategories(dispatch, true, id);
        }
    }

    //Delete Development

    const [currentSpinCategoryToDelete, setCurrentSpinCategoryToDelete] = useState({});
    const [isDeleteSpinCategory, setIsDeleteSpinCategory] = useState(false);

    const handleOpenDeleteSpinCategoryModal = (item) => {
        setCurrentSpinCategoryToDelete(item);
        setIsDeleteSpinCategory(true);
    }

    const handleCloseDeleteSpinCategoryModal = () => {
        getSpinCategories();
        setIsDeleteSpinCategory(false);
    }

    const handleDeleteSpinCategory = async () => {
        try {
            await deleteSpinCategory(currentSpinCategoryToDelete.id);
            handleCloseDeleteSpinCategoryModal()
            toast.success("Categoria Deletada com Sucesso");
        }
        catch {
            toast.error("Erro ao Deletar Categoria");
        }
    }

    //Add development

    const spinBackgroundColors = [
        { id: 1, name: 'Purple' },
        { id: 2, name: 'Orange' },
        { id: 3, name: 'Blue' },
        { id: 4, name: 'Peach' },
        { id: 5, name: 'Pink' },
        { id: 6, name: 'Green' },
        { id: 7, name: 'Lime' },
        { id: 8, name: 'Rainbow' },
        { id: 9, name: 'Gold' },
        { id: 10, name: 'Gold Stripe' },
        { id: 11, name: 'Yellow Stripe' },
        { id: 12, name: 'Purple Stars' },
        { id: 13, name: 'Mystery' },
        { id: 14, name: 'Booby' },
    ]

    const [isCreateSpinCategoryModalOpen, setIsCreateSpinCategoryModalOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [activeInput, setActiveInput] = useState(false);
    const [spinBackgroundColorInput, setSpinBackgroundColorInput] = useState(spinBackgroundColors.length != 0 ? spinBackgroundColors[0].id : {});
    const [countInput, setCountInput] = useState(0);
    const [weightInput, setWeightInput] = useState(0);

    const handleOpenCreateSpinCategoryModal = () => {
        setIsCreateSpinCategoryModalOpen(true)
    }

    const handleCloseCreateSpinCategoryModal = () => {
        setCheck(false)
        setNameInput('')
        setActiveInput(false)
        setSpinBackgroundColorInput(spinBackgroundColors.length != 0 ? spinBackgroundColors[0].id : {})
        setCountInput(0)
        setWeightInput(0)

        getSpinCategories()


        setIsCreateSpinCategoryModalOpen(false)
    }

    useEffect(() => {
        if((countInput == 0 || countInput.trim() == '') || (weightInput == 0 || weightInput.trim() == '') || (nameInput.trim() == '')){
            setCheck(false);
        }
        else{
            setCheck(true)
        }
    }, [nameInput, activeInput, spinBackgroundColorInput, countInput, weightInput])

    const handleSaveSpinCategory = async () => {
        try{
            if(slicesCount == 32 && activeInput == true){
                handleCloseCreateSpinCategoryModal()
                toast.error('Lista no limite de Slices Ativos (32)')
            }
            else{
                let isDeluxe = tab == 1 ? false : true;
                const desc = nameInput;
                const active = activeInput;
                const background = spinBackgroundColorInput;
                const count = countInput;
                const weighting = weightInput;

                await createSpinCategory({isDeluxe, desc, active, background, count, weighting});
                handleCloseCreateSpinCategoryModal()
                toast.success('Dados Salvos com Sucesso!')
            }
        }
        catch(err){
            toast.error('Erro ao Salvar Dados!')
            console.log(err)
        }
    }

    const handleDetailItem = (item) => {
        console.log(item)
        console.log(selectedSpinCategory)
        dispatch(fetchSelectedSpinCategory(item))
    }


    return (
        <>
            {verifySpin && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-7'>
                    <BasicTable
                        onDeleteItem={handleOpenDeleteSpinCategoryModal}
                        onDetailRow={handleDetailItem}
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={spinCategoriesHeaders}
                        headers={spinCategoriesMomentHeaders}
                        title={isDeluxe ? 'Roleta Deluxe' : 'Roleta Normal'}
                        items={spinCategories}
                        slotActions={<Button text="Atualizar" onClick={getSpinCategories} color="cyan" />}
                        slotFooter={<>
                            <span>Total de pedaços Ativos: {slicesCount}/32</span>
                            <Button text="Nova Categoria" onClick={handleOpenCreateSpinCategoryModal} color="cyan" />
                        </>}
                        slotHeader={<>
                            <Tabs tabs={tabs} onChangeTab={search} tab={tab} />
                        </>}
                        height='100%'
                    />
                </div>
                <div className='col-span-5'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg'>
                        {selectedSpinCategory && selectedSpinCategory.length != 0 && <SpinDetails item={selectedSpinCategory} slicesCount={slicesCount}/>}
                    </div>
                </div>
            </div>}
            <Modal
                header={<div><span>Atenção!</span></div>}
                isOpen={isDeleteSpinCategory}
                onClose={handleCloseDeleteSpinCategoryModal}
            >
                <div className={style.modalModLogContainer}>
                    <div className={style.modalSlotHeaderTitle}>
                        <span className={style.modalSubtitle}>Deseja excluir definitivamente a categoria?</span>
                    </div>
                    <div className={style.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteSpinCategory} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteSpinCategoryModal} color="cyan" />
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isCreateSpinCategoryModalOpen}
                onClose={handleCloseCreateSpinCategoryModal}
                footer={
                    <div className={dndStyle.modalFooter}>
                        <Button disabled={!check} text="Salvar" onClick={handleSaveSpinCategory} color="cyan" />
                    </div>
                }
                header={<div><span>{tab == 1 ? 'Criar Categoria Spin Normal' : 'Criar Categoria Spin Deluxe'}</span></div>}
            >
                <div className={interstitialStyle.createModalContainer}>

                    <div className={interstitialStyle.createGroupInfoContainer}>
                        <div className={interstitialStyle.createGroupNameContainer}>
                            <span style={{ fontWeight: 'bold' }}>Nome:</span>
                            <input value={nameInput} className={styleUserTabs.modalInput} onChange={(e) => setNameInput(e.target.value)} placeholder='Nome' />
                        </div>
                        <div className={interstitialStyle.createGroupCheckboxContainer}>
                            <input
                                type="checkbox"
                                checked={activeInput}
                                onChange={() => setActiveInput(!activeInput)}
                                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                            />
                            <label className="ml-2">{"Ativo"}</label>
                        </div>

                    </div>

                    <div className={interstitialStyle.createGroupNameContainer}>
                        <span style={{ fontWeight: 'bold' }}>Cor:</span>
                        <select value={spinBackgroundColorInput} className={styleUserTabs.select} onChange={(e) => setSpinBackgroundColorInput(e.target.value)}>
                            {spinBackgroundColors != [] && spinBackgroundColors.map((item, i) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className='flex'>
                        <div className={interstitialStyle.createGroupTokenContainer}>
                            <span style={{ fontWeight: 'bold' }}>Contador:</span>
                            <input type='number' className={interstitialStyle.modalInput} value={countInput} onChange={(e) => setCountInput(e.target.value)} min="0" />
                        </div>
                        <div className={interstitialStyle.createGroupTokenContainer}>
                            <span style={{ fontWeight: 'bold' }}>Peso:</span>
                            <input type='number' className={interstitialStyle.modalInput} value={weightInput} onChange={(e) => setWeightInput(e.target.value)} min="0" />
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}
