const connections = {
    headers: [
        { title: 'Slice' },
        { title: 'Ponderação' },
        { title: 'Início' },
        { title: 'Fim' },
    ],
    items: [
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
        { slice: 'Deluxe', weighting: '1500(4%)', start: '', end: '', },
    ]
}

export default connections
