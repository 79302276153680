import { getModelList } from "services/categoryService";


const { createSlice } = require("@reduxjs/toolkit");

const modelListSlice = createSlice({
    name: 'modelList',
    initialState: {
        data: [],
    },
    reducers: {
        setModelListData: (state, action) => {
            return { ...state, data: action.payload }
        }
    }
})

export async function fetchModelList(dispatch) {
    try {
        const modelList = await getModelList();
        dispatch(setModelListData(modelList))


    }
    catch (error) {
        console.error('Erro ao obter Lista de Modelos', error)
    }
}
export const { setModelListData } = modelListSlice.actions
export default modelListSlice.reducer