import { createSlice } from '@reduxjs/toolkit'
import { getAvatars } from 'services/avatarsService';
import { getMissions } from 'services/missionsService';

const selectedAvatarSlice = createSlice({
    name: 'selectedAvatar',
    initialState: {},
    reducers: {
        setSelectedAvatar: (state, action) => {
            let avatar;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                avatar = action.payload[0]
            } else {
                avatar = {}
            }            

            return avatar
        },
    },
})

export const selectAvatarForDetails = (params) => async (dispatch) => {
    try {
        const selectedAvatar = await getAvatars(params)
        dispatch(setSelectedAvatar(selectedAvatar))

    } catch (error) {
        console.error('Erro ao carregar avatar:', error)
    }
}

export const { setSelectedAvatar } = selectedAvatarSlice.actions
export default selectedAvatarSlice.reducer
