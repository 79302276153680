import { getHourFromTimestamp, getDateTimeFromTimestamp } from 'helpers/timeHelpers'
import { Paths } from 'paths/Paths'

const transactionMapping = item => ({
    id: item._id,
    timestamp: getHour(item) ?? null,
    type: item.transactionType ?? null,
    sender: item.sender ? handleFullName(item.sender) : '-',
    senderId: item.sender?._id ?? null,
    details: item.description ?? null,
    receiver: item.receiver ? handleFullName(item.receiver) : '-',
    receiverId: item.receiver?._id ?? null,
    item: item.item_id ?? '-',
    space: item.space_id ?? '-',
    gold: item.gold ?? 0,
    tokens: item.tokens ?? 0,
    modelId: item.model_id ?? '-',
    modelIcon: item.model_icon ? Paths.libraryPath + item.model_icon : null
})

const handleFullName = (user) => {
    return `${user.firstName} ${user.lastName}`
}

const getId = (id) => {
    return typeof id === 'object' ? id.oid : id
}

const getHour = (item) => {
    return getDateTimeFromTimestamp(item.timestamp)
}

const getModerador = (item) => {
    return item.modUser?.lastName
}

export { transactionMapping }
