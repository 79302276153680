const spacesAvatarModelMapping = item => ({
    spaceId: item._id ?? null,
    avatarId: item.avatar_id ?? null,
    modelId: item.modelId ?? null,
    spaceName: item.spaceName ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    modelName: item.modelName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? 0
})

const spaceDetailsMapping = item => ({
    spaceId: item.spaceId ?? null,
    spaceName: item.spaceName ?? null,
    desc: item.desc ?? '-',
    accessControl: item.accessControl ?? null,
    showInPlacePanel: item.showInPlacePanel ? 'Sim' : 'Não',
    instanciable: item.instanciable ? 'Sim' : 'Não',
    maxVisitors: item.maxVisitors ?? null,
    password: item.password ?? '-',
    spaceSnapShotSource: item.spaceSnapShotSource ?? '-',
    spaceThumbnailSource: item.spaceThumbnailSource ?? '-',
    type: item.type ?? null,
    forSale: item.forSale ?? null,
    salePriceTokens: item.salePriceTokens ?? null,
    salePriceGold: item.salePriceGold ?? null,
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    modelId: item.modelId ?? null,
    modelName: item.modelName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
})

const spaceMembersMapping = (item) => ({
    memberId: item.memberId ?? null,
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    avatarRole: item.avatarRole ?? null,
})

const categorySpaceEntryMapping = (item) => ({
    entryId: item.entryId ?? null,
    categoryId: item.categoryId ?? null,
    categoryName: item.categoryName ?? null,
    spaceId: item.spaceId ?? null,
    spaceName: item.spaceName ?? null,
    spaceIcon: item.spaceIcon ?? null,
    ownerId: item.ownerId ?? null,
    ownerFirstName: item.ownerFirstName ?? null,
    ownerLastName: item.ownerLastName ?? null,
    ownerNameInstance: item.ownerNameInstance ?? null,
    ownerIcon: (!item.ownerFirstName || !item.ownerLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.ownerIcon}`,
    startDate: item.startDate ?? null,
    endDate: item.endDate ?? null
})

export { spacesAvatarModelMapping, spaceDetailsMapping, spaceMembersMapping, categorySpaceEntryMapping }