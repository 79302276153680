import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedInterstitialGroupDetails } from "store/modules/administration/interstitials/selectedInterstitialGroupDetailSlice";
import { fetchSelectedInterstitialItemDetails } from "store/modules/administration/interstitials/selectedInterstitialItemDetailSlice";

export function useInterstitialItem(item){
    const interstitialItem = useSelector((state) => {
        return (state.selectedInterstitialItemDetails.details) ? state.selectedInterstitialItemDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedInterstitialItemDetails(dispatch, { id: item.interstitialId });
    }, [item])

    return interstitialItem
}

export function useInterstitialGroup(item){
    const interstitialGroup = useSelector((state) => {
        return (state.selectedInterstitialGroupDetails.details) ? state.selectedInterstitialGroupDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedInterstitialGroupDetails(dispatch, { id: item.groupId });
    }, [item])

    return interstitialGroup
}