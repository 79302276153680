class SpinCategoryDetail{
    constructor({ id, name, weighting, background, active, count }) {
        this.id = id
        this.name = name
        this.weighting = weighting
        this.backgroundColor = background
        this.active = active
        this.count = count
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'active',
                    name: "Ativo",
                    value: this.active ? 'Sim' : 'Não',
                },
                {
                    key: 'id',
                    name: "ID",
                    value: this.id,
                },
                {
                    key: 'name',
                    name: "Nome",
                    value: this.name,
                },
                {
                    key: 'count',
                    name: "Contador",
                    value: this.count,
                },
                {
                    key: 'weighting',
                    name: "Peso",
                    value: this.weighting,
                },
                {
                    key: 'background',
                    name: "Cor",
                    value: this.background,
                }
            ],
            id: this.id,
            name: this.name,
            active: this.active ? 'Sim' : 'Não',
            weighting: this.weighting,
            count: this.count,
            background: this.background


        }
    }

    get background(){
        const color = {
                1: 'Purple',
                2: 'Orange',
                3: 'Blue',
                4: 'Peach',
                5: 'Pink',
                6: 'Green',
                7: 'Lime',
                8: 'Rainbow',
                9: 'Gold',
                10: 'Gold Stripe',
                11: 'Yellow Stripe',
                12: 'Purple Stars',
                13: 'Mystery',
                14: 'Booby',
        }

        return color[this.backgroundColor]
    }
}

export default SpinCategoryDetail