import { createSlice } from '@reduxjs/toolkit'

const staffSlice = createSlice({
    name: 'staff',
    initialState: [],
    reducers: {
        setStaff: (state, action) => {
            return action.payload
        },
    },
})

export const { setStaff } = staffSlice.actions
export default staffSlice.reducer
