import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchFriendsFilters } from "store/modules/users/friends/friendsSlice";

export default function FriendsFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;
        fetchFriendsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'senderAvatarId',
            label: 'ID Avatar Solicitante',
            value: defaultValues.senderAvatarId,
            placeholder: 'ID Avatar Solicitante',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'receiverAvatarId',
            label: 'ID Avatar Recebedor',
            value: defaultValues.receiverAvatarId,
            placeholder: 'ID Avatar Recebedor',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'isAccepted',
            label: 'Apenas Aceitos',
            value: defaultValues.isAccepted,
            type: 'checkbox',
            gridClass: 'col-span-4'
        }
    ]

    return(
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit}/>
        </>
    )
}