import moment from "moment"

class CategoryEntry {
    constructor({ entryId, modelId, modelName, icon, salePriceGold, startDate, endDate, shareDiscount, discounted, active }) {
        this.entryId = entryId
        this.modelName = modelName
        this.icon = icon
        this.salePriceGold = salePriceGold
        this.startDate = startDate
        this.endDate = endDate
        this.shareDiscount = shareDiscount
        this.discounted = discounted
        this.active = active
        this.modelId = modelId
    }

    get() {
        return ({
            entryId: this.entryId,
            name: this.modelName,
            icon: this.icon,
            salePriceGold: this.salePriceGold,
            startDate: this.startDate ? moment(this.startDate).format('DD/MM/YYYY') + " " + moment(this.startDate).format('HH:mm') : null,
            endDate: this.endDate ? moment(this.endDate).format('DD/MM/YYYY') + " " + moment(this.endDate).format('HH:mm') : null,
            discount: this.discounted ? Number(this.shareDiscount) : null,
            displayDiscount: this.discounted ? `${String(this.shareDiscount).replace('.', ',')}%` : null,
            active: this.active,
            modelId: this.modelId,
            discountQuantity: Number(this.shareDiscount),
        })
    }
}

export default CategoryEntry