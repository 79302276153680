const users = {
    headers: [
        { title: 'Usuário', key: 'user' },
        { title: 'ID', key: 'id' },
        { title: 'Avatar', key: 'avatar' },
        { title: 'Ouro', key: 'gold'},
        { title: 'Tokens', key: 'tokens' },
        { title: 'Kudos', key: 'kudos' },
        { title: 'CP', key:  'cp'},
    ],
    items: [
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
        { user: 'Test User', id: '98e4r984re98er4', avatar: 'Jhone Silver', gold: 500, tokens: 120, cp: 82, kudos: 15 },
    ]
}

export default users
