import { getInfractions } from 'services/infractionService'
import { setInfractions } from './infractionSlice'

export const fetchInfractions = (params) => async (dispatch) => {
    try {
        const infractions = await getInfractions(params)
        dispatch(setInfractions(infractions))
    } catch (error) {
        console.error('Erro ao carregar infrações:', error)
    }
}
