import { createSlice } from '@reduxjs/toolkit'
import { getSpace } from 'services/spaceService';

const selectedSpaceSlice = createSlice({
    name: 'selectedSpace',
    initialState: {},
    reducers: {
        setSelectedSpace: (state, action) => {
            let space;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                space = action.payload[0]
            } else {
                space = {}
            }            

            return space
        },
    },
})

export const selectSpaceForDetails = (params) => async (dispatch) => {
    try {
        const selectedSpace = await getSpace(params)
        dispatch(setSelectedSpace(selectedSpace))
    } catch (error) {
        console.error('Erro ao carregar Espaço:', error)
    }
}

export const { setSelectedSpace } = selectedSpaceSlice.actions
export default selectedSpaceSlice.reducer