const ips = {
    headers: [
        { title: 'Data' },
        { title: 'Usuário' },
        { title: 'Endereço IP' },
    ],
    items: [
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
        { timestamp: '2023-04-06 18:35:05', user: 'Test User', ip: '2001:db8:3333:4444:5555:6666:7777:8888' },
    ]
}

export default ips
