import { createSlice } from '@reduxjs/toolkit'

const selectedUserSlice = createSlice({
    name: 'selectedUser',
    initialState: {},
    reducers: {
        setSelectedUser: (state, action) => {
            let user;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                user = action.payload[0]
            } else {
                user = action.payload
            }            

            return user
        },
    },
})

export const { setSelectedUser } = selectedUserSlice.actions
export default selectedUserSlice.reducer
