import React, { useRef } from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { required, minLength, isEmail } from 'helpers/validation'
import { uuidMask } from 'helpers/masks'
import { useDispatch } from 'react-redux'
import { fetchUsersFilters } from 'store/modules/users/userActions'

const FormByUserNew = ({onFormChange, defaultValues}) => {
    const formRef = useRef()

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFormChange(formData, isValid)
        
        let newValue= {};
        newValue[name] = value;

        console.log(newValue)

        fetchUsersFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'userId',
            label: 'ID do Usuário',
            placeholder: 'ID do Usuário',
            type: 'text',
            value: defaultValues.userId,
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'userFirstName',
            label: 'Nome do Usuário',
            placeholder: 'Nome do Usuário',
            type: 'text',
            value: defaultValues.userFirstName,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'userLastName',
            label: 'Sobrenome do Usuário',
            placeholder: 'Sobrenome do Usuário',
            type: 'text',
            value: defaultValues.userLastName,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'userEmail',
            label: 'Email do Usuário',
            placeholder: 'Email do Usuário',
            type: 'text',
            value: defaultValues.userEmail,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'userExact',
            label: 'Combinação exata',
            type: 'checkbox',
            value: defaultValues.userExact,
            gridClass: 'col-span-6'
        },
    ]

    return (
        <>
            <DefaultForm ref={ formRef } fields={ fields } onChange={handleSubmit} />
        </>
    )
}

export default FormByUserNew
