import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchMissionsFilters } from "store/modules/missions/missionsSlice";

export default function MissionsFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        fetchMissionsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'missionId',
            label: 'ID da Missão',
            value: defaultValues.missionId,
            placeholder: 'ID da Missão',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'missionName',
            label: 'Nome da Missão',
            value: defaultValues.missionName,
            placeholder: 'Nome da Missão',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'avatarId',
            label: 'ID do Dono da Missão',
            value: defaultValues.avatarId,
            placeholder: 'ID do Dono da Missão',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'spaceId',
            label: 'ID do Espaço Inicial',
            value: defaultValues.spaceId,
            placeholder: 'ID do Espaço Inicial',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        }
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit}/>
}