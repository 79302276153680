import { getSmallID } from 'helpers'
class Transaction {
    constructor ({ id, timestamp, type, sender, details, receiver, item, space, senderId, receiverId, gold, tokens, modelId, modelIcon  }) {
        this.id         = id
        this.timestamp  = timestamp
        this.type       = type
        this.sender     = sender
        this.details    = details
        this.receiver   = receiver,
        this.item       = item
        this.space      = space
        this.senderId   = senderId
        this.receiverId = receiverId
        this.gold       = gold
        this.tokens     = tokens
        this.modelId    = modelId
        this.modelIcon  = modelIcon
    }

    get () {
        return {
            id: this.id,
            small_id: getSmallID(this.id, 7),
            timestamp: this.timestamp,
            type: this.type,
            small_type: getSmallID(this.type, 7),
            sender: this.sender,
            senderId: this.senderId,
            details: this.details,
            receiver: this.receiver,
            receiverId: this.receiverId,
            item: this.item,
            small_item: getSmallID(this.item, 7),
            space: this.space,
            small_space: getSmallID(this.space, 7),
            gold: this.gold,
            tokens: this.tokens,
            modelId: this.modelId,
            modelIcon: this.modelIcon,
        }
    }

    smallID () {
        return getSmallID(this.id, 7)
    }
}

export default Transaction
