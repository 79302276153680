import style from '../../../styles/Message.module.css'
import SelectField from '../../../components/form/Select'
import {ChatMessage, AvatarInteraction, EmoteMessage} from './chatMessage'
import Primary from '../../../img/Skills/primarySkill.png'
import useSMISocket from '../../../hooks/useSMISocket'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { setSpaceChatInstances, setCurrentInstance, addSpaceChatMessages, clearSpaceChatMessages } from 'store/modules/spaces/spaceChatSlice'
import Button from 'components/form/Button'
import { Paths } from 'paths/Paths'

const SpaceChat = ({ space }) => {

    const socket = useSMISocket();

    const chatBoxRef = useRef();

    const spaceChatInstances = useSelector((state) => {
        return state.spaceChat?.instances || [];
    })

    const spaceChatInstance = useSelector((state) => {
        return  state.spaceChat?.currentInstance;
    })

    const messages = useSelector((state) => {
        return state.spaceChat?.messages || [];
    })


    const handleOnChangeSelect = (e) => {
        dispatch(clearSpaceChatMessages());
        dispatch(setCurrentInstance(e.target.value));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (!socket) return;
        socket.emit("space.instances", space.id, (result) => {
            console.log(result);
            dispatch(setSpaceChatInstances(result.instances));
        });
    }, [space, socket])
    
    const handleReloadInstances = () => {
        if (!socket) return;
        socket.emit("space.instances", space.id, (result) => {
            
            dispatch(setSpaceChatInstances(result.instances));
        });
    }

    useLayoutEffect(() => {
        if(chatBoxRef.current) {
            const scrollScale = (chatBoxRef.current.scrollTop + chatBoxRef.current.clientHeight) / chatBoxRef.current.scrollHeight;
            if(scrollScale > 0.9) {
                chatBoxRef.current.scrollTo(0, (chatBoxRef.current.scrollTop + chatBoxRef.current.clientHeight));
            }
        }
    }, [messages, chatBoxRef])

    useEffect(() => {
        if(spaceChatInstance < 0) return;

        const onChat = (message) => {
            if(message.type === "emote" && message.message?.includes("typing")) {
                return;
            }
            dispatch(addSpaceChatMessages(message));
            
        }

        const eventName = "space." + space.id + ":" + spaceChatInstance + ".chat";

        if (socket) {
            socket.on(eventName, onChat);
            socket.emit("space.register", space.id, spaceChatInstance);
        }

        return () => {
            if (socket) {
                socket.emit("space.unregister", space.id, spaceChatInstance);
                socket.off(eventName, onChat);
            }
        }
    }, [spaceChatInstance, socket, space])

    return (
        <>
            <div className={style.chatHeader}>
                <div className={style.chatSelect}>
                    <SelectField
                        items={spaceChatInstances.map((item) => {
                            return (
                                {
                                    value: item.instanceId,
                                    name: `${item.instanceId}  (${item.currentVisitors} online)`
                                }
                            )
                        })}

                        order={'A-Z'}
                        onChange={handleOnChangeSelect}
                        name={'instance'}
                        label={'Instância'}
                        defaultItem={'-- Instância do Espaço --'}

                    />
                </div>
                <div className={style.chatButton}>
                    <Button text="Atualizar Instâncias" color="cyan" onClick={handleReloadInstances} disabled={false} />
                </div>
            </div>
            <div className={style.chatBox} ref={chatBoxRef}>
                <div className={style.viewport}>
                    {messages.map((item, i) => {
                        const headUrl = Paths.getAvatarHeadURL(item.avatar_id, item.avatar_headPostfix);

                        const types = {
                            "says": () => <ChatMessage key={i} avatarIcon={headUrl} avatarFirstName={item.avatar_name} message={item.message} />,
                            "joinAvatar": () => <AvatarInteraction key={i} avatarFirstName={item.avatar_name} message={"joined space"} />,
                            "leaveAvatar": () => <AvatarInteraction key={i} avatarFirstName={item.avatar_name} message={"left space"} />,
                            "emote": () => <EmoteMessage avatarIcon={headUrl} key={i} avatarFirstName={item.avatar_name} message={item.message} />
                        }
                        if(!types[item.type]) return;
                        if(item.message === "null") return;
                        return types[item.type].apply(this, []);
                    })}
                </div>

            </div>
        </>
    )
}

export default SpaceChat    