import { createSlice } from '@reduxjs/toolkit'

const SelectedInterstitialGroupSlice = createSlice({
    name: 'SelectedInterstitialGroup',
    initialState: [],
    reducers: {
        setSelectedInterstitialGroup: (state, action) => {
            return action.payload
        },
    },
})

export const fetchSelectedInterstitialGroup = (params) => async (dispatch) => {
    try {
        dispatch(setSelectedInterstitialGroup(params))
    } catch (error) {
        console.error('Erro ao carregar grupo:', error)
    }
}

export const fetchClearInterstitialGroup = () => async (dispatch) => {
    try {
        dispatch(setSelectedInterstitialGroup([]))
    } catch (error) {
        console.error('Erro ao carregar grupos:', error)
    }
}

export const { setSelectedInterstitialGroup } = SelectedInterstitialGroupSlice.actions
export default SelectedInterstitialGroupSlice.reducer