import Category from 'domains/Category/Category'
import api, { apiNormalize } from './api'
import { categoryDetailMapping, categoryEntryMapping, categoryMapping, categoryModelEntryMapping, modelListItemsMapping } from 'domains/Category/mappings'
import handleRequestError from 'helpers/apiErrorHandler'
import CategoryEntry from 'domains/Category/CategoryEntry'
import CategoryModelEntry from 'domains/Category/CategoryModelEntry'
import ModelListItem from 'domains/Category/ModelListItem'

export const getCategories = async (params) => {
    try {
        const response = await api.get('/catalog/item/categories', { params })
        const categories = apiNormalize.responseMapping(response.data, categoryMapping)

        const normalizedCategories = categories.map(item => new Category(item).get())

        return normalizedCategories
    } catch (error) {
        handleRequestError(error)
    }
}

export const getCategory = async (params) => {
    try {
        const response = await api.get('/catalog/byCategory', { params })
        const categories = apiNormalize.responseMapping(response.data, categoryDetailMapping)
        const normalizedCategories = categories.map(item => new Category(item).get())
        return normalizedCategories
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveReorderCategories = async (list) => {
    try {
        const response = await api.post(`/catalog/saveReorderCategories`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveEditCategory = async (categoryId, changes) => {
    try {
        const response = await api.post(`/catalog/${categoryId}/saveEditCategory`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteCategory = async (categoryId) => {
    try {
        const response = await api.post(`/catalog/${categoryId}/deleteCategory`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const createCategory = async (categoryName, categoryUrl, type) => {
    try {
        const response = await api.post(`/catalog/${type}/createCategory`, { categoryName, categoryUrl })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export async function getCategoryDetails(params, skip) {
    const response = await api.get("/catalog/getCategoryDetails", { params: { ...params, skip } });
    const categoryEntries = apiNormalize.responseMapping(response.data, categoryEntryMapping)
    const normalizedcategoryEntries = categoryEntries.map(categoryEntry => new CategoryEntry(categoryEntry).get())
    return normalizedcategoryEntries
}

export const getModelEntries = async (modelId) => {
    try {
        const response = await api.get(`/catalog/${modelId}/getModelEntries`)
        const categories = apiNormalize.responseMapping(response.data, categoryModelEntryMapping)
        const normalizedCategories = categories.map(item => new CategoryModelEntry(item).get())
        return normalizedCategories
    } catch (error) {
        handleRequestError(error)
    }
}

export const getModelList = async () => {
    try {
        const response = await api.get('/catalog/getModelList')
        const modelListItems = apiNormalize.responseMapping(response.data, modelListItemsMapping)

        const normalizedmodelListItems = modelListItems.map(modelListItem => new ModelListItem(modelListItem).get())

        return normalizedmodelListItems
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveEntries = async (objList) => {
    try {
        const response = await api.post(`/catalog/saveEntries`, { objList })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteEntry = async (entryId) => {
    try {
        const response = await api.post(`/catalog/${entryId}/deleteEntry`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteInactives = async (categoryId) => {
    try {
        const response = await api.post(`/catalog/${categoryId}/deleteInactives`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveDiscount = async (categoryId, discountFrom, discountTo) => {
    try {
        const response = await api.post(`/catalog/saveDiscount`, { categoryId, discountFrom, discountTo })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}
