import { getIps } from "services/ipService";

const { createSlice } = require("@reduxjs/toolkit");

const ipsSlice = createSlice({
    name: "ips",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            idUser: '',
            ip: ''
        }
    },
    reducers: {
        setIpsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addIpsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setIpsFilter: (state, action) => {
            return {...state, filters: action.payload}
        },
    },
})

export async function fetchIps(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const ips = await getIps(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addIpsData(ips))
        }
        else{
            dispatch(setIpsData(ips));
        }
        
        if(ips.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Ips', error);
        dispatch(setCurrentSkip(0));
        dispatch(setIpsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchIpsFilters(dispatch, params){
    dispatch(setIpsFilter(params));
}

export const { setIpsData, setCurrentSkip, setHasMore, addIpsData, setIpsFilter } = ipsSlice.actions
export default ipsSlice.reducer