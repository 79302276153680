import { getSpaceCategoryDetails } from "services/spaceService";


const { createSlice } = require("@reduxjs/toolkit");

const spaceCategoryDetailsSlice = createSlice({
    name: 'spaceCategoryDetails',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
    },
    reducers: {
        setSpaceCategoryDetailsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addSpaceCategoryDetailsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
    }
})

export async function fetchSpaceCategoryDetails(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const spaceCategoryDetails = await getSpaceCategoryDetails(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addSpaceCategoryDetailsData(spaceCategoryDetails))
        }
        else{
            dispatch(setSpaceCategoryDetailsData(spaceCategoryDetails));
        }
        
        if(spaceCategoryDetails.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}
export const { setSpaceCategoryDetailsData, addSpaceCategoryDetailsData, setCurrentSkip, setHasMore } = spaceCategoryDetailsSlice.actions
export default spaceCategoryDetailsSlice.reducer