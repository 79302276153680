import { faker } from '@faker-js/faker'

const infractionsData = () => {
    const items = []

    for (let i = 0; i < 25; i++) {
        const item = {
            horario: faker.date.recent().toLocaleString(),
            usuario: faker.internet.userName(),
            ofensa: faker.lorem.words(2),
            punicao: faker.lorem.words(1),
            dada_por: faker.person.fullName(),
        }

        items.push(item)
    }

    return items
}

const infractions = {
    headers: [
        { title: 'Horário' },
        { title: 'Usuário' },
        { title: 'Ofensa' },
        { title: 'Punição' },
        { title: 'Dada por' },
    ],
    items: infractionsData()
}

export default infractions
