class ItemDetail {
    constructor({ id, modelName, spaceId, spaceName, modelId }) {
        this.id = id
        this.modelName = modelName
        this.spaceId = spaceId
        this.spaceName = spaceName
        this.modelId = modelId
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'id',
                    name: "ID Item",
                    value: this.id,
                },
                {
                    key: 'modelId',
                    name: "ID Modelo",
                    value: this.modelId,
                },
                {
                    key: 'modelName',
                    name: "Nome do Modelo",
                    value: this.modelName,
                },
                {
                    key: 'spaceId',
                    name: "ID Espaço",
                    value: this.spaceId,
                },
                {
                    key: 'spaceName',
                    name: "Nome do Espaço",
                    value: this.spaceName,
                },
                
            ],
            id: this.id,
            modelName: this.modelName,
            spaceId: this.spaceId,
            spaceName: this.spaceName,
            modelId: this.modelId,
        }
    }
}

export default ItemDetail