import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { EmailsFilter } from './Partials/EmailFilters'
import Details from './Partials/Details'
import Modal from 'components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmailById, fetchEmails } from 'store/modules/users/emails/emailsSlice'
import styles from '../../../styles/Search.module.css'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'

export default function Email({ emailId, emailStatus, emailType, senderId, receiverId, senderType, receiverType, emailSubject, emailText }) {
    const verifyEmailList = useUserPermission(SMIPermissionsCode.MOD_USER_MAIL);

    const iconSenderObject = { key: 'senderIcon' }
    const iconReceiverObject = { key: 'receiverIcon' }

    const [emailHeaders, setEmailHeaders] = useState([
        { title: 'Data de criação', key: 'date', appear: true },
        { title: 'ID do Email', key: 'smallIdEmail', copy: 'emailId', detailable: true, appear: true },
        { title: 'Remetente', key: 'senderFullName', copy: 'senderId', icon: () => iconSenderObject, appear: true },
        { title: 'Destinatário', key: 'receiverFullName', copy: 'receiverId', icon: () => iconReceiverObject, appear: true },
        { title: 'Tipo de Email', key: 'emailType', appear: true },
        { title: 'Status do Email', key: 'emailStatus', appear: true },
        { title: 'Assunto do Email', key: 'emailSubject', appear: true },
        { title: 'Deletado pelo Remetente', key: 'isDelletedSender', appear: true },
        { title: 'Deletado pelo Destinatário', key: 'isDelletedReceiver', appear: true },
    ]);

    const [emailMomentHeaders, setEmailMomentHeaders] = useState([...emailHeaders]);

    const handleAppearHeader = (item) => {
        setEmailHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setEmailMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoadingDetails, setLoadingDetails] = useState(false)
    const [detailsItem, setDetailsItem] = useState({
        _id: '',
        emailSubject: '',
        userSenderFirstName: '',
        userSenderLastName: '',
        avatarSenderFirstName: '',
        avatarSenderLastName: '',
        avatarSenderInstance: '',
        userReceiverFirstName: '',
        userReceiverLastName: '',
        avatarReceiverFirstName: '',
        avatarReceiverLastName: '',
        avatarReceiverInstance: '',
        emailText: '',
        senderType: '',
        receiverType: '',
    })

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const emails = useSelector((state) => {
        return Array.isArray(state.emails.data) ? state.emails.data : []
    })

    const skip = useSelector((state) => {
        return (state.emails.currentSkip) ? state.emails.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.emails.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.emails.filters) ? state.emails.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [emails]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (emailId || emailStatus || emailType || senderId || receiverId || senderType || receiverType || emailSubject || emailText) {
            setLoading(true)
            fetchEmails(dispatch, { emailId, emailStatus, emailType, senderId, receiverId, senderType, receiverType, emailSubject, emailText }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.emailId) query.append('emailId', filter.form.emailId);
        if (filter.form.emailStatus) query.append('emailStatus', filter.form.emailStatus);
        if (filter.form.emailType) query.append('emailType', filter.form.emailType);
        if (filter.form.senderId) query.append('senderId', filter.form.senderId);
        if (filter.form.receiverId) query.append('receiverId', filter.form.receiverId);
        if (filter.form.senderType) query.append('senderType', filter.form.senderType);
        if (filter.form.receiverType) query.append('receiverType', filter.form.receiverType);
        if (filter.form.emailSubject) query.append('emailSubject', filter.form.emailSubject);
        if (filter.form.emailText) query.append('emailText', filter.form.emailText);
        window.history.pushState({}, '', `/users/email?${query.toString()}`);
        fetchEmails(dispatch, filter.form, 0);
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchEmails(dispatch, filter.form, skip + 200);
    })

    const searchEmailById = async (item) => {
        setLoadingDetails(true)
        const email = await fetchEmailById(item.emailId)
        setLoadingDetails(false)
        return email
    }

    async function selectedEmailDetails(item) {
        handleOpenModal()
        const email = await searchEmailById(item)
        setDetailsItem(email)
    }

    return (
        <>
            { verifyEmailList && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <EmailsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={emailHeaders}
                        headers={emailMomentHeaders}
                        onDetailRow={selectedEmailDetails}
                        title='Buscar Emails'
                        isLoading={isLoading && emails.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={emails}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(emails.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : emails.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
            </div>}

            <Details
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                item={detailsItem}
                isLoading={isLoadingDetails}
            />
        </>
    )
}
