import api from './api'
export const login = (email, password, token = null) => {
    const data = { email, password, token }

    return api.post('/login', data)
}

export const isAuthenticated = () => {
    const token = localStorage.getItem('token')
    return token ? token : false

    // return true
}

export const logout = () => {
    const token = localStorage.removeItem('token')
    window.location.href = '/'
    return token ? token : false
    // return true
}

export const getUserSession = async (params) => {
    try {
        const response = await api.get('/user', { params })
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

