import { createSlice } from '@reduxjs/toolkit'
import { getSelectedSpinPrizes } from 'services/spinService'

const selectedSpinPrizesSlice = createSlice({
    name: 'selectedSpinPrizes',
    initialState: {
        data: [],
    },
    reducers: {
        setSelectedSpinPrizesData: (state, action) => {
            return {...state, data: action.payload}
        },
    },
})

export async function fetchSelectedSpinPrizes(dispatch, params) {
    try {
        const selectedSpinPrizes = await getSelectedSpinPrizes(params)
        dispatch(setSelectedSpinPrizesData(selectedSpinPrizes))
    } catch (error) {
        console.error('Erro ao carregar Prêmios:', error)
    }
}

export const { setSelectedSpinPrizesData } = selectedSpinPrizesSlice.actions
export default selectedSpinPrizesSlice.reducer