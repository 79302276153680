import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchModLogsFilters } from "store/modules/administration/modLogs/modLogsSlice";

export default function ModLogsFilter({ onFilterChange, defaultValues }) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue = {};


        if (name == 'days') {
            let propName = {
                '': 'Todos',
                '1': 'Último dia',
                '7': 'Últimos 7 dias',
                '30': 'Últimos 30 dias',
            }
            newValue[name] = { name: propName[value], value };
        }
        else {
            newValue[name] = value;
        }

        fetchModLogsFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const fields = [
        {
            name: 'modId',
            label: 'ID do Moderador',
            value: defaultValues.modId,
            placeholder: 'ID do Moderador',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'property',
            label: 'Propriedade',
            value: defaultValues.property,
            placeholder: 'Propriedade',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'days',
            value: defaultValues.days.value ?? '',
            label: 'Período de tempo',
            type: 'select',
            items: [
                {
                    value:'',
                    name: 'Todos'
                },
                {
                    value: '1',
                    name: 'Último dia'
                },
                {
                    value: '7',
                    name: 'Últimos 7 dias'
                },
                {
                    value: '30',
                    name: 'Últimos 30 dias'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.days != '') ? defaultValues.days.name : '-- Período de tempo --',
            gridClass: 'col-span-4',
        },
    ]

    return (
        <>
            <DefaultForm fields={fields} onChange={handleSubmit} />
        </>
    )

}