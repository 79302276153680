import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import MissionsFilter from './missionsFilter'
import styles from '../../styles/Search.module.css'
import { fetchMissions } from 'store/modules/missions/missionsSlice'
import { PiPokerChipBold } from 'react-icons/pi'
import Modal from 'components/Modal'
import Details from './Partials/Details'
import { selectMissionForDetails } from 'store/modules/missions/selectedMissionSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Missions({ missionId, missionName, avatarId, spaceId }) {
    const verifyMission = useUserPermission(SMIPermissionsCode.MOD_MISSION_MANAGE);

    const iconObject = { key: 'avatarIcon' }

    const [missionsHeaders, setMissionsHeaders] = useState([
        { title: 'ID da Missão', key: 'smallMissionId', copy: 'missionId', detailable: 'true', appear: true },
        { title: 'Nome da Missão', key: 'missionName', appear: true },
        { title: 'Espaço Inicial', key: 'spaceName', copy: 'spaceId', appear: true },
        { title: 'Nome do Avatar', key: 'avatarName', copy: 'avatarId', icon: () => iconObject, appear: true },
        { title: 'Custo de Entrada', key: 'entryFee', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />, appear: true },
        { title: 'Tipo de Missão', key: 'experiencePath', appear: true },
        { title: 'Aprovada', key: 'approved', appear: true },
        { title: 'Oficial', key: 'isOfficial', appear: true },

    ]);

    const [missionsMomentHeaders, setMissionsMomentHeaders] = useState([...missionsHeaders]);

    const handleAppearHeader = (item) => {
        setMissionsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setMissionsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const missions = useSelector((state) => {
        return Array.isArray(state.missions.data) ? state.missions.data : []
    })

    const selectedMission = useSelector((state) => {
        return state.selectedMission
    })

    const skip = useSelector((state) => {
        return (state.missions.currentSkip) ? state.missions.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.missions.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.missions.filters) ? state.missions.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [missions]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (missionId || missionName || avatarId || spaceId) {
            setLoading(true)
            fetchMissions(dispatch, { missionId, missionName, avatarId, spaceId }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.missionId) query.append('missionId', filter.form.missionId);
        if (filter.form.missionName) query.append('missionName', filter.form.missionName);
        if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
        if (filter.form.spaceId) query.append('spaceId', filter.form.spaceId);
        window.history.pushState({}, '', `/missions?${query.toString()}`);
        fetchMissions(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchMissions(dispatch, filter.form, skip + 200);
    })

    const handleMissionDetails = (item) => {
        dispatch(selectMissionForDetails({ id: item.missionId }))
    }

    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyMission && <div className='col-span-8'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={missionsHeaders}
                    headers={missionsMomentHeaders}
                    slotHeader={<MissionsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Buscar Missões'
                    onDetailRow={handleMissionDetails}
                    isLoading={isLoading && missions.length === 0}
                    slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                    items={missions}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(missions.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : missions.length} resultados</span>}
                        </div>
                    }
                />
            </div>}
            <div className='col-span-4'>
                {selectedMission.missionId && <Details item={selectedMission} />}
            </div>
        </div>
    )
}
