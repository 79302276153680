import { createSlice } from '@reduxjs/toolkit'

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            sender: '',
            receiver: '',
            space_id: '',
            space_instance_id: '',
            item_id: '',
            transactionType: '',
            dateTo: '',
            dateFrom: '',
            desc: '',
        }
    },
    reducers: {
        setTransactionsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addTransactionsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setTransactionsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    },
})

export const { setTransactionsData, addTransactionsData, setCurrentSkip, setHasMore, setTransactionsFilter } = transactionsSlice.actions
export default transactionsSlice.reducer
