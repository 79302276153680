import { getAvatars } from "services/avatarsService";

const { createSlice } = require("@reduxjs/toolkit");

const avatarsSlice = createSlice({
    name: 'avatars',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            userId: '',
            avatarOnline: '',
            avatarActive: '',
            avatarId: '',
            avatarFirstName: '',
            avatarLastName: '',
            avatarNameInstance: ''
        }
    },
    reducers: {
        setAvatarsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addAvatarsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setAvatarsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchAvatars(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const avatars = await getAvatars(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addAvatarsData(avatars))
        }
        else{
            dispatch(setAvatarsData(avatars));
        }
        
        if(avatars.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Avatars', error)
    }
}

export async function fetchAvatarsFilters(dispatch, params){
    dispatch(setAvatarsFilter(params));
}

export const { setAvatarsData, addAvatarsData, setCurrentSkip, setHasMore, setAvatarsFilter } = avatarsSlice.actions
export default avatarsSlice.reducer