import React from 'react'
import BasicTable from 'components/BasicTable'
import FormSearch from './Partials/FormSearch'
import Button from 'components/form/Button'

import { ips } from 'data/users'

export default function BlackList () {
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <BasicTable
                        title='Buscar IPs'
                        headers={ ips.headers }
                        items={ ips.items }
                        slotHeader={ <FormSearch /> }
                        slotActions={ <Button text="Buscar" color="cyan" /> }
                    />
                </div>
            </div>
        </>
    )
}
