class Mission {
    constructor({ missionName, missionId, avatarId, avatarFirstName, avatarLastName, avatarNameInstance, avatarIcon, entryFee, experiencePath, approved, isOfficial, spaceId, spaceName }) {
        this.missionName = missionName
        this.missionId = missionId
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarIcon = avatarIcon
        this.entryFee = entryFee
        this.experiencePath = experiencePath
        this.approved = approved
        this.isOfficial = isOfficial
        this.spaceId = spaceId,
        this.spaceName = spaceName
    }

    get() {
        return {
            missionName: this.missionName,
            missionId: this.missionId,
            avatarId: this.avatarId,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarName: this.avatarFullName(this.avatarNameInstance, this.avatarFirstName, this.avatarLastName),
            avatarNameInstance: this.avatarNameInstance,
            avatarIcon: this.avatarIcon,
            smallMissionId: this.smallId(this.missionId),
            entryFee: this.entryFee,
            experiencePath: this.handleExperience(this.experiencePath),
            approved: this.approved,
            isOfficial: this.isOfficial,
            spaceName: this.spaceName,
            spaceId: this.spaceId,
        }
    }

    handleExperience(experiencePath){
        const experiencePathName = {
            1: "Artista",
            2: "Explorador",
            3: "Jogador",
            4: "Social",
            5: "Arena",
            6: "Fazendeiro",
            7: "Artesão",
            8: "Primário"
        }
        return experiencePathName[experiencePath]
    }

    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }

    smallId(id) {
        return id?.slice(0, 7)
    }
}

export default Mission