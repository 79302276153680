import React, { useEffect, useRef, useState } from 'react'
import Tabs from 'components/Tabs'
import BasicTable from 'components/BasicTable'
import Checkbox from 'components/form/Checkbox'

import { users, logins, flag, changes, connections } from 'data/users'
import style from '../../../styles/UserTabs.module.css'
import styles from '../../../styles/Search.module.css'
import { UseUser, UseGroups } from './userSearch'
import { PiPokerChipBold } from 'react-icons/pi'
import { BiCoin, BiShield } from 'react-icons/bi'
import Check from '../../../img/check-mark.png'
import Close from '../../../img/close.png'
import Button from 'components/form/Button'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import ButtonCss from 'components/BasicTable/ButtonCss'
import InputField from 'components/form/InputField'
import Modal from 'components/Modal'
import moment from 'moment'
import { addChange, addUserCp, addUserGold, addUserToken, removeUserSessions, saveUser } from 'services/userService'
import { toast } from 'react-toastify'
import { fetchSelectedUserDetails } from 'store/modules/users/selectedUserDetailsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserLogins } from 'store/modules/users/userLoginsSlice'
import { fetchModLogs } from 'store/modules/administration/modLogs/modLogsSlice'
import { Link } from 'react-router-dom';
import { fetchIps } from 'store/modules/users/ips/ipsSlice'
import { fetchUserSessions } from 'store/modules/users/userSessionsSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Details({ item }) {

    const verifyUserGroup = useUserPermission(SMIPermissionsCode.MOD_USER_GROUP_VIEW);
    const verifyUserGroupEdit = useUserPermission(SMIPermissionsCode.MOD_USER_GROUP_VIEW);
    const verifyAddGold = useUserPermission(SMIPermissionsCode.MOD_USER_GOLD_ADD);
    const verifyRemoveGold = useUserPermission(SMIPermissionsCode.MOD_USER_GOLD_REMOVE);
    const verifyAddTokens = useUserPermission(SMIPermissionsCode.MOD_USER_TOKENS_ADD);
    const verifyRemoveTokens = useUserPermission(SMIPermissionsCode.MOD_USER_TOKENS_REMOVE);
    const verifyAddCp = useUserPermission(SMIPermissionsCode.MOD_USER_CP_ADD);
    const verifyRemoveCp = useUserPermission(SMIPermissionsCode.MOD_USER_CP_REMOVE);
    const verifyRemoveAuth = useUserPermission(SMIPermissionsCode.MOD_USER_NOTES_VIEW);
    const verifyUserNotes = useUserPermission(SMIPermissionsCode.MOD_USER_NOTES_VIEW);
    const verifyUserNotesEdit = useUserPermission(SMIPermissionsCode.MOD_CAN_REMOVE_AUTHENTICATOR);
    const verifyUserIps = useUserPermission(SMIPermissionsCode.MOD_USER_IP_VIEW);
    const verifyUserLogout = useUserPermission(SMIPermissionsCode.MOD_USER_LOGOUT);
    const verifyUserChanges = useUserPermission(SMIPermissionsCode.MOD_USER_CHANGES_VIEW);
    const verifyUserEmailEdit = useUserPermission(SMIPermissionsCode.MOD_USER_EMAIL_EDIT);
    
    

    const usersHeaders = [
        { title: 'Nome', key: 'name' },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const [isAuth, setIsAuth] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [selectedPrimaryGroupValue, setSelectedPrimaryGroupValue] = useState(4)
    const [selectedSecondaryGroup, setSelectedSecondaryGroup] = useState([])

    const [isGoldOpen, setIsGoldOpen] = useState(false);
    const [goldBalance, setGoldBalance] = useState(0)
    const [goldInput, setGoldInput] = useState("")
    const [goldReasonInput, setGoldReasonInput] = useState("")
    const [isAdd, setIsAdd] = useState(true)

    const [isTokenOpen, setIsTokenOpen] = useState(false);
    const [tokenBalance, setTokenBalance] = useState(0)
    const [tokenInput, setTokenInput] = useState("")
    const [tokenReasonInput, setTokenReasonInput] = useState("")
    const [isAddToken, setIsAddToken] = useState(true)

    const [isCpOpen, setIsCpOpen] = useState(false);
    const [cpBalance, setCpBalance] = useState(0)
    const [cpInput, setCpInput] = useState("")
    const [cpReasonInput, setCpReasonInput] = useState("")
    const [isAddCp, setIsAddCp] = useState(true)

    const [isCheckedGroup, setIsCheckedGroup] = useState({});


    const keys = Object.keys(originalValues);


    const firstUserData = UseUser(item);

    const userGroups = UseGroups();
    const [userData, setUserData] = useState(firstUserData)

    useEffect(() => {
        setSelectedPrimaryGroupValue(isNaN(userData.primaryGroupId) ? 4 : userData.primaryGroupId)
        setSelectedSecondaryGroup(userData.secondaryGroups || [])
        setGoldBalance(userData.gold || 0)
        setTokenBalance(userData.tokens || 0)
        setCpBalance(userData.cp || 0)

        if (userData.secondaryGroups) {
            let isCheckedGroupCopy = {}
            let prev = isCheckedGroupCopy

            for (let j = 0; j < userData.secondaryGroups.length; j++) {
                for (let i = 0; i < userGroups.length; i++) {

                    if (userGroups[i].id == userData.secondaryGroups[j].secondary_group) {
                        prev[userGroups[i].id] = true
                    }
                }
            }

            setIsCheckedGroup(prev)
        }

        console.log(userData.secondaryGroups)

    }, [userData, userGroups])

    console.log(isCheckedGroup)

    useEffect(() => {
        let firstUserDataCopy = { ...firstUserData }
        setUserData(firstUserDataCopy)

        setOriginalValues({
            id: firstUserData.id,
            firstName: firstUserData.firstName,
            lastName: firstUserData.lastName,
            dateOfBirth: firstUserData.dateOfBirth,
            gender: firstUserData.gender,
            email: firstUserData.email,
            primaryGroupId: firstUserData.primaryGroupId,
            secondaryGroup: JSON.stringify(firstUserData.secondaryGroups),
            gold: firstUserData.gold,
            tokens: firstUserData.tokens,
            cp: firstUserData.cp,
            authenticator: firstUserData.authenticator
        })

        setIsAuth(firstUserData.authenticator != null ? (firstUserData.authenticator.verified ? true : false) : false)

        setChangedValues({
            id: firstUserData.id,
            firstName: firstUserData.firstName,
            lastName: firstUserData.lastName,
            dateOfBirth: firstUserData.dateOfBirth,
            gender: firstUserData.gender,
            email: firstUserData.email,
            primaryGroupId: firstUserData.primaryGroupId,
            secondaryGroup: JSON.stringify(firstUserData.secondaryGroups),
            gold: firstUserData.gold,
            tokens: firstUserData.tokens,
            cp: firstUserData.cp,
            authenticator: firstUserData.authenticator
        })
    }, [firstUserData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID" && (item.name == "Email" && verifyUserEmailEdit)) {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let key, prevValue;
        let userDataCopy = { ...userData, tableProperties: [...userData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            if (currentItem.name == "Data de Nascimento") {
                const date = new Date
                const selectedDate = new Date(inputValue);

                if (selectedDate < date) {
                    for (let i = 0; i < keys.length; i++) {
                        if (moment(changedValues[keys[i]]).format('DD/MM/YYYY') == currentItem.value) {
                            key = keys[i].toString();
                            prevValue = moment(changedValues[key]).format('DD/MM/YYYY')
                            changedValuesCopy[key] = moment(inputValue).format('DD/MM/YYYY');
                        }
                    }
                    for (let i = 0; i < userData.tableProperties.length; i++) {
                        if (userData.tableProperties[i].value == prevValue) {
                            userDataCopy.tableProperties[i] = { name: currentItem.name, value: changedValuesCopy[key] };
                            setUserData(userDataCopy);
                            changedValuesCopy[key] = moment(inputValue).format('MM/DD/YYYY');
                            setChangedValues(changedValuesCopy)
                        }
                    }
                }
                else {

                }
            }
            else {
                console.log(userDataCopy)
                for (let i = 0; i < userData.tableProperties.length; i++) {
                    if (userData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                        userDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                        userDataCopy[currentItem.key] = inputValue;
                        changedValuesCopy[currentItem.key] = inputValue;
                    }
                }
                setChangedValues(changedValuesCopy)
                setUserData(userDataCopy);

            }
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleOnChangeGoldInput = (e) => {
        setGoldInput(e.target.value)
    }

    const handleOnChangeGoldReasonInput = (e) => {
        setGoldReasonInput(e.target.value)
    }

    const handleGold = () => {
        if (isAdd) {
            const newValue = (goldBalance + parseInt(goldInput))
            addUserGold(item.id, goldReasonInput, newValue)
            setGoldBalance(newValue)
            setIsGoldOpen(false)
        }
        else {
            const newValue = (goldBalance - parseInt(goldInput))
            addUserGold(item.id, goldReasonInput, newValue)
            setGoldBalance(newValue)
            setIsGoldOpen(false)
        }
    }

    const handleOnChangeTokenInput = (e) => {
        setTokenInput(e.target.value)
    }

    const handleOnChangeTokenReasonInput = (e) => {
        setTokenReasonInput(e.target.value)
    }

    const handleToken = () => {
        if (isAddToken) {
            const newValue = (tokenBalance + parseInt(tokenInput))
            addUserToken(item.id, tokenReasonInput, newValue)
            setTokenBalance(newValue)
            setIsTokenOpen(false)
        }
        else {
            const newValue = (tokenBalance - parseInt(tokenInput))
            addUserToken(item.id, tokenReasonInput, newValue)
            setTokenBalance(newValue)
            setIsTokenOpen(false)
        }
    }

    const handleOnChangeCpInput = (e) => {
        setCpInput(e.target.value)
    }

    const handleOnChangeCpReasonInput = (e) => {
        setCpReasonInput(e.target.value)
    }

    const handleCp = () => {
        if (isAddCp) {
            const newValue = (cpBalance + parseInt(cpInput))
            addUserCp(item.id, cpReasonInput, newValue)
            setCpBalance(newValue)
            setIsCpOpen(false)
        }
        else {
            const newValue = (cpBalance - parseInt(cpInput))
            addUserCp(item.id, cpReasonInput, newValue)
            setCpBalance(newValue)
            setIsCpOpen(false)
        }
    }

    const handleSelectedPrimaryGroup = (e) => {
        if (verifyUserGroupEdit) {
            setSelectedPrimaryGroupValue(e.target.value)
            let changedValuesCopy = { ...changedValues }
            changedValuesCopy.primaryGroupId = e.target.value
            setChangedValues(changedValuesCopy)
        }
    }

    const handleChangeCheckGroup = (item) => {
        if (verifyUserGroupEdit) {
            let isCheckedGroupCopy = { ...isCheckedGroup }
            let prev = isCheckedGroupCopy
            const currentValue = Boolean(prev[item.id]);
            prev[item.id] = !prev[item.id]
            setIsCheckedGroup(prev)

            let selectedSecondaryGroupCopy = [...selectedSecondaryGroup]
            selectedSecondaryGroupCopy = selectedSecondaryGroupCopy.filter((group) => group.secondary_group != item.id)
            if (!currentValue) {
                selectedSecondaryGroupCopy.push({ secondary_group: item.id, since: new Date(), expires: moment().add(1, 'year').toDate() })
            }
            setSelectedSecondaryGroup(selectedSecondaryGroupCopy)

            let changedValuesCopy = { ...changedValues }
            changedValuesCopy.secondaryGroup = JSON.stringify(selectedSecondaryGroupCopy);
            setChangedValues(changedValuesCopy);
        }
    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setUserData(firstUserData)
    }

    const handleRemoveAuth = () => {
        let changedValuesCopy = { ...changedValues }
        changedValuesCopy.authenticator = null
        setChangedValues(changedValuesCopy)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                firstName: 'firstName',
                lastName: 'lastName',
                dateOfBirth: 'dob',
                gender: 'sex',
                email: 'email',
                primaryGroupId: 'primaryGroupId',
                secondaryGroup: 'secondaryGroups',
                authenticator: 'authenticator'
            }

            let changes = {}
            let formatter = {
                secondaryGroup: (value) => {
                    return JSON.parse(value)
                }
            }

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    const formatterFn = formatter[propName];
                    changes[propertyApi[propName]] = formatterFn ? formatterFn(changedValues[propName]) : changedValues[propName]
                }
            }

            await saveUser(item.id, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedUserDetails(dispatch, { id: item.id });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    //ABA NOTAS

    const [modNotes, setModNotes] = useState("")
    const [originalModNotes, setOriginalModNotes] = useState("")
    const [isModNotesChange, setIsModNotesChange] = useState(true)


    useEffect(() => {
        setModNotes(userData.modNotes || "")
        setOriginalModNotes(userData.modNotes || "")
    }, [userData])

    const handleModNotes = (e) => {
        setModNotes(e.target.value)
    }

    useEffect(() => {
        if (originalModNotes != modNotes) {
            setIsModNotesChange(false)
        }
        else {
            setIsModNotesChange(true)
        }
    }, [modNotes, userData])

    const handleDiscardModNotesChanges = () => {
        setModNotes(originalModNotes)
        setIsModNotesChange(true)

    }

    const handleSaveModNotesChanges = async () => {
        try {
            let changes = {}
            if (originalModNotes !== modNotes) {
                changes['modNotes'] = modNotes
            }

            await saveUser(item.id, changes)

            setModNotes(modNotes)
            setOriginalModNotes(modNotes)

            fetchSelectedUserDetails(dispatch, { id: item.id });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }
    }

    //ABA LOGINS

    const userLoginsHeaders = [
        { title: 'Data', key: 'timestamp' },
        { title: 'IP', key: 'ip', detailable: true },
    ]

    const [isRedirect, setIsRedirect] = useState(false)

    const linkRef = useRef(null);

    useEffect(() => {
        if (linkRef.current) {
            if (isRedirect === true) {
                linkRef.current.click();
            }
        }
        setIsRedirect(false)
    }, [isRedirect]);

    const [isLoadingLogins, setLoadingLogins] = useState(false)

    const loginItems = useSelector((state) => {
        return (state.userLogins.data) ? state.userLogins.data : []
    })

    const skipLoginItems = useSelector((state) => {
        return (state.userLogins.currentSkip) ? state.userLogins.currentSkip : 0
    })
    const hasMoreLoginItems = useSelector((state) => {
        return Boolean(state.userLogins.hasMore)
    })

    const handleLoadMoreLogins = (() => {
        setLoadingLogins(true);
        fetchUserLogins(dispatch, { id: item.id }, skipLoginItems + 50);
    })

    const handleRedirectIp = (item) => {
        fetchIps(dispatch, { ip: item.ip }, 0);
        setIsRedirect(true);
    }

    useEffect(() => {
        fetchUserLogins(dispatch, { id: item.id }, 0);
    }, [item])

    //ABA MUDANÇAS

    const modLogsHeaders = [
        { title: "Data", key: "date", detailable: true },
        { title: "Propriedade", key: "property" },
        { title: "Nome do Moderador", key: "modName", copy: "modId" },
    ]

    const [isLoadingModLogs, setLoadingModLogs] = useState(false)

    const [isModalModLogsOpen, setIsModalModLogsOpen] = useState(false)
    const [isLoadingDetails, setLoadingDetails] = useState(false)
    const [detailsItem, setDetailsItem] = useState({
        newValue: '',
        oldValue: '',
        property: '',
    })

    const handleOpenModalModLogs = () => {
        setIsModalModLogsOpen(true)
    }

    const handleCloseModalModLogs = () => {
        setIsModalModLogsOpen(false)
    }

    const modLogs = useSelector((state) => {
        return Array.isArray(state.modLogs.data) ? state.modLogs.data : []
    })

    const skipModLogs = useSelector((state) => {
        return (state.modLogs.currentSkip) ? state.modLogs.currentSkip : 0
    })
    const hasMoreModLogs = useSelector((state) => {
        return Boolean(state.modLogs.hasMore)
    })

    useEffect(() => {
        setLoadingModLogs(false)
    }, [modLogs]);

    const handleLoadMoreModLogs = (() => {
        setLoadingModLogs(true);
        fetchModLogs(dispatch, { searchText: item.id }, skipModLogs + 50);
    })

    async function selectedDetail(item) {
        let detailsItemCopy = detailsItem
        const apiProperty = item.property.split('.');
        const clientProperty = {
            firstName: 'Primeiro Nome',
            lastName: 'Sobrenome',
            dob: 'Data de Nascimento',
            sex: 'Gênero',
            email: 'Email',
            primaryGroupId: 'Grupo Primário',
            secondaryGroups: 'Grupo Secundário',
            authenticator: 'Autenticação',
            goldBalance: 'Moedas',
            tokenBalance: 'Fichas',
            cpBalance: 'Pontos Civis',
            modNotes: 'Notas de Moderador'

        }
        detailsItemCopy = { newValue: item.newValue, oldValue: item.oldValue, property: clientProperty[apiProperty[2]] }
        setDetailsItem(detailsItemCopy)
        handleOpenModalModLogs()
    }

    useEffect(() => {
        fetchModLogs(dispatch, { searchText: item.id }, 0);
    }, [item])

    //SLOTHEADER

    const [isSession, setIsSession] = useState(false);
    const [isRemoveSessions, setIsRemoveSession] = useState(false);

    const sessions = useSelector((state) => {
        return state.sessions.data ? state.sessions.data : 0
    })

    useEffect(() => {
        if (sessions) {
            setIsSession(true)
        }
        else {
            setIsSession(false)
        }
    }, [sessions])

    useEffect(() => {
        handleFetchUserSessions()
    }, [item])

    const handleFetchUserSessions = async () => {
        await fetchUserSessions(dispatch, item.id);
    }

    const handleRemoveSessions = async () => {
        try {
            removeUserSessions(item.id)

            await fetchUserSessions(dispatch, item.id);
            if (sessions) {
                setIsSession(true)
            }
            else {
                setIsSession(false)
            }
            handleCloseRemoveSessionsModal()
            toast.success('Acessos Removidos com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover Acessos!')
        }
    }

    const handleOpenRemoveSessionsModal = () => {
        setIsRemoveSession(true)
    }

    const handleCloseRemoveSessionsModal = () => {
        setIsRemoveSession(false)
    }

    const tabs = []

    tabs.push({
        id: 1,
        tabTitle: 'Propriedades',
        content: (
            <>
                <BasicTable
                    onSelectRow={handleSelectRow}
                    height='38vh'
                    headers={usersHeaders}
                    items={userData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                    slotFooter={(
                        <div className='p-2 text-sm w-full flex'>
                            {verifyUserGroup && <div className={style.userGroups}>
                                <b>Grupos de usuários</b>
                                <select className={style.select} value={selectedPrimaryGroupValue} onChange={handleSelectedPrimaryGroup}>
                                    {userGroups.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                <div className='h-24 overflow-y-auto py-2 my-2'>
                                    {userGroups.map((item, index) => (
                                        <>
                                            <Checkbox key={item.id} value={item.id} label={item.name} checked={isCheckedGroup[item.id]} onChange={() => { handleChangeCheckGroup(item) }} />
                                        </>
                                    ))}
                                </div>
                            </div>}
                            <div className={style.authBalance}>
                                <div className={style.balance}>
                                    <b className={style.subTitle}>Saldos</b>
                                    <div className={style.balanceGroup}>
                                        <div className={style.balanceItem}>
                                            <div className={style.balanceData}>
                                                {<BiCoin className='w-4 h-4 mr-1 text-yellow-500' />}
                                                <span>{goldBalance}</span>
                                            </div>
                                            <div className={style.btnContainer}>
                                                {verifyAddGold && <ButtonCss text='Adicionar' onClick={() => { setIsGoldOpen(true), setIsAdd(true) }} color='#94d460' height='17px' width='100%' isRound={false} padding='3px' />}
                                                {verifyRemoveGold && <ButtonCss text='Remover' onClick={() => { setIsGoldOpen(true), setIsAdd(false) }} color='#f35252' height='17px' width='100%' isRound={false} padding='3px' />}
                                            </div>
                                        </div>
                                        {isGoldOpen ?
                                            <div className={style.balanceUpdate}>
                                                <div className={style.balanceUpdateInput}>
                                                    <input type='number' placeholder='Valor' className={style.updateInputVal} value={goldInput} onChange={handleOnChangeGoldInput} />
                                                    <input type='text' placeholder='Motivo' className={style.updateInputRes} value={goldReasonInput} onChange={handleOnChangeGoldReasonInput}></input>
                                                </div>
                                                <div className={style.balanceUpdateBtn}>
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleGold() }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setIsGoldOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <div className={style.balanceGroup}>
                                        <div className={style.balanceItem}>
                                            <div className={style.balanceData}>
                                                {<PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />}
                                                <span>{tokenBalance}</span>
                                            </div>
                                            <div className={style.btnContainer}>
                                                {verifyAddTokens &&<ButtonCss text='Adicionar' onClick={() => { setIsTokenOpen(true), setIsAddToken(true) }} color='#94d460' height='17px' width='100%' isRound={false} padding='3px' />}
                                                {verifyRemoveTokens && <ButtonCss text='Remover' onClick={() => { setIsTokenOpen(true), setIsAddToken(false) }} color='#f35252' height='17px' width='100%' isRound={false} padding='3px' />}
                                            </div>
                                        </div>
                                        {isTokenOpen ?
                                            <div className={style.balanceUpdate}>
                                                <div className={style.balanceUpdateInput}>
                                                    <input type='number' placeholder='Valor' className={style.updateInputVal} value={tokenInput} onChange={handleOnChangeTokenInput}></input>
                                                    <input placeholder='Motivo' className={style.updateInputRes} value={tokenReasonInput} onChange={handleOnChangeTokenReasonInput}></input>
                                                </div>
                                                <div className={style.balanceUpdateBtn}>
                                                    <ButtonCss onlyIcon={true} icon={Check} imgHeight='10px' onClick={() => { handleToken() }} color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setIsTokenOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <div className={style.balanceGroup}>
                                        <div className={style.balanceItem}>
                                            <div className={style.balanceData}>
                                                {<BiShield className='w-4 h-4 mr-1 text-purple-700' />}
                                                <span>{cpBalance}</span>
                                            </div>
                                            <div className={style.btnContainer}>
                                                {verifyAddCp && <ButtonCss text='Adicionar' onClick={() => { setIsCpOpen(true), setIsAddCp(true) }} color='#94d460' height='17px' width='100%' isRound={false} padding='3px' />}
                                                {verifyRemoveCp && <ButtonCss text='Remover' onClick={() => { setIsCpOpen(true), setIsAddCp(false) }} color='#f35252' height='17px' width='100%' isRound={false} padding='3px' />}
                                            </div>
                                        </div>
                                        {isCpOpen ?
                                            <div className={style.balanceUpdate}>
                                                <div className={style.balanceUpdateInput}>
                                                    <input type='number' placeholder='Valor' className={style.updateInputVal} value={cpInput} onChange={handleOnChangeCpInput}></input>
                                                    <input placeholder='Motivo' className={style.updateInputRes} value={cpReasonInput} onChange={handleOnChangeCpReasonInput}></input>
                                                </div>
                                                <div className={style.balanceUpdateBtn}>
                                                    <ButtonCss onlyIcon={true} icon={Check} imgHeight='10px' onClick={() => { handleCp() }} color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setIsCpOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                {verifyRemoveAuth && <div className={style.auth}>
                                    <b className={style.subTitle}>Autenticação 2FA</b>
                                    <div className={style.authContent}>
                                        {isAuth ? <ButtonCss text='Remover Autenticação' onClick={handleRemoveAuth} color='#f35252' height='17px' width='80%' isRound={false} padding='3px' /> : ""}
                                    </div>
                                </div>}
                            </div>

                        </div>
                    )}
                />
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                >
                    <div className={style.modalContent}>
                        <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>
                        {modalTitle != "Data de Nascimento" ?
                            (modalTitle != 'Gênero') ? <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} /> :
                                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                    <option key={'F'} value={'F'}>F</option>
                                    <option key={'M'} value={'M'}>M</option>
                                </select>
                            : <input className={style.modalInput} type='date' placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                        }
                        <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                    </div>
                </Modal>
            </>
        )
    },)

    if (verifyUserIps) {
        tabs.push({
            id: 2,
            tabTitle: 'Logins',
            content: (
                <>
                    <BasicTable
                        height='56vh'
                        onDetailRow={handleRedirectIp}
                        headers={userLoginsHeaders}
                        items={loginItems}
                        isLoading={isLoadingLogins && loginItems.length === 0}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMoreLoginItems || isLoadingLogins} text="Carregar Mais" onClick={handleLoadMoreLogins} color="cyan" />
                                {(loginItems.length === 0) ? "" : <span>Exibindo {0}-{(hasMoreLoginItems) ? skipLoginItems + 50 : loginItems.length} resultados</span>}
                            </div>
                        }
                    />
                    <Link to="/users/ips" ref={linkRef} style={{ display: 'none' }}></Link>
                </>
            )
        })
    }

    if(verifyUserNotes){
        tabs.push({
            id: 3,
            tabTitle: 'Notas',
            content: (
                <div className="mt-2 p-2">
                    <div className={style.modNotesHeader}>
                        {(isModNotesChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveModNotesChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardModNotesChanges} color="cyan" />
                            </div>
                        }
                    </div>
                    {<textarea rows="4" value={modNotes} onChange={verifyUserNotesEdit ? handleModNotes : () => {}} name="comment" id="comment" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[57vh]"></textarea>}
                </div>
            )
        })
    }

    if(verifyUserChanges){
        tabs.push({
            id: 4,
            tabTitle: 'Mudanças',
            content:
                (
                    <>
                        <BasicTable
                            height='56vh'
                            onDetailRow={selectedDetail}
                            headers={modLogsHeaders}
                            items={modLogs}
                            isLoading={isLoadingModLogs && modLogs.length === 0}
                            slotFooter={
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMoreModLogs || isLoadingModLogs} text="Carregar Mais" onClick={handleLoadMoreModLogs} color="cyan" />
                                    {(modLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMoreModLogs) ? skipModLogs + 50 : modLogs.length} resultados</span>}
                                </div>
                            }
                        />
                        <Modal
                            header={`${detailsItem.property}`}
                            isOpen={isModalModLogsOpen}
                            onClose={handleCloseModalModLogs}
                            isLoading={isLoadingDetails}
                        >
                            <div className={styles.modalModLogContainer}>
                                <div className={styles.modalValue}>
                                    <span className={styles.modalSubtitle}>Valor Antigo:</span>
                                    <span className={styles.modalText}>{detailsItem.oldValue}</span>
                                </div>
                                <div className={styles.modalValue}>
                                    <span className={styles.modalSubtitle}>Valor Novo:</span>
                                    <span className={styles.modalText}>{(detailsItem.newValue) ?? "null"}</span>
                                </div>
                            </div>
                        </Modal>
                    </>
                )
        })
    }

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <>
                        <div className={styles.slotHeader}>
                            <div className='p-2 text-sm'>
                                <b>{userData.firstName + " " + userData.lastName}</b>
                                <p>{item.id}</p>
                            </div>
                            <div className={styles.slotHeaderBtn}>
                                {verifyUserLogout && <Button disabled={!isSession} text="Remover Acessos" onClick={handleOpenRemoveSessionsModal} color="cyan" />}
                            </div>

                        </div>
                        <Modal
                            header={`Atenção!`}
                            isOpen={isRemoveSessions}
                            onClose={handleCloseRemoveSessionsModal}
                        >
                            <div className={styles.modalModLogContainer}>
                                <div className={styles.modalSlotHeaderTitle}>
                                    <span className={styles.modalSubtitle}>Deseja deslogar o usuário de todos os seus aparelhos?</span>
                                </div>
                                <div className={styles.modalSlotHeaderBtn}>
                                    <Button disabled={false} text="Sim" onClick={handleRemoveSessions} color="cyan" />
                                    <Button disabled={false} text="Não" onClick={handleCloseRemoveSessionsModal} color="cyan" />
                                </div>
                            </div>
                        </Modal>
                    </>
                )}
            />
        </div>
    )
}
