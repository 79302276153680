import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getInfractionOptions, getInfractionSteps } from "services/infractionService";
import {useState, useEffect} from 'react';
import Infractions from "Pages/Moderation/Infractions";
import { fetchAllInfractionsFilters } from "store/modules/moderation/infractions/allInfractionsSlice";

export function InfractionsUrl(){

    const [propName, setPropName] = useState({});
    const [propNameStep, setPropNameStep] = useState({});

    useEffect(() => {
        handleInfractionOptions()
        handleInfractionSteps()
    }, [])

    const handleInfractionOptions = async () => {
        let tempPropName = {}
        const apiInfractionOptions = await getInfractionOptions()

        apiInfractionOptions.map((item) => {
            tempPropName[`${item.typeId}`] = item.desc
        })
        setPropName(tempPropName)
    }

    const handleInfractionSteps = async () => {
        let tempPropName = {}
        const apiInfractionOptions = await getInfractionSteps()

        apiInfractionOptions.map((item) => {
            tempPropName[`${item.stepId}`] = item.desc
        })

        setPropNameStep(tempPropName)
    }



    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId");
    const infractionId = searchParams.get("infractionId");
    const punishmentId = searchParams.get("punishmentId");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");

    const dispatch = useDispatch()

    if (userId || infractionId || punishmentId || dateFrom || dateTo) {
        fetchAllInfractionsFilters(dispatch, { userId, infractionId: infractionId ? {name: propName[infractionId], value: infractionId} : '', punishmentId: punishmentId ? {name: propNameStep[punishmentId], value: punishmentId} : '', dateFrom, dateTo })
        return <Infractions userId={userId ?? null} infractionId={infractionId ?? null} punishmentId={punishmentId ?? null}  dateFrom={dateFrom ?? null} dateTo={dateTo ?? null}/>;
    }
    else{
        return <Infractions/>;
    }

}