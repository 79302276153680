import { tab } from "@testing-library/user-event/dist/tab";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserForDetails } from "store/modules";
import { fetchUserGroups } from "store/modules/administration/userGroups/userGroupsSlice";
import { fetchSelectedUserDetails, setSelectedUserDetailsDetails } from "store/modules/users/selectedUserDetailsSlice";

export function UseUser(item){
    let verifyUserEmail = useUserPermission(SMIPermissionsCode.MOD_USER_EMAIL_VIEW);

    const user = useSelector((state) => {
        return (state.selectedUserDetails.details) ? state.selectedUserDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedUserDetails(dispatch, { id: item.id }, verifyUserEmail);
    }, [item])

    return user
}

export function UseGroups(){
    const groups = useSelector((state) => {
        return Array.isArray(state.userGroups.groups) ? state.userGroups.groups : []
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchUserGroups(dispatch);
    }, [])

    return groups
}