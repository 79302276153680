class Staff {
    constructor ({ id, group, user, avatar, space}) {
        this.id     = id
        this.group  = group
        this.user   = user
        this.avatar = avatar
        this.space  = space
    }

    get () {
        return {
            id: this.id,
            group: this.group,
            user: this.user,
            avatar: this.avatar,
            space: this.space
        }
    }

    smallID () {
        return this.id?.slice(0, 7)
    }
}

export default Staff
