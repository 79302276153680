import moment from "moment"

class UserLogin{
    constructor({ip, timestamp}){
        this.ip = ip
        this.timestamp = timestamp
    }

    get(){
        return{
            ip: this.ip,
            timestamp: moment(this.timestamp).format('DD/MM/YYYY') + " " + moment(this.timestamp).format('HH:mm'),
        }
    }
}

export default UserLogin