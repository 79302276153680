const avatarsMapping = (item) => ({
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    userId: item.userId ?? null,
    userFirstName: item.userFirstName ?? null,
    userLastName: item.userLastName ?? null,
    headPostfix: item.headPostfix ?? null
})

const avatarDetailsMapping = (item) => ({
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    timeInSpaces: item.timeInSpaces ?? null
})

const avatarProfileDetailsMapping = (item) => ({
    avatarId: item._id ?? null,
    result: item.result ?? null,
    texts: item.texts ?? null
})

const avatarOptionsMapping = (item) => ({
    options: item.options ?? null
})

const avatarXpLogsMapping = (item) => ({
    date: item.date ?? null,
    xpType: item.xpType ?? null,
    value: item.value ?? null,
    desc: item.desc ?? '-'
})

const avatarXpMapping = (item) => ({
    xpType: item.xpType ?? null,
    value: item.xpValue ?? null,
})

const avatarPetsMapping = (item) => ({
    petName: item.name ?? null,
    petType: item.type ?? null,
    petDesc: item.desc ?? null,
})

export {avatarsMapping, avatarDetailsMapping, avatarProfileDetailsMapping, avatarOptionsMapping, avatarXpLogsMapping, avatarXpMapping, avatarPetsMapping}