import { createSlice } from '@reduxjs/toolkit'

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            avatarFirstName: '',
            avatarLastName: '',
            avatarId: '',
            avatarExact: '',
            avatarOnline: '',
            userFirstName: '',
            userLastName: '',
            userId: '',
            userEmail: '',
            userExact: '',
            ip: '',
            ipOnline: ''
        }
    },
    reducers: {
        setUsersData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addUsersData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setUsersFilters: (state, action) => {
            return {...state, filters: action.payload}
        },
    },
})

export const { setUsersData, addUsersData, setCurrentSkip, setHasMore, setUsersFilters } = usersSlice.actions
export default usersSlice.reducer
