import { getAllInfractions } from "services/infractionService";

const { createSlice } = require("@reduxjs/toolkit");

const allInfractionsSlice = createSlice({
    name: 'allInfractions',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            userId: '',
            dateFrom: '',
            dateTo: '',
            infractionId: '',
            punishmentId: '',
        }
    },
    reducers: {
        setAllInfractionsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addAllInfractionsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setAllInfractionsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchAllInfractions(dispatch, params, skip) {
    try {
        const LIMIT = 200;
        const allInfractions = await getAllInfractions(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addAllInfractionsData(allInfractions))
        }
        else {
            dispatch(setAllInfractionsData(allInfractions));
        }

        if (allInfractions.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Infrações', error)
        dispatch(setCurrentSkip(0));
        dispatch(setAllInfractionsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchAllInfractionsFilters(dispatch, params){
    dispatch(setAllInfractionsFilter(params));
}

export const { setAllInfractionsData, addAllInfractionsData, setCurrentSkip, setHasMore, setAllInfractionsFilter } = allInfractionsSlice.actions
export default allInfractionsSlice.reducer