import { createSlice } from '@reduxjs/toolkit'
import { getCategory } from 'services/categoryService';

const selectedCategorySlice = createSlice({
    name: 'selectedCategory',
    initialState: {},
    reducers: {
        setSelectedCategory: (state, action) => {
            let category;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                category = action.payload[0]
            } else {
                category = {}
            }            

            return category
        },
    },
})

export const selectCategoryForDetails = (params) => async (dispatch) => {
    try {
        const selectedCategory = await getCategory(params)
        dispatch(setSelectedCategory(selectedCategory))
    } catch (error) {
        console.error('Erro ao carregar categoria:', error)
    }
}

export const { setSelectedCategory } = selectedCategorySlice.actions
export default selectedCategorySlice.reducer
