import { getChatLogs } from "services/chatLogService";

const { createSlice } = require("@reduxjs/toolkit");

const chatLogsSlice = createSlice({
    name:"chatLogs",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            avatarId: '',
            spaceId: '',
            instanceId: '',
            dateFrom: '',
            dateTo: '',
            messageText: ''
        }
    },
    reducers: {
        setChatLogsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addChatLogsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setChatLogsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchChatLogs(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const items = await getChatLogs(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addChatLogsData(items))
        }
        else{
            dispatch(setChatLogsData(items));
        }
        
        if(items.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter ChatLogs', error)
        dispatch(setCurrentSkip(0));
        dispatch(setChatLogsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchChatLogsFilters(dispatch, params){
    dispatch(setChatLogsFilter(params));

    console.log(params)
}

export const { setChatLogsData, setCurrentSkip, setHasMore, addChatLogsData, setChatLogsFilter } = chatLogsSlice.actions
export default chatLogsSlice.reducer