import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { InfinitySpin } from 'react-loader-spinner'

const Modal = ({ isOpen, onClose, isLoading, header = null, children = null, footer = null }) => {
    if (!isOpen) {
        return null
    }

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <button
                        type="button"
                        className="items-center text-gray-400 hover:text-gray-500 h-full w-full absolute"
                        onClick={() => onClose(false)}>
                    </button>
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                        <div className='mb-3 px-4 py-2 bg-gray-300 flex justify-between'>
                            <div>
                                {header}
                            </div>
                            <button
                                type="button"
                                className="items-center text-gray-400 hover:text-gray-500"
                                onClick={() => onClose(false)}>
                                <XMarkIcon className='h-4 w-4' />
                            </button>
                        </div>
                        <div className='px-4 py-2 w-fit'>
                            {isLoading ? (
                                <InfinitySpin
                                    width='200'
                                    color="#155f75"
                                />
                            ) : children}
                        </div>
                        <div className="mt-3 px-4 py-2 bg-gray-100">
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
