const missionsMapping = (item) => ({
    missionId: item.missionId ?? null,
    missionName: item.missionName ?? null,
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" :`https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    entryFee: item.entryFee ?? null,
    experiencePath: item.experiencePath ?? null,
    approved: item.approved ? "Sim" : "Não",
    isOfficial: item.isOfficial ? "Sim" : "Não",
    spaceId: item.initialSpace ?? null,
    spaceName: item.initialSpaceName ?? "-"
})

const missionDetailsMapping = (item) => ({
    missionId: item.missionId ?? null,
    missionName: item.missionName ?? null,
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" :`https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    entryFee: item.entryFee ?? null,
    experiencePath: item.experiencePath ?? null,
    approved: item.approved ? "Sim" : "Não",
    isOfficial: item.isOfficial ? "Sim" : "Não",
    spaceId: item.initialSpace ?? "-",
    spaceName: item.initialSpaceName ?? "-"
})

export {missionsMapping, missionDetailsMapping}