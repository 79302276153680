import { useSelector } from "react-redux"

export default function useUserPermission(permission){
    const loggedUser = useSelector((state) => {
        return state.loggedUser
    })

    if(loggedUser == null){
        return false
    }

    const permissions = loggedUser.permissions || []

    return Boolean(permissions[permission])
}