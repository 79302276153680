const ofences = [
    { name: 'Todas as Ofenças' },
    { name: 'Investigação' },
    { name: 'Compra Falsa' },
    { name: 'Reembolso' },
    { name: 'Racismo Leve' },
    { name: 'Ameaça' },
    { name: 'Bullyng' },
]

export default ofences
