import { createSlice } from '@reduxjs/toolkit'
import { getPermissionCategories } from 'services/userGroupsService'

const permissionCategoriesSlice = createSlice({
    name: 'permissionCategories',
    initialState: {
        data: [],
        tab: 1
    },
    reducers: {
        setPermissionCategoriesData: (state, action) => {
            return {...state, data: action.payload}
        },
        setPermissionCategoriesTab: (state, action) => {
            return {...state, tab: action.payload}
        },
    },
})

export const fetchPermissionCategories = (tab) => async (dispatch) => {
    try {
        const categories = await getPermissionCategories(tab)
        dispatch(setPermissionCategoriesData(categories))
        dispatch(setPermissionCategoriesTab(tab))
    } catch (error) {
        console.error('Erro ao carregar categorias:', error)
    }
}

export const { setPermissionCategoriesData, setPermissionCategoriesTab } = permissionCategoriesSlice.actions
export default permissionCategoriesSlice.reducer