import Spaces from "Pages/Spaces";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchSpacesFilters } from "store/modules/spaces/spacesSlice";

export function SpacesUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const idSpace = searchParams.get("idSpace");
    const nameSpace = searchParams.get("nameSpace");
    const idAvatar = searchParams.get("idAvatar");
    const idModel = searchParams.get("idModel");

    const dispatch = useDispatch()

    if (idSpace || nameSpace || idAvatar || idModel) {
        fetchSpacesFilters(dispatch, {idSpace, nameSpace, idSpace, idModel })
        return <Spaces idSpace={idSpace ?? null} nameSpace={nameSpace ?? null} idAvatar={idAvatar ?? null} idModel={idModel ?? null}/>;
    }
    else{
        return <Spaces/>;
    }

}