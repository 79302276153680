import Staff from 'domains/Staff/Staff'
import api, { apiNormalize } from './api'
import { staffMapping } from 'domains/Staff/mappings'
import handleRequestError from 'helpers/apiErrorHandler'

export const getStaff = async (params) => {
    try {
        const response = await api.get('/staff', { params })
        const staff = apiNormalize.responseMapping(response.data, staffMapping)

        const normalizedStaff = staff.map(item => new Staff(item).get())

        return normalizedStaff
    } catch (error) {
        handleRequestError(error)
    }
}

export const createStaff = async (staffData) => {
    try {
        const response = await api.post('/stafs', staffData)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const updateStaff = async (staffId, staffData) => {
    try {
        const response = await api.put(`/stafs/${ staffId }`, staffData)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}

export const deleteStaff = async (staffId) => {
    try {
        const response = await api.delete(`/stafs/${ staffId }`)
        return response.data
    } catch (error) {
        handleRequestError(error)
    }
}
