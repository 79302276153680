const spinCategoriesMapping = item => ({
    id: item._id ?? null,
    name: item.name ?? null,
    weighting: item.weighting ?? null,
    background: item.background ?? null,
    active: item.active ?? false,
    count: item.count ?? null,
    isDeluxe: item.isDeluxe ?? false,
    totalWeighting: item.totalWeighting ?? null
})

const prizesMapping = item => ({
    id: item._id ?? null,
    idCategory: item.idCategory ?? null,
    gold: item.gold ?? null,
    tokens: item.tokens ?? null,
    modelId: item.modelId ?? null,
    modelQuantity: item.modelQuantity ?? null,
    modelName: item.modelName ?? null,
    modelIcon: item.modelIcon ?? null
})

export { spinCategoriesMapping, prizesMapping }