import React, { useState } from 'react'
import InputField from 'components/form/InputField'
import Checkbox from 'components/form/Checkbox'
import RadioButton from 'components/form/RadioButton'
export default function EmailFilters () {
    const [ownerId, setOwnerID] = useState('')
    const [selectedOption, setSelectedOption] = useState('')

    const options = [
        { value: 'option1', label: 'Usuário selecionado' }
    ]

    const handleOwnerIDChange = (event) => {
        setOwnerID(event.target.value)
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value)
    }

    return (
        <div className='border-b'>
            <div className='flex'>
                <InputField type="text" label="ID do Dono" placeholder="ID do Dono" value={ ownerId } onChange={ handleOwnerIDChange } />
                <RadioButton
                    options={ options }
                    selectedOption={ selectedOption }
                    onChange={ handleOptionChange }
                />
            </div>
        </div>
    )
}
