import moment from "moment"

class AvatarXpLogs {
    constructor({ date, xpType, value, desc }) {
        this.date = date
        this.xpType = xpType
        this.value = value
        this.desc = desc
    }

    get() {
        return ({
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm'),
            xpType: this.handleXpType(this.xpType),
            value: this.value,
            desc: this.desc
        })
    }

    handleXpType(xpType) {

        const xpTypeName = {
            1: 'Artist',
            2: 'Explorer',
            3: 'Gamer',
            4: 'Social',
            5: 'Arena',
            6: 'Farmer',
            7: 'Crafter',
            8: 'Primary',
            9: 'CP',
        }

        return xpTypeName[xpType]
    }
}

export default AvatarXpLogs