import BasicTable from "components/BasicTable"
import Button from "components/form/Button"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchSpaceCategoryDetails } from "store/modules/spaces/categories/spaceCategoryDetailsSlice"
import catalogStyle from '../../../../styles/CatalogStyle.module.css'
import styles from '../../../../styles/Search.module.css'
import style from '../../../../styles/UserTabs.module.css'
import proofStyle from '../../../../styles/Proof.module.css'
import dndStyle from '../../../../styles/DnDStyle.module.css'
import { deleteInactiveSpaces, deleteSpaceEntry, getSpaceEntries, saveSpaceEntries } from "services/spaceService"
import { InfinitySpin } from "react-loader-spinner"
import Modal from "components/Modal"
import SpaceEntryContainer from "./SpaceEntryContainer"
import { toast } from "react-toastify"
import moment from "moment"

const spaceIconObject = { key: 'spaceIcon' }
const ownerIconObject = { key: 'ownerIcon' }

const categoryActions = [
    { action: 'edit', icon: null },
    { action: 'delete', icon: null }
]

const categoryHeaders = [
    { title: 'Espaço', key: 'spaceName', icon: () => spaceIconObject },
    { title: 'Dono', key: 'ownerName', icon: () => ownerIconObject, copy: 'ownerId' },
    { title: 'Começo', key: 'startDate' },
    { title: 'Fim', key: 'endDate' },
    { title: 'Ações', key: 'acoes', actions: () => categoryActions }
]


export default function SpaceCategoryDetails({ item }) {

    const [isLoading, setLoading] = useState(false)
    const [isControlModalOpen, setIsControlModalOpen] = useState(false)

    const spaceCategoryDetails = useSelector((state) => {
        return Array.isArray(state.spaceCategoryDetails.data) ? state.spaceCategoryDetails.data : []
    })

    const skip = useSelector((state) => {
        return (state.spaceCategoryDetails.currentSkip) ? state.spaceCategoryDetails.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.spaceCategoryDetails.hasMore)
    })

    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        fetchSpaceCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)
        console.log(spaceCategoryDetails)
    }, [item])

    const handleLoadMore = (() => {
        fetchSpaceCategoryDetails(dispatch, { id: item.id }, skip + 200);
    })

    //Manage Entries Development

    const [entries, setEntries] = useState({})
    const [entriesCopy, setEntriesCopy] = useState({})
    const [isEntryLoading, setIsEntryLoading] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [editEntryInput, setEditEntryInput] = useState('')
    const [spaceIdInputValue, setSpaceIdInputValue] = useState('')
    const [order, setOrder] = useState([])

    function isEqual(obj1, obj2) {
        if (obj1 === null && obj2 === null) return true;

        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const handleCloseControlModal = () => {
        setEntries({})
        setEntriesCopy({})
        setHasChanged(false)
        setOrder([])

        setLoading(true)
        fetchSpaceCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setIsControlModalOpen(false)
    }

    const handleOpenControlModal = () => {
        setIsControlModalOpen(true)
    }

    const handleAddEntry = async (tableItemSpaceId) => {

        let tempObject = {}
        if (!order.includes(tableItemSpaceId)) {

            const spaceEntries = await getSpaceEntries(tableItemSpaceId)
            setOrder((prev) => [...prev, tableItemSpaceId])

            let spaceId = spaceEntries[0].spaceId
            tempObject[spaceId] = {
                icon: spaceEntries[0].icon,
                spaceId: spaceEntries[0].spaceId,
                spaceName: spaceEntries[0].spaceName,
                categories: {}
            }

            for (let i = 0; i < spaceEntries.length; i++) {
                if (spaceEntries[i].categoryName) {
                    tempObject[spaceId].categories[spaceEntries[i].categoryName] = {
                        entryId: spaceEntries[i].entryId,
                        categoryId: spaceEntries[i].categoryId,
                        startDate: spaceEntries[i].startDate,
                        endDate: spaceEntries[i].endDate,
                        categoryName: spaceEntries[i].categoryName,
                    }
                }
            }

            setEntries((prev) => { return ({ ...JSON.parse(JSON.stringify(tempObject)), ...prev }) })
            setEntriesCopy((prev) => { return ({ ...JSON.parse(JSON.stringify(tempObject)), ...prev }) })

            console.log(entriesCopy)

            setIsEntryLoading(false)

            toast.success('Item Adicionado!')
        }
        else {
            toast.error('Item já adicionado')
        }
    }

    const handleEditItem = (tableItem) => {
        setIsEntryLoading(true)
        handleAddEntry(tableItem.spaceId)
        handleOpenControlModal()
    }

    const handleCloseModel = (spaceId) => {
        const { [spaceId]: _, ...newObj } = entries;
        setEntries(newObj);

        const { [spaceId]: __, ...newObjCopy } = entriesCopy;
        setEntriesCopy(newObjCopy);

        let tempArr = [...order]
        tempArr.splice(order.indexOf(spaceId), 1)
        setOrder(tempArr)

        if (!isEqual(newObj, newObjCopy)) {
            setHasChanged(true)
        }
        else {
            setHasChanged(false)
        }

    }

    const handleCloseCategory = (categoryName, spaceId) => {
        const { [categoryName]: __, ...newObj } = entriesCopy[spaceId].categories;

        let tempObj = { ...entriesCopy }

        tempObj[spaceId].categories = newObj

        setEntriesCopy(tempObj);

        if (!isEqual(entries[spaceId], entriesCopy[spaceId])) {
            setHasChanged(true)
        }
        else {
            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }
        }
    }

    const handleAddCategory = (option, spaceId) => {
        if (!Object.keys(entriesCopy[spaceId].categories).includes(option.label)) {
            let tempObj = { ...entriesCopy }

            const categoryName = option.label

            let newCateg = {}

            if (Object.keys(entries[spaceId].categories).includes(option.label)) {
                newCateg = {
                    entryId: entries[spaceId].categories[option.label].entryId,
                    categoryId: entries[spaceId].categories[option.label].categoryId,
                    startDate: entries[spaceId].categories[option.label].startDate,
                    endDate: entries[spaceId].categories[option.label].endDate,
                    categoryName: entries[spaceId].categories[option.label].categoryName,
                }
            }
            else {
                newCateg = {
                    categoryId: option.value,
                    startDate: null,
                    endDate: null,
                    categoryName: categoryName,
                }
            }
            tempObj[spaceId].categories = { ...entriesCopy[spaceId].categories, [categoryName]: newCateg }
            setEntriesCopy(tempObj)

            if (!isEqual(entries[spaceId], entriesCopy[spaceId])) {
                setHasChanged(true)
            }
            else {
                if ((!isEqual(entries, entriesCopy))) {
                    setHasChanged(true)
                }
                else {
                    setHasChanged(false)
                }
            }


            toast.success('Categoria Adicionada com Sucesso!')
        }
        else {
            toast.error('Categoria já Existente!')
        }
    }

    const handleChangeStartDate = (date, categoryName, spaceId) => {
        let tempObj = { ...entriesCopy }

        tempObj[spaceId].categories[categoryName].startDate = moment(date).toISOString()
        setEntriesCopy(tempObj)

        if (!isEqual(entries[spaceId], entriesCopy[spaceId])) {
            setHasChanged(true)
        }
        else {
            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }
        }
    }

    const handleChangeEndDate = (date, categoryName, spaceId) => {
        let tempObj = { ...entriesCopy }

        tempObj[spaceId].categories[categoryName].endDate = moment(date).toISOString()
        setEntriesCopy(tempObj)

        if (!isEqual(entries[spaceId], entriesCopy[spaceId])) {
            setHasChanged(true)
        }
        else {
            if ((!isEqual(entries, entriesCopy))) {
                setHasChanged(true)
            }
            else {
                setHasChanged(false)
            }
        }
    }

    const handleEditEntryInput = () => {
        handleAddEntry(spaceIdInputValue);
        setSpaceIdInputValue('')
    }

    const handleOnChangeSpaceIdInput = (e) => {
        setSpaceIdInputValue(e.target.value);
    }

    const handleSaveEntries = async () => {
        try {
            let changedObj = {}
            let originalObjKeys = Object.keys(entries)
            originalObjKeys.map((key, i) => {
                if (!isEqual(entries[key], entriesCopy[key])) {
                    changedObj[key] = { ...entriesCopy[key] }
                }
            })

            await saveSpaceEntries(JSON.stringify(changedObj))

            handleCloseControlModal()

            toast.success("Dados Salvo com Sucesso!")
        } catch (ex) {
            console.log(ex)
            toast.error("Erro ao Salvar Dados!")
        }
    }
    //Delete Inactives Development

    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const handleOpenDeleteModal = () => {
        setIsDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setLoading(true)
        fetchSpaceCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setIsDeleteModal(false)
    }

    const handleDeleteInactives = async () => {
        try {

            await deleteInactiveSpaces(item.id)

            handleCloseDeleteModal()

            toast.success('Operação Realizada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Realizar Operação!')
        }
    }

    //Delete Entry Development

    const [isDeleteEntryModal, setIsDeleteEntryModal] = useState(false)
    const [currentItemToDelete, setCurrentItemToDelete] = useState({})

    const handleOpenDeleteEntryModal = (tableItem) => {
        setIsDeleteEntryModal(true)
        setCurrentItemToDelete(tableItem)
    }

    const handleCloseDeleteEntryModal = () => {
        setLoading(true)
        fetchSpaceCategoryDetails(dispatch, { id: item.id }, 0);
        setLoading(false)

        setCurrentItemToDelete({})
        setIsDeleteEntryModal(false)
    }

    const handleDeleteEntry = async () => {
        try {
            console.log(currentItemToDelete)
            await deleteSpaceEntry(currentItemToDelete.entryId)

            handleCloseDeleteEntryModal()

            toast.success('Entrada Deletada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Deletar Entrada!')
        }
    }

    return (
        <div>
            <BasicTable
                onEditItem={handleEditItem}
                onDeleteItem={handleOpenDeleteEntryModal}
                title='Entradas'
                slotActions={
                    <></>
                }
                headers={categoryHeaders}
                items={spaceCategoryDetails}
                isLoading={isLoading}
                slotFooter={
                    <div className={catalogStyle.tableFooter}>
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(spaceCategoryDetails.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : spaceCategoryDetails.length} resultados</span>}
                        </div>
                        <div className={catalogStyle.tableFooterActions}>
                            <Button disabled={false} text="Controlar Entradas" onClick={handleOpenControlModal} color="cyan" />
                            <Button disabled={!(spaceCategoryDetails.length > 0)} text="Deletar Inativos" onClick={handleOpenDeleteModal} color="cyan" />
                        </div>
                    </div>
                }
                height='70vh'
            />
            <Modal
                isOpen={isControlModalOpen}
                onClose={handleCloseControlModal}
                footer={
                    <div className={dndStyle.modalFooter}>
                        <Button disabled={!hasChanged} text="Salvar" onClick={handleSaveEntries} color="cyan" />
                    </div>
                }
                header={<div><span>Controlar Entradas</span></div>}
            >
                <div className={catalogStyle.controlModalContainer}>
                    <div>
                        {isEntryLoading && <div className={proofStyle.proofModalContainerSpin}>
                            <InfinitySpin
                                width='200'
                                color="#155f75"
                            />
                        </div>}
                        {!isEntryLoading &&
                            <>
                                <div className={proofStyle.filterContainer}>
                                    <input className={style.modalInput} type="text" placeholder={'ID do Espaço'} value={spaceIdInputValue} onChange={handleOnChangeSpaceIdInput} />
                                    <Button disabled={spaceIdInputValue == ''} text="Adicionar" onClick={handleEditEntryInput} color="cyan" />
                                </div>
                                <div className={catalogStyle.entriesContainer}>
                                    {
                                        order.map((key, i) => {
                                            return (
                                                <SpaceEntryContainer prop={key} value={entriesCopy[key]} onCloseModel={handleCloseModel} onCloseCategory={handleCloseCategory} onAddCategory={handleAddCategory} onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} />
                                            )
                                        })
                                    }
                                </div>
                            </>}
                    </div>

                </div>
            </Modal>
            <Modal
                header={<div><span>Atenção!</span></div>}
                isOpen={isDeleteModal}
                onClose={handleCloseDeleteModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja excluir definitivamente todas entradas inativas?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteInactives} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteModal} color="cyan" />
                    </div>
                </div>
            </Modal>
            <Modal
                header={<div><span>Atenção!</span></div>}
                isOpen={isDeleteEntryModal}
                onClose={handleCloseDeleteEntryModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja excluir definitivamente essa entrada?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteEntry} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteEntryModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}