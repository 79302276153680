import { createSlice } from '@reduxjs/toolkit'
import { getInterstitialGroups, getInterstitials } from 'services/interstitialService'

const interstitialsSlice = createSlice({
    name: 'interstitials',
    initialState: [],
    reducers: {
        setInterstitials: (state, action) => {
            return action.payload
        },
    },
})

export const fetchInterstitials = (params) => async (dispatch) => {
    try {
        const interstitials = await getInterstitials(params)
        dispatch(setInterstitials(interstitials))
    } catch (error) {
        console.error('Erro ao carregar Interstitials:', error)
    }
}

export const fetchClearInterstitials = () => async (dispatch) => {
    try {
        dispatch(setInterstitials([]))
    } catch (error) {
        console.error('Erro ao carregar Interstitials:', error)
    }
}

export const { setInterstitials } = interstitialsSlice.actions
export default interstitialsSlice.reducer