import React, { useRef } from 'react'
import DefaultForm from 'components/form/DefaultForm'
import { required, minLength, isEmail } from 'helpers/validation'
import { useDispatch } from 'react-redux'
import { fetchUsersFilters } from 'store/modules/users/userActions'

const FormByAvatar = ({ onFormChange, defaultValues }) => {
    const formRef = useRef()

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFormChange(formData, isValid)
        
        let newValue= {};
        newValue[name] = value;

        console.log(newValue)

        fetchUsersFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'avatarFirstName',
            label: 'Nome do Avatar',
            placeholder: 'Nome do Avatar',
            type: 'text',
            value: defaultValues.avatarFirstName,
            validate: [
                // { rule: required }
            ],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarLastName',
            label: 'Sobrenome do Avatar',
            placeholder: 'Sobrenome do Avatar',
            type: 'text',
            value: defaultValues.avatarLastName,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarId',
            label: 'ID do Avatar',
            placeholder: 'ID do Avatar',
            type: 'text',
            value: defaultValues.avatarId,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarExact',
            label: 'Combinação exata',
            type: 'checkbox',
            value: defaultValues.avatarExact,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarOnline',
            label: 'Apenas Online',
            type: 'checkbox',
            value: defaultValues.avatarOnline,
            validate: [],
            gridClass: 'col-span-4'
        },
    ]

    return (
        <>
            <DefaultForm ref={ formRef } fields={ fields } onChange={ handleSubmit } />
        </>
    )
}

export default FormByAvatar
