import { Link } from 'react-router-dom'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { UserIcon, ScaleIcon, IdentificationIcon, FlagIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchStats } from 'store/modules/stats/statsSlice'
import { BiCoin } from "react-icons/bi";
import { PiPokerChipBold } from "react-icons/pi";
import styles from "../../styles/Home.module.css"
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from '../../domains/Permissions/SMIPermissionsCode'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Home() {
    const veryfyShiftLead = useUserPermission(SMIPermissionsCode.MOD_DASHBOARD_SHIFTLEAD);

    const [days, setdays] = useState("30");

    const dispatch = useDispatch()

    const stats = useSelector((state) => {
        return state.stats;
    })

    useEffect(() => {
        fetchStats(dispatch, days);
    }, [days])

    const newUsers = stats.newUsers[0];
    const lastMonthNewUsers = stats.newUsers[1];
    const newUsersChange = (newUsers > lastMonthNewUsers) ? ((newUsers / lastMonthNewUsers) - 1) * 100 : (1 - (newUsers / lastMonthNewUsers)) * 100;

    const newInfractions = stats.newInfractions[0];
    const lastMonthNewInfractions = stats.newInfractions[1];
    const newInfractionsChange = (newInfractions > lastMonthNewInfractions) ? ((newInfractions / lastMonthNewInfractions) - 1) * 100 : (1 - (newInfractions / lastMonthNewInfractions)) * 100;

    const newAbuseReports = stats.newAbuseReports[0];
    const lastMonthNewAbuseReports = stats.newAbuseReports[1];
    const newAbuseReportsChange = (newAbuseReports > lastMonthNewAbuseReports) ? ((newAbuseReports / lastMonthNewAbuseReports) - 1) * 100 : (1 - (newAbuseReports / lastMonthNewAbuseReports)) * 100;

    const newGoldTransactions = stats.newTransactions[0].newGoldSum;
    const lastMonthNewGoldTransactions = stats.newTransactions[1].lastMonthNewGoldSum;
    const newGoldTransactionsChange = (newGoldTransactions > lastMonthNewGoldTransactions) ? ((newGoldTransactions / lastMonthNewGoldTransactions) - 1) * 100 : (1 - (newGoldTransactions / lastMonthNewGoldTransactions)) * 100;

    const newTokenTransactions = stats.newTransactions[0].newTokenSum;
    const lastMonthNewTokenTransactions = stats.newTransactions[1].lastMonthNewTokenSum;
    const newTokenTransactionsChange = (newTokenTransactions > lastMonthNewTokenTransactions) ? ((newTokenTransactions / lastMonthNewTokenTransactions) - 1) * 100 : (1 - (newTokenTransactions / lastMonthNewTokenTransactions)) * 100;


    const statsInfo = [
        { id: 1, to: '/users', name: 'Novos usuários', stat: newUsers, icon: IdentificationIcon, change: `${newUsersChange.toFixed(2)}%`, changeType: (newUsers > lastMonthNewUsers) ? 'increase' : 'decrease' },
        { id: 2, to: '/moderation', name: 'Infrações', stat: newInfractions, icon: ScaleIcon, change: `${newInfractionsChange.toFixed(2)}%`, changeType: (newInfractions > lastMonthNewInfractions) ? 'increase' : 'decrease' },
        { id: 3, to: '/moderation/abuse-reports', name: 'Denúncias', stat: newAbuseReports, icon: FlagIcon, change: `${newAbuseReportsChange.toFixed(2)}%`, changeType: (newAbuseReports > lastMonthNewAbuseReports) ? 'increase' : 'decrease' },
        { id: 4, to: '/revenue/transactions', name: 'Transações Moedas', stat: newGoldTransactions, icon: () => <BiCoin className='w-4 h-4 text-white' />, change: `${newGoldTransactionsChange.toFixed(2)}%`, changeType: (newGoldTransactions > lastMonthNewGoldTransactions) ? 'increase' : 'decrease' },
        { id: 5, to: '/revenue/transactions', name: 'Transações Fichas', stat: newTokenTransactions, icon: () => <PiPokerChipBold className='w-4 h-4 text-white' />, change: `${newTokenTransactionsChange.toFixed(2)}%`, changeType: (newTokenTransactions > lastMonthNewTokenTransactions) ? 'increase' : 'decrease' },
    ]

    function handleDays(e) {
        const days = e.target.value;
        setdays(days);
    }

    return (
        <>
        { veryfyShiftLead && <div>
            <div className={styles.header}>
                {/* 
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Últimos 30 dias</h3>
                */}
                <h3 className="text-base font-semibold leading-6 text-gray-900">Último(s)</h3>
                <select  className={styles.days} onChange={handleDays}>
                    <option value="30">30 dias</option>
                    <option value="7">7 dias</option>
                    <option value="1">1 dia</option>
                </select>
            </div>


            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                {statsInfo.map((item) => (
                    <div
                        key={item.id}
                        className="relative border-2 border-grey-500 overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow-lg sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-cyan-800 p-3">
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                            <p
                                className={classNames(
                                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}
                            >
                                {item.changeType === 'increase' ? (
                                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                                ) : (
                                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                                )}

                                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                                {item.change}
                            </p>
                            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    <Link to={item.to} className="font-medium text-cyan-600 hover:text-cyan-800">
                                        Ver detalhes
                                    </Link>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>}
        </>
    )
}
