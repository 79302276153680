import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import ItemsFilter from './Partials/ItemsFilter'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/form/Button'
import { fetchItems } from 'store/modules/items/itemsSlice'
import styles from 'styles/Search.module.css'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { selectItemForDetails } from 'store/modules/items/selectedItemSlice'
import Details from './Partials/Details'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Items({ idUser, idSpace, idItem, idModel, state, forSale }) {
    const verifyItem = useUserPermission(SMIPermissionsCode.ITEMS_SEARCH);
    const iconObject = { key: 'modelIcon' }

    const [itemsHeaders, setItemsHeaders] = useState([
        { title: 'ID do Item', key: 'smallItemId', copy: 'id', detailable: true, appear: true },
        { title: 'Nome do Modelo', key: 'modelName', icon: () => iconObject, copy: 'modelId', appear: true },
        { title: 'Dono', key: 'owner', copy: 'userId', appear: true },
        { title: 'Nome do espaço', key: 'spaceName', copy: 'spaceId', detailable: false, appear: true },
        { title: 'À venda', key: 'forSale', appear: true },
        { title: 'Preço em Moedas', key: 'goldPrice', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />, appear: true },
        { title: 'Preço em Fichas', key: 'tokenPrice', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />, appear: true },
    ])

    const [itemsMomentHeaders, setItemsMomentHeaders] = useState([...itemsHeaders]);

    const handleAppearHeader = (item) => {
        setItemsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setItemsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }



    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const items = useSelector((state) => {
        return Array.isArray(state.items.data) ? state.items.data : []
    })

    const skip = useSelector((state) => {
        return (state.items.currentSkip) ? state.items.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.items.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.items.filters) ? state.items.filters : {}
    })

    const selectedItem = useSelector((state) => {
        return state.selectedItem
    })

    useEffect(() => {
        setLoading(false)
    }, [items]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (idUser || idSpace || idItem || idModel || state || forSale) {
            setLoading(true)
            fetchItems(dispatch, { idUser, idSpace, idItem, idModel, state, forSale }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.idUser) query.append('idUser', filter.form.idUser);
        if (filter.form.idSpace) query.append('idSpace', filter.form.idSpace);
        if (filter.form.idItem) query.append('idItem', filter.form.idItem);
        if (filter.form.idModel) query.append('idModel', filter.form.idModel);
        if (filter.form.state) query.append('state', filter.form.state);
        if (filter.form.forSale === true || filter.form.forSale === false) query.append('forSale', filter.form.forSale);
        window.history.pushState({}, '', `/items?${query.toString()}`);
        fetchItems(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchItems(dispatch, filter.form, skip + 200);
    })

    const handleItemDetails = (item) => {
        dispatch(selectItemForDetails({ id: item.id }))
    }
    return (
        <>
            {verifyItem && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-8'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={itemsHeaders}
                        headers={itemsMomentHeaders}
                        slotHeader={<ItemsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Itens'
                        onDetailRow={handleItemDetails}
                        isLoading={isLoading && items.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={items}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(items.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : items.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
                <div className='col-span-4'>
                    {selectedItem.id && <Details item={selectedItem} />}
                </div>
            </div>}
        </>
    )
}

