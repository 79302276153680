import { createSlice } from '@reduxjs/toolkit'
import { getSelectedAvatarDetails, getSelectedAvatarProfileDetails } from 'services/avatarsService';

const selectedAvatarDetailsSlice = createSlice({
    name: 'selectedAvatarDetails',
    initialState: {
        details: {
            tableProperties: []
        },
        profile: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedAvatarDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
        setSelectedAvatarDetailsProfile: (state, action) => {
            return {...state, profile: action.payload}
        },
    },
})

export async function fetchSelectedAvatarDetails(dispatch, params){
    try{
        const details = await getSelectedAvatarDetails(params);
        dispatch(setSelectedAvatarDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export async function fetchSelectedAvatarProfileDetails(dispatch, params){
    try{
        const profileDetails = await getSelectedAvatarProfileDetails(params);
        dispatch(setSelectedAvatarDetailsProfile(profileDetails));
    }
    catch(error){
        console.error('Erro ao obter Detalhes de Perfil', error)
    }
}

export const { setSelectedAvatarDetailsDetails, setSelectedAvatarDetailsProfile } = selectedAvatarDetailsSlice.actions
export default selectedAvatarDetailsSlice.reducer