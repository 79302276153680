const { default: Ips } = require("Pages/Users/Ips");
const { useDispatch } = require("react-redux");
const { useSearchParams } = require("react-router-dom");
const { fetchIpsFilters } = require("store/modules/users/ips/ipsSlice");

export function IpsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const idUser = searchParams.get("idUser");
    const ip = searchParams.get("ip");
    

    const dispatch = useDispatch()

    if (idUser || ip) {
        fetchIpsFilters(dispatch, {idUser, ip })
        return <Ips idUser={idUser ?? null} ip={ip ?? null}/>;
    }
    else{
        return <Ips/>;
    }

}