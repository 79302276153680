import moment from "moment"

class AvatarDetail{
    constructor({avatarId, avatarFirstName, avatarLastName, avatarNameInstance, timeInSpaces}){
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.timeInSpaces = timeInSpaces
    }

    get(){
        return{
            tableProperties: [
                {
                    key: 'avatarId',
                    name: "ID do Avatar",
                    value: this.avatarId,
                },
                {
                    key: 'avatarFirstName',
                    name: "Nome do Avatar",
                    value: this.avatarFirstName,
                },
                {
                    key: 'avatarLastName',
                    name: "Sobrenome do Avatar",
                    value: this.avatarLastName,
                },
                {
                    key: 'avatarNameInstance',
                    name: "Instância do Avatar",
                    value: this.avatarNameInstance,
                },
                {
                    key: 'timeInSpaces',
                    name: "Tempo Total em espaços",
                    value: this.timeInSpaces,
                } 
            ],
            avatarId: this.avatarId,
            avatarFirstName: this.avatarFirstName,
            avatarLastName: this.avatarLastName,
            avatarNameInstance: this.avatarNameInstance,
            timeInSpaces: this.timeInSpaces,
        }
    }
}

export default AvatarDetail