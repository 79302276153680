const { createSlice } = require("@reduxjs/toolkit");

const spaceChatSlice = createSlice({
    name: "spaceChat",
    initialState: {
        instances: [],
        currentInstance: -1,
        messages: [],
    },
    reducers: {
        setSpaceChatMessages: (state, action) => {
            return {...state, messages: action.payload}
        },
        addSpaceChatMessages: (state, action) => {
            return {...state, messages: [...state.messages, action.payload]}
        },
        setCurrentInstance: (state, action) => {
            return {...state, currentInstance: action.payload}
        },
        setSpaceChatInstances: (state, action) => {
            return {...state, instances: action.payload}
        },
        clearSpaceChatMessages: (state) => {
            return {...state, messages: []}
        }
    }
})

export const { setSpaceChatMessages, addSpaceChatMessages, setCurrentInstance, setSpaceChatInstances, clearSpaceChatMessages } = spaceChatSlice.actions
export default spaceChatSlice.reducer