import { categorySpaceEntryMapping, spaceDetailsMapping, spaceMembersMapping, spacesAvatarModelMapping } from 'domains/Space/mapping';
import api, { apiNormalize } from './api'
import Space from 'domains/Space/Space';
import handleRequestError from 'helpers/apiErrorHandler';
import SpaceDetail from 'domains/Space/SpaceDetail';
import SpaceMember from 'domains/Space/SpaceMember';
import { categoryMapping } from 'domains/Category/mappings';
import Category from 'domains/Category/Category';
import SpaceCategoryEntry from 'domains/Space/SpaceCategoryEntry';
import CategorySpaceEntry from 'domains/Space/CategorySpaceEntry';

export async function getSpaces(params, skip) {
    try {
        const response = await api.get("/spaces", { params: { ...params, skip } });
        const spaces = apiNormalize.responseMapping(response.data, spacesAvatarModelMapping)
        const normalizedSpaces = spaces.map(space => new Space(space).get());
        return normalizedSpaces
    }
    catch (error) {
        handleRequestError(error)
    }

}

export const getSpace = async (params) => {
    try {
        const response = await api.get('/spaces/bySpace', { params })
        const space = apiNormalize.responseMapping(response.data, spacesAvatarModelMapping)
        const normalizedSpace = space.map(space => new Space(space).get());
        return normalizedSpace
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedSpaceDetails = async (params) => {
    try {
        const response = await api.get('/spaces/details', { params })
        const detail = spaceDetailsMapping(response.data[0])
        const normalizedDetail = new SpaceDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveSpace = async (spaceId, changes) => {
    try {
        const response = await api.post(`/space/${spaceId}/saveSpace`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export async function getSpaceMembers(params, skip) {
    try {
        const response = await api.get("/space/getSpaceMembers", { params: { ...params, skip } });
        const spaceMembers = apiNormalize.responseMapping(response.data, spaceMembersMapping)
        const normalizedSpaceMembers = spaceMembers.map(spaceMember => new SpaceMember(spaceMember).get());
        return normalizedSpaceMembers
    }
    catch (error) {
        handleRequestError(error)
    }
}

export const saveSpaceMember = async (spaceId, changes) => {
    try {
        const response = await api.post(`/space/${spaceId}/saveSpaceMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteSpaceMember = async (spaceId, changes) => {
    try {
        const response = await api.post(`/space/${spaceId}/deleteSpaceMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const editSpaceMember = async (spaceId, changes) => {
    try {
        const response = await api.post(`/space/${spaceId}/editSpaceMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveSpaceSnapshot = async (spaceId, img) => {
    try {
        const formData = new FormData();
        formData.append('img', img);
        const response = await api.post(`/space/${spaceId}/saveSpaceSnapshot`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveSpaceThumbnail = async (spaceId, img) => {
    try {
        const formData = new FormData();
        formData.append('img', img);
        const response = await api.post(`/space/${spaceId}/saveSpaceThumbnail`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getSpaceCategories = async (params) => {
    try {
        const response = await api.get('/catalog/space/categories', { params })
        const categories = apiNormalize.responseMapping(response.data, categoryMapping)

        const normalizedCategories = categories.map(item => new Category(item).get())

        return normalizedCategories
    } catch (error) {
        handleRequestError(error)
    }
}

export async function getSpaceCategoryDetails(params, skip) {
    try {
        const response = await api.get("/space/getSpaceCategoryDetails", { params: { ...params, skip } });
        const categoryEntries = apiNormalize.responseMapping(response.data, categorySpaceEntryMapping)
        const normalizedcategoryEntries = categoryEntries.map(categoryEntry => new SpaceCategoryEntry(categoryEntry).get())
        return normalizedcategoryEntries
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSpaceEntries = async (spaceId) => {
    try {
        const response = await api.get(`/space/${spaceId}/getSpaceEntries`)
        const categories = apiNormalize.responseMapping(response.data, categorySpaceEntryMapping)
        const normalizedCategories = categories.map(item => new CategorySpaceEntry(item).get())
        return normalizedCategories
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveSpaceEntries = async (objList) => {
    try {
        const response = await api.post(`/space/saveSpaceEntries`, { objList })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteSpaceEntry = async (entryId) => {
    try {
        const response = await api.post(`/space/${entryId}/deleteSpaceEntry`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteInactiveSpaces = async (categoryId) => {
    try {
        const response = await api.post(`/space/${categoryId}/deleteInactiveSpaces`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}