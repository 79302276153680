import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchAbuseReportsFilters } from 'store/modules/moderation/abuseReports/abuseReportSlice';

export default function FormSearch ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        if(name == 'type'){
            let propName = {
                A: 'Enviada pelo Usuário',
                S: 'Gerado pelo Sistema',
            }
            newValue[name] = {name: propName[value], value};
        }
        else if(name == 'reportType'){
            let propName = {
                A: 'Denúncia de Avatar',
                U: 'Denúncia de Usuário',
                S: 'Denúncia de Espaço',
                E: 'Denúncia In-World Mail',
                M: 'Denúncia de Missão'
            }
            newValue[name] = {name: propName[value], value};
        }
        else if(name == 'categoryId'){
            let propName = {
                '': 'Todas',
                1: 'Trapaça/Mentira',
                2: 'Abuso Sexual/Racial/Mau Comportamento',
                3: 'Textos ou Imagens Pornográficas',
                4: 'Exploração/Fraude',
                5: 'Spam',
                6: 'Usuário menor de idade',
                7: 'Passando-se por membro da equipe',
                9: 'Outro',
            }
            newValue[name] = {name: propName[value], value};
        }
        else if(name == 'status'){
            let propName = {
                A: 'Atribuída',
                U: 'Não lida',
                R: 'Resolvida',
                B: 'Fechada',
                '': 'Todas'
            }
            newValue[name] = {name: propName[value], value};
        }
        else{
            newValue[name] = value;
        }

        fetchAbuseReportsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'type',
            label: 'Tipo',
            value: defaultValues.type.value ?? '',
            placeholder: 'Tipo',
            type: 'select',
            items: [
                {
                    value: 'A',
                    name: 'Enviada pelo Usuário'
                },
                {
                    value: 'S',
                    name: 'Gerado pelo Sistema'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.type !=  '') ? defaultValues.type.name : '-- Tipo --',
            gridClass: 'col-span-4',
        },
        {
            name: 'reportType',
            label: 'Tipo de Denúncia',
            value: defaultValues.reportType.value ?? '',
            placeholder: 'Tipo de Denúncia',
            type: 'select',
            items: [
                {
                    value: 'A',
                    name: 'Denúncia de Avatar'
                },
                {
                    value: 'U',
                    name: 'Denúncia de Usuário'
                },
                {
                    value: 'S',
                    name: 'Denúncia de Espaço'
                },
                {
                    value: 'E',
                    name: 'Denúncia In-World Mail'
                },
                {
                    value: 'M',
                    name: 'Denúncia de Missão'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.reportType !=  '') ? defaultValues.reportType.name : '-- Tipo de Denúncia --',
            gridClass: 'col-span-4',
        },
        {
            name: 'categoryId',
            label: 'Categoria',
            value: defaultValues.categoryId.value ?? '',
            placeholder: 'Todas',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todas'
                },
                {
                    value: 1,
                    name: 'Trapaça/Mentira'
                },
                {
                    value: 2,
                    name: 'Abuso Sexual/Racial/Mau Comportamento'
                },
                {
                    value: 3,
                    name: 'Textos ou Imagens Pornográficas'
                },
                {
                    value: 4,
                    name: 'Exploração/Fraude'
                },
                {
                    value: 5,
                    name: 'Spam'
                },
                {
                    value: 6,
                    name: 'Usuário menor de idade'
                },
                {
                    value: 7,
                    name: 'Passando-se por membro da equipe'
                },
                {
                    value: 9,
                    name: 'Outro'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.categoryId !=  '') ? defaultValues.categoryId.name : 'Todas',
            gridClass: 'col-span-4',
        },
        {
            name: 'reporterId',
            label: 'ID Denunciante',
            value: defaultValues.reporterId,
            placeholder: 'ID Denunciante',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'reportedId',
            label: 'ID Denunciado',
            value: defaultValues.reportedId,
            placeholder: 'ID Denunciado',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'assignedTo',
            label: 'ID Moderador Atribuído',
            value: defaultValues.assignedTo,
            placeholder: 'ID Moderador Atribuído',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'status',
            label: 'Status',
            value: defaultValues.status.value ?? '',
            placeholder: 'Todas',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todas'
                },
                {
                    value: 'U',
                    name: 'Não Lida'
                },
                {
                    value: 'A',
                    name: 'Atribuída'
                },
                {
                    value: 'R',
                    name: 'Resolvida'
                },
                {
                    value: 'B',
                    name: 'Fechada'
                }
            ],
            order: 'A-Z',
            defaultItem: (defaultValues.status !=  '') ? defaultValues.status.name : 'Todas',
            gridClass: 'col-span-4',
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
