import { createSlice } from '@reduxjs/toolkit'
import { getUnreadAbuseReportCount } from 'services/abuseReportService';

const abuseReportCountSlice = createSlice({
    name: 'abuseReportCount',
    initialState: [0, 0, 0, 0, 0, 0],
    reducers: {
        setAbuseReportCount: (state, action) => {
            return action.payload;
        },
    },
})

export async function fetchAbuseReportCount(dispatch){
    try{
        const abuseReportCount = await getUnreadAbuseReportCount();
        dispatch(setAbuseReportCount(abuseReportCount));

    }
    catch(error){
        console.error('Erro ao obter número de Unread Abuse Reports:', error)
    }
}

export const { setAbuseReportCount } = abuseReportCountSlice.actions
export default abuseReportCountSlice.reducer