import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import ItemsFilter from './Partials/ItemsFilter'

import { itemsSearch } from 'data/items'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/form/Button'
import ModLogsFilter from './Partials/modLogsFilter'
import styles from '../../../styles/Search.module.css'
import { fetchModLogs } from 'store/modules/administration/modLogs/modLogsSlice'
import Modal from 'components/Modal'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'

export default function Logs({ modId, property, days }) {
    const verifyModAdmin = useUserPermission(SMIPermissionsCode.MOD_ADMIN);

    const [modLogsHeaders, setModLogsHeaders] = useState([
        { title: "Data", key: "date", appear: true },
        { title: "Propriedade", key: "property", detailable: true, appear: true },
        { title: "Nome do Moderador", key: "modName", copy: "modId", appear: true },
    ])

    const [modLogsMomentHeaders, setModLogsMomentHeaders] = useState([...modLogsHeaders]);

    const handleAppearHeader = (item) => {
        setModLogsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setModLogsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoadingDetails, setLoadingDetails] = useState(false)
    const [detailsItem, setDetailsItem] = useState({
        newValue: '',
        oldValue: '',
        property: '',
    })

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const modLogs = useSelector((state) => {
        return Array.isArray(state.modLogs.data) ? state.modLogs.data : []
    })

    const skip = useSelector((state) => {
        return (state.modLogs.currentSkip) ? state.modLogs.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.modLogs.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.modLogs.filters) ? state.modLogs.filters : {}
    })

    console.log(filters)

    useEffect(() => {
        setLoading(false)
    }, [modLogs]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (modId || property || days) {
            setLoading(true)
            fetchModLogs(dispatch, { modId, property, days }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.modId) query.append('modId', filter.form.modId);
        if (filter.form.property) query.append('property', filter.form.property);
        if (filter.form.days) query.append('days', filter.form.days);
        window.history.pushState({}, '', `/administration/logs?${query.toString()}`);
        fetchModLogs(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchModLogs(dispatch, filter.form, skip + 200);
    })

    async function selectedDetail(item) {
        let detailsItemCopy = detailsItem
        detailsItemCopy = { newValue: item.newValue, oldValue: item.oldValue, property: item.property }
        setDetailsItem(detailsItemCopy)
        handleOpenModal()
    }
    return (
        <>
            {verifyModAdmin && <div>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                            <ModLogsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                        </div>
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={modLogsHeaders}
                        headers={modLogsMomentHeaders}
                        onDetailRow={selectedDetail}
                        title='Buscar Mod Logs'
                        isLoading={isLoading && modLogs.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={modLogs}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(modLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : modLogs.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
                <Modal
                    header={detailsItem.property}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isLoading={isLoadingDetails}
                >
                    <div className={styles.modalModLogContainer}>
                        <div className={styles.modalValue}>
                            <span className={styles.modalSubtitle}>Valor Antigo:</span>
                            <span className={styles.modalText}>{detailsItem.oldValue}</span>
                        </div>
                        <div className={styles.modalValue}>
                            <span className={styles.modalSubtitle}>Valor Novo:</span>
                            <span className={styles.modalText}>{(detailsItem.newValue) ?? "null"}</span>
                        </div>
                    </div>
                </Modal>
            </div>}
        </>
    )
}

