import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchIpsFilters } from "store/modules/users/ips/ipsSlice";

export default function IpsFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;
        fetchIpsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'idUser',
            label: 'ID do Usuário',
            value: defaultValues.idUser,
            placeholder: 'ID do Usuário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'ip',
            label: 'IP de Endereço',
            value: defaultValues.ip,
            placeholder: 'IP de Endereço',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        }
    ]

    return(
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit}/>
        </>
    )
}