import { createSlice } from '@reduxjs/toolkit'
import { getSelectedMissionDetails } from 'services/missionsService';

const selectedMissionDetailsSlice = createSlice({
    name: 'selectedMissionDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedMissionDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedMissionDetails(dispatch, params){
    try{
        const details = await getSelectedMissionDetails(params);
        console.log(details)
        dispatch(setSelectedMissionDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedMissionDetailsDetails } = selectedMissionDetailsSlice.actions
export default selectedMissionDetailsSlice.reducer