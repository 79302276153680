class PermissionCategory {
    constructor({ id, name, product, children }) {
        this.id = id
        this.name = name
        this.product = product
        this.children = children
    }

    get() {
        return {
            id: this.id,
            name: this.name,
            product: this.product,
            children: this.children
        }
    }
}

export default PermissionCategory