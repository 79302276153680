import { createSlice } from '@reduxjs/toolkit'
import { getUserSessions } from 'services/userService';

const userSessionsSlice = createSlice({
    name: 'userSessions',
    initialState: {
        data: 0,
    },
    reducers: {
        setUserSessionsData: (state, action) => {
            return { ...state, data: action.payload }
        },
    },
})

export async function fetchUserSessions(dispatch, userId){
    try{
        const userSessions = await getUserSessions(userId);
        dispatch(setUserSessionsData(userSessions));
    }
    catch(error){
        console.error('Erro ao obter Sessions', error)
    }
}



export const { setUserSessionsData } = userSessionsSlice.actions
export default userSessionsSlice.reducer