import AbuseReport from 'domains/Moderation/AbuseReport/AbuseReport';
import api, { apiNormalize } from './api'
import { abuseReportsMapping } from 'domains/Moderation/AbuseReport/mapping';
import handleRequestError from 'helpers/apiErrorHandler';

export async function getUnreadAbuseReportCount(){
    const response = await api.get("/abusereport/unread");
    return response.data;
}

export async function getAbuseReports(params, skip){
    const response = await api.get("/abuseReports", {params:{...params, skip}});
    const abuseReports = apiNormalize.responseMapping(response.data, abuseReportsMapping)
    const normalizedIAbuseReports = abuseReports.map(abuseReport => new AbuseReport(abuseReport).get());
    return normalizedIAbuseReports
}

export const assignAbuseReports = async (list) => {
    try {
        const response = await api.post(`/abuseReports/assignAbuseReports`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const closeAbuseReports = async (list) => {
    try {
        const response = await api.post(`/abuseReports/closeAbuseReports`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const reassignAbuseReports = async (list) => {
    try {
        const response = await api.post(`/abuseReports/reassignAbuseReports`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const reactivateClosedAbuseReports = async (list) => {
    try {
        const response = await api.post(`/abuseReports/reactivateClosedAbuseReports`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveProofList = async (list) => {
    try {
        const response = await api.post(`/abuseReports/saveProofList`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteChatLogProofList = async (abuseReportId, proofId) => {
    try {
        const response = await api.post(`/abuseReports/deleteChatLogProofList`, { abuseReportId, proofId })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deleteTransactionProofList = async (abuseReportId, proofId) => {
    try {
        const response = await api.post(`/abuseReports/deleteTransactionProofList`, { abuseReportId, proofId })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}