import Infraction from 'domains/Infraction/Infraction'
import api, { apiNormalize } from './api'
import { allInfractionsMapping, infractionMapping } from 'domains/Infraction/mappings'
import handleRequestError from 'helpers/apiErrorHandler'
import AllInfraction from 'domains/Infraction/AllInfraction'

export const getInfractions = async (params) => {
    try {
        const response = await api.get('/infractions', { params })
        console.log(response.data);
        const staff = apiNormalize.responseMapping(response.data, infractionMapping)

        const normalizedInfractions = staff.map(item => new Infraction(item).get())

        return normalizedInfractions
    } catch (error) {
        handleRequestError(error)
    }
}

export const getInfractionOptions = async () => {
    try {
        const response = await api.get('/infractions/getInfractionOptions')
        response.data.sort((a, b) => {
            if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                return -1;
            }
            if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return(response.data)
    } catch (error) {
        handleRequestError(error)
    }
}

export const getInfractionSteps = async () => {
    try {
        const response = await api.get('/infractions/getInfractionSteps')
        response.data.sort((a, b) => {
            if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                return -1;
            }
            if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return(response.data)
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveUserInfraction = async (idOffence, abuseReportId) => {
    try {
        const response = await api.post(`/infractions/saveUserInfraction`, { idOffence, abuseReportId })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const saveUserInfractionByUserId = async (idOffence, userId) => {
    try {
        const response = await api.post(`/infractions/saveUserInfractionByUserId`, { idOffence, userId })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getUserStep  = async (idOffence, abuseReportId) => {
    try {
        const response = await api.get(`/infractions/getUserStep`, {params:{idOffence, abuseReportId}})
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getUserStepByUserId  = async (idOffence, userId) => {
    try {
        const response = await api.get(`/infractions/getUserStepByUserId`, {params:{idOffence, userId}})
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getUserInfractionPoints  = async (abuseReportId) => {
    try {
        const response = await api.get(`/infractions/getUserInfractionPoints`, {params:{abuseReportId}})
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const getUserInfractionPointsByUserId  = async (userId) => {
    try {
        const response = await api.get(`/infractions/getUserInfractionPointsByUserId`, {params:{userId}})
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const reverseUserInfraction = async (list) => {
    try {
        const response = await api.post(`/infractions/reverseUserInfraction`, { list })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export async function getAllInfractions(params, skip){
    const response = await api.get("/infractions/getAllInfractions", {params:{...params,skip}});
    const allInfractions = apiNormalize.responseMapping(response.data, allInfractionsMapping)
    const normalizedModLogs = allInfractions.map(infraction => new AllInfraction(infraction).get());
    return normalizedModLogs
}

export const reverseInfraction = async (userInfractionId) => {
    try {
        const response = await api.post(`/infractions/reverseInfraction`, { userInfractionId })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

