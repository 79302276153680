import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useMainNavigation } from '../../../data/navigation'
import { useLocation } from 'react-router-dom';

function classNames (...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example () {
    const mainNavigation = useMainNavigation()

    const location = useLocation()
    const currentPath = location.pathname

    mainNavigation.forEach((item) => {
        if (item.to === currentPath) {
            item.current = true
        } else {
            item.current = false
        }

        if (item.children) {
            item.children.forEach((child) => {
                if (child.to === currentPath) {
                    item.current = true
                    child.current = true
                } else {
                    child.current = false
                }
            })
        }
    });

    return (
        <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" className="-mx-2 space-y-1">
                        { mainNavigation.map((item) => (
                            item.active !== false && (
                                <li key={ item.name }>
                                    { !item.children ? (
                                        <Link
                                            to={ item.to }
                                            className={ classNames(
                                                item.current ? 'bg-cyan-900' : 'hover:bg-cyan-900',
                                                'group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold text-cyan-200'
                                            ) }
                                        >
                                            <item.icon className="h-6 w-6 shrink-0 text-cyan-200" aria-hidden="true" />
                                            { item.name }
                                        </Link>
                                    ) : (
                                        <Disclosure as="div">
                                            { ({ open }) => (
                                                <>
                                                    <Disclosure.Button
                                                        as={ Link }
                                                        to={ item.to }
                                                        className={ classNames(
                                                            item.current ? 'bg-cyan-900' : 'hover:bg-cyan-900',
                                                            'flex items-center w-full text-left rounded-md p-2 gap-x-2 text-sm leading-6 font-semibold text-cyan-200'
                                                        ) }
                                                    >
                                                        <item.icon className="h-6 w-6 shrink-0 text-cyan-200" aria-hidden="true" />
                                                        { item.name }
                                                        <ChevronRightIcon
                                                            className={ classNames(
                                                                open ? 'rotate-90 text-cyan-200' : 'text-gray-100',
                                                                'ml-auto h-5 w-5 shrink-0'
                                                            ) }
                                                            aria-hidden="true"
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                        { item.children.map((subItem) => (
                                                            subItem.active !== false && (
                                                                <li key={ subItem.name }>
                                                                    { subItem.component ? (
                                                                        <subItem.component />
                                                                    ) : (
                                                                        <Link
                                                                            to={ subItem.to }
                                                                            className={ classNames(
                                                                                subItem.current ? 'bg-cyan-900' : 'hover:bg-cyan-900',
                                                                                'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200'
                                                                            ) }>
                                                                            { subItem.name }
                                                                        </Link>
                                                                    ) }
                                                                </li>
                                                            )
                                                        )) }
                                                    </Disclosure.Panel>
                                                </>
                                            ) }
                                        </Disclosure>
                                    ) }
                                </li>
                            )
                        )) }
                    </ul>
                </li>
            </ul>
        </nav>
    )
}
