class Infraction {
    constructor ({ id, horario, user, ofensa, punicao, moderador}) {
        this.id         = id
        this.horario    = horario
        this.user       = user
        this.ofensa     = ofensa
        this.punicao    = punicao
        this.moderador  = moderador
    }

    get () {
        return {
            id: this.id,
            horario: this.horario,
            user: this.user,
            ofensa: this.ofensa,
            punicao: this.punicao,
            moderador: this.moderador
        }
    }

    smallID () {
        return this.id?.slice(0, 7)
    }
}

export default Infraction
