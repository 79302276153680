const interstitialGroupMapping = (item) => ({
    groupId: item._id ?? null,
    name: item.name ?? null,
    active: item.active ?? null,
    token: item.token ?? null,
    tokenAge: item.tokenAge ?? null
})

const interstitialMapping = (item) => ({
    interstitialId: item.interstitialId ?? null,
    name: item.name ?? null,
    active: item.active ?? null,
    groupId: item.groupId ?? null,
    imgUrl: item.imgUrl ?? null,
    redirectUrl: item.redirectUrl ?? null,
    newWindow: item.newWindow ?? null,
    startDate: item.startDate ?? null,
    endDate: item.endDate ?? null,
    token: item.token ?? null,
    tokenAge: item.tokenAge ?? null,
})

const interstitialDetailMapping = (item) => ({
    interstitialId: item.interstitialId ?? null,
    name: item.name ?? null,
    active: item.active ? 'Sim' : 'Não',
    groupId: item.groupId ?? null,
    imgUrl: item.imgUrl ?? null,
    redirectUrl: item.redirectUrl ?? '-',
    newWindow: item.newWindow ? 'Sim' : 'Não',
    startDate: item.startDate ?? null,
    endDate: item.endDate ?? null,
    token: item.token ?? null,
    tokenAge: item.tokenAge ?? null,
    groupName: item.groupName ?? null
})

const interstitialGroupDetailMapping = (item) => ({
    groupId: item._id ?? null,
    name: item.name ?? null,
    active: item.active ? 'Sim' : 'Não',
    token: item.token ?? null,
    tokenAge: item.tokenAge ?? null
})

export {
    interstitialGroupMapping,
    interstitialMapping,
    interstitialDetailMapping,
    interstitialGroupDetailMapping
}