import { getSpinCategories } from "services/spinService";

const { createSlice } = require("@reduxjs/toolkit");

const SpincategoriesSlice = createSlice({
    name:"Spincategories",
    initialState: {
        data: [],
        isDeluxe: false,
        tab: 1
    },
    reducers: {
        setSpinCategoriesData: (state, action) => {
            return {...state, data: action.payload}
        },
        setIsDeluxe: (state, action) => {
            return {...state, isDeluxe: action.payload}
        },
        setTab: (state, action) => {
            return {...state, tab: action.payload}
        },
    },
})

export async function fetchSpinCategories(dispatch, status, id){
    try{
        const spinCategories = await getSpinCategories(status);
        dispatch(setSpinCategoriesData(spinCategories));
        dispatch(setIsDeluxe(status));
        dispatch(setTab(id));
    }
    catch(error){
        console.error('Erro ao obter Categorias', error)
    }
}
export const { setIsDeluxe, setSpinCategoriesData, setTab } = SpincategoriesSlice.actions
export default SpincategoriesSlice.reducer