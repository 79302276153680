import { getUserSession } from 'services/auth'
import { setLoggedUser } from './loggedUserSlice'

export const fetchLoggedUser = () => async (dispatch) => {
    try {
        const user = await getUserSession()
        dispatch(setLoggedUser(user))
    } catch (error) {
        console.error('Erro ao obter dados do usuário logado:', error)
    }
}
