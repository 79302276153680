import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { PiSpinnerGapBold } from "react-icons/pi";

/**
 * A reusable button component that can be customized with various props.
 * @param {Object} props - The props object containing the button's properties.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.title - The title attribute for the button.
 * @param {string} props.link - The URL to link to when the button is clicked.
 * @param {function} props.onClick - The function to execute when the button is clicked.
 * @param {string} props.color - The color of the button.
 * @param {JSX.Element} props.icon - The icon to display on the button.
 * @param {string} props.className - The CSS class name(s) to apply to the button.
 * @param {string} props.type - The type of button (e.g. "submit").
 * @param {boolean} props.onlyIcon - Whether the button should only display the icon.
 * @param {boolean} props.isLoading - Whether the button is in a loading state.
 * @returns {JSX.Element} - The rendered button component.
 */
const Button = ({ text, title, link, onClick, color, colorTone, icon, className, type, onlyIcon, isLoading, disabled }) => {

    const buttonOnlyIcon = `block rounded-md px-1 py-1 text-center text-sm font-semibold text-${ color} shadow-sm hover:bg-${ color }-900 hover:bg-opacity-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${ color }-${ colorTone ?? 800 }`
    const buttonClasses = onlyIcon ? buttonOnlyIcon : `flex justify-beetwen rounded-md bg-${ color }-${ colorTone ?? 800 } px-2 py-1 text-center text-sm font-semibold text-white shadow-sm hover:bg-${ color }-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${ color }-${ colorTone ?? 800 } disabled:opacity-50`

    const classes = twMerge(`${ buttonClasses } ${ className ?? '' }`)

    if (link) {
        // Se tiver um link, renderiza um link externo ou um link de rota dependendo do formato do link
        const isExternalLink = link.startsWith('http')
        if (isExternalLink) {
            return (
                <a href={ link } target="_blank" rel="noopener noreferrer" className={ classes }>
                    { icon }
                    { text }
                </a>
            )
        } else {
            return (
                <Link to={ link } className={ classes }>
                    { icon }
                    { text }
                </Link>
            )
        }
    } else if (onClick) {
        // Se tiver uma função onClick, renderiza um botão com a função de execução
        return (
            <button className={ classes } onClick={ onClick } title={ title } disabled={ isLoading || disabled }>
                { isLoading && <PiSpinnerGapBold className='w-4 h-4 mr-1 text-white animate-spin' /> }
                { icon }
                { text }
            </button>
        )
    } else if (type === 'submit') {
        // Se não tiver link nem onClick, renderiza um botão desabilitado
        return (
            <button className={ `${ classes }` } >
                { icon }
                { text }
            </button>
        )
    } else {
        // Se não tiver link nem onClick, renderiza um botão desabilitado
        return (
            <button className={ `${ classes } opacity-50 cursor-not-allowed` } disabled>
                { icon }
                { text }
            </button>
        )
    }
}

export default Button
