class SpaceDetail {
    constructor({ spaceId, spaceName, desc, accessControl, showInPlacePanel, instanciable, maxVisitors, password, spaceSnapShotSource, spaceThumbnailSource, type, forSale, salePriceTokens, salePriceGold, avatarId, avatarFirstName, avatarLastName, modelId, modelName, avatarNameInstance }) {
        this.spaceId = spaceId
        this.spaceName = spaceName
        this.desc = desc
        this.accessControl = accessControl
        this.showInPlacePanel = showInPlacePanel
        this.instanciable = instanciable
        this.maxVisitors = maxVisitors
        this.password = password
        this.spaceSnapShotSource = spaceSnapShotSource
        this.spaceThumbnailSource = spaceThumbnailSource
        this.type = type
        this.forSale = forSale
        this.salePriceTokens = salePriceTokens
        this.salePriceGold = salePriceGold
        this.avatarId = avatarId
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.modelId = modelId
        this.modelName = modelName
        this.avatarNameInstance = avatarNameInstance
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'spaceId',
                    name: "ID do Espaço",
                    value: this.spaceId,
                },
                {
                    key: 'spaceName',
                    name: "Nome do Espaço",
                    value: this.spaceName,
                },
                {
                    key: 'desc',
                    name: "Descrição",
                    value: this.desc,
                },
                {
                    key: 'avatarId',
                    name: "ID do Avatar",
                    value: this.avatarId,
                },
                {
                    key: 'avatarName',
                    name: "Nome do Avatar",
                    value: this.avatarFullName,
                },
                {
                    key: 'modelId',
                    name: "ID do Modelo",
                    value: this.modelId,
                },
                {
                    key: 'modelName',
                    name: "Nome do Modelo",
                    value: this.modelName,
                },
                {
                    key: 'accessControlName',
                    name: "Controle de Acesso",
                    value: this.accessControlName,
                },
                {
                    key: 'showInPlacePanel',
                    name: "Mostrar em Painel de Populares",
                    value: this.showInPlacePanel,
                },
                {
                    key: 'instanciable',
                    name: "Instanciável",
                    value: this.instanciable,
                },
                {
                    key: 'maxVisitors',
                    name: "Máximo de Visitantes",
                    value: this.maxVisitors,
                },
                {
                    key: 'password',
                    name: "Senha",
                    value: this.password,
                },
                {
                    key: 'spaceSnapShotSource',
                    name: "Fonte de SnapShot do Espaço",
                    value: this.spaceSnapShotSource,
                },
                {
                    key: 'spaceThumbnailSource',
                    name: "Fonte da Thumbnail do Espaço",
                    value: this.spaceThumbnailSource,
                },
                {
                    key: 'typeName',
                    name: "Tipo",
                    value: this.typeName,
                },
                {
                    key: 'forSaleName',
                    name: "A venda",
                    value: this.forSaleName,
                },
                {
                    key: 'salePriceTokens',
                    name: "Custo em Fichas",
                    value: this.salePriceTokens,
                },
                {
                    key: 'salePriceGold',
                    name: "Custo em Moedas",
                    value: this.salePriceGold,
                },
            ],
            spaceId: this.spaceId,
            spaceName: this.spaceName,
            desc: this.desc,
            accessControl: this.accessControlName,
            showInPlacePanel: this.showInPlacePanel,
            instanciable: this.instanciable,
            maxVisitors: this.maxVisitors,
            password: this.password,
            spaceSnapShotSource: this.spaceSnapShotSource,
            spaceThumbnailSource: this.spaceThumbnailSource,
            type: this.typeName,
            forSale: this.forSaleName,
            salePriceTokens: this.salePriceTokens,
            salePriceGold: this.salePriceGold,
            avatarId: this.avatarId,
            modelId: this.modelId,
            modelName: this.modelName,
            avatarName: this.avatarFullName,

        }
    }

    get avatarFullName() {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (this.avatarNameInstance > 1) return this.avatarFirstName + " " + this.avatarLastName + " the " + ordinal(this.avatarNameInstance);
        else if (this.avatarFirstName === null || this.avatarLastName === null) return "Avatar Apagado"
        else return this.avatarFirstName + " " + this.avatarLastName;
    }

    get accessControlName() {
        const name = {
            'O': 'Officer',
            'C': 'Collaborator',
            'M': 'Member',
            'A': 'Anyone',
        }

        return name[this.accessControl]
    }

    get forSaleName() {
        const name = {
            'N': 'Não a venda',
            'I': 'Instance Sale',
            'M': 'Model Sale',
        }

        return name[this.forSale]
    }

    get typeName() {
        const name = {
            'A': 'Avatar'
        }

        return name[this.type]
    }
}

export default SpaceDetail