import Users from "Pages/Users";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchUsersFilters } from "store/modules/users/userActions";

export function UsersUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    const avatarFirstName = searchParams.get("avatarFirstName");
    const avatarLastName = searchParams.get("avatarLastName");
    const avatarId = searchParams.get("avatarId");
    const avatarExact = searchParams.get("avatarExact");
    const avatarOnline = searchParams.get("avatarOnline");

    const userId = searchParams.get("userId");
    const userFirstName = searchParams.get("userFirstName");
    const userLastName = searchParams.get("userLastName");
    const userEmail = searchParams.get("userEmail");
    const userExact = searchParams.get("userExact");

    const ip = searchParams.get("ip");
    const ipOnline = searchParams.get("ipOnline");


    const dispatch = useDispatch()

    if (tab == 1) {
        if (avatarFirstName || avatarLastName || avatarId || avatarExact || avatarOnline) {
            fetchUsersFilters(dispatch, {avatarFirstName, avatarLastName, avatarId, avatarExact, avatarOnline})
            return <Users tab={1} avatarFirstName={avatarFirstName ?? null} avatarLastName={avatarLastName ?? null} avatarId={avatarId ?? null} avatarExact={avatarExact ?? null} avatarOnline={avatarOnline ?? null}/>;
        }
        else{
            return <Users tab={1}/>;
        }
    }
    else if(tab == 2){
        if (userId || userFirstName || userLastName || userEmail || userExact) {
            fetchUsersFilters(dispatch, {userId, userFirstName, userLastName, userEmail, userExact})
            return <Users tab={2} userId={userId ?? null} userFirstName={userFirstName ?? null} userLastName={userLastName ?? null} userEmail={userEmail ?? null} userExact={userExact ?? null}/>;
        }
        else{
            return <Users tab={2}/>;
        }
    }
    else if(tab == 3){
        if (ip || ipOnline) {
            fetchUsersFilters(dispatch, {ip, ipOnline})
            return <Users tab={3} ip={ip ?? null} ipOnline={ipOnline ?? null}/>;
        }
        else{
            return <Users tab={3}/>;
        }
    }
    else{
        return <Users/>;
    }

}