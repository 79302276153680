import { getFriends } from "services/friendsService";

const { createSlice } = require("@reduxjs/toolkit");

const friendsSlice = createSlice({
    name:"friends",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            senderAvatarId: '',
            receiverAvatarId: '',
            isAccepted: ''
        }
    },
    reducers: {
        setFriendsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addFriendsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setFriendsFilter: (state, action) => {
            return {...state, filters: action.payload}
        },
    },
})

export async function fetchFriends(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const friends = await getFriends(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addFriendsData(friends))
        }
        else{
            dispatch(setFriendsData(friends));
        }
        
        if(friends.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Friends', error);
        dispatch(setCurrentSkip(0));
        dispatch(setFriendsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchFriendsFilters(dispatch, params){
    dispatch(setFriendsFilter(params));
}

export const { setFriendsData, setCurrentSkip, setHasMore, addFriendsData, setFriendsFilter } = friendsSlice.actions
export default friendsSlice.reducer