import Button from 'components/form/Button'
import TableCell from './cell'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'

export default function TableRow({
    headers = [],
    item,
    cellWidth,
    onSelectRow,
    onSelectItem,
    onDetailRow,
    children = null,
    isSelectable,
    isSelected,
    hasButton,
    onClickBtnItem,
    onClickBtnItemInfo,
    isActive,
    index,
    onDragEndChild
}) {

    const handleSelectRow = (item, cell) => {
        onSelectRow(item, cell)
    }

    const handleDetailRow = (item) => {
        onDetailRow(item)
    }

    const handleSelectItem = () => {
        onSelectItem(item)
    }

    const handleBtnItem = () => {
        onClickBtnItem(item)
    }

    const handleOnDragEnd = (result) => {
        onDragEndChild(result, item)
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#f5f2f2" : "#f5f2f2",
        width: isDraggingOver ? '40vw' : '40vw',
        display: isDraggingOver ? 'block' : 'block',
    });


    return (
        <Draggable draggableId={String(index)} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <div  {...provided.dragHandleProps}>


                        {onSelectItem &&
                            <tr className='flex w-full'>
                                {
                                    isSelectable && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`} onClick={handleSelectItem}>
                                            <input
                                                type="checkbox"
                                                onChange={handleSelectItem}
                                                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                                value={isSelected}
                                                checked={Boolean(isSelected)}
                                            />


                                        </td>
                                    )}
                                {
                                    hasButton && !isActive && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                            <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                                        </td>
                                    )}
                                {
                                    hasButton && isActive && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                            <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                                        </td>
                                    )}
                                {
                                    headers.map((cell, key) => (
                                        cell.key ?
                                            <TableCell
                                                key={key}
                                                chain={key}
                                                cell={cell}
                                                item={item}
                                                handleSelectRow={handleSelectRow}
                                                handleDetailRow={handleDetailRow}
                                                cellWidth={cellWidth}
                                            />

                                            : Object.entries(item).map((cell, key) => (
                                                <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                                    {cell[1]}
                                                </td>
                                            ))
                                    ))
                                }
                            </tr>}
                        {!onSelectItem &&
                            <tr className='flex w-full'>
                                {
                                    isSelectable && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 w-1/12 flex items-center`}>
                                            <input
                                                type="checkbox"
                                                onChange={handleSelectItem}
                                                className="form-checkbox h-4 w-4 text-cyan-700 rounded"
                                                value={isSelected}
                                                checked={Boolean(isSelected)}
                                            />


                                        </td>
                                    )}
                                {
                                    hasButton && !isActive && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                            <Button disabled={false} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                                        </td>
                                    )}
                                {
                                    hasButton && isActive && (
                                        <td key={100} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth} flex items-center`}>
                                            <Button disabled={(isActive == 'Não')} text={onClickBtnItemInfo} onClick={handleBtnItem} color="cyan" />
                                        </td>
                                    )}
                                {
                                    headers.map((cell, key) => (
                                        cell.key ?
                                            <TableCell
                                                key={key}
                                                chain={key}
                                                cell={cell}
                                                item={item}
                                                handleSelectRow={handleSelectRow}
                                                handleDetailRow={handleDetailRow}
                                                cellWidth={cellWidth}
                                            />

                                            : Object.entries(item).map((cell, key) => (
                                                <td key={key} className={`whitespace-nowrap py-1 text-sm text-gray-900 px-2 ${cellWidth}`}>
                                                    {cell[1]}
                                                </td>
                                            ))
                                    ))
                                }
                            </tr>}
                    </div>
                    <div style={{width: '40vw'}}
                    >
                        {/*
                        typeof children === 'function' && item[children().childrenKey].length > 0 && (
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId='childItems' type='list' direction='vertical'>
                                    {(provided, snapshot) => (
                                        <tr //className='flex w-full'
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={getListStyle(snapshot.isDraggingOver)}

                                        >
                                            <td style={{width: '40vw'}}
                                            >
                                                {
                                                    item[children().childrenKey].map((child, key) => (
                                                        <Draggable draggableId={String(key)} index={key}>
                                                            {(provided) => (
                                                                <tr className='flex w-full border-b'
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {
                                                                        children().headers?.map((cell, key) => (
                                                                            <TableCell
                                                                                key={key}
                                                                                cell={cell}
                                                                                item={child}
                                                                                handleSelectRow={handleSelectRow}
                                                                                handleDetailRow={handleDetailRow}
                                                                                cellWidth={cellWidth}
                                                                            />
                                                                        ))
                                                                    }
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                }
                                            </td>
                                            {provided.placeholder}
                                        </tr>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )
                            */}
                        {children && item[children().childrenKey]?.length > 0 && (
                            <div style={{ marginLeft: '0px' }}>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId='childItems' type='list' direction='vertical'>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                                {item[children().childrenKey].map((child, childIndex) => (
                                                    <TableRow
                                                        key={childIndex}
                                                        item={child}
                                                        headers={children().headers}
                                                        cellWidth={cellWidth}
                                                        onSelectRow={onSelectRow}
                                                        onSelectItem={onSelectItem}
                                                        onDetailRow={onDetailRow}
                                                        isSelectable={isSelectable}
                                                        hasButton={hasButton}
                                                        onClickBtnItem={onClickBtnItem}
                                                        onClickBtnItemInfo={onClickBtnItemInfo}
                                                        isActive={isActive}
                                                        index={childIndex}
                                                        onDragEndChild={onDragEndChild}
                                                        children={children}
                                                    />
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        )}
                    </div>

                </div>
            )}
        </Draggable>
    )
}
