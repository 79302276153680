import { faker } from '@faker-js/faker'

const staffData = () => {
    const items = []

    for (let i = 0; i < 25; i++) {
        const item = {
            group: faker.lorem.word(10),
            user: faker.internet.userName(),
            avatar: faker.lorem.word(10),
            space: faker.lorem.word(10),
        }

        items.push(item)
    }

    return items
}

const staff = {
    headers: [
        { title: 'Grupo' },
        { title: 'Usuário' },
        { title: 'Avatar' },
        { title: 'Space' },
    ],
    items: staffData()
}

export default staff
