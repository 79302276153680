import { createSlice } from '@reduxjs/toolkit'
import { getNewAbuseReportStats, getNewInfractionStats, getNewTransactionStats, getNewUserStats } from 'services/statsService';

const statsSlice = createSlice({
    name: 'stats',
    initialState: {
        newUsers: [0, 0],
        newInfractions: [0, 0],
        newAbuseReports: [0, 0],
        newTransactions: [
            {
                newGoldSum: 0,
                newTokenSum: 0
            },
            {
                lastMonthNewGoldSum: 0,
                lastMonthNewTokenSum: 0

            }
        ]
    },
    reducers: {
        setStats: (state, action) => {
            return {...state, ...action.payload};
        },
    },
})

export async function fetchStats(dispatch, days){
    try{
        const newUsers = await getNewUserStats(days);
        const newInfractions = await getNewInfractionStats(days);
        const newAbuseReports = await getNewAbuseReportStats(days);
        const newTransactions = await getNewTransactionStats(days);
        dispatch(setStats({
            newUsers, 
            newInfractions, 
            newAbuseReports, 
            newTransactions: [
                {
                    newGoldSum: newTransactions[0].goldSum,
                    newTokenSum: newTransactions[0].tokenSum
                },
                {
                    lastMonthNewGoldSum: newTransactions[1].goldSum,
                    lastMonthNewTokenSum: newTransactions[1].tokenSum,
                }
            ]
        }));

    }
    catch(error){
        console.error('Erro ao obter objeto stats:', error)
    }
}

export const { setStats } = statsSlice.actions
export default statsSlice.reducer