import { createSlice } from '@reduxjs/toolkit'
import { getCategory } from 'services/categoryService';

const selectedSpaceCategorySlice = createSlice({
    name: 'selectedSpaceCategory',
    initialState: {},
    reducers: {
        setSelectedSpaceCategory: (state, action) => {
            let category;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                category = action.payload[0]
            } else {
                category = {}
            }            

            return category
        },
    },
})

export const selectSpaceCategoryForDetails = (params) => async (dispatch) => {
    try {
        const selectedSpaceCategory = await getCategory(params)
        dispatch(setSelectedSpaceCategory(selectedSpaceCategory))
    } catch (error) {
        console.error('Erro ao carregar categoria:', error)
    }
}

export const { setSelectedSpaceCategory } = selectedSpaceCategorySlice.actions
export default selectedSpaceCategorySlice.reducer
