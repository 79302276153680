class User {
    constructor ({ id, firstName, lastName, user, email, avatar, gold, tokens, cp, kudos,  avatarFirstName, avatarLastName, avatarNameInstance, avatarId }) {
        this.id = id
        this.firstName = firstName
        this.lastName = lastName
        this.user = user
        this.email = email
        this.avatar = avatar
        this.gold = gold
        this.tokens = tokens
        this.cp = cp
        this.kudos = kudos
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarId = avatarId
    }

    get () {
        return {
            id: this.id,
            small_id: this.smallID(),
            name: this.firstName + " " + this.lastName,
            user: this.user,
            email: this.email,
            avatar: this.avatarFullName,
            avatarId: this.avatarId,
            gold: this.gold,
            tokens: this.tokens,
            cp: this.cp,
            kudos: this.kudos,

        }
    }

    smallID () {
        return this.id?.slice(0, 7)
    }

    get avatarFullName() {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (this.avatarNameInstance > 1) return this.avatarFirstName + " " + this.avatarLastName + " the " + ordinal(this.avatarNameInstance);
        else if(this.avatarFirstName===null || this.avatarLastName === null) return "Avatar Apagado"
        else return this.avatarFirstName + " " + this.avatarLastName;
    }
}

export default User
